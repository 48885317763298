import React, { useState } from 'react';
import { useClickOutside } from '../../hooks';
import A from './A'

function AreYouSure({ children, className, callback, disabled }) {
    let [clicked, setClicked] = useState(false);
    let [ref, onClickOutside] = useClickOutside();

    onClickOutside(() => {
        setClicked(false);
    })

    function submit() {
        callback();
        setClicked(false);
    }

    if (disabled) {
        return <div className={className ? 'AreYouSure disabledA ' + className : 'AreYouSure disabledA'}>{children}</div>
    }

    return <div ref={ref} className={className ? 'AreYouSure ' + className : 'AreYouSure'}>
        {!clicked ? <A onClick={e => {
            e.stopPropagation()
            setClicked(true)
        }}>{children}</A> : <>
            <div>
                Sure?
            </div>
            <div>
                <A onClick={e=>{
                    e.stopPropagation()
                    submit()
                }} className="small">✔</A>
            </div>
            <div>
                <A onClick={e => {
                    e.stopPropagation()
                    setClicked(false)
                }} className="small">✘</A>
            </div>
        </>}
        </div>
}

export default AreYouSure;