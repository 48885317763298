import React, { useRef, useState } from 'react';
import { useMount } from '../../hooks';


export default function BarGraph({ width=300, height=200, data }) {
    let [selected, setSelected] = useState(-1);
    let max = useRef(Math.max(...data.map(d => d.value)));

    return <div className="BarGraph">
        <div className="graph" onMouseLeave={()=>setSelected(-1)} style={{width, height}}>
            {data.map((d, i) => <div
                key={i}
                className={d.value == 0 ? "zero bar" : "bar"}
                onMouseEnter={() => setSelected(i)}
            >
                <div className="blank" />
                <div className={selected==i?"black inner":"inner"} style={{ height: (d.value / max.current) * height }}/>
            </div>)}
        </div>
        {selected >= 0 ? <>
            <h2>{data[selected].title}: {data[selected].value}</h2>
        </> : null}
    </div>
}