import React, { Component } from 'react';
import { NavLink } from "react-router-dom";

import B from "./B";
import Thumb from './Thumb';

class Thumbnails extends Component {
    constructor(props) {
        super(props);
        let { items, k } = props;
        this.state = {
            items, h: 0, hh:0, k, expand: false, multi:false
        }
        this.resize = this.resize.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.resize, true);
        this.resize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }

    resize() {
        let { k, expand, items } = this.state;
        if (!expand && items.length>0) {
            let d = document.getElementById(k + '_0');
            if (d) {
                let h = d.scrollHeight *1.25;
                let p = d.getBoundingClientRect().top;
                let multi = false;
                for (let i = 1; i < items.length; i++){
                    if (document.getElementById(k + '_' + i).getBoundingClientRect().top != p) {
                        multi = true;
                        break;
                    }
                }
                let hh = document.getElementById(k + '_' + (items.length-1)).getBoundingClientRect().top + h
                this.setState({ h, hh, multi });
            } else {
                setTimeout(this.resize, 1);
            }
        }
    }

    render() {
        let { items,k,h,hh,expand,multi } = this.state;
        return <div>
            <div className="items" id={k} style={{
                maxHeight: multi ? expand ? hh : h : hh,
                marginBottom: !expand && multi ? 0 : null
            }}>
                <div className="flex">
                    {items.map((item, i) =>
                        <Thumb
                            key={'thumb ' + item.title}
                            id={k+'_'+i}
                            className="item"
                            style={{
                                backgroundImage:'url(/api/pictures/' + encodeURIComponent(item.path) +')'
                            }}
                        >
                            <NavLink to={item.link}><div className="tool">
                                {item.title}
                                <h3>{item.bigText}</h3>
                            </div></NavLink>
                        </Thumb>
                    )}
                </div>
            </div>
            <div className="expandItems" style={{display:!expand&&multi?null:'none'}}>
                <B
                    title='See more...'
                    callback={()=>this.setState({expand:true})}
                />
            </div>
            <br/>
        </div>
    }
}

export default Thumbnails;