import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import Banner from './Banner';

import axios from 'axios';
import { useNavigate } from 'react-router';
import { AdminContext } from '../../hooks';

import imgKai from '../../pics/kai.png';
import A from '../admin2/A';

let noGifs = 9;
let gifs = [];
for (let i = 0; i < noGifs; i++) {
    gifs.push(i);
}

function shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
}

gifs = shuffle(gifs);

let PlayingContext = createContext();

let svgPlay = <svg viewBox="0 0 24 24">
    <polygon points="6,12 6,4.7 12.1,8.3 18.4,12 12.2,15.7 6,19.3 " />
</svg>;

let svgPause = <svg viewBox="0 0 24 24" >
    <rect x="5" y="5" width="5" height="14"/>
    <rect x="14" y="5" width="5" height="14"/>
</svg>

let svgStop = <svg viewBox="0 0 24 24" >
    <rect x="6" y="6" width="12" height="12"/>
</svg>

let svgDownload = <svg viewBox="0 0 24 24" >
<polygon points="16,12 16,4 8,4 8,12 3.9,12 12,20.8 20.1,12 "/>
</svg>

export default function Music() {
    let [playing, setPlaying] = useState();

    let navigate = useNavigate();
    let { admin, setBackgroundColour } = useContext(AdminContext);
    useEffect(() => {
        // if (localStorage.getItem('m') != 'FUNK') navigate('/')
        
        setBackgroundColour("Orange");
        return () => setBackgroundColour(false);
    }, [])


    let [albums, setAlbums] = useState();

    useEffect(() => {
        (async () => {
            let albums = (await axios.post('/api/music')).data;
            setAlbums(albums);
        })()
    }, [])

    if (!albums) return null;

    return <PlayingContext.Provider value={[playing, setPlaying]}>
        <div className="Music">
            <div className="musicHeading">
                <img src={imgKai} width={55}/>
                <p>Bangin beats brought to you</p>
                <p>over the power of the internet!</p>
            </div>
            <div className="Albums">
                {albums.map((album, i) => <Album album={album} key={album._id} i={i} />)}
            </div>
            {playing ? <Player src={playing.path} /> : null}
            <div style={{ height: "6rem"}} />
        </div>
    </PlayingContext.Provider>
}

function Album({ album, i }) {
    return <div className="Album">
        <div className="gif"><img alt="gif" src={`/api/pictures/gif${gifs[i%gifs.length]}.gif` } /></div>
        <h1>{album.title}</h1>
        <p>{album.body}</p>
        <div className="albumSplit">
            <div className="left">
                <img alt="cover" src={'/api/pictures/' + album.cover} />
            </div>
            <div className="right">
                {album.songs.map((song, i) => <Song key={i} song={song} />)}
            </div>
        </div>
    </div>
}

function Song({ song }) {
    let [playing, setPlaying] = useContext(PlayingContext);
    let mePlaying = playing && playing.title == song.title;
    return <button className="song" onClick={()=>setPlaying(song)}>{mePlaying?svgPlay:null}{song.title}</button>
}

function Player({ src }) {
    let ref = useRef();
    let bar = useRef();
    let [playing, setPlaying] = useContext(PlayingContext);

    let [play, setPlay] = useState(false);
    let [time, setTime] = useState(0);

    let [length, setLength] = useState(0);

    let [dragging, setDragging] = useState(false);

    useEffect(() => {
        if (playing) {
            setTimeout(()=>setPlay(true),1);
        }
        return () => setPlay(false);
    }, [playing])

    useEffect(() => {
        if (ref.current) {
            let interval;
            if (play) {
                ref.current.play();
                let checkTime = () => {
                    if (ref.current) {
                        setLength(ref.current.duration);
                        ref.current.volume = 1;
                        if (ref.current.ended) {
                            setPlay(false);
                            setTime(0);
                            ref.current.currentTime = 0;
                        } else {
                            setTime(ref.current.currentTime);
                        }
                    }
                }
                interval = setInterval(checkTime, 1000 / 30);
                return () => clearInterval(interval);
            } else {
                ref.current.pause();
            }
        }
    }, [play])

    function drag(X) {
        let e = window.event;

        let posX = X?X:e.clientX;
        if (!posX) posX = e.touches[0];
        let rect = bar.current.getBoundingClientRect();
        let barX = rect.left;
        let x = posX - barX;
        x -= 7;
        x = x < 0 ? 0 : x > rect.width ? rect.width : x;
        let percent = x / rect.width;
        if (!isNaN(percent)) {
            let time = percent * ref.current.duration;
            ref.current.currentTime = time;
            setTime(time);
        }
    }
    
    useEffect(() => {
        if (dragging) {
            let mouseup = () => {
                setDragging(false);
            }
            let mousemove = e => {
                let x = e.clientX
                if (!x) x = e.touches[0];
                if (x.clientX) x = x.clientX;
                drag(x);
            }
            drag();
            window.addEventListener('mouseup', mouseup);
            window.addEventListener('mousemove', mousemove);
            window.addEventListener('touchend', mouseup);
            window.addEventListener('touchmove', mousemove);
            return () => {
                window.removeEventListener('mouseup', mouseup);
                window.removeEventListener('mousemove', mousemove);
                window.removeEventListener('touchend', mouseup);
                window.removeEventListener('touchmove', mousemove);
            }
        }
    },[dragging])
    
    let path = '/api/pictures/' + src;

    return <div className="musicPlayer">
        <div className="controls">
            <div className="playButton">
                {play ? <button onClick={()=>setPlay(false)}>
                    {svgPause}
                </button> : <button onClick={()=>setPlay(true)}>
                    {svgPlay}
                </button>}
            </div>
            <div className="bar" onMouseDown={()=>setDragging(true)} onTouchStart={()=>setDragging(true)} ref={bar}>
                <div className="line" />
                <div className="dot" style={{ left: `${(time /length)*100}%`}} />
            </div>
            <div className="barExtra" onMouseDown={() => setDragging(true)} onTouchStart={() => setDragging(true)} />
            <div className="downloadButton">
                <a href={path} download onClick={()=>console.log(path)}>
                    {svgDownload}
                </a>
            </div>
        </div>
        <audio className="HIDDEN" src={path} ref={ref}>
            <a href={path}>Download music</a>
        </audio>
    </div>
}