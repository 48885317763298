import axios from 'axios';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import { useHistory, useNavigate } from 'react-router-dom';
import { useGetState } from '../../hooks';

let font = {
    K:<svg viewBox="-513 585.3 37.9 81.7"><path d="M-497.6,624h21.4l1-1v-7.9l0.1-0.1v-21.5l-1-1h-14.3l-0.7,0.3l-12.7,12.6v-3.9v-8l-1-1h-7.2l-1,1v8V658v8l1,1h7.2l1-1v-8v-3.9l12.7,12.6l0.7,0.3h14.3l1-1v-8v-13.5v-8l-1-1h-21.5c-3.3,0-5.9-2.5-6.2-5.7C-503.5,626.6-500.8,624-497.6,624z"/><path className="st0" d="M-490,657h12.9v-19.5h-20.5c-4.6,0-8.2-3.6-8.2-8.2v-7.2c0-4.5,3.7-8.2,8.2-8.2h20.5v-19.4H-490l-14,14l-1.7-0.7v-13.3h-5.2V657h5.2v-13.4l1.7-0.7L-490,657z"/></svg>,
    A: <svg viewBox="-470 585.3 37.9 81.7"><path d="M-440.3,592.4h-21.5c-4.5,0-8.2,3.7-8.2,8.2v8V658v8l1,1h7.2l1-1v-8v-19.7h19.5V658v8l1,1h7.2l1-1.1v-8v-49.3v-8C-432.1,596.1-435.9,592.4-440.3,592.4z" /><path className="st0" d="M-440.3,594.4h-21.5c-3.3,0-6.2,2.8-6.2,6.2v56.3h5.2v-27.6l1-1h21.5l1,1V657h5.2v-56.4C-434.1,597.3-437,594.4-440.3,594.4z M-440.3,623.1h-21.5l-1-1c0-4.5,3.7-8.2,8.2-8.2h7.2c4.4,0,8.2,3.8,8.2,8.2L-440.3,623.1z" /></svg>,
    I: <svg viewBox="-426.9 585.3 23.5 81.7"><polygon points="-404.4,592.5 -425.9,592.5 -426.9,593.5 -426.9,601.5 -426.9,615 -426.9,623 -425.9,624 -419.7,624 -419.7,635.5 -425.9,635.5 -426.9,636.5 -426.9,644.5 -426.9,658 -426.9,666 -425.9,667 -404.4,667 -403.4,666 -403.4,658 -403.4,644.5 -403.4,636.5 -404.4,635.5 -410.6,635.5 -410.6,624 -404.4,624 -403.4,623 -403.4,615 -403.4,601.5 -403.4,593.5 " /><polygon className="st0" points="-424.9,657 -405.4,657 -405.4,637.5 -411.6,637.5 -412.6,636.5 -412.6,615 -411.6,614 -405.4,614 -405.4,594.5 -424.9,594.5 -424.9,614 -418.7,614 -417.7,615 -417.7,636.5 -418.7,637.5 -424.9,637.5 " /></svg>,
    "'": <svg viewBox="-398.3 585.3 9.2 81.7"><path d="M-390.1,585.3h-7.2l-1,1v8v13.5v8l1,1c4.5,0,8.2-3.7,8.2-8.2v-8v-6.3v-8L-390.1,585.3z" /><path className="st0" d="M-396.3,587.3v19.4c2.9-0.5,5.2-3,5.2-6.1v-13.3H-396.3z" /></svg>,
    S: <svg viewBox="-383.9 585.3 38 81.7"><path d="M-348,623.9h1l1-1v-8v-13.5v-8l-1-1h-28.7c-4.5,0-8.2,3.7-8.2,8.2v8v13.5v8c0,2.1,0.7,4,2,5.4h-1l-1,1v8V658v8l1,1h28.7c4.5,0,8.1-3.7,8.3-8.2v-8v-13.5v-8C-345.9,627.2-346.7,625.3-348,623.9z" /><path className="st0" d="M-381.9,657h27.7c3.4,0,6.2-2.8,6.2-6.2v-21.5c0-3.3-2.9-6.2-6.2-6.2h-17.9c-2.7,0-4.6-1.9-4.6-4.6c0-2.4,1.8-4.3,4.4-4.6h0.1h24.2v-19.4h-27.7c-3.3,0-6.2,2.8-6.2,6.2v21.5c0,3.5,2.7,6.2,6.2,6.2h17.9c2.4,0,4.6,2.1,4.6,4.6s-2.1,4.6-4.6,4.6h-24.1L-381.9,657L-381.9,657z" /></svg>,
    B: <svg viewBox="-339.1 585.3 37.9 81.7"><path d="M-309.4,592.5h-28.7l-1,1v8V658v8l1,1h28.7c4.5,0,8.1-3.7,8.2-8.1v-8v-13.5v-7.2v-0.8v-7.2v-13.5v-8C-301.2,596.2-305,592.5-309.4,592.5z" /><path className="st0" d="M-303.2,622.1v-21.5c0-3.3-2.9-6.2-6.2-6.2h-27.7V657h27.7c3.4,0,6.2-2.8,6.2-6.2v-21.5c0-1.4-1.2-2.6-2.6-2.6v-2C-304.4,624.7-303.2,623.5-303.2,622.1z M-313,637.5h-17.9l-1-1v-7.2l1-1h17.9c2.4,0,4.6,2.1,4.6,4.6S-310.5,637.5-313,637.5zM-313,623.1h-17.9l-1-1v-7.2l1-1h17.9c2.4,0,4.6,2.1,4.6,4.6C-308.4,621.1-310.5,623.1-313,623.1z" /></svg>,
    O: <svg viewBox="-296.1 585.3 37.9 81.7"><path d="M-266.4,592.4h-21.5c-4.5,0-8.2,3.7-8.2,8.2v8v42.2v8c0,4.6,3.6,8.2,8.2,8.2h21.5c4.5,0,8.2-3.7,8.2-8.2v-8v-42.2v-8C-258.2,596.1-262,592.4-266.4,592.4z M-273.6,635.5h-7.2c-3.3,0-5.9-2.5-6.2-5.7c0.2-3.2,3-5.8,6.2-5.8v-0.1h7.2c3.2,0,6,2.8,6.2,5.8C-267.6,632.9-270.4,635.5-273.6,635.5z" /><path className="st0" d="M-266.4,594.4h-21.5v0.1c-3.3,0-6.2,2.8-6.2,6.2v50.2c0,3.5,2.7,6.2,6.2,6.2h21.5c3.4,0,6.2-2.8,6.2-6.2v-50.3C-260.2,597.3-263.1,594.4-266.4,594.4z M-265.4,629.3c0,4.5-3.7,8.2-8.2,8.2h-7.2c-4.6,0-8.2-3.6-8.2-8.2v-7.2c0-4.5,3.7-8.2,8.2-8.2h7.2c4.4,0,8.2,3.8,8.2,8.2V629.3z" /></svg>,
    T: <svg viewBox="-253.5 585.3 37.8 81.7"><polygon points="-216.7,592.5 -252.5,592.5 -253.5,593.5 -253.5,601.5 -253.5,615 -253.5,623 -252.5,624 -239.2,624 -239.2,658 -239.2,666 -238.2,667 -231.1,667 -230,666 -230,658 -230,624 -216.7,624 -215.7,623 -215.7,615 -215.7,601.5 -215.7,593.5 " /><polygon className="st0" points="-237.2,657 -232,657 -232,615 -231,614 -217.7,614 -217.7,594.5 -251.5,594.5 -251.5,614 -238.2,614 -237.2,615 " /></svg>,
    F: <svg viewBox="-211 585.3 37.8 81.7"><polygon points="-174.2,592.5 -210,592.5 -211,593.5 -211,601.5 -211,658 -211,666 -210,667 -202.8,667 -201.8,666 -201.8,658 -201.8,638.3 -181.3,638.3 -180.3,637.3 -180.3,630.2 -180.7,629.8 -180.3,629.3 -180.3,624 -174.2,624 -173.2,623 -173.2,615 -173.2,601.5 -173.2,593.5 " /><polygon className="st0" points="-209,657 -203.8,657 -203.8,629.3 -202.8,628.3 -182.3,628.3 -182.3,623.2 -202.8,623.2 -203.8,622.2 -203.8,615 -202.8,614 -175.2,614 -175.2,594.5 -209,594.5 " /></svg>,
    U: <svg viewBox="-168 585.3 38 81.7"><path d="M-131,592.5h-7.2l-1,1v8v27.8c0,3.4-2.8,6.2-6.2,6.2h-7.2c-3.5,0-6.2-2.7-6.2-6.2v-27.8v-8l-1-1h-7.2l-1,1v8v49.3v8c0,4.6,3.6,8.2,8.2,8.2h21.6c4.5,0,8.2-3.7,8.2-8.2v-8v-49.3v-8L-131,592.5z" /><path className="st0" d="M-166,594.5v56.3c0,3.5,2.7,6.2,6.2,6.2h21.6c3.4,0,6.2-2.8,6.2-6.2v-56.3h-5.2v34.8c0,4.5-3.7,8.2-8.2,8.2h-7.2c-4.6,0-8.2-3.6-8.2-8.2v-34.8H-166z" /></svg>,
    N: <svg viewBox="-125 585.3 37.9 81.7"><path d="M-88,592.5h-7.2l-1,1v8V633c0,1.4-1.2,2.6-2.6,2.6c-1.6,0-2.6-1-2.6-2.6v-24.3v-8c0-4.4-3.8-8.2-8.2-8.2H-124l-1,1v8V658v8l1,1h7.2l1-1v-8v-31.4c0-1.4,1.2-2.6,2.6-2.6c1.3,0,2.6,1.3,2.6,2.6v24.2v8c0,4.6,3.6,8.2,8.2,8.2H-88l1-1v-8v-56.5v-8L-88,592.5z" /><path className="st0" d="M-113.2,614c2.5,0,4.6,2.1,4.6,4.6v32.2c0,3.5,2.7,6.2,6.2,6.2H-89v-62.5h-5.2V633c0,2.5-2.1,4.6-4.6,4.6c-2.7,0-4.6-1.9-4.6-4.6v-32.3c0-3.3-2.9-6.2-6.2-6.2H-123V657h5.2v-38.4C-117.8,616.1-115.6,614-113.2,614z" /></svg>,
    D: <svg viewBox="-82 585.3 37.9 81.7" ><path d="M-52.3,592.5H-81l-1,1v8v56.6v8l1,1h28.7c4.5,0,8.2-3.7,8.2-8.2v-8v-42.2v-8C-44,596.3-47.9,592.5-52.3,592.5z M-59.4,635.5L-59.4,635.5l-13.4-0.1v-11.5h13.3c3.1,0,6,2.7,6.2,5.8C-53.5,632.9-56.1,635.4-59.4,635.5z" /><path className="st0" d="M-52.3,594.5H-80v62.6h27.7c3.4,0,6.2-2.8,6.2-6.2v-50.2C-46,597.4-48.9,594.5-52.3,594.5z M-51.3,629.3c0,4.5-3.7,8.2-8.2,8.2l-0.1-0.1h-14.2l-1-1v-21.5l1-1h14.3c4.4,0,8.2,3.8,8.2,8.2V629.3z" /></svg>
}

let kaisBoatFund = "KAI'S BOAT FUND";

export default function Title({setAdmin, animating, showStore}) {
    let [password, setPassword] = useReducer((p, l) => p + l, '');
    let navigate = useNavigate();

    useEffect(() => {
        if (password) (async () => {
            let { data } = await axios.post('/api/p', { p: password })
            if (data) {
                if (data.admin) {
                    localStorage.setItem('p', password);
                    setAdmin(true);
                    setTimeout(()=>navigate('/admin'),500);
                } else if (data.music) {
                    localStorage.setItem('m', password);
                    setTimeout(()=>navigate('/music'),500);
                }
            }

        })()
    }, [password])
    
    return <div className={`Title ${animating?'animating':''} ${showStore?'showStore':''}`}>
        {kaisBoatFund.split(' ').map((word) => <div className="word" key={word}>
            {word.split('').map((l, i) => <Letter
                key={i}
                l={l}
                setPassword={setAdmin?setPassword:null}
            />)}
        </div>)}
    </div>
}

function Letter({ l, setPassword }) {
    let [wiggle, setWiggle] = useState(false);
    let interval = useRef();
    let timeout = useRef();
    let [rotation, setRotation, getRotation] = useGetState(0);

    useEffect(() => {
        if (wiggle) {
            setRotation((Math.floor(Math.random()*2)*2-1)*8)
            interval.current = setInterval(() => {
                setRotation(-getRotation());
            }, 1000 * 0.1)
            clearTimeout(timeout.current);
            setTimeout(() => {
                setWiggle(false);
            },1000*0.5)
        } else {
            clearInterval(interval.current);
            setRotation(0);
        }
    }, [wiggle])

    return <div
        className="letter"
        onClick={setPassword ? () => {
            setPassword(l);
            setWiggle(true);
        } : null}
        style={{
            transform: `rotate(${rotation}deg)`
        }}
    >
        {font[l]}
    </div>
}

//transform: rotate(5deg);

{/* <img className='logo' style={logoStyle} src={require('../../logo/kai2.svg') } alt="KAI'S"/>
<img className='logo' style={logoStyle} src={require('../../logo/boat2.svg') } alt="BOAT"/>
<img className='logo' style={logoStyle} src={require('../../logo/fund2.svg')} alt="FUND" /> */}

// let {data} = await axios.post('/api/p', { p: code }); 
// this.setState({ p: data.p });
// localStorage.setItem('p', data.p);