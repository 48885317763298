import React, { useState, useEffect, useContext } from 'react';
import AdminTabs from './AdminTabs';
import axios from 'axios';
import { AdminContext, p } from '../../hooks';
import Q from './Q';
import A from './A';
import U from './U';

export default function MusicAdmin() {
    let [albums, setAlbums] = useState();
    let [addAlbum, setAddAlbum] = useState();
    let { admin } = useContext(AdminContext);

    useEffect(() => {
        if (admin) (async () => {
            let albums = (await axios.post('/api/music')).data;
            setAlbums(albums);
        })()
    }, [admin])

    console.log({addAlbum})
    
    return <div>
        {addAlbum ? <EditAlbum
            close={() => setAddAlbum(false)}
            save={async ({ title, body, cover }) => {
                let { data } = await axios.post('/api/addalbum', { p: p(), title, body, cover });
                albums = albums.map(a => ({ ...a, n: a.n + 1 }));
                setAlbums([data, ...albums]);
            }}
        /> : null}
        {albums ? <div className="Albums">{albums.map((a,i) => <Album
            key={a._id} album={a} force={() => setAlbums([...albums])} setAlbum={({ title, body, cover }) => {
                a.title = title;
                a.body = body;
                a.cover = cover;
                setAlbums([...albums])
            }}
            swapPrev={i > 0 ? async () => {
                let n1 = a.n;
                let n2 = albums[i-1].n;
                await axios.post('/api/swapalbums', { p: p(), n1, n2 });
                albums[i] = {...albums[i - 1],n:a.n};
                albums[i - 1] = {...a, n:albums[i-1].n};
                setAlbums([...albums]);
            } : null}
            swapNext={i < albums.length-1 ? async () => {
                let n1 = a.n;
                let n2 = albums[i+1].n;
                await axios.post('/api/swapalbums', { p: p(), n1, n2 });
                albums[i] = {...albums[i + 1],n:a.n};
                albums[i + 1] = {...a, n:albums[i+1].n};
                setAlbums([...albums]);
            } : null}
            deleteMe={async () => {
                await axios.post('/api/deletealbum', { p:p(), _id: a._id });
                setAlbums([...albums.filter(b => b._id != a._id)]);
            }}
        />)}</div> : null}
        <A onClick={()=>setAddAlbum(true)}>Add album</A>
    </div>
}

function Album({ album, force, swapPrev, swapNext, deleteMe, setAlbum }) {
    let [title, setTitle] = useState(album.title);
    let [cover, setCover] = useState(album.cover);
    let [songs, setSongs] = useState(album.songs);
    let [body, setBody] = useState(album.body);
    let [hover, setHover] = useState(false);
    let [hover1, setHover1] = useState(false);

    let [addSong, setAddSong] = useState();
    let [editAlbum, setEditAlbum] = useState();

    return <div className="album" onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)}>

        {addSong ? <AddSong
            close={() => setAddSong(false)}
            save={async ({ title, path }) => {
                await axios.post('/api/addsong', { p: p(), title, path, album:album._id });
                album.songs.push({title, path});
                setSongs(album.songs)
                force();
            }}
        /> : null}

        {editAlbum ? <EditAlbum
            album = {album}
            close={() => setEditAlbum(false)}
            save={async ({ title, body, cover }) => {
                await axios.post('/api/editalbum', { p: p(), _id:album._id, title, body, cover });
                setAlbum({ title, body, cover });
                setTitle(title);
                setBody(body);
                setCover(cover);
            }}
        /> : null}

        <h2>{title}</h2>
        <div className="itemSplit">
            <div className="left">
                <p>{body}</p>
                <img width={200} src={'/api/pictures/' + cover} alt="cover" />
            </div>
            <div className="right" onMouseEnter={()=>setHover1(true)} onMouseLeave={()=>setHover1(false)}>
                <div className="songs">{songs.map((s, i) => <Song title={s.title} key={`${i} ${s.title}`} deleteMe={async () => {
                    await axios.post('/api/deletesong', { p: p(), album: album._id, i });
                    album.songs.splice(i, 1);
                    force();
                }}/>)}</div>
                <div className={`hideButtons ${hover1?'visible':''}`}>
                    <A onClick={() => setAddSong(true)}>Add song</A>
                </div>
            </div>
        </div>

        <div className={`hideButtons ${hover?'visible':''}`}>
            {swapPrev ? <A onClick={swapPrev}>▲</A> : null}
            {swapNext ? <A onClick={swapNext}>▼</A> : null}
            <A onClick={()=>setEditAlbum(true)}>Edit</A>
            <Q callback={deleteMe}>Delete</Q>
        </div>

    </div>
}

function Song({ title, deleteMe }) {
    let [hover, setHover] = useState(false);
    return <div className="song" onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)}>
        <div>{title}</div>
        <div className={`dots ${hover?'visible':''}`}></div>
        <div className={`hideButtons ${hover?'visible':''}`}>
            <Q callback={deleteMe}>Delete</Q>
        </div>
    </div>
}

function AddSong({ close, save }) {
    let [title, setTitle] = useState('');
    let [path, setPath] = useState(null);
    return <div className="expanded">
        <div className="window">
            <div className="modalHeading">
                <div className="heading">Song</div>
                <button onClick={close}>╳</button>
            </div>
            <div className="itemSplit">
                <div className="left">
                    Title
                </div>
                <div className="right">
                    <input
                        type="text"
                        value={title}
                        onChange={e=>setTitle(e.target.value)}
                    />
                </div>
            </div>
            <div className="itemSplit">
                <div className="left">
                    Song
                </div>
                <div className="right">
                    {path ? <>
                        <A onClick={() => {
                            setPath(null)
                        }}>Remove song</A>
                    </>:<U callback={path => { 
                        setPath(path)
                    }}/>}
                </div>
            </div>
            <div className="buts">
                <Q className="but" disabled={!(title && path)} callback={async () => {
                    await save({ title, path })
                    close();
                }}>Save</Q>
            </div>
        </div>
    </div>
}

function EditAlbum({ album, close, save }) {
    let [title, setTitle] = useState(album?album.title:'');
    let [body, setBody] = useState(album?album.body:'');
    let [cover, setCover] = useState(album?album.cover:null);
    return <div className="expanded">
        <div className="window">
            <div className="modalHeading">
                <div className="heading">Album</div>
                <button onClick={close}>╳</button>
            </div>
            <div className="itemSplit">
                <div className="left">
                    Title
                </div>
                <div className="right">
                    <input
                        type="text"
                        value={title}
                        onChange={e=>setTitle(e.target.value)}
                    />
                </div>
            </div>
            <div className="itemSplit">
                <div className="left">
                    Body
                </div>
                <div className="right">
                    <input
                        type="text"
                        value={body}
                        onChange={e=>setBody(e.target.value)}
                    />
                </div>
            </div>
            <div className="itemSplit">
                <div className="left">
                    Cover
                </div>
                <div className="right">
                    {cover ? <>
                        <img width={100} src={'/api/pictures/' + cover} alt="cover" />
                        <div>
                            <A onClick={() => {
                                setCover(null)
                            }}>Remove picture</A>
                        </div>
                    </>:<U callback={path => { 
                        setCover(path)
                    }}/>}
                </div>
            </div>
            <div className="buts">
                <Q className="but" disabled={!(title && body && cover)} callback={async () => {
                    await save({ title, body, cover })
                    close();
                }}>Save</Q>
            </div>
        </div>
    </div>
}


