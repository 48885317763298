import React, { useState, useEffect, useReducer, useRef, useContext } from 'react';
import AdminTabs from './AdminTabs';
import U from './U';
import {useMount, useClickOutside, p, AdminContext} from '../../hooks';
import axios from 'axios';
import SwapTabs from './SwapTabs'
import A from './A'
import Q from './Q'
import I from './I'
import Paragraph from '../Paragraph'
import Check from './C';
import Pages from './Pages';

let perPage = 10;

export default function Items() {
    let { componentDidMount } = useMount();
    let [categories, setCategories] = useState();
    let [category, setCategory] = useState(0);
    let [subcategory, setSubcategory] = useState(0);
    let [page, setPage] = useState(0);
    let [editCategory, setEditCategory] = useState(false);
    let [force, forceRender] = useReducer(c => c + 1, 0);
    let { admin, setBackgroundColour } = useContext(AdminContext);

    let getBg = useRef();

    useEffect(() => {
        if (admin) (async () => {
            let categories = (await axios.post('/api/items',{includeInvisible:true})).data;
            setCategories(categories);
        })()
    }, [admin])

    useEffect(() => {
        setBackgroundColour("Admin");
        return () => setBackgroundColour(false);
    }, [])

    useEffect(() => {
        setSubcategory(0);
    }, [category])

    useEffect(() => {
        setPage(0);
    }, [category, subcategory])

    if (!admin) return null;

    return <div className="Items" ref={getBg}>
        {editCategory ? <ExpandedCategory
            category={categories[category].children[subcategory]}
            close={() => setEditCategory(false)}
            save={async ({ title, body }) => {
                let n = categories[category].children[subcategory].order;
                let { data } = await axios.post('/api/updatecategory', { p:p(), n, title, body, parent: categories[category]._id });
                categories[category].children[subcategory].title = title;
                categories[category].children[subcategory].body = body;
                setCategories(categories.slice());
            }}
        /> : null}
        <AdminTabs tab="Items" />
        <h1>Items</h1>
        {categories ? <>
            <SwapTabs
                tab={category}
                tabs={categories}
                setTab={setCategory}
                onSwap={async (n1, n2) => {
                    await axios.post('/api/swapcategories', { p:p(), n1, n2, parent: '' });
                }}
                onAdd={async title => {
                    let { data } = await axios.post('/api/addcategory', { p:p(), title, parent: '' });
                    data.children = [];
                    categories.push(data);
                    setCategories(categories.slice());
                }}
                onDelete={async () => {
                    let n = categories[category].order;
                    let { data } = await axios.post('/api/deleteCategory', { p:p(), n, parent: '' });
                    categories.splice(category, 1);
                    categories.forEach(c => {
                        if (c.order > n) c.order--;
                    })
                    if (category >= categories.length) {
                        category = categories.length - 1;
                        setCategory(category);
                    }
                    setCategories(categories.slice());
                }}
                onRename={async title => {
                    let n = categories[category].order;
                    let { data } = await axios.post('/api/renameCategory', { p:p(), n, title, parent: '' });
                    categories[category].title = title;
                    setCategories(categories.slice());
                }}
            />
            {categories.length > 0 ? <>
                <SwapTabs
                    tab={subcategory}
                    tabs={categories[category].children}
                    setTab={setSubcategory}
                    onSwap={async (n1, n2) => {
                        await axios.post('/api/swapcategories', { p:p(), n1, n2, parent: categories[category]._id });
                    }}
                    onAdd={async title => {
                        let { data } = await axios.post('/api/addcategory', { p:p(), title, parent: categories[category]._id });
                        data.items = [];
                        categories[category].children.push(data);
                        setCategories(categories.slice());
                    }}
                    onDelete={async () => {
                        let n = categories[category].children[subcategory].order;
                        let { data } = await axios.post('/api/deletecategory', { p:p(), n, parent: categories[category]._id });
                        categories[category].children.splice(subcategory, 1);
                        categories[category].children.forEach(c => {
                            if (c.order > n) c.order--;
                        })
                        if (subcategory >= categories[category].children.length) {
                            subcategory = categories[category].children.length - 1;
                            setSubcategory(subcategory);
                        }
                        setCategories(categories.slice());
                    }}
                    // onRename={async title => {
                    //     let n = categories[category].children[subcategory].order;
                    //     let { data } = await axios.post('/api/renamecategory', { p:p(), n, title, parent: categories[category]._id });
                    //     categories[category].children[subcategory].title = title;
                    //     setCategories(categories.slice());
                    // }}
                />

                {categories[category].children.length > 0 ? <>
                    <div className="addItem">
                        <A onClick={async () => {
                            let Item = { 
                                title: "Item",
                                body: "Hello there!",
                                category: categories[category].children[subcategory]._id,
                                deleted: false,
                                images: [],
                                order: 0,
                                postage: 0,
                                needPhone: false,
                                needAddress: true,
                                price: 19.2,
                                infinite: true,
                                stock: 10,
                                variations: [],
                                visible: true
                            };
                            categories[category].children[subcategory].items.forEach(i => i.order++);
                            categories[category].children[subcategory].items.unshift(Item);
                            let { data } = await axios.post('/api/additem', { p:p(), item: Item, category: Item.category });
                            setPage(0);
                            forceRender();
                        }}>Add Item</A>
                        <A
                            onClick={()=>setEditCategory(true)}
                        >Edit Category</A>
                    </div>
                    <Pages
                        pages={Math.floor((categories[category].children[subcategory].items.length - 1) / perPage)}
                        page={page}
                        setPage={setPage}
                    />
                    {categories[category].children[subcategory].items.map((item,i) => 
                        i>=page*perPage && i < (page+1)*perPage ? <Item
                            key={category + ' ' + subcategory + ' ' + i}
                            item={item}
                            reload={() => {
                                setPage(0);
                            }}
                            swapPrev={i > 0 ? async () => {
                                let n1 = categories[category].children[subcategory].items[i - 1].order;
                                let n2 = item.order;
                                await axios.post('/api/swapitems', { p:p(), n1, n2, category: item.category });
                                categories[category].children[subcategory].items[i - 1].order = n2;
                                item.order = n1;
                                categories[category].children[subcategory].items.sort((a, b) => a.order - b.order);
                                forceRender();
                            } : null}
                            swapNext={i < categories[category].children[subcategory].items.length - 1 ? async () => {
                                let n1 = categories[category].children[subcategory].items[i + 1].order;
                                let n2 = item.order;
                                await axios.post('/api/swapitems', { p:p(), n1, n2, category: item.category });
                                categories[category].children[subcategory].items[i + 1].order = n2;
                                item.order = n1;
                                categories[category].children[subcategory].items.sort((a, b) => a.order - b.order);
                                forceRender();
                            } : null}
                            deleteMe={async () => {
                                let n = item.order;
                                let { data } = await axios.post('/api/deleteitem', { p:p(), n, category: item.category });
                                categories[category].children[subcategory].items.splice(i, 1);
                                categories[category].children[subcategory].items.forEach(i => {
                                    if (i.order > n) i.order--;
                                })
                                forceRender();
                            }}
                            duplicate={async () => {
                                let Item = { ...item };
                                delete Item._id;
                                Item.title = 'Duplicate of ' + Item.title;
                                Item.images = [];
                                Item.deleted = false;
                                categories[category].children[subcategory].items.forEach(i => {
                                    if (i.order >= Item.order) i.order++;
                                })
                                categories[category].children[subcategory].items.sort((a, b) => a.order - b.order);
                                let { data } = await axios.post('/api/additem', { p:p(), item: Item, category: Item.category });
                                categories[category].children[subcategory].items.splice(i,0,data);
                                forceRender();
                            }}
                        /> : null
                    )}
                </> : null}
            </> : null}
        </> : null}
    </div>
}

function Item({ item, deleteMe, swapPrev, swapNext, duplicate, reload }) {
    let [expand, setExpand] = useState(false);
    return <>
        {expand ? <Expanded
            item={item}
            close={() => setExpand(false)}
            reload={reload}
        /> : null}
        <div className="item" >
            <div className="clickme" onClick={()=>setExpand(true)}>
                <div className="title">
                    <div className="left">
                        {item.title}
                    </div>
                    <div className="right">
                        £{item.price.toFixed(2)}
                    </div>
                </div>
                <Paragraph text={item.body} />
                <div className="images">
                    {item.images.map((image,i) => <div key={'image '+image.path} className="mr">
                        <img src={'/api/pictures/' + image.path} alt={image.path}/>
                    </div>)}
                </div>
            </div>
            <div className="hideButtons">
                {swapPrev ? <A onClick={swapPrev}>▲</A> : null}
                {swapNext ? <A onClick={swapNext}>▼</A> : null}
                <A onClick={duplicate}>Duplicate</A>
                <Q callback={deleteMe}>Delete</Q>
            </div>
        </div>
    </>
}

function ExpandedCategory({ close, save, category }) {
    let [title, setTitle] = useState(category.title);
    let [body, setBody] = useState(category.body ?? '');
    
    return <div className="expanded">
        <div className="window">
            <div className="modalHeading">
                <div className="heading">Category</div>
                <button onClick={close}>╳</button>
            </div>
            <div className="itemSplit">
                <div className="left">
                    Title
                </div>
                <div className="right">
                    <input
                        type="text"
                        value={title}
                        onChange={e=>setTitle(e.target.value)}
                    />
                </div>
            </div>
            <div className="itemSplit">
                <div className="left">
                    Body
                </div>
                <div className="right">
                    <input
                        type="text"
                        value={body}
                        onChange={e=>setBody(e.target.value)}
                    />
                </div>
            </div>
            <div className="buts">
                <Q className="but" callback={async () => {
                    await save({title, body})
                    close();
                }}>Save</Q>
            </div>
        </div>
    </div>
}

function Expanded({ item, close, reload }) {
    let [force, forceRender] = useReducer(c => c + 1, 0);

    let [title, setTitle] = useState(item.title);
    let [body, setBody] = useState(item.body);
    let [price, setPrice] = useState(item.price);
    let [postage, setPostage] = useState(item.postage);
    let [stock, setStock] = useState(item.stock);
    let [visible, setVisible] = useState(item.visible);
    let [infinite, setInfinite] = useState(item.infinite);
    let [needPhone, setNeedPhone] = useState(item.needPhone);
    let [needAddress, setNeedAddress] = useState(item.needAddress);
    let [images, setImages] = useState(item.images.slice());
    let [variations, setVariations] = useState(JSON.parse(JSON.stringify(item.variations)));

    images.sort((a, b) => a.order - b.order);
    variations.sort((a, b) => a.order - b.order);
    variations.forEach(v => v.options.sort((a, b) => a.order - b.order));

    return <div className="expanded">
        <div className="window">
            <div className="modalHeading">
                <div className="heading">Item</div>
                <button onClick={close}>╳</button>
            </div>
            <div className="itemSplit">
                <div className="left">
                    Title
                </div>
                <div className="right">
                    <input
                        type="text"
                        value={title}
                        onChange={e=>setTitle(e.target.value)}
                    />
                </div>
            </div>
            <div className="itemSplit">
                <div className="left">
                    Body
                </div>
                <div className="right">
                    <textarea
                        value={body}
                        onChange={e=>setBody(e.target.value)}
                    />
                </div>
            </div>
            <div className="itemSplit">
                <div className="left">
                    Images
                </div>
                <div className="right images">
                    {images.map((image,i) => <div key={'image '+image.path} className="image">
                        <img src={'/api/pictures/' + image.path} alt={image.path} />
                        <div className="imageOptions">
                            {i > 0 ? <A onClick={() => {
                                let tmp = images[i - 1].order;
                                images[i - 1].order = images[i].order;
                                images[i].order = tmp;
                                setImages(images.slice());
                            }}>◀</A> : <div className="nothing"/>}
                            <A className="x" onClick={() => {
                                images.splice(i, 1);
                                images.forEach((im, i) => im.order = i);
                                setImages(images.slice());
                            }}>✖</A>
                            {i < images.length - 1 ? <A onClick={() => {
                                let tmp = images[i + 1].order;
                                images[i + 1].order = images[i].order;
                                images[i].order = tmp;
                                setImages(images.slice());
                            }}>▶</A> : <div className="nothing"/>}
                        </div>
                    </div>)}
                    <U callback={path => { 
                        images.push({ path, order:images.length })
                        setImages(images.slice());
                    }}/>
                </div>
            </div>
            <div className="itemSplit">
                <div className="left">
                    Variations
                </div>
                <div className="right variations">
                    {variations.map((v, i) => <div key={i} className="variation">
                        <I className="variationTitle"
                            callback={s => {
                                v.title = s;
                                forceRender();
                            }}
                            pre={i > 0 ? <A className="aLeft" onClick={() => {
                                let tmp = v.order;
                                v.order = variations[i - 1].order;
                                variations[i - 1].order = tmp;
                                forceRender();
                            }}>◀</A>:null}
                            post={i<variations.length-1?<A className="aRight" onClick={() => {
                                let tmp = v.order;
                                v.order = variations[i + 1].order;
                                variations[i + 1].order = tmp;
                                forceRender();
                            }}>▶</A>:null}
                        >
                            <h4>{v.title}</h4>
                        </I>
                        {v.options.map((o, j) => <div key={j} className="variationOptions">
                            <div className="optionsLeft">
                                <I callback={s => {
                                    o.title = s;
                                    forceRender();
                                }}>{o.title}</I>
                            </div>
                            <div className="optionsRight">
                                <I callback={s => {
                                    o.cost = parseFloat(s);
                                    forceRender();
                                }}>£{o.cost}</I>
                            </div>
                        </div>)}
                        <I className="hide" callback={p => {
                            v.options.push({ title: p, cost: 0, order: v.options.length });
                            forceRender();
                        }}>Add option</I>
                    </div>)}
                    <I callback={p => {
                        variations.push({ title: p, options: [], order: variations.length });
                        forceRender();
                    }}>Add variation</I>
                </div>
            </div>
            <div className="itemSplit lil">
                <div className="left">
                    Price
                </div>
                <div className="right">
                    £<input
                        type="text"
                        value={price}
                        onChange={e=>setPrice(e.target.value)}
                    />
                </div>
            </div>
            <div className="itemSplit lil">
                <div className="left">
                    Postage
                </div>
                <div className="right">
                    £<input
                        type="text"
                        value={postage}
                        onChange={e=>setPostage(e.target.value)}
                    />
                </div>
            </div>
            <div className="itemSplit">
                <div className="left">
                    Require
                </div>
                <div className="right columns">
                    <div className="column">
                        <div className="columnTitle">
                            Phone number
                        </div>
                        <div className="columnStuff">
                            <Check check={needPhone} setCheck={setNeedPhone}/>
                        </div>
                    </div>
                    <div className="column">
                        <div className="columnTitle">
                            Address
                        </div>
                        <div className="columnStuff">
                            <Check check={needAddress} setCheck={setNeedAddress}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="itemSplit">
                <div className="left">
                    Infinite
                </div>
                <div className="right">
                    <Check check={infinite} setCheck={setInfinite}/>
                </div>
            </div>
            {infinite ? null : <div className="itemSplit lil">
                <div className="left">
                    Stock
                </div>
                <div className="right">
                    <input
                        type="text"
                        value={stock}
                        onChange={e=>setStock(parseInt(e.target.value))}
                    />
                </div>
            </div>}
            <div className="itemSplit">
                <div className="left">
                    Visible
                </div>
                <div className="right">
                    <Check check={visible} setCheck={setVisible}/>
                </div>
            </div>
            <div className="buts">
                <Q className="but" callback={() => {
                    item.title = title;
                    item.body = body;
                    item.price = parseFloat(price);
                    item.postage = parseFloat(postage);
                    item.needPhone = needPhone;
                    item.needAddress = needAddress;
                    item.infinite = infinite;
                    item.stock = stock;
                    item.visible = visible;
                    item.images = images;
                    item.variations = variations;
                    let { data } = axios.post('/api/updateitem', { p:p(), item });
                    close();
                }}>Save</Q>
                <Q className="but" callback={async () => {
                    let item1 = {};
                    item1.title = title;
                    item1.body = body;
                    item1.price = parseFloat(price);
                    item1.postage = parseFloat(postage);
                    item1.needPhone = needPhone;
                    item1.needAddress = needAddress;
                    item1.infinite = infinite;
                    item1.stock = stock;
                    item1.visible = visible;
                    item1.images = images;
                    item1.variations = variations;
                    let { data } = await axios.post('/api/additem', { p:p(), item: item1, category: item.category });
                    close();
                    reload();
                }}>New</Q>
            </div>
        </div>
    </div>
}