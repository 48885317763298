import React, { Component } from 'react';
import { NavLink } from "react-router-dom";

import Thumb from './Thumb';
import axios from 'axios';

export default class Store extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            loading: true
        }
    }

    async componentDidMount() {
        let categories = (await axios.post('/api/items')).data;
        categories.forEach(c => {
            c.children.forEach(cc => {
                cc.items.forEach((item,i) => {
                    item.path = item.images[0].path;
                    item.bigText = '£' + item.price.toFixed(2);
                    item.link = '/item/' + item.title.replace(/ /g, '_');
                })
            })
        })
        this.setState({ categories, loading:false });
    }

    render() {
        let c = [
            'rgb(41,239,173)',
            'rgb(244,179,33)',
        ]
        let k = 0;
        let { categories, loading } = this.state;
        return <div className="content shop">
            <h1>Why not buy a nice gift?</h1>
            {loading?<div className="center"><div className="spinner"></div></div>:categories.map((category, i) => category.children.map((category,j)=><div key={'items ' + category.title + ' ' + i+'/'+j}>
                <h2>{category.title}</h2>
                {category.body?<h3>{category.body}</h3>:null}
                <div className="thumbFlex">
                    {category.items.map((item,i) => <Thumb
                        key={'thumb ' + item.title}
                        className="item"
                        style={{backgroundImage: 'url(/api/pictures/' + encodeURIComponent(item.path) + ')'}}
                    >
                        <NavLink to={item.link}><div className="tool">
                            <div className="title" style={{backgroundColor:c[k%2]}}>{item.title}
                            <h3 style={{backgroundColor:c[k++%2]}}>{item.bigText}</h3></div>
                        </div></NavLink>
                    </Thumb>)}
                </div>
            </div>))}
        </div>
    }
}