import React, { Component } from 'react';
import axios from 'axios';

import { NavLink, useNavigate } from "react-router-dom";
import Banner from './Banner';
import Quantity from './Quantity';
import { secretItems } from '../../hooks';

export default function FunctionalWrapper(props) {
    let navigate = useNavigate();
    return <Basket {...props} navigate={navigate} />
}

class Basket extends Component {
    constructor(props) {
        super(props);
        this.state = {
            basket: [], loading: true, removing: [], animate:[], localBasket: []
        }
        this.updateBasket = this.updateBasket.bind(this);
        this.changeQuantity = this.changeQuantity.bind(this);
        this.remove = this.remove.bind(this);
        this.buy = this.buy.bind(this);
        this.getAnimateHeight = this.getAnimateHeight.bind(this);
        this.changeOrder = this.changeOrder.bind(this);
    }

    getAnimateHeight(i) {
        let { animate } = this.state;
        setTimeout(() => {
            animate[i] = true;
            this.setState({ animate });
        },1)
        return document.getElementById("animate " + i).getBoundingClientRect().height;
    }

    async componentDidMount() {
        let basket = this.updateBasket();
        let removing = basket.map(() => false);
        let animate = basket.map(() => false);
        let country = sessionStorage.getItem('country');
        if (!country) {
            let { data } = await axios.get('/api/country');
            country = data;
            sessionStorage.setItem('country', country);
        }
        let { data } = await axios.post('/api/basket', { basket, country });
        data = data.basket;
        for (let i = data.length - 1; i >= 0; i--) {
            if (data[i].deleted) {
                data.splice(i, 1);
                basket.splice(i, 1);
            }
        }
        basket.forEach((item, i) => {
            item.variations = data[i].variations;
        })
        // console.log(basket);
        localStorage.setItem('b', JSON.stringify(basket));
        this.setState({ basket: data, loading: false, removing, animate, localBasket: basket },this.getBigWidth);
    }

    changeOrder(i) {
        let { localBasket } = this.state;
        let deleted = localBasket[i];
        localBasket.splice(i, 1);
        localStorage.setItem('b', JSON.stringify(localBasket));
        this.props.navigate('/item/' + deleted.title.replace(/ /g, '_'));
    }

    updateBasket() {
        let basket = JSON.parse(localStorage.getItem('b'));
        if (!basket) basket = [];
        return (basket);
    }

    changeQuantity(i, q) {
        let { basket, localBasket } = this.state;
        basket[i].quantity = q;
        localBasket[i].quantity = q;
        basket[i].calculatedPrice = basket[i].price;
        basket[i].variations.forEach(v => {
            basket[i].calculatedPrice += v.selected.cost;
        })
        basket[i].calculatedPrice *= basket[i].quantity;
        localStorage.setItem('b', JSON.stringify(localBasket));
        this.setState({ basket, localBasket });
    }

    remove(i, q) {
        let { basket, removing, animate, localBasket } = this.state;
        if (removing.indexOf(true) == -1) {
            removing[i] = true;
            this.setState({ removing }, () => {
                setTimeout(() => {
                    basket.splice(i,1);
                    localBasket.splice(i,1);
                    removing.splice(i,1);
                    animate.splice(i,1);
                    localStorage.setItem('b', JSON.stringify(localBasket));
                    this.setState({ basket, removing, animate, localBasket });
                },1000*0.3)
            })    
        }
    }

    buy() {
        if (secretItems(this.state.localBasket)) {
            this.props.navigate('/railway-club');
        } else {
            sessionStorage.setItem('b', localStorage.getItem('b'));
            this.props.navigate('/buy');
        }
    }

    render() {
        let { basket, loading, removing, animate } = this.state;
        let total = basket.reduce((a, b) => a + b.calculatedPrice, 0);
        return <div className="Store">
            <Banner />
            <div className="bg2">
                <div className="content">
                    <div className="basket">
                        <h1>Basket</h1>
                        {loading ? <div className="center"><div className="spinner"></div></div> : basket.length > 0 ? <div className="flex">
                            <div className="left">
                                {basket.map((item, i) => <div className="animate" key={'item ' + i} id={"animate "+i} style={{
                                    height: removing[i] ? animate[i] ? 0 : this.getAnimateHeight(i) : null,
                                }}><div className="basketItem" >
                                    <div className="first">
                                        <NavLink to={'/item/'+item.title.replace(/ /g,'_')}>
                                            <div
                                                className="image"
                                                style={{ backgroundImage: 'url(/api/pictures/' + encodeURIComponent(item.path) + ')' }}
                                            />                               
                                        </NavLink>
                                    </div>
                                    <div className="second">
                                        <div className="shit">
                                            <div className="one">
                                                <NavLink className="black" to={'/item/'+item.title.replace(/ /g,'_')}><h4>{item.title}</h4></NavLink>
                                                {item.variations.map((v, i) => <div key={i}>{v.title + ': ' + v.selected.title}</div>)} 
                                                <br/>
                                                <div><b>Quantity: </b><div className="inlineBlock"><Quantity value={item.quantity} callback={q => this.changeQuantity(i, q)} /></div></div>
                                            </div>
                                            <div className="two">
                                                <h4>£{item.calculatedPrice.toFixed(2)}</h4>
                                                <button className="red" onClick={()=>this.remove(i)}>Remove</button>
                                            </div>  
                                        </div>
                                    </div>
                                </div></div>)}
                                </div>
                                <div className="right">
                                    <div>
                                        <h2 style={{marginBottom:0}}>{basket.reduce((a,b)=>a+b.quantity,0)} items</h2>
                                        <h2>Total: £{total.toFixed(2)}</h2>
                                    </div>
                                    <button onClick={this.buy} className="green">Buy now</button>
                                </div>
                            </div> : <div className="box">
                                    <div>You have nothing in your basket!</div>
                                    <br/>
                                    <button className="green" onClick={() => this.props.navigate('/shop/')}>Buy something!</button>
                                </div>}
                    </div>
                </div>
            </div>
        </div>
    }
}