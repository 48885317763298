import React, { useState } from 'react';

export default function A({ onClick, className, children, onMouseDown, onMouseEnter, disabled }) {
    if (disabled) return <span className="disabledA">{children}</span>
    return <span
        className={className ? "A " + className : "A"}
        onClick={onClick}
        onMouseDown={onMouseDown}
        onMouseEnter={onMouseEnter}
    >{children}</span>
}