import React, { useState, useRef } from 'react';
import axios from 'axios';
import A from './A';
import { p } from '../../hooks';

export default function U({ callback = () => { } }) {
    let [uploading, setUploading] = useState();
    let [error, setError] = useState('');
    let ref = useRef();

    function fileSelected(e) {
        setError('');
        let { files } = e.target;
        let array = [];
        for (let i = 0; i < files.length; i++) array.push(files[i]);
        if (array.length > 0) {
            upload(array);
        }
    }

    async function upload(files) {
        setUploading(true);
        setError('');
        let error;
        function uploadOne(file) {
            return new Promise(resolve => {
                axios({
                    method: 'post',
                    url: '/api/upload',
                    data: file,
                    config: { headers: { 'Content-Type': 'multipart/form-data' } }
                }).then(async res => {
                    resolve(res.data);
                });
            })
        }

        for (let i = 0; i < files.length; i++) {
            let fd = new FormData();
            fd.append('picture', files[i]);
            let { name } = files[i];
            let { data } = await axios.post('/api/prepareUpload', { p:p() });
            if (data) {
                let res = await uploadOne(fd);
                if (res.error) {
                    error = res.error;
                    break;
                } else {
                    callback(res);
                }
            } else {
                error = "Error preparing upload";
                break;
            }
        }

        setUploading(false);
        if (error) setError(error);
        if (ref.current) {
            ref.current.value = "";
        }
    }

    return <div className="uploader">
        {uploading ? <div className="uploadButton">…</div> :<A onClick={()=>ref.current.click()}><div className="uploadButton">+</div></A>}
        <div className="hidden">
            <input type="file" onChange={fileSelected} multiple ref={ref} />
        </div>
        {error ? <div className="error">{error}</div> : null}
    </div>
}