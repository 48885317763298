import React, { useState, useEffect, useRef, useContext } from 'react';
import AdminTabs from './AdminTabs';
import Tabs from './Tabs';
import Pages from './Pages';
import axios from 'axios';
import Paragraph from '../Paragraph';
import { AdminContext, p, useClickOutside, useMount } from '../../hooks';
import Q from './Q';

export default function Orders() {
    let [tab, setTab] = useState('Unfulfilled');
    let [page, setPage] = useState(0);
    let [pages, setPages] = useState(0);
    let [orders, setOrders] = useState([]);
    let [loading, setLoading] = useState(false);
    let getBg = useRef();

    useEffect(() => {
        setBackgroundColour("Admin");
        return () => setBackgroundColour(false);
    }, [])

    useEffect(() => {
        setPage(0);
    },[tab])

    useEffect(() => {
        (async () => {
            setLoading(true);
            let { data } = await axios.post('/api/orders', { p:p(), fulfilled: tab == "Fulfilled", page: page });
            setPages(data.pages);
            let orders = data.orders;
            setOrders(orders);
            setLoading(false);
        })()
    }, [tab, page]);

    let { admin, setBackgroundColour } = useContext(AdminContext);
    if (!admin) return null;
    
    return <div className="Orders" ref={getBg}>
        <AdminTabs tab="Orders" />
        <h1>Orders</h1>
        <Tabs tab={tab} setTab={setTab} tabs={['Unfulfilled', 'Fulfilled']} />
        <Pages page={page} pages={pages} setPage={setPage} />
        
        {loading ? <div className="i">Loading...</div> : orders.length==0 ? <div className="i">Nothing here...</div> : orders.map((o, i) => <Order
            key={tab + ' ' + page + ' ' + i}
            order={o}
            deleteMe={() => {
                orders.splice(i, 1);
                setOrders(orders.slice());
            }}
        />)}
    </div>
}

function Order({ order, deleteMe }) {
    let [age, setAge] = useState('');
    let [expand, setExpand] = useState(false);
    let { componentDidMount } = useMount();

    componentDidMount(() => {
        let d = new Date(order.stamp);
        let now = new Date().getTime();
        let age = now - d.getTime();
        age = Math.round(age / (1000 * 60 * 60 * 24));
        if (age == 0) {
            age = 'Today';
        } else if (age == 1) {
            age = 'Yesterday';
        } else {
            age = age + ' days old';
        }
        setAge(age);
    })
    return <>
        {expand ? <Expanded order={order} deleteMe={deleteMe} close={() => setExpand(false)}/> : null}
        <div className={order.success?"order":"order red"}  onClick={() => { setExpand(true) }}>
            <div className="title">
                <div className="left">
                    {order.name}
                </div>
                <div>
                    {age}
                </div>
            </div>
            <div className="indent">
                {order.message.length > 0 ? <div className="mb">
                    <Paragraph text={order.message} noGap />
                </div> : null}

                <div className="items">
                    {order.items.map((item, i) =>
                        <div key={i} className="item">
                            <div className="left">{item.title}</div>
                            <div className="right">
                                <div>{'Quantity: '}<b>{item.quantity}</b></div>
                                {item.variations.map((v, i) => 
                                    <div key={'v'+i+' '+v.title}>{v.title+': '+v.selected.title}</div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    </>
}

function Expanded({ order, close, deleteMe }) {
    let [date, setDate] = useState('');
    let [fulfilled, setFulfilled] = useState('');
    let [response, setResponse] = useState('');

    let [ref, onClickOutside] = useClickOutside();
    let { componentDidMount } = useMount();

    onClickOutside(close)

    componentDidMount(() => {
        let d = new Date(order.stamp);
        let dd = d.getDate();
        if (dd < 10) dd = '0' + dd;
        let mm = d.getMonth() + 1;
        if (mm < 10) mm = '0' + mm;
        let yy = d.getFullYear();
        let date = dd + '/' + mm + '/' + yy;
        setDate(date);

        let fulfilled;
        if (order.fulfilled) {
            d = new Date(order.fulfilled);
            let dd = d.getDate();
            if (dd < 10) dd = '0' + dd;
            let mm = d.getMonth() + 1;
            if (mm < 10) mm = '0' + mm;
            let yy = d.getFullYear();
            fulfilled = dd + '/' + mm + '/' + yy;
        }
        setFulfilled(fulfilled);
    })
    return <div className="expanded">
        <div className={order.success?"window":"window red"} ref={ref}>
            <div className="title">
                <div className="left">
                    {order.name}
                </div>
            </div>
            {order.message.length > 0 ? <div className="mb">
                    <Paragraph text={order.message} noGap />
                </div> : null}
            <div className="items">
                {order.items.map((item, i) =>
                    <div key={i} className="item">
                        <div className="left">{item.title}</div>
                        <div className="right">
                            <div>{'Quantity: '}<b>{item.quantity}</b></div>
                            {item.variations.map((v, i) => 
                                <div key={'v'+i+' '+v.title}>{v.title+': '+v.selected.title}</div>
                            )}
                        </div>
                    </div>
                )}
            </div>

            <div className="info">
                <div className="left">Ordered on</div>
                <div className="right">{date}</div>
            </div>
            {fulfilled?<div className="info">
                <div className="left">Fulfilled on</div>
                <div className="right">{fulfilled}</div>
            </div>:null}
            <div className="info">
                <div className="left">Order ID</div>
                <div className="right">{order._id}</div>
            </div>
            <div className="info">
                <div className="left">Country</div>
                <div className="right">{order.country}</div>
            </div>
            <div className="info">
                <div className="left">Email</div>
                <div className="right"><a href={"mailto:"+order.email}>{order.email}</a></div>
            </div>
            <div className="info">
                <div className="left">Address</div>
                <div className="right">
                    <Paragraph text={order.name + '\r\n' + order.address} noGap />
                    <a target="_blank" href={'https://www.google.com/maps/place/'+order.address.replace(/ /g,'+').replace(/\n/g,',')}><i>Google maps</i></a>
                </div>
            </div>
            <div className="info">
                <div className="left">Phone</div>
                <div className="right">{order.phone}</div>
            </div>
            {order.voucher?<div className="info">
                <div className="left">Voucher</div>
                <div className="right">{order.voucher.description+': '+(order.voucher.discount*100)+'%'}</div>
            </div>:null}
            <div className="info">
                <div className="left">Total</div>
                <div className="right">£{order.total.toFixed(2)}</div>
            </div>
            <div className="info">
                <div className="left">Stripe</div>
                <div className="right"><a target="_blank" rel="noopener noreferrer" href={'https://dashboard.stripe.com/payments/'+order.stripe}>{order.stripe}</a></div>
            </div>
            {!order.success?null:<div className="info">
                <div className="left">Response</div>
                <div className="right">
                    <input
                        type="text"
                        value={response}
                        onChange={e=>setResponse(e.target.value)}
                    />
                </div>
            </div>}

            {!order.fulfilled ? <div className="mt horizontalButtons">
                <Q callback={async () => {
                    deleteMe();
                    close();
                    await axios.post('/api/fulfill/', { p:p(), id: order._id, message: response });
                }} >{order.success?'Fulfill order':'Delete'}</Q>
                {order.success?<Q callback={async () => {
                    deleteMe();
                    close();
                    await axios.post('/api/fulfill/', { p:p(), id: order._id, message: response, silent: true });
                }} >{'Fulfill silently'}</Q>:null}
            </div> : null}
            
        </div>
    </div>
}