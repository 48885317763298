import React, { Component, useEffect } from 'react';
import fire from '../../pics/fire.gif';
import ad from '../../pics/ad1.gif';
import ad2 from '../../pics/adz.gif';
import pent from '../../pics/pent.gif';
import { ReactP5Wrapper, P5Instance } from 'react-p5-wrapper';

import { Navigate, useNavigate } from 'react-router-dom';

import './train.css';
import { secretItems } from '../../hooks';

let stations = [
  {"name": "ABBEY WOOD","code": "ABW"},
  {"name": "ABER","code": "ABE"},
  {"name": "ABERCYNON","code": "ACY"},
  {"name": "ABERDARE","code": "ABA"},
  {"name": "ABERDEEN","code": "ABD"},
  {"name": "ABERDOUR","code": "AUR"},
  {"name": "ABERDOVEY","code": "AVY"},
  {"name": "ABERERCH","code": "ABH"},
  {"name": "ABERGAVENNY","code": "AGV"},
  {"name": "ABERGELE & PENSARN","code": "AGL"},
  {"name": "ABERYSTWYTH","code": "AYW"},
  {"name": "ACCRINGTON","code": "ACR"},
  {"name": "ACHANALT","code": "AAT"},
  {"name": "ACHNASHEEN","code": "ACN"},
  {"name": "ACHNASHELLACH","code": "ACH"},
  {"name": "ACKLINGTON","code": "ACK"},
  {"name": "ACLE","code": "ACL"},
  {"name": "ACOCKS GREEN","code": "ACG"},
  {"name": "ACTON BRIDGE (CHESHIRE)","code": "ACB"},
  {"name": "ACTON CENTRAL","code": "ACC"},
  {"name": "ACTON MAIN LINE","code": "AML"},
  {"name": "ADDERLEY PARK","code": "ADD"},
  {"name": "ADDIEWELL","code": "ADW"},
  {"name": "ADDLESTONE","code": "ASN"},
  {"name": "ADISHAM","code": "ADM"},
  {"name": "ADLINGTON (CHESHIRE)","code": "ADC"},
  {"name": "ADLINGTON (LANCS)","code": "ADL"},
  {"name": "ADWICK","code": "AWK"},
  {"name": "AIGBURTH","code": "AIG"},
  {"name": "AINSDALE","code": "ANS"},
  {"name": "AINTREE","code": "AIN"},
  {"name": "AIRBLES","code": "AIR"},
  {"name": "AIRDRIE","code": "ADR"},
  {"name": "ALBANY PARK","code": "AYP"},
  {"name": "ALBRIGHTON","code": "ALB"},
  {"name": "ALDERLEY EDGE","code": "ALD"},
  {"name": "ALDERMASTON","code": "AMT"},
  {"name": "ALDERSHOT","code": "AHT"},
  {"name": "ALDRINGTON","code": "AGT"},
  {"name": "ALEXANDRA PALACE","code": "AAP"},
  {"name": "ALEXANDRA PARADE","code": "AXP"},
  {"name": "ALEXANDRIA","code": "ALX"},
  {"name": "ALFRETON","code": "ALF"},
  {"name": "ALLENS WEST","code": "ALW"},
  {"name": "ALLOA","code": "ALO"},
  {"name": "ALNESS","code": "ASS"},
  {"name": "ALNMOUTH","code": "ALM"},
  {"name": "ALRESFORD (ESSEX)","code": "ALR"},
  {"name": "ALSAGER","code": "ASG"},
  {"name": "ALTHORNE (ESSEX)","code": "ALN"},
  {"name": "ALTHORPE","code": "ALP"},
  {"name": "ALTNABREAC","code": "ABC"},
  {"name": "ALTON","code": "AON"},
  {"name": "ALTRINCHAM","code": "ALT"},
  {"name": "ALVECHURCH","code": "ALV"},
  {"name": "AMBERGATE","code": "AMB"},
  {"name": "AMBERLEY","code": "AMY"},
  {"name": "AMERSHAM","code": "AMR"},
  {"name": "AMMANFORD","code": "AMF"},
  {"name": "ANCASTER","code": "ANC"},
  {"name": "ANDERSTON","code": "AND"},
  {"name": "ANDOVER","code": "ADV"},
  {"name": "ANERLEY","code": "ANZ"},
  {"name": "ANGEL ROAD","code": "AGR"},
  {"name": "ANGMERING","code": "ANG"},
  {"name": "ANNAN","code": "ANN"},
  {"name": "ANNIESLAND","code": "ANL"},
  {"name": "ANSDELL & FAIRHAVEN","code": "AFV"},
  {"name": "APPERLEY BRIDGE","code": "APY"},
  {"name": "APPLEBY","code": "APP"},
  {"name": "APPLEDORE (KENT)","code": "APD"},
  {"name": "APPLEFORD","code": "APF"},
  {"name": "APPLEY BRIDGE","code": "APB"},
  {"name": "APSLEY","code": "APS"},
  {"name": "ARBROATH","code": "ARB"},
  {"name": "ARDGAY","code": "ARD"},
  {"name": "ARDLUI","code": "AUI"},
  {"name": "ARDROSSAN HARBOUR","code": "ADS"},
  {"name": "ARDROSSAN SOUTH BEACH","code": "ASB"},
  {"name": "ARDROSSAN TOWN","code": "ADN"},
  {"name": "ARDWICK","code": "ADK"},
  {"name": "ARGYLE STREET","code": "AGS"},
  {"name": "ARISAIG","code": "ARG"},
  {"name": "ARLESEY","code": "ARL"},
  {"name": "ARMADALE (WEST LOTHIAN)","code": "ARM"},
  {"name": "ARMATHWAITE","code": "AWT"},
  {"name": "ARNSIDE","code": "ARN"},
  {"name": "ARRAM","code": "ARR"},
  {"name": "ARROCHAR & TARBET","code": "ART"},
  {"name": "ARUNDEL","code": "ARU"},
  {"name": "ASCOT (BERKS)","code": "ACT"},
  {"name": "ASCOTT-UNDER-WYCHWOOD","code": "AUW"},
  {"name": "ASH","code": "ASH"},
  {"name": "ASH VALE","code": "AHV"},
  {"name": "ASHBURYS","code": "ABY"},
  {"name": "ASHCHURCH FOR TEWKESBURY","code": "ASC"},
  {"name": "ASHFIELD","code": "ASF"},
  {"name": "ASHFORD (SURREY)","code": "AFS"},
  {"name": "ASHFORD INTERNATIONAL","code": "AFK"},
  {"name": "ASHFORD INTERNATIONAL (EUROSTAR)","code": "ASI"},
  {"name": "ASHLEY","code": "ASY"},
  {"name": "ASHTEAD","code": "AHD"},
  {"name": "ASHTON-UNDER-LYNE","code": "AHN"},
  {"name": "ASHURST (KENT)","code": "AHS"},
  {"name": "ASHURST NEW FOREST","code": "ANF"},
  {"name": "ASHWELL & MORDEN","code": "AWM"},
  {"name": "ASKAM","code": "ASK"},
  {"name": "ASLOCKTON","code": "ALK"},
  {"name": "ASPATRIA","code": "ASP"},
  {"name": "ASPLEY GUISE","code": "APG"},
  {"name": "ASTON","code": "AST"},
  {"name": "ATHERSTONE","code": "ATH"},
  {"name": "ATHERTON","code": "ATN"},
  {"name": "ATTADALE","code": "ATT"},
  {"name": "ATTENBOROUGH","code": "ATB"},
  {"name": "ATTLEBOROUGH","code": "ATL"},
  {"name": "AUCHINLECK","code": "AUK"},
  {"name": "AUDLEY END","code": "AUD"},
  {"name": "AUGHTON PARK","code": "AUG"},
  {"name": "AVIEMORE","code": "AVM"},
  {"name": "AVONCLIFF","code": "AVF"},
  {"name": "AVONMOUTH","code": "AVN"},
  {"name": "AXMINSTER","code": "AXM"},
  {"name": "AYLESBURY","code": "AYS"},
  {"name": "AYLESBURY VALE PARKWAY","code": "AVP"},
  {"name": "AYLESFORD","code": "AYL"},
  {"name": "AYLESHAM","code": "AYH"},
  {"name": "AYR","code": "AYR"},
  {"name": "BACHE","code": "BAC"},
  {"name": "BAGLAN","code": "BAJ"},
  {"name": "BAGSHOT","code": "BAG"},
  {"name": "BAILDON","code": "BLD"},
  {"name": "BAILLIESTON","code": "BIO"},
  {"name": "BALCOMBE","code": "BAB"},
  {"name": "BALDOCK","code": "BDK"},
  {"name": "BALHAM","code": "BAL"},
  {"name": "BALLOCH","code": "BHC"},
  {"name": "BALMOSSIE","code": "BSI"},
  {"name": "BAMBER BRIDGE","code": "BMB"},
  {"name": "BAMFORD","code": "BAM"},
  {"name": "BANAVIE","code": "BNV"},
  {"name": "BANBURY","code": "BAN"},
  {"name": "BANGOR (GWYNEDD)","code": "BNG"},
  {"name": "BANK HALL","code": "BAH"},
  {"name": "BANSTEAD","code": "BAD"},
  {"name": "BARASSIE","code": "BSS"},
  {"name": "BARBICAN","code": "ZBB"},
  {"name": "BARDON MILL","code": "BLL"},
  {"name": "BARE LANE","code": "BAR"},
  {"name": "BARGEDDIE","code": "BGI"},
  {"name": "BARGOED","code": "BGD"},
  {"name": "BARKING","code": "BKG"},
  {"name": "BARLASTON","code": "BRT"},
  {"name": "BARMING","code": "BMG"},
  {"name": "BARMOUTH","code": "BRM"},
  {"name": "BARNEHURST","code": "BNH"},
  {"name": "BARNES","code": "BNS"},
  {"name": "BARNES BRIDGE","code": "BNI"},
  {"name": "BARNETBY","code": "BTB"},
  {"name": "BARNHAM","code": "BAA"},
  {"name": "BARNHILL","code": "BNL"},
  {"name": "BARNSLEY","code": "BNY"},
  {"name": "BARNSTAPLE","code": "BNP"},
  {"name": "BARNT GREEN","code": "BTG"},
  {"name": "BARRHEAD","code": "BRR"},
  {"name": "BARRHILL","code": "BRL"},
  {"name": "BARROW HAVEN","code": "BAV"},
  {"name": "BARROW-IN-FURNESS","code": "BIF"},
  {"name": "BARROW-UPON-SOAR","code": "BWS"},
  {"name": "BARRY","code": "BRY"},
  {"name": "BARRY DOCKS","code": "BYD"},
  {"name": "BARRY ISLAND","code": "BYI"},
  {"name": "BARRY LINKS","code": "BYL"},
  {"name": "BARTON-ON-HUMBER","code": "BAU"},
  {"name": "BASILDON","code": "BSO"},
  {"name": "BASINGSTOKE","code": "BSK"},
  {"name": "BAT & BALL","code": "BBL"},
  {"name": "BATH SPA","code": "BTH"},
  {"name": "BATHGATE","code": "BHG"},
  {"name": "BATLEY","code": "BTL"},
  {"name": "BATTERSBY","code": "BTT"},
  {"name": "BATTERSEA PARK","code": "BAK"},
  {"name": "BATTLE","code": "BAT"},
  {"name": "BATTLESBRIDGE","code": "BLB"},
  {"name": "BAYFORD","code": "BAY"},
  {"name": "BEACONSFIELD","code": "BCF"},
  {"name": "BEARLEY","code": "BER"},
  {"name": "BEARSDEN","code": "BRN"},
  {"name": "BEARSTED","code": "BSD"},
  {"name": "BEASDALE","code": "BSL"},
  {"name": "BEAULIEU ROAD","code": "BEU"},
  {"name": "BEAULY","code": "BEL"},
  {"name": "BEBINGTON","code": "BEB"},
  {"name": "BECCLES","code": "BCC"},
  {"name": "BECKENHAM HILL","code": "BEC"},
  {"name": "BECKENHAM JUNCTION","code": "BKJ"},
  {"name": "BEDFORD","code": "BDM"},
  {"name": "BEDFORD ST JOHNS","code": "BSJ"},
  {"name": "BEDHAMPTON","code": "BDH"},
  {"name": "BEDMINSTER","code": "BMT"},
  {"name": "BEDWORTH","code": "BEH"},
  {"name": "BEDWYN","code": "BDW"},
  {"name": "BEESTON","code": "BEE"},
  {"name": "BEKESBOURNE","code": "BKS"},
  {"name": "BELLE VUE","code": "BLV"},
  {"name": "BELLGROVE","code": "BLG"},
  {"name": "BELLINGHAM","code": "BGM"},
  {"name": "BELLSHILL","code": "BLH"},
  {"name": "BELMONT","code": "BLM"},
  {"name": "BELPER","code": "BLP"},
  {"name": "BELTRING","code": "BEG"},
  {"name": "BELVEDERE","code": "BVD"},
  {"name": "BEMPTON","code": "BEM"},
  {"name": "BEN RHYDDING","code": "BEY"},
  {"name": "BENFLEET","code": "BEF"},
  {"name": "BENTHAM","code": "BEN"},
  {"name": "BENTLEY (HANTS)","code": "BTY"},
  {"name": "BENTLEY (SOUTH YORKS)","code": "BYK"},
  {"name": "BERE ALSTON","code": "BAS"},
  {"name": "BERE FERRERS","code": "BFE"},
  {"name": "BERKHAMSTED","code": "BKM"},
  {"name": "BERKSWELL","code": "BKW"},
  {"name": "BERMUDA PARK","code": "BEP"},
  {"name": "BERNEY ARMS","code": "BYA"},
  {"name": "BERRY BROW","code": "BBW"},
  {"name": "BERRYLANDS","code": "BRS"},
  {"name": "BERWICK (SUSSEX)","code": "BRK"},
  {"name": "BERWICK-UPON-TWEED","code": "BWK"},
  {"name": "BESCAR LANE","code": "BES"},
  {"name": "BESCOT STADIUM","code": "BSC"},
  {"name": "BETCHWORTH","code": "BTO"},
  {"name": "BETHNAL GREEN","code": "BET"},
  {"name": "BETWS-Y-COED","code": "BYC"},
  {"name": "BEVERLEY","code": "BEV"},
  {"name": "BEXHILL","code": "BEX"},
  {"name": "BEXLEY","code": "BXY"},
  {"name": "BEXLEYHEATH","code": "BXH"},
  {"name": "BICESTER NORTH","code": "BCS"},
  {"name": "BICESTER VILLAGE","code": "BIT"},
  {"name": "BICKLEY","code": "BKL"},
  {"name": "BIDSTON","code": "BID"},
  {"name": "BIGGLESWADE","code": "BIW"},
  {"name": "BILBROOK","code": "BBK"},
  {"name": "BILLERICAY","code": "BIC"},
  {"name": "BILLINGHAM (CLEVELAND)","code": "BIL"},
  {"name": "BILLINGSHURST","code": "BIG"},
  {"name": "BINGHAM","code": "BIN"},
  {"name": "BINGLEY","code": "BIY"},
  {"name": "BIRCHGROVE","code": "BCG"},
  {"name": "BIRCHINGTON-ON-SEA","code": "BCH"},
  {"name": "BIRCHWOOD","code": "BWD"},
  {"name": "BIRKBECK","code": "BIK"},
  {"name": "BIRKDALE","code": "BDL"},
  {"name": "BIRKENHEAD CENTRAL","code": "BKC"},
  {"name": "BIRKENHEAD HAMILTON SQUARE","code": "BKQ"},
  {"name": "BIRKENHEAD NORTH","code": "BKN"},
  {"name": "BIRKENHEAD PARK","code": "BKP"},
  {"name": "BIRMINGHAM INTERNATIONAL","code": "BHI"},
  {"name": "BIRMINGHAM MOOR STREET","code": "BMO"},
  {"name": "BIRMINGHAM NEW STREET","code": "BHM"},
  {"name": "BIRMINGHAM SNOW HILL","code": "BSW"},
  {"name": "BISHOP AUCKLAND","code": "BIA"},
  {"name": "BISHOPBRIGGS","code": "BBG"},
  {"name": "BISHOPS STORTFORD","code": "BIS"},
  {"name": "BISHOPSTONE (SUSSEX)","code": "BIP"},
  {"name": "BISHOPTON (STRATHCLYDE)","code": "BPT"},
  {"name": "BITTERNE","code": "BTE"},
  {"name": "BLACKBURN","code": "BBN"},
  {"name": "BLACKHEATH","code": "BKH"},
  {"name": "BLACKHORSE ROAD","code": "BHO"},
  {"name": "BLACKPOOL NORTH","code": "BPN"},
  {"name": "BLACKPOOL PLEASURE BEACH","code": "BPB"},
  {"name": "BLACKPOOL SOUTH","code": "BPS"},
  {"name": "BLACKRIDGE","code": "BKR"},
  {"name": "BLACKROD","code": "BLK"},
  {"name": "BLACKWATER","code": "BAW"},
  {"name": "BLAENAU FFESTINIOG","code": "BFF"},
  {"name": "BLAIR ATHOLL","code": "BLA"},
  {"name": "BLAIRHILL","code": "BAI"},
  {"name": "BLAKE STREET","code": "BKT"},
  {"name": "BLAKEDOWN","code": "BKD"},
  {"name": "BLANTYRE","code": "BLT"},
  {"name": "BLAYDON","code": "BLO"},
  {"name": "BLEASBY","code": "BSB"},
  {"name": "BLETCHLEY","code": "BLY"},
  {"name": "BLOXWICH","code": "BLX"},
  {"name": "BLOXWICH NORTH","code": "BWN"},
  {"name": "BLUNDELLSANDS & CROSBY","code": "BLN"},
  {"name": "BLYTHE BRIDGE","code": "BYB"},
  {"name": "BODMIN PARKWAY","code": "BOD"},
  {"name": "BODORGAN","code": "BOR"},
  {"name": "BOGNOR REGIS","code": "BOG"},
  {"name": "BOGSTON","code": "BGS"},
  {"name": "BOLTON","code": "BON"},
  {"name": "BOLTON-UPON-DEARNE","code": "BTD"},
  {"name": "BOOKHAM","code": "BKA"},
  {"name": "BOOTLE (CUMBRIA)","code": "BOC"},
  {"name": "BOOTLE NEW STRAND","code": "BNW"},
  {"name": "BOOTLE ORIEL ROAD","code": "BOT"},
  {"name": "BORDESLEY","code": "BBS"},
  {"name": "BOROUGH GREEN & WROTHAM","code": "BRG"},
  {"name": "BORTH","code": "BRH"},
  {"name": "BOSHAM","code": "BOH"},
  {"name": "BOSTON","code": "BSN"},
  {"name": "BOTLEY","code": "BOE"},
  {"name": "BOTTESFORD","code": "BTF"},
  {"name": "BOURNE END","code": "BNE"},
  {"name": "BOURNEMOUTH","code": "BMH"},
  {"name": "BOURNVILLE","code": "BRV"},
  {"name": "BOW BRICKHILL","code": "BWB"},
  {"name": "BOWES PARK","code": "BOP"},
  {"name": "BOWLING","code": "BWG"},
  {"name": "BOX HILL & WESTHUMBLE","code": "BXW"},
  {"name": "BRACKNELL","code": "BCE"},
  {"name": "BRADFORD FORSTER SQUARE","code": "BDQ"},
  {"name": "BRADFORD INTERCHANGE","code": "BDI"},
  {"name": "BRADFORD-ON-AVON","code": "BOA"},
  {"name": "BRADING","code": "BDN"},
  {"name": "BRAINTREE","code": "BTR"},
  {"name": "BRAINTREE FREEPORT","code": "BTP"},
  {"name": "BRAMHALL","code": "BML"},
  {"name": "BRAMLEY (HANTS)","code": "BMY"},
  {"name": "BRAMLEY (W YORKS)","code": "BLE"},
  {"name": "BRAMPTON (CUMBRIA)","code": "BMP"},
  {"name": "BRAMPTON (SUFFOLK)","code": "BRP"},
  {"name": "BRANCHTON","code": "BCN"},
  {"name": "BRANDON","code": "BND"},
  {"name": "BRANKSOME","code": "BSM"},
  {"name": "BRAYSTONES (CUMBRIA)","code": "BYS"},
  {"name": "BREDBURY","code": "BDY"},
  {"name": "BREICH","code": "BRC"},
  {"name": "BRENTFORD","code": "BFD"},
  {"name": "BRENTWOOD","code": "BRE"},
  {"name": "BRICKET WOOD","code": "BWO"},
  {"name": "BRIDGE OF ALLAN","code": "BEA"},
  {"name": "BRIDGE OF ORCHY","code": "BRO"},
  {"name": "BRIDGEND","code": "BGN"},
  {"name": "BRIDGETON","code": "BDG"},
  {"name": "BRIDGWATER","code": "BWT"},
  {"name": "BRIDLINGTON","code": "BDT"},
  {"name": "BRIERFIELD","code": "BRF"},
  {"name": "BRIGG","code": "BGG"},
  {"name": "BRIGHOUSE","code": "BGH"},
  {"name": "BRIGHTON (EAST SUSSEX)","code": "BTN"},
  {"name": "BRIMSDOWN","code": "BMD"},
  {"name": "BRINNINGTON","code": "BNT"},
  {"name": "BRISTOL PARKWAY","code": "BPW"},
  {"name": "BRISTOL TEMPLE MEADS","code": "BRI"},
  {"name": "BRITHDIR","code": "BHD"},
  {"name": "BRITON FERRY","code": "BNF"},
  {"name": "BRIXTON","code": "BRX"},
  {"name": "BROAD GREEN","code": "BGE"},
  {"name": "BROADBOTTOM","code": "BDB"},
  {"name": "BROADSTAIRS","code": "BSR"},
  {"name": "BROCKENHURST","code": "BCU"},
  {"name": "BROCKHOLES","code": "BHS"},
  {"name": "BROCKLEY","code": "BCY"},
  {"name": "BROMBOROUGH","code": "BOM"},
  {"name": "BROMBOROUGH RAKE","code": "BMR"},
  {"name": "BROMLEY CROSS (LANCS)","code": "BMC"},
  {"name": "BROMLEY NORTH","code": "BMN"},
  {"name": "BROMLEY SOUTH","code": "BMS"},
  {"name": "BROMSGROVE","code": "BMV"},
  {"name": "BRONDESBURY","code": "BSY"},
  {"name": "BRONDESBURY PARK","code": "BSP"},
  {"name": "BROOKMANS PARK","code": "BPK"},
  {"name": "BROOKWOOD","code": "BKO"},
  {"name": "BROOME","code": "BME"},
  {"name": "BROOMFLEET","code": "BMF"},
  {"name": "BRORA","code": "BRA"},
  {"name": "BROUGH","code": "BUH"},
  {"name": "BROUGHTY FERRY","code": "BYF"},
  {"name": "BROXBOURNE","code": "BXB"},
  {"name": "BRUCE GROVE","code": "BCV"},
  {"name": "BRUNDALL","code": "BDA"},
  {"name": "BRUNDALL GARDENS","code": "BGA"},
  {"name": "BRUNSTANE","code": "BSU"},
  {"name": "BRUNSWICK","code": "BRW"},
  {"name": "BRUTON","code": "BRU"},
  {"name": "BRYN","code": "BYN"},
  {"name": "BUCKENHAM (NORFOLK)","code": "BUC"},
  {"name": "BUCKLEY","code": "BCK"},
  {"name": "BUCKNELL","code": "BUK"},
  {"name": "BUCKSHAW PARKWAY","code": "BSV"},
  {"name": "BUGLE","code": "BGL"},
  {"name": "BUILTH ROAD","code": "BHR"},
  {"name": "BULWELL","code": "BLW"},
  {"name": "BURES","code": "BUE"},
  {"name": "BURGESS HILL","code": "BUG"},
  {"name": "BURLEY PARK","code": "BUY"},
  {"name": "BURLEY-IN-WHARFEDALE","code": "BUW"},
  {"name": "BURNAGE","code": "BNA"},
  {"name": "BURNESIDE (CUMBRIA)","code": "BUD"},
  {"name": "BURNHAM (BUCKS)","code": "BNM"},
  {"name": "BURNHAM-ON-CROUCH","code": "BUU"},
  {"name": "BURNLEY BARRACKS","code": "BUB"},
  {"name": "BURNLEY CENTRAL","code": "BNC"},
  {"name": "BURNLEY MANCHESTER ROAD","code": "BYM"},
  {"name": "BURNSIDE (STRATHCLYDE)","code": "BUI"},
  {"name": "BURNTISLAND","code": "BTS"},
  {"name": "BURSCOUGH BRIDGE","code": "BCB"},
  {"name": "BURSCOUGH JUNCTION","code": "BCJ"},
  {"name": "BURSLEDON","code": "BUO"},
  {"name": "BURTON JOYCE","code": "BUJ"},
  {"name": "BURTON-ON-TRENT","code": "BUT"},
  {"name": "BURY ST EDMUNDS","code": "BSE"},
  {"name": "BUSBY","code": "BUS"},
  {"name": "BUSH HILL PARK","code": "BHK"},
  {"name": "BUSHEY","code": "BSH"},
  {"name": "BUTLERS LANE","code": "BUL"},
  {"name": "BUXTED","code": "BXD"},
  {"name": "BUXTON","code": "BUX"},
  {"name": "BYFLEET & NEW HAW","code": "BFN"},
  {"name": "BYNEA","code": "BYE"},
  {"name": "CADOXTON","code": "CAD"},
  {"name": "CAERGWRLE","code": "CGW"},
  {"name": "CAERPHILLY","code": "CPH"},
  {"name": "CAERSWS","code": "CWS"},
  {"name": "CALDERCRUIX","code": "CAC"},
  {"name": "CALDICOT","code": "CDT"},
  {"name": "CALEDONIAN RD & BARNSBURY","code": "CIR"},
  {"name": "CALSTOCK","code": "CSK"},
  {"name": "CAM & DURSLEY","code": "CDU"},
  {"name": "CAMBERLEY","code": "CAM"},
  {"name": "CAMBORNE","code": "CBN"},
  {"name": "CAMBRIDGE","code": "CBG"},
  {"name": "CAMBRIDGE HEATH","code": "CBH"},
  {"name": "CAMBUSLANG","code": "CBL"},
  {"name": "CAMDEN ROAD","code": "CMD"},
  {"name": "CAMELON","code": "CMO"},
  {"name": "CANADA WATER","code": "ZCW"},
  {"name": "CANLEY","code": "CNL"},
  {"name": "CANNOCK","code": "CAO"},
  {"name": "CANONBURY","code": "CNN"},
  {"name": "CANTERBURY EAST","code": "CBE"},
  {"name": "CANTERBURY WEST","code": "CBW"},
  {"name": "CANTLEY","code": "CNY"},
  {"name": "CAPENHURST","code": "CPU"},
  {"name": "CARBIS BAY","code": "CBB"},
  {"name": "CARDENDEN","code": "CDD"},
  {"name": "CARDIFF BAY","code": "CDB"},
  {"name": "CARDIFF CENTRAL","code": "CDF"},
  {"name": "CARDIFF QUEEN STREET","code": "CDQ"},
  {"name": "CARDONALD","code": "CDO"},
  {"name": "CARDROSS","code": "CDR"},
  {"name": "CARFIN","code": "CRF"},
  {"name": "CARK & CARTMEL","code": "CAK"},
  {"name": "CARLISLE","code": "CAR"},
  {"name": "CARLTON","code": "CTO"},
  {"name": "CARLUKE","code": "CLU"},
  {"name": "CARMARTHEN","code": "CMN"},
  {"name": "CARMYLE","code": "CML"},
  {"name": "CARNFORTH","code": "CNF"},
  {"name": "CARNOUSTIE","code": "CAN"},
  {"name": "CARNTYNE","code": "CAY"},
  {"name": "CARPENDERS PARK","code": "CPK"},
  {"name": "CARRBRIDGE","code": "CAG"},
  {"name": "CARSHALTON","code": "CSH"},
  {"name": "CARSHALTON BEECHES","code": "CSB"},
  {"name": "CARSTAIRS","code": "CRS"},
  {"name": "CARTSDYKE","code": "CDY"},
  {"name": "CASTLE BAR PARK","code": "CBP"},
  {"name": "CASTLE CARY","code": "CLC"},
  {"name": "CASTLEFORD","code": "CFD"},
  {"name": "CASTLETON (MANCHESTER)","code": "CAS"},
  {"name": "CASTLETON MOOR","code": "CSM"},
  {"name": "CATERHAM","code": "CAT"},
  {"name": "CATFORD","code": "CTF"},
  {"name": "CATFORD BRIDGE","code": "CFB"},
  {"name": "CATHAYS","code": "CYS"},
  {"name": "CATHCART","code": "CCT"},
  {"name": "CATTAL","code": "CTL"},
  {"name": "CAUSELAND","code": "CAU"},
  {"name": "CEFN-Y-BEDD","code": "CYB"},
  {"name": "CHADWELL HEATH","code": "CTH"},
  {"name": "CHAFFORD HUNDRED LAKESIDE","code": "CFH"},
  {"name": "CHALFONT & LATIMER","code": "CFO"},
  {"name": "CHALKWELL","code": "CHW"},
  {"name": "CHANDLERS FORD","code": "CFR"},
  {"name": "CHAPEL-EN-LE-FRITH","code": "CEF"},
  {"name": "CHAPELTON (DEVON)","code": "CPN"},
  {"name": "CHAPELTOWN (SOUTH YORKS)","code": "CLN"},
  {"name": "CHAPPEL & WAKES COLNE","code": "CWC"},
  {"name": "CHARING (KENT)","code": "CHG"},
  {"name": "CHARING CROSS (GLASGOW)","code": "CHC"},
  {"name": "CHARLBURY","code": "CBY"},
  {"name": "CHARLTON","code": "CTN"},
  {"name": "CHARTHAM","code": "CRT"},
  {"name": "CHASSEN ROAD","code": "CSR"},
  {"name": "CHATELHERAULT","code": "CTE"},
  {"name": "CHATHAM","code": "CTM"},
  {"name": "CHATHILL","code": "CHT"},
  {"name": "CHEADLE HULME","code": "CHU"},
  {"name": "CHEAM","code": "CHE"},
  {"name": "CHEDDINGTON","code": "CED"},
  {"name": "CHELFORD (CHESHIRE)","code": "CEL"},
  {"name": "CHELMSFORD","code": "CHM"},
  {"name": "CHELSFIELD","code": "CLD"},
  {"name": "CHELTENHAM SPA","code": "CNM"},
  {"name": "CHEPSTOW","code": "CPW"},
  {"name": "CHERRY TREE","code": "CYT"},
  {"name": "CHERTSEY","code": "CHY"},
  {"name": "CHESHUNT","code": "CHN"},
  {"name": "CHESSINGTON NORTH","code": "CSN"},
  {"name": "CHESSINGTON SOUTH","code": "CSS"},
  {"name": "CHESTER","code": "CTR"},
  {"name": "CHESTER ROAD","code": "CRD"},
  {"name": "CHESTERFIELD","code": "CHD"},
  {"name": "CHESTER-LE-STREET","code": "CLS"},
  {"name": "CHESTFIELD & SWALECLIFFE","code": "CSW"},
  {"name": "CHETNOLE","code": "CNO"},
  {"name": "CHICHESTER","code": "CCH"},
  {"name": "CHILHAM","code": "CIL"},
  {"name": "CHILWORTH","code": "CHL"},
  {"name": "CHINGFORD","code": "CHI"},
  {"name": "CHINLEY","code": "CLY"},
  {"name": "CHIPPENHAM","code": "CPM"},
  {"name": "CHIPSTEAD","code": "CHP"},
  {"name": "CHIRK","code": "CRK"},
  {"name": "CHISLEHURST","code": "CIT"},
  {"name": "CHISWICK","code": "CHK"},
  {"name": "CHOLSEY","code": "CHO"},
  {"name": "CHORLEY","code": "CRL"},
  {"name": "CHORLEYWOOD","code": "CLW"},
  {"name": "CHRISTCHURCH","code": "CHR"},
  {"name": "CHRISTS HOSPITAL","code": "CHH"},
  {"name": "CHURCH & OSWALDTWISTLE","code": "CTW"},
  {"name": "CHURCH FENTON","code": "CHF"},
  {"name": "CHURCH STRETTON","code": "CTT"},
  {"name": "CILMERI","code": "CIM"},
  {"name": "CITY THAMESLINK","code": "CTK"},
  {"name": "CLACTON-ON-SEA","code": "CLT"},
  {"name": "CLANDON","code": "CLA"},
  {"name": "CLAPHAM (NORTH YORKSHIRE)","code": "CPY"},
  {"name": "CLAPHAM HIGH STREET","code": "CLP"},
  {"name": "CLAPHAM JUNCTION","code": "CLJ"},
  {"name": "CLAPTON","code": "CPT"},
  {"name": "CLARBESTON ROAD","code": "CLR"},
  {"name": "CLARKSTON","code": "CKS"},
  {"name": "CLAVERDON","code": "CLV"},
  {"name": "CLAYGATE","code": "CLG"},
  {"name": "CLEETHORPES","code": "CLE"},
  {"name": "CLELAND","code": "CEA"},
  {"name": "CLIFTON (MANCHESTER)","code": "CLI"},
  {"name": "CLIFTON DOWN","code": "CFN"},
  {"name": "CLITHEROE","code": "CLH"},
  {"name": "CLOCK HOUSE","code": "CLK"},
  {"name": "CLUNDERWEN","code": "CUW"},
  {"name": "CLYDEBANK","code": "CYK"},
  {"name": "COATBRIDGE CENTRAL","code": "CBC"},
  {"name": "COATBRIDGE SUNNYSIDE","code": "CBS"},
  {"name": "COATDYKE","code": "COA"},
  {"name": "COBHAM & STOKE D'ABERNON","code": "CSD"},
  {"name": "CODSALL","code": "CSL"},
  {"name": "COGAN","code": "CGN"},
  {"name": "COLCHESTER","code": "COL"},
  {"name": "COLCHESTER TOWN","code": "CET"},
  {"name": "COLESHILL PARKWAY","code": "CEH"},
  {"name": "COLLINGHAM","code": "CLM"},
  {"name": "COLLINGTON","code": "CLL"},
  {"name": "COLNE","code": "CNE"},
  {"name": "COLWALL","code": "CWL"},
  {"name": "COLWYN BAY","code": "CWB"},
  {"name": "COMBE (OXON)","code": "CME"},
  {"name": "COMMONDALE","code": "COM"},
  {"name": "CONGLETON","code": "CNG"},
  {"name": "CONISBROUGH","code": "CNS"},
  {"name": "CONNEL FERRY","code": "CON"},
  {"name": "CONON BRIDGE","code": "CBD"},
  {"name": "CONONLEY","code": "CEY"},
  {"name": "CONWAY PARK","code": "CNP"},
  {"name": "CONWY","code": "CNW"},
  {"name": "COODEN BEACH","code": "COB"},
  {"name": "COOKHAM","code": "COO"},
  {"name": "COOKSBRIDGE","code": "CBR"},
  {"name": "COOMBE JUNCTION HALT","code": "COE"},
  {"name": "COPPLESTONE","code": "COP"},
  {"name": "CORBRIDGE","code": "CRB"},
  {"name": "CORBY","code": "COR"},
  {"name": "CORKERHILL","code": "CKH"},
  {"name": "CORKICKLE","code": "CKL"},
  {"name": "CORPACH","code": "CPA"},
  {"name": "CORROUR","code": "CRR"},
  {"name": "CORYTON","code": "COY"},
  {"name": "COSELEY","code": "CSY"},
  {"name": "COSFORD","code": "COS"},
  {"name": "COSHAM","code": "CSA"},
  {"name": "COTTINGHAM","code": "CGM"},
  {"name": "COTTINGLEY","code": "COT"},
  {"name": "COULSDON SOUTH","code": "CDS"},
  {"name": "COULSDON TOWN","code": "CDN"},
  {"name": "COVENTRY","code": "COV"},
  {"name": "COVENTRY ARENA","code": "CAA"},
  {"name": "COWDEN (KENT)","code": "CWN"},
  {"name": "COWDENBEATH","code": "COW"},
  {"name": "CRADLEY HEATH","code": "CRA"},
  {"name": "CRAIGENDORAN","code": "CGD"},
  {"name": "CRAMLINGTON","code": "CRM"},
  {"name": "CRANBROOK (DEVON)","code": "CBK"},
  {"name": "CRAVEN ARMS","code": "CRV"},
  {"name": "CRAWLEY","code": "CRW"},
  {"name": "CRAYFORD","code": "CRY"},
  {"name": "CREDITON","code": "CDI"},
  {"name": "CRESSING (ESSEX)","code": "CES"},
  {"name": "CRESSINGTON","code": "CSG"},
  {"name": "CRESWELL","code": "CWD"},
  {"name": "CREWE","code": "CRE"},
  {"name": "CREWKERNE","code": "CKN"},
  {"name": "CREWS HILL","code": "CWH"},
  {"name": "CRIANLARICH","code": "CNR"},
  {"name": "CRICCIETH","code": "CCC"},
  {"name": "CRICKLEWOOD","code": "CRI"},
  {"name": "CROFTFOOT","code": "CFF"},
  {"name": "CROFTON PARK","code": "CFT"},
  {"name": "CROMER","code": "CMR"},
  {"name": "CROMFORD","code": "CMF"},
  {"name": "CROOKSTON","code": "CKT"},
  {"name": "CROSS GATES","code": "CRG"},
  {"name": "CROSSFLATTS","code": "CFL"},
  {"name": "CROSSHILL","code": "COI"},
  {"name": "CROSSKEYS","code": "CKY"},
  {"name": "CROSSMYLOOF","code": "CMY"},
  {"name": "CROSTON","code": "CSO"},
  {"name": "CROUCH HILL","code": "CRH"},
  {"name": "CROWBOROUGH","code": "COH"},
  {"name": "CROWHURST","code": "CWU"},
  {"name": "CROWLE","code": "CWE"},
  {"name": "CROWTHORNE","code": "CRN"},
  {"name": "CROY","code": "CRO"},
  {"name": "CRYSTAL PALACE","code": "CYP"},
  {"name": "CUDDINGTON","code": "CUD"},
  {"name": "CUFFLEY","code": "CUF"},
  {"name": "CULHAM","code": "CUM"},
  {"name": "CULRAIN","code": "CUA"},
  {"name": "CUMBERNAULD","code": "CUB"},
  {"name": "CUPAR","code": "CUP"},
  {"name": "CURRIEHILL","code": "CUH"},
  {"name": "CUXTON","code": "CUX"},
  {"name": "CWMBACH","code": "CMH"},
  {"name": "CWMBRAN","code": "CWM"},
  {"name": "CYNGHORDY","code": "CYN"},
  {"name": "DAGENHAM DOCK","code": "DDK"},
  {"name": "DAISY HILL","code": "DSY"},
  {"name": "DALGETY BAY","code": "DAG"},
  {"name": "DALMALLY","code": "DAL"},
  {"name": "DALMARNOCK","code": "DAK"},
  {"name": "DALMENY","code": "DAM"},
  {"name": "DALMUIR","code": "DMR"},
  {"name": "DALREOCH","code": "DLR"},
  {"name": "DALRY","code": "DLY"},
  {"name": "DALSTON (CUMBRIA)","code": "DLS"},
  {"name": "DALSTON JUNCTION","code": "DLJ"},
  {"name": "DALSTON KINGSLAND","code": "DLK"},
  {"name": "DALTON (CUMBRIA)","code": "DLT"},
  {"name": "DALWHINNIE","code": "DLW"},
  {"name": "DANBY","code": "DNY"},
  {"name": "DANESCOURT","code": "DCT"},
  {"name": "DANZEY","code": "DZY"},
  {"name": "DARLINGTON","code": "DAR"},
  {"name": "DARNALL","code": "DAN"},
  {"name": "DARSHAM","code": "DSM"},
  {"name": "DARTFORD","code": "DFD"},
  {"name": "DARTON","code": "DRT"},
  {"name": "DARWEN","code": "DWN"},
  {"name": "DATCHET","code": "DAT"},
  {"name": "DAVENPORT","code": "DVN"},
  {"name": "DAWLISH","code": "DWL"},
  {"name": "DAWLISH WARREN","code": "DWW"},
  {"name": "DEAL","code": "DEA"},
  {"name": "DEAN (WILTS)","code": "DEN"},
  {"name": "DEANSGATE","code": "DGT"},
  {"name": "DEGANWY","code": "DGY"},
  {"name": "DEIGHTON","code": "DHN"},
  {"name": "DELAMERE","code": "DLM"},
  {"name": "DENBY DALE","code": "DBD"},
  {"name": "DENHAM","code": "DNM"},
  {"name": "DENHAM GOLF CLUB","code": "DGC"},
  {"name": "DENMARK HILL","code": "DMK"},
  {"name": "DENT","code": "DNT"},
  {"name": "DENTON","code": "DTN"},
  {"name": "DEPTFORD","code": "DEP"},
  {"name": "DERBY","code": "DBY"},
  {"name": "DERBY ROAD (IPSWICH)","code": "DBR"},
  {"name": "DEVONPORT (DEVON)","code": "DPT"},
  {"name": "DEVONPORT DOCKYARD","code": "DOC"},
  {"name": "DEWSBURY","code": "DEW"},
  {"name": "DIDCOT PARKWAY","code": "DID"},
  {"name": "DIGBY & SOWTON","code": "DIG"},
  {"name": "DILTON MARSH","code": "DMH"},
  {"name": "DINAS (RHONDDA)","code": "DMG"},
  {"name": "DINAS POWYS","code": "DNS"},
  {"name": "DINGLE ROAD","code": "DGL"},
  {"name": "DINGWALL","code": "DIN"},
  {"name": "DINSDALE","code": "DND"},
  {"name": "DINTING","code": "DTG"},
  {"name": "DISLEY","code": "DSL"},
  {"name": "DISS","code": "DIS"},
  {"name": "DODWORTH","code": "DOD"},
  {"name": "DOLAU","code": "DOL"},
  {"name": "DOLEHAM","code": "DLH"},
  {"name": "DOLGARROG","code": "DLG"},
  {"name": "DOLWYDDELAN","code": "DWD"},
  {"name": "DONCASTER","code": "DON"},
  {"name": "DORCHESTER SOUTH","code": "DCH"},
  {"name": "DORCHESTER WEST","code": "DCW"},
  {"name": "DORE & TOTLEY","code": "DOR"},
  {"name": "DORKING (MAIN)","code": "DKG"},
  {"name": "DORKING DEEPDENE","code": "DPD"},
  {"name": "DORKING WEST","code": "DKT"},
  {"name": "DORMANS","code": "DMS"},
  {"name": "DORRIDGE","code": "DDG"},
  {"name": "DOVE HOLES","code": "DVH"},
  {"name": "DOVER PRIORY","code": "DVP"},
  {"name": "DOVERCOURT","code": "DVC"},
  {"name": "DOVEY JUNCTION","code": "DVY"},
  {"name": "DOWNHAM MARKET","code": "DOW"},
  {"name": "DRAYTON GREEN","code": "DRG"},
  {"name": "DRAYTON PARK","code": "DYP"},
  {"name": "DREM","code": "DRM"},
  {"name": "DRIFFIELD","code": "DRF"},
  {"name": "DRIGG","code": "DRI"},
  {"name": "DROITWICH SPA","code": "DTW"},
  {"name": "DRONFIELD","code": "DRO"},
  {"name": "DRUMCHAPEL","code": "DMC"},
  {"name": "DRUMFROCHAR","code": "DFR"},
  {"name": "DRUMGELLOCH","code": "DRU"},
  {"name": "DRUMRY","code": "DMY"},
  {"name": "DUBLIN FERRYPORT","code": "DFP"},
  {"name": "DUBLIN PORT - STENA","code": "DPS"},
  {"name": "DUDDESTON","code": "DUD"},
  {"name": "DUDLEY PORT","code": "DDP"},
  {"name": "DUFFIELD","code": "DFI"},
  {"name": "DUIRINISH","code": "DRN"},
  {"name": "DUKE STREET","code": "DST"},
  {"name": "DULLINGHAM","code": "DUL"},
  {"name": "DUMBARTON CENTRAL","code": "DBC"},
  {"name": "DUMBARTON EAST","code": "DBE"},
  {"name": "DUMBRECK","code": "DUM"},
  {"name": "DUMFRIES","code": "DMF"},
  {"name": "DUMPTON PARK","code": "DMP"},
  {"name": "DUNBAR","code": "DUN"},
  {"name": "DUNBLANE","code": "DBL"},
  {"name": "DUNCRAIG","code": "DCG"},
  {"name": "DUNDEE","code": "DEE"},
  {"name": "DUNFERMLINE QUEEN MARGARET","code": "DFL"},
  {"name": "DUNFERMLINE TOWN","code": "DFE"},
  {"name": "DUNKELD & BIRNAM","code": "DKD"},
  {"name": "DUNLOP","code": "DNL"},
  {"name": "DUNROBIN CASTLE","code": "DNO"},
  {"name": "DUNSTON","code": "DOT"},
  {"name": "DUNTON GREEN","code": "DNG"},
  {"name": "DURHAM","code": "DHM"},
  {"name": "DURRINGTON-ON-SEA","code": "DUR"},
  {"name": "DYCE","code": "DYC"},
  {"name": "DYFFRYN ARDUDWY","code": "DYF"},
  {"name": "EAGLESCLIFFE","code": "EAG"},
  {"name": "EALING BROADWAY","code": "EAL"},
  {"name": "EARLESTOWN","code": "ERL"},
  {"name": "EARLEY","code": "EAR"},
  {"name": "EARLSFIELD","code": "EAD"},
  {"name": "EARLSWOOD (SURREY)","code": "ELD"},
  {"name": "EARLSWOOD (WEST MIDLANDS)","code": "EWD"},
  {"name": "EAST CROYDON","code": "ECR"},
  {"name": "EAST DIDSBURY","code": "EDY"},
  {"name": "EAST DULWICH","code": "EDW"},
  {"name": "EAST FARLEIGH","code": "EFL"},
  {"name": "EAST GARFORTH","code": "EGF"},
  {"name": "EAST GRINSTEAD","code": "EGR"},
  {"name": "EAST KILBRIDE","code": "EKL"},
  {"name": "EAST MALLING","code": "EML"},
  {"name": "EAST MIDLANDS PARKWAY","code": "EMD"},
  {"name": "EAST TILBURY","code": "ETL"},
  {"name": "EAST WORTHING","code": "EWR"},
  {"name": "EASTBOURNE","code": "EBN"},
  {"name": "EASTBROOK","code": "EBK"},
  {"name": "EASTERHOUSE","code": "EST"},
  {"name": "EASTHAM RAKE","code": "ERA"},
  {"name": "EASTLEIGH","code": "ESL"},
  {"name": "EASTRINGTON","code": "EGN"},
  {"name": "EBBSFLEET INTERNATIONAL","code": "EBD"},
  {"name": "EBBW VALE PARKWAY","code": "EBV"},
  {"name": "EBBW VALE TOWN","code": "EBB"},
  {"name": "ECCLES (MANCHESTER)","code": "ECC"},
  {"name": "ECCLES ROAD","code": "ECS"},
  {"name": "ECCLESTON PARK","code": "ECL"},
  {"name": "EDALE","code": "EDL"},
  {"name": "EDEN PARK","code": "EDN"},
  {"name": "EDENBRIDGE","code": "EBR"},
  {"name": "EDENBRIDGE TOWN","code": "EBT"},
  {"name": "EDGE HILL","code": "EDG"},
  {"name": "EDINBURGH","code": "EDB"},
  {"name": "EDINBURGH GATEWAY","code": "EGY"},
  {"name": "EDINBURGH PARK","code": "EDP"},
  {"name": "EDMONTON GREEN","code": "EDR"},
  {"name": "EFFINGHAM JUNCTION","code": "EFF"},
  {"name": "EGGESFORD","code": "EGG"},
  {"name": "EGHAM","code": "EGH"},
  {"name": "EGTON","code": "EGT"},
  {"name": "ELEPHANT & CASTLE","code": "EPH"},
  {"name": "ELEPHANT & CASTLE (UNDERGROUND)","code": "ZEL"},
  {"name": "ELGIN","code": "ELG"},
  {"name": "ELLESMERE PORT","code": "ELP"},
  {"name": "ELMERS END","code": "ELE"},
  {"name": "ELMSTEAD WOODS","code": "ESD"},
  {"name": "ELMSWELL","code": "ESW"},
  {"name": "ELSECAR","code": "ELR"},
  {"name": "ELSENHAM (ESSEX)","code": "ESM"},
  {"name": "ELSTREE & BOREHAMWOOD","code": "ELS"},
  {"name": "ELTHAM","code": "ELW"},
  {"name": "ELTON & ORSTON","code": "ELO"},
  {"name": "ELY","code": "ELY"},
  {"name": "EMERSON PARK","code": "EMP"},
  {"name": "EMSWORTH","code": "EMS"},
  {"name": "ENERGLYN & CHURCHILL PARK","code": "ECP"},
  {"name": "ENFIELD CHASE","code": "ENC"},
  {"name": "ENFIELD LOCK","code": "ENL"},
  {"name": "ENFIELD TOWN","code": "ENF"},
  {"name": "ENTWISTLE","code": "ENT"},
  {"name": "EPSOM (SURREY)","code": "EPS"},
  {"name": "EPSOM DOWNS","code": "EPD"},
  {"name": "ERDINGTON","code": "ERD"},
  {"name": "ERIDGE","code": "ERI"},
  {"name": "ERITH","code": "ERH"},
  {"name": "ESHER","code": "ESH"},
  {"name": "ESKBANK","code": "EKB"},
  {"name": "ESSEX ROAD","code": "EXR"},
  {"name": "ETCHINGHAM","code": "ETC"},
  {"name": "EUXTON BALSHAW LANE","code": "EBA"},
  {"name": "EVESHAM","code": "EVE"},
  {"name": "EWELL EAST","code": "EWE"},
  {"name": "EWELL WEST","code": "EWW"},
  {"name": "EXETER CENTRAL","code": "EXC"},
  {"name": "EXETER ST DAVID'S","code": "EXD"},
  {"name": "EXETER ST THOMAS","code": "EXT"},
  {"name": "EXHIBITION CENTRE (GLASGOW)","code": "EXG"},
  {"name": "EXMOUTH","code": "EXM"},
  {"name": "EXTON","code": "EXN"},
  {"name": "EYNSFORD","code": "EYN"},
  {"name": "FAIRBOURNE","code": "FRB"},
  {"name": "FAIRFIELD","code": "FRF"},
  {"name": "FAIRLIE","code": "FRL"},
  {"name": "FAIRWATER","code": "FRW"},
  {"name": "FALCONWOOD","code": "FCN"},
  {"name": "FALKIRK GRAHAMSTON","code": "FKG"},
  {"name": "FALKIRK HIGH","code": "FKK"},
  {"name": "FALLS OF CRUACHAN","code": "FOC"},
  {"name": "FALMER","code": "FMR"},
  {"name": "FALMOUTH DOCKS","code": "FAL"},
  {"name": "FALMOUTH TOWN","code": "FMT"},
  {"name": "FAREHAM","code": "FRM"},
  {"name": "FARNBOROUGH (MAIN)","code": "FNB"},
  {"name": "FARNBOROUGH NORTH","code": "FNN"},
  {"name": "FARNCOMBE","code": "FNC"},
  {"name": "FARNHAM","code": "FNH"},
  {"name": "FARNINGHAM ROAD","code": "FNR"},
  {"name": "FARNWORTH","code": "FNW"},
  {"name": "FARRINGDON","code": "ZFD"},
  {"name": "FAULDHOUSE","code": "FLD"},
  {"name": "FAVERSHAM","code": "FAV"},
  {"name": "FAYGATE","code": "FGT"},
  {"name": "FAZAKERLEY","code": "FAZ"},
  {"name": "FEARN","code": "FRN"},
  {"name": "FEATHERSTONE","code": "FEA"},
  {"name": "FELIXSTOWE","code": "FLX"},
  {"name": "FELTHAM","code": "FEL"},
  {"name": "FENITON","code": "FNT"},
  {"name": "FENNY STRATFORD","code": "FEN"},
  {"name": "FERNHILL","code": "FER"},
  {"name": "FERRIBY","code": "FRY"},
  {"name": "FERRYSIDE","code": "FYS"},
  {"name": "FFAIRFACH","code": "FFA"},
  {"name": "FILEY","code": "FIL"},
  {"name": "FILTON ABBEY WOOD","code": "FIT"},
  {"name": "FINCHLEY ROAD & FROGNAL","code": "FNY"},
  {"name": "FINSBURY PARK","code": "FPK"},
  {"name": "FINSTOCK","code": "FIN"},
  {"name": "FISHBOURNE (SUSSEX)","code": "FSB"},
  {"name": "FISHERSGATE","code": "FSG"},
  {"name": "FISHGUARD & GOODWICK","code": "FGW"},
  {"name": "FISHGUARD HARBOUR","code": "FGH"},
  {"name": "FISKERTON","code": "FSK"},
  {"name": "FITZWILLIAM","code": "FZW"},
  {"name": "FIVE WAYS","code": "FWY"},
  {"name": "FLEET","code": "FLE"},
  {"name": "FLIMBY","code": "FLM"},
  {"name": "FLINT","code": "FLN"},
  {"name": "FLITWICK","code": "FLT"},
  {"name": "FLIXTON","code": "FLI"},
  {"name": "FLOWERY FIELD","code": "FLF"},
  {"name": "FOLKESTONE CENTRAL","code": "FKC"},
  {"name": "FOLKESTONE WEST","code": "FKW"},
  {"name": "FORD","code": "FOD"},
  {"name": "FOREST GATE","code": "FOG"},
  {"name": "FOREST HILL","code": "FOH"},
  {"name": "FORMBY","code": "FBY"},
  {"name": "FORRES","code": "FOR"},
  {"name": "FORSINARD","code": "FRS"},
  {"name": "FORT MATILDA","code": "FTM"},
  {"name": "FORT WILLIAM","code": "FTW"},
  {"name": "FOUR OAKS","code": "FOK"},
  {"name": "FOXFIELD","code": "FOX"},
  {"name": "FOXTON","code": "FXN"},
  {"name": "FRANT","code": "FRT"},
  {"name": "FRATTON","code": "FTN"},
  {"name": "FRESHFIELD","code": "FRE"},
  {"name": "FRESHFORD","code": "FFD"},
  {"name": "FRIMLEY","code": "FML"},
  {"name": "FRINTON-ON-SEA","code": "FRI"},
  {"name": "FRIZINGHALL","code": "FZH"},
  {"name": "FRODSHAM","code": "FRD"},
  {"name": "FROME","code": "FRO"},
  {"name": "FULWELL","code": "FLW"},
  {"name": "FURNESS VALE","code": "FNV"},
  {"name": "FURZE PLATT","code": "FZP"},
  {"name": "GAINSBOROUGH CENTRAL","code": "GNB"},
  {"name": "GAINSBOROUGH LEA ROAD","code": "GBL"},
  {"name": "GALASHIELS","code": "GAL"},
  {"name": "GARELOCHHEAD","code": "GCH"},
  {"name": "GARFORTH","code": "GRF"},
  {"name": "GARGRAVE","code": "GGV"},
  {"name": "GARROWHILL","code": "GAR"},
  {"name": "GARSCADDEN","code": "GRS"},
  {"name": "GARSDALE","code": "GSD"},
  {"name": "GARSTON (HERTFORDSHIRE)","code": "GSN"},
  {"name": "GARSWOOD","code": "GSW"},
  {"name": "GARTCOSH","code": "GRH"},
  {"name": "GARTH (MID GLAMORGAN)","code": "GMG"},
  {"name": "GARTH (POWYS)","code": "GTH"},
  {"name": "GARVE","code": "GVE"},
  {"name": "GATHURST","code": "GST"},
  {"name": "GATLEY","code": "GTY"},
  {"name": "GATWICK AIRPORT","code": "GTW"},
  {"name": "GEORGEMAS JUNCTION","code": "GGJ"},
  {"name": "GERRARDS CROSS","code": "GER"},
  {"name": "GIDEA PARK","code": "GDP"},
  {"name": "GIFFNOCK","code": "GFN"},
  {"name": "GIGGLESWICK","code": "GIG"},
  {"name": "GILBERDYKE","code": "GBD"},
  {"name": "GILFACH FARGOED","code": "GFF"},
  {"name": "GILLINGHAM (DORSET)","code": "GIL"},
  {"name": "GILLINGHAM (KENT)","code": "GLM"},
  {"name": "GILSHOCHILL","code": "GSC"},
  {"name": "GIPSY HILL","code": "GIP"},
  {"name": "GIRVAN","code": "GIR"},
  {"name": "GLAISDALE","code": "GLS"},
  {"name": "GLAN CONWY","code": "GCW"},
  {"name": "GLASGOW CENTRAL","code": "GLC"},
  {"name": "GLASGOW QUEEN STREET","code": "GLQ"},
  {"name": "GLASSHOUGHTON","code": "GLH"},
  {"name": "GLAZEBROOK","code": "GLZ"},
  {"name": "GLENEAGLES","code": "GLE"},
  {"name": "GLENFINNAN","code": "GLF"},
  {"name": "GLENGARNOCK","code": "GLG"},
  {"name": "GLENROTHES WITH THORNTON","code": "GLT"},
  {"name": "GLOSSOP","code": "GLO"},
  {"name": "GLOUCESTER","code": "GCR"},
  {"name": "GLYNDE","code": "GLY"},
  {"name": "GOBOWEN","code": "GOB"},
  {"name": "GODALMING","code": "GOD"},
  {"name": "GODLEY","code": "GDL"},
  {"name": "GODSTONE","code": "GDN"},
  {"name": "GOLDTHORPE","code": "GOE"},
  {"name": "GOLF STREET","code": "GOF"},
  {"name": "GOLSPIE","code": "GOL"},
  {"name": "GOMSHALL","code": "GOM"},
  {"name": "GOODMAYES","code": "GMY"},
  {"name": "GOOLE","code": "GOO"},
  {"name": "GOOSTREY","code": "GTR"},
  {"name": "GORDON HILL","code": "GDH"},
  {"name": "GOREBRIDGE","code": "GBG"},
  {"name": "GORING & STREATLEY","code": "GOR"},
  {"name": "GORING-BY-SEA","code": "GBS"},
  {"name": "GORTON","code": "GTO"},
  {"name": "GOSPEL OAK","code": "GPO"},
  {"name": "GOUROCK","code": "GRK"},
  {"name": "GOWERTON","code": "GWN"},
  {"name": "GOXHILL","code": "GOX"},
  {"name": "GRANGE PARK","code": "GPK"},
  {"name": "GRANGE-OVER-SANDS","code": "GOS"},
  {"name": "GRANGETOWN (CARDIFF)","code": "GTN"},
  {"name": "GRANTHAM","code": "GRA"},
  {"name": "GRATELEY","code": "GRT"},
  {"name": "GRAVELLY HILL","code": "GVH"},
  {"name": "GRAVESEND","code": "GRV"},
  {"name": "GRAYS","code": "GRY"},
  {"name": "GREAT AYTON","code": "GTA"},
  {"name": "GREAT BENTLEY","code": "GRB"},
  {"name": "GREAT CHESTERFORD","code": "GRC"},
  {"name": "GREAT COATES","code": "GCT"},
  {"name": "GREAT MALVERN","code": "GMV"},
  {"name": "GREAT MISSENDEN","code": "GMN"},
  {"name": "GREAT YARMOUTH","code": "GYM"},
  {"name": "GREEN LANE","code": "GNL"},
  {"name": "GREEN ROAD","code": "GNR"},
  {"name": "GREENBANK","code": "GBK"},
  {"name": "GREENFAULDS","code": "GRL"},
  {"name": "GREENFIELD","code": "GNF"},
  {"name": "GREENFORD","code": "GFD"},
  {"name": "GREENHITHE","code": "GNH"},
  {"name": "GREENOCK CENTRAL","code": "GKC"},
  {"name": "GREENOCK WEST","code": "GKW"},
  {"name": "GREENWICH","code": "GNW"},
  {"name": "GRETNA GREEN","code": "GEA"},
  {"name": "GRIMSBY DOCKS","code": "GMD"},
  {"name": "GRIMSBY TOWN","code": "GMB"},
  {"name": "GRINDLEFORD","code": "GRN"},
  {"name": "GROSMONT","code": "GMT"},
  {"name": "GROVE PARK","code": "GRP"},
  {"name": "GUIDE BRIDGE","code": "GUI"},
  {"name": "GUILDFORD","code": "GLD"},
  {"name": "GUISELEY","code": "GSY"},
  {"name": "GUNNERSBURY","code": "GUN"},
  {"name": "GUNNISLAKE","code": "GSL"},
  {"name": "GUNTON","code": "GNT"},
  {"name": "GWERSYLLT","code": "GWE"},
  {"name": "GYPSY LANE","code": "GYP"},
  {"name": "HABROUGH","code": "HAB"},
  {"name": "HACKBRIDGE","code": "HCB"},
  {"name": "HACKNEY CENTRAL","code": "HKC"},
  {"name": "HACKNEY DOWNS","code": "HAC"},
  {"name": "HACKNEY WICK","code": "HKW"},
  {"name": "HADDENHAM & THAME PARKWAY","code": "HDM"},
  {"name": "HADDISCOE","code": "HAD"},
  {"name": "HADFIELD","code": "HDF"},
  {"name": "HADLEY WOOD","code": "HDW"},
  {"name": "HAG FOLD","code": "HGF"},
  {"name": "HAGGERSTON","code": "HGG"},
  {"name": "HAGLEY","code": "HAG"},
  {"name": "HAIRMYRES","code": "HMY"},
  {"name": "HALE (MANCHESTER)","code": "HAL"},
  {"name": "HALESWORTH","code": "HAS"},
  {"name": "HALEWOOD","code": "HED"},
  {"name": "HALIFAX","code": "HFX"},
  {"name": "HALL GREEN","code": "HLG"},
  {"name": "HALL ROAD","code": "HLR"},
  {"name": "HALLING","code": "HAI"},
  {"name": "HALL-I'-TH'-WOOD","code": "HID"},
  {"name": "HALTWHISTLE","code": "HWH"},
  {"name": "HAM STREET","code": "HMT"},
  {"name": "HAMBLE","code": "HME"},
  {"name": "HAMILTON CENTRAL","code": "HNC"},
  {"name": "HAMILTON WEST","code": "HNW"},
  {"name": "HAMMERTON","code": "HMM"},
  {"name": "HAMPDEN PARK (SUSSEX)","code": "HMD"},
  {"name": "HAMPSTEAD HEATH","code": "HDH"},
  {"name": "HAMPTON (LONDON)","code": "HMP"},
  {"name": "HAMPTON COURT","code": "HMC"},
  {"name": "HAMPTON WICK","code": "HMW"},
  {"name": "HAMPTON-IN-ARDEN","code": "HIA"},
  {"name": "HAMSTEAD (BIRMINGHAM)","code": "HSD"},
  {"name": "HAMWORTHY","code": "HAM"},
  {"name": "HANBOROUGH","code": "HND"},
  {"name": "HANDFORTH","code": "HTH"},
  {"name": "HANWELL","code": "HAN"},
  {"name": "HAPTON","code": "HPN"},
  {"name": "HARLECH","code": "HRL"},
  {"name": "HARLESDEN","code": "HDN"},
  {"name": "HARLING ROAD","code": "HRD"},
  {"name": "HARLINGTON (BEDS)","code": "HLN"},
  {"name": "HARLOW MILL","code": "HWM"},
  {"name": "HARLOW TOWN","code": "HWN"},
  {"name": "HAROLD WOOD","code": "HRO"},
  {"name": "HARPENDEN","code": "HPD"},
  {"name": "HARRIETSHAM","code": "HRM"},
  {"name": "HARRINGAY","code": "HGY"},
  {"name": "HARRINGAY GREEN LANES","code": "HRY"},
  {"name": "HARRINGTON","code": "HRR"},
  {"name": "HARROGATE","code": "HGT"},
  {"name": "HARROW & WEALDSTONE","code": "HRW"},
  {"name": "HARROW-ON-THE-HILL","code": "HOH"},
  {"name": "HARTFORD (CHESHIRE)","code": "HTF"},
  {"name": "HARTLEBURY","code": "HBY"},
  {"name": "HARTLEPOOL","code": "HPL"},
  {"name": "HARTWOOD","code": "HTW"},
  {"name": "HARWICH INTERNATIONAL","code": "HPQ"},
  {"name": "HARWICH TOWN","code": "HWC"},
  {"name": "HASLEMERE","code": "HSL"},
  {"name": "HASSOCKS","code": "HSK"},
  {"name": "HASTINGS","code": "HGS"},
  {"name": "HATCH END","code": "HTE"},
  {"name": "HATFIELD & STAINFORTH","code": "HFS"},
  {"name": "HATFIELD (HERTS)","code": "HAT"},
  {"name": "HATFIELD PEVEREL","code": "HAP"},
  {"name": "HATHERSAGE","code": "HSG"},
  {"name": "HATTERSLEY","code": "HTY"},
  {"name": "HATTON","code": "HTN"},
  {"name": "HAVANT","code": "HAV"},
  {"name": "HAVENHOUSE","code": "HVN"},
  {"name": "HAVERFORDWEST","code": "HVF"},
  {"name": "HAWARDEN","code": "HWD"},
  {"name": "HAWARDEN BRIDGE","code": "HWB"},
  {"name": "HAWKHEAD","code": "HKH"},
  {"name": "HAYDON BRIDGE","code": "HDB"},
  {"name": "HAYDONS ROAD","code": "HYR"},
  {"name": "HAYES & HARLINGTON","code": "HAY"},
  {"name": "HAYES (KENT)","code": "HYS"},
  {"name": "HAYLE","code": "HYL"},
  {"name": "HAYMARKET","code": "HYM"},
  {"name": "HAYWARDS HEATH","code": "HHE"},
  {"name": "HAZEL GROVE","code": "HAZ"},
  {"name": "HEADCORN","code": "HCN"},
  {"name": "HEADINGLEY","code": "HDY"},
  {"name": "HEADSTONE LANE","code": "HDL"},
  {"name": "HEALD GREEN","code": "HDG"},
  {"name": "HEALING","code": "HLI"},
  {"name": "HEATH HIGH LEVEL","code": "HHL"},
  {"name": "HEATH LOW LEVEL","code": "HLL"},
  {"name": "HEATHROW AIRPORT TERMINAL 4","code": "HAF"},
  {"name": "HEATHROW AIRPORT TERMINAL 5","code": "HWV"},
  {"name": "HEATHROW AIRPORT TERMINALS 1, 2 AND 3","code": "HXX"},
  {"name": "HEATON CHAPEL","code": "HTC"},
  {"name": "HEBDEN BRIDGE","code": "HBD"},
  {"name": "HECKINGTON","code": "HEC"},
  {"name": "HEDGE END","code": "HDE"},
  {"name": "HEDNESFORD","code": "HNF"},
  {"name": "HEIGHINGTON","code": "HEI"},
  {"name": "HELENSBURGH CENTRAL","code": "HLC"},
  {"name": "HELENSBURGH UPPER","code": "HLU"},
  {"name": "HELLIFIELD","code": "HLD"},
  {"name": "HELMSDALE","code": "HMS"},
  {"name": "HELSBY","code": "HSB"},
  {"name": "HEMEL HEMPSTEAD","code": "HML"},
  {"name": "HENDON","code": "HEN"},
  {"name": "HENGOED","code": "HNG"},
  {"name": "HENLEY-IN-ARDEN","code": "HNL"},
  {"name": "HENLEY-ON-THAMES","code": "HOT"},
  {"name": "HENSALL","code": "HEL"},
  {"name": "HEREFORD","code": "HFD"},
  {"name": "HERNE BAY","code": "HNB"},
  {"name": "HERNE HILL","code": "HNH"},
  {"name": "HERSHAM","code": "HER"},
  {"name": "HERTFORD EAST","code": "HFE"},
  {"name": "HERTFORD NORTH","code": "HFN"},
  {"name": "HESSLE","code": "HES"},
  {"name": "HESWALL","code": "HSW"},
  {"name": "HEVER","code": "HEV"},
  {"name": "HEWORTH","code": "HEW"},
  {"name": "HEXHAM","code": "HEX"},
  {"name": "HEYFORD","code": "HYD"},
  {"name": "HEYSHAM PORT","code": "HHB"},
  {"name": "HIGH BROOMS","code": "HIB"},
  {"name": "HIGH STREET (GLASGOW)","code": "HST"},
  {"name": "HIGH STREET KENSINGTON UNDERGROUND","code": "ZHS"},
  {"name": "HIGH WYCOMBE","code": "HWY"},
  {"name": "HIGHAM (KENT)","code": "HGM"},
  {"name": "HIGHAMS PARK","code": "HIP"},
  {"name": "HIGHBRIDGE & BURNHAM","code": "HIG"},
  {"name": "HIGHBURY & ISLINGTON","code": "HHY"},
  {"name": "HIGHTOWN","code": "HTO"},
  {"name": "HILDENBOROUGH","code": "HLB"},
  {"name": "HILLFOOT","code": "HLF"},
  {"name": "HILLINGTON EAST","code": "HLE"},
  {"name": "HILLINGTON WEST","code": "HLW"},
  {"name": "HILLSIDE","code": "HIL"},
  {"name": "HILSEA","code": "HLS"},
  {"name": "HINCHLEY WOOD","code": "HYW"},
  {"name": "HINCKLEY (LEICS)","code": "HNK"},
  {"name": "HINDLEY","code": "HIN"},
  {"name": "HINTON ADMIRAL","code": "HNA"},
  {"name": "HITCHIN","code": "HIT"},
  {"name": "HITHER GREEN","code": "HGR"},
  {"name": "HOCKLEY","code": "HOC"},
  {"name": "HOLLINGBOURNE","code": "HBN"},
  {"name": "HOLMES CHAPEL","code": "HCH"},
  {"name": "HOLMWOOD","code": "HLM"},
  {"name": "HOLTON HEATH","code": "HOL"},
  {"name": "HOLYHEAD","code": "HHD"},
  {"name": "HOLYTOWN","code": "HLY"},
  {"name": "HOMERTON","code": "HMN"},
  {"name": "HONEYBOURNE","code": "HYB"},
  {"name": "HONITON","code": "HON"},
  {"name": "HONLEY","code": "HOY"},
  {"name": "HONOR OAK PARK","code": "HPA"},
  {"name": "HOOK","code": "HOK"},
  {"name": "HOOTON","code": "HOO"},
  {"name": "HOPE (DERBYSHIRE)","code": "HOP"},
  {"name": "HOPE (FLINTSHIRE)","code": "HPE"},
  {"name": "HOPTON HEATH","code": "HPT"},
  {"name": "HORLEY","code": "HOR"},
  {"name": "HORNBEAM PARK","code": "HBP"},
  {"name": "HORNSEY","code": "HRN"},
  {"name": "HORSFORTH","code": "HRS"},
  {"name": "HORSHAM","code": "HRH"},
  {"name": "HORSLEY","code": "HSY"},
  {"name": "HORTON-IN-RIBBLESDALE","code": "HIR"},
  {"name": "HORWICH PARKWAY","code": "HWI"},
  {"name": "HOSCAR","code": "HSC"},
  {"name": "HOUGH GREEN","code": "HGN"},
  {"name": "HOUNSLOW","code": "HOU"},
  {"name": "HOVE","code": "HOV"},
  {"name": "HOVETON & WROXHAM","code": "HXM"},
  {"name": "HOW WOOD (HERTS)","code": "HWW"},
  {"name": "HOWDEN","code": "HOW"},
  {"name": "HOWWOOD (RENFREWSHIRE)","code": "HOZ"},
  {"name": "HOXTON","code": "HOX"},
  {"name": "HOYLAKE","code": "HYK"},
  {"name": "HUBBERTS BRIDGE","code": "HBB"},
  {"name": "HUCKNALL","code": "HKN"},
  {"name": "HUDDERSFIELD","code": "HUD"},
  {"name": "HULL","code": "HUL"},
  {"name": "HUMPHREY PARK","code": "HUP"},
  {"name": "HUNCOAT","code": "HCT"},
  {"name": "HUNGERFORD","code": "HGD"},
  {"name": "HUNMANBY","code": "HUB"},
  {"name": "HUNTINGDON","code": "HUN"},
  {"name": "HUNTLY","code": "HNT"},
  {"name": "HUNTS CROSS","code": "HNX"},
  {"name": "HURST GREEN","code": "HUR"},
  {"name": "HUTTON CRANSWICK","code": "HUT"},
  {"name": "HUYTON","code": "HUY"},
  {"name": "HYDE CENTRAL","code": "HYC"},
  {"name": "HYDE NORTH","code": "HYT"},
  {"name": "HYKEHAM","code": "HKM"},
  {"name": "HYNDLAND","code": "HYN"},
  {"name": "HYTHE (ESSEX)","code": "HYH"},
  {"name": "IBM HALT","code": "IBM"},
  {"name": "IFIELD","code": "IFI"},
  {"name": "ILFORD","code": "IFD"},
  {"name": "ILKLEY","code": "ILK"},
  {"name": "IMPERIAL WHARF","code": "IMW"},
  {"name": "INCE & ELTON","code": "INE"},
  {"name": "INCE (MANCHESTER)","code": "INC"},
  {"name": "INGATESTONE","code": "INT"},
  {"name": "INSCH","code": "INS"},
  {"name": "INVERGORDON","code": "IGD"},
  {"name": "INVERGOWRIE","code": "ING"},
  {"name": "INVERKEITHING","code": "INK"},
  {"name": "INVERKIP","code": "INP"},
  {"name": "INVERNESS","code": "INV"},
  {"name": "INVERSHIN","code": "INH"},
  {"name": "INVERURIE","code": "INR"},
  {"name": "IPSWICH","code": "IPS"},
  {"name": "IRLAM","code": "IRL"},
  {"name": "IRVINE","code": "IRV"},
  {"name": "ISLEWORTH","code": "ISL"},
  {"name": "ISLIP","code": "ISP"},
  {"name": "IVER","code": "IVR"},
  {"name": "IVYBRIDGE","code": "IVY"},
  {"name": "JAMES COOK","code": "JCH"},
  {"name": "JEWELLERY QUARTER","code": "JEQ"},
  {"name": "JOHNSTON (PEMBS)","code": "JOH"},
  {"name": "JOHNSTONE (STRATHCLYDE)","code": "JHN"},
  {"name": "JORDANHILL","code": "JOR"},
  {"name": "KEARSLEY (MANCHESTER)","code": "KSL"},
  {"name": "KEARSNEY (KENT)","code": "KSN"},
  {"name": "KEIGHLEY","code": "KEI"},
  {"name": "KEITH","code": "KEH"},
  {"name": "KELVEDON","code": "KEL"},
  {"name": "KELVINDALE","code": "KVD"},
  {"name": "KEMBLE","code": "KEM"},
  {"name": "KEMPSTON HARDWICK","code": "KMH"},
  {"name": "KEMPTON PARK RACECOURSE","code": "KMP"},
  {"name": "KEMSING","code": "KMS"},
  {"name": "KEMSLEY","code": "KML"},
  {"name": "KENDAL","code": "KEN"},
  {"name": "KENLEY","code": "KLY"},
  {"name": "KENNETT","code": "KNE"},
  {"name": "KENNISHEAD","code": "KNS"},
  {"name": "KENSAL GREEN","code": "KNL"},
  {"name": "KENSAL RISE","code": "KNR"},
  {"name": "KENSINGTON OLYMPIA","code": "KPA"},
  {"name": "KENT HOUSE","code": "KTH"},
  {"name": "KENTISH TOWN","code": "KTN"},
  {"name": "KENTISH TOWN WEST","code": "KTW"},
  {"name": "KENTON","code": "KNT"},
  {"name": "KENTS BANK","code": "KBK"},
  {"name": "KETTERING","code": "KET"},
  {"name": "KEW BRIDGE","code": "KWB"},
  {"name": "KEW GARDENS","code": "KWG"},
  {"name": "KEYHAM","code": "KEY"},
  {"name": "KEYNSHAM","code": "KYN"},
  {"name": "KIDBROOKE","code": "KDB"},
  {"name": "KIDDERMINSTER","code": "KID"},
  {"name": "KIDSGROVE","code": "KDG"},
  {"name": "KIDWELLY","code": "KWL"},
  {"name": "KILBURN HIGH ROAD","code": "KBN"},
  {"name": "KILDALE","code": "KLD"},
  {"name": "KILDONAN","code": "KIL"},
  {"name": "KILGETTY","code": "KGT"},
  {"name": "KILMARNOCK","code": "KMK"},
  {"name": "KILMAURS","code": "KLM"},
  {"name": "KILPATRICK","code": "KPT"},
  {"name": "KILWINNING","code": "KWN"},
  {"name": "KINBRACE","code": "KBC"},
  {"name": "KINGHAM","code": "KGM"},
  {"name": "KINGHORN","code": "KGH"},
  {"name": "KINGS LANGLEY","code": "KGL"},
  {"name": "KINGS LYNN","code": "KLN"},
  {"name": "KINGS NORTON","code": "KNN"},
  {"name": "KINGS NYMPTON","code": "KGN"},
  {"name": "KINGS PARK","code": "KGP"},
  {"name": "KINGS SUTTON","code": "KGS"},
  {"name": "KINGSKNOWE","code": "KGE"},
  {"name": "KINGSTON","code": "KNG"},
  {"name": "KINGSWOOD","code": "KND"},
  {"name": "KINGUSSIE","code": "KIN"},
  {"name": "KINTBURY","code": "KIT"},
  {"name": "KIRBY CROSS","code": "KBX"},
  {"name": "KIRK SANDALL","code": "KKS"},
  {"name": "KIRKBY (MERSEYSIDE)","code": "KIR"},
  {"name": "KIRKBY STEPHEN","code": "KSW"},
  {"name": "KIRKBY-IN-ASHFIELD","code": "KKB"},
  {"name": "KIRKBY-IN-FURNESS","code": "KBF"},
  {"name": "KIRKCALDY","code": "KDY"},
  {"name": "KIRKCONNEL","code": "KRK"},
  {"name": "KIRKDALE","code": "KKD"},
  {"name": "KIRKHAM & WESHAM","code": "KKM"},
  {"name": "KIRKHILL","code": "KKH"},
  {"name": "KIRKNEWTON","code": "KKN"},
  {"name": "KIRKSTALL FORGE","code": "KLF"},
  {"name": "KIRKWOOD","code": "KWD"},
  {"name": "KIRTON LINDSEY","code": "KTL"},
  {"name": "KIVETON BRIDGE","code": "KIV"},
  {"name": "KIVETON PARK","code": "KVP"},
  {"name": "KNARESBOROUGH","code": "KNA"},
  {"name": "KNEBWORTH","code": "KBW"},
  {"name": "KNIGHTON","code": "KNI"},
  {"name": "KNOCKHOLT","code": "KCK"},
  {"name": "KNOTTINGLEY","code": "KNO"},
  {"name": "KNUCKLAS","code": "KNU"},
  {"name": "KNUTSFORD","code": "KNF"},
  {"name": "KYLE OF LOCHALSH","code": "KYL"},
  {"name": "LADYBANK","code": "LDY"},
  {"name": "LADYWELL","code": "LAD"},
  {"name": "LAINDON","code": "LAI"},
  {"name": "LAIRG","code": "LRG"},
  {"name": "LAKE","code": "LKE"},
  {"name": "LAKENHEATH","code": "LAK"},
  {"name": "LAMPHEY","code": "LAM"},
  {"name": "LANARK","code": "LNK"},
  {"name": "LANCASTER","code": "LAN"},
  {"name": "LANCING","code": "LAC"},
  {"name": "LANDYWOOD","code": "LAW"},
  {"name": "LANGBANK","code": "LGB"},
  {"name": "LANGHO","code": "LHO"},
  {"name": "LANGLEY (BERKS)","code": "LNY"},
  {"name": "LANGLEY GREEN","code": "LGG"},
  {"name": "LANGLEY MILL","code": "LGM"},
  {"name": "LANGSIDE","code": "LGS"},
  {"name": "LANGWATHBY","code": "LGW"},
  {"name": "LANGWITH-WHALEY THORNS","code": "LAG"},
  {"name": "LAPFORD","code": "LAP"},
  {"name": "LAPWORTH","code": "LPW"},
  {"name": "LARBERT","code": "LBT"},
  {"name": "LARGS","code": "LAR"},
  {"name": "LARKHALL","code": "LRH"},
  {"name": "LAURENCEKIRK","code": "LAU"},
  {"name": "LAWRENCE HILL","code": "LWH"},
  {"name": "LAYTON (LANCS)","code": "LAY"},
  {"name": "LAZONBY & KIRKOSWALD","code": "LZB"},
  {"name": "LEA BRIDGE","code": "LEB"},
  {"name": "LEA GREEN","code": "LEG"},
  {"name": "LEA HALL","code": "LEH"},
  {"name": "LEAGRAVE","code": "LEA"},
  {"name": "LEALHOLM","code": "LHM"},
  {"name": "LEAMINGTON SPA","code": "LMS"},
  {"name": "LEASOWE","code": "LSW"},
  {"name": "LEATHERHEAD","code": "LHD"},
  {"name": "LEDBURY","code": "LED"},
  {"name": "LEE (LONDON)","code": "LEE"},
  {"name": "LEEDS","code": "LDS"},
  {"name": "LEICESTER","code": "LEI"},
  {"name": "LEIGH (KENT)","code": "LIH"},
  {"name": "LEIGH-ON-SEA","code": "LES"},
  {"name": "LEIGHTON BUZZARD","code": "LBZ"},
  {"name": "LELANT","code": "LEL"},
  {"name": "LELANT SALTINGS","code": "LTS"},
  {"name": "LENHAM","code": "LEN"},
  {"name": "LENZIE","code": "LNZ"},
  {"name": "LEOMINSTER","code": "LEO"},
  {"name": "LETCHWORTH GARDEN CITY","code": "LET"},
  {"name": "LEUCHARS (FOR ST. ANDREWS)","code": "LEU"},
  {"name": "LEVENSHULME","code": "LVM"},
  {"name": "LEWES","code": "LWS"},
  {"name": "LEWISHAM","code": "LEW"},
  {"name": "LEYLAND","code": "LEY"},
  {"name": "LEYTON MIDLAND ROAD","code": "LEM"},
  {"name": "LEYTONSTONE HIGH ROAD","code": "LER"},
  {"name": "LICHFIELD CITY","code": "LIC"},
  {"name": "LICHFIELD TRENT VALLEY","code": "LTV"},
  {"name": "LIDLINGTON","code": "LID"},
  {"name": "LIMEHOUSE","code": "LHS"},
  {"name": "LINCOLN CENTRAL","code": "LCN"},
  {"name": "LINGFIELD","code": "LFD"},
  {"name": "LINGWOOD","code": "LGD"},
  {"name": "LINLITHGOW","code": "LIN"},
  {"name": "LIPHOOK","code": "LIP"},
  {"name": "LISKEARD","code": "LSK"},
  {"name": "LISS","code": "LIS"},
  {"name": "LISVANE & THORNHILL","code": "LVT"},
  {"name": "LITTLE KIMBLE","code": "LTK"},
  {"name": "LITTLE SUTTON","code": "LTT"},
  {"name": "LITTLEBOROUGH","code": "LTL"},
  {"name": "LITTLEHAMPTON","code": "LIT"},
  {"name": "LITTLEHAVEN","code": "LVN"},
  {"name": "LITTLEPORT","code": "LTP"},
  {"name": "LIVERPOOL CENTRAL","code": "LVC"},
  {"name": "LIVERPOOL JAMES STREET","code": "LVJ"},
  {"name": "LIVERPOOL LIME STREET","code": "LIV"},
  {"name": "LIVERPOOL SOUTH PARKWAY","code": "LPY"},
  {"name": "LIVINGSTON NORTH","code": "LSN"},
  {"name": "LIVINGSTON SOUTH","code": "LVG"},
  {"name": "LLANABER","code": "LLA"},
  {"name": "LLANBEDR","code": "LBR"},
  {"name": "LLANBISTER ROAD","code": "LLT"},
  {"name": "LLANBRADACH","code": "LNB"},
  {"name": "LLANDAF","code": "LLN"},
  {"name": "LLANDANWG","code": "LDN"},
  {"name": "LLANDECWYN","code": "LLC"},
  {"name": "LLANDEILO","code": "LLL"},
  {"name": "LLANDOVERY","code": "LLV"},
  {"name": "LLANDRINDOD","code": "LLO"},
  {"name": "LLANDUDNO","code": "LLD"},
  {"name": "LLANDUDNO JUNCTION","code": "LLJ"},
  {"name": "LLANDYBIE","code": "LLI"},
  {"name": "LLANELLI","code": "LLE"},
  {"name": "LLANFAIRFECHAN","code": "LLF"},
  {"name": "LLANFAIRPWLL","code": "LPG"},
  {"name": "LLANGADOG","code": "LLG"},
  {"name": "LLANGAMMARCH","code": "LLM"},
  {"name": "LLANGENNECH","code": "LLH"},
  {"name": "LLANGYNLLO","code": "LGO"},
  {"name": "LLANHARAN","code": "LLR"},
  {"name": "LLANHILLETH","code": "LTH"},
  {"name": "LLANISHEN","code": "LLS"},
  {"name": "LLANRWST","code": "LWR"},
  {"name": "LLANSAMLET","code": "LAS"},
  {"name": "LLANTWIT MAJOR","code": "LWM"},
  {"name": "LLANWRDA","code": "LNR"},
  {"name": "LLANWRTYD","code": "LNW"},
  {"name": "LLWYNGWRIL","code": "LLW"},
  {"name": "LLWYNYPIA","code": "LLY"},
  {"name": "LOCH AWE","code": "LHA"},
  {"name": "LOCH EIL OUTWARD BOUND","code": "LHE"},
  {"name": "LOCHAILORT","code": "LCL"},
  {"name": "LOCHEILSIDE","code": "LCS"},
  {"name": "LOCHGELLY","code": "LCG"},
  {"name": "LOCHLUICHART","code": "LCC"},
  {"name": "LOCHWINNOCH","code": "LHW"},
  {"name": "LOCKERBIE","code": "LOC"},
  {"name": "LOCKWOOD","code": "LCK"},
  {"name": "LONDON BLACKFRIARS","code": "BFR"},
  {"name": "LONDON BRIDGE","code": "LBG"},
  {"name": "LONDON CANNON STREET","code": "CST"},
  {"name": "LONDON CHARING CROSS","code": "CHX"},
  {"name": "LONDON EUSTON","code": "EUS"},
  {"name": "LONDON FENCHURCH STREET","code": "FST"},
  {"name": "LONDON FIELDS","code": "LOF"},
  {"name": "LONDON KINGS CROSS","code": "KGX"},
  {"name": "LONDON LIVERPOOL STREET","code": "LST"},
  {"name": "LONDON MARYLEBONE","code": "MYB"},
  {"name": "LONDON PADDINGTON","code": "PAD"},
  {"name": "LONDON ROAD (BRIGHTON)","code": "LRB"},
  {"name": "LONDON ROAD (GUILDFORD)","code": "LRD"},
  {"name": "LONDON ST PANCRAS (INTL)","code": "SPX"},
  {"name": "LONDON ST PANCRAS INTERNATIONAL","code": "STP"},
  {"name": "LONDON VICTORIA","code": "VIC"},
  {"name": "LONDON WATERLOO","code": "WAT"},
  {"name": "LONDON WATERLOO EAST","code": "WAE"},
  {"name": "LONG BUCKBY","code": "LBK"},
  {"name": "LONG EATON","code": "LGE"},
  {"name": "LONG PRESTON","code": "LPR"},
  {"name": "LONGBECK","code": "LGK"},
  {"name": "LONGBRIDGE","code": "LOB"},
  {"name": "LONGCROSS","code": "LNG"},
  {"name": "LONGFIELD","code": "LGF"},
  {"name": "LONGNIDDRY","code": "LND"},
  {"name": "LONGPORT","code": "LPT"},
  {"name": "LONGTON","code": "LGN"},
  {"name": "LOOE","code": "LOO"},
  {"name": "LOSTOCK","code": "LOT"},
  {"name": "LOSTOCK GRALAM","code": "LTG"},
  {"name": "LOSTOCK HALL","code": "LOH"},
  {"name": "LOSTWITHIEL","code": "LOS"},
  {"name": "LOUGHBOROUGH","code": "LBO"},
  {"name": "LOUGHBOROUGH JUNCTION","code": "LGJ"},
  {"name": "LOWDHAM","code": "LOW"},
  {"name": "LOWER SYDENHAM","code": "LSY"},
  {"name": "LOWESTOFT","code": "LWT"},
  {"name": "LUDLOW","code": "LUD"},
  {"name": "LUTON","code": "LUT"},
  {"name": "LUTON AIRPORT PARKWAY","code": "LTN"},
  {"name": "LUXULYAN","code": "LUX"},
  {"name": "LYDNEY","code": "LYD"},
  {"name": "LYE (WEST MIDLANDS)","code": "LYE"},
  {"name": "LYMINGTON PIER","code": "LYP"},
  {"name": "LYMINGTON TOWN","code": "LYT"},
  {"name": "LYMPSTONE COMMANDO","code": "LYC"},
  {"name": "LYMPSTONE VILLAGE","code": "LYM"},
  {"name": "LYTHAM","code": "LTM"},
  {"name": "MACCLESFIELD","code": "MAC"},
  {"name": "MACHYNLLETH","code": "MCN"},
  {"name": "MAESTEG","code": "MST"},
  {"name": "MAESTEG (EWENNY ROAD)","code": "MEW"},
  {"name": "MAGHULL","code": "MAG"},
  {"name": "MAIDEN NEWTON","code": "MDN"},
  {"name": "MAIDENHEAD","code": "MAI"},
  {"name": "MAIDSTONE BARRACKS","code": "MDB"},
  {"name": "MAIDSTONE EAST","code": "MDE"},
  {"name": "MAIDSTONE WEST","code": "MDW"},
  {"name": "MALDEN MANOR","code": "MAL"},
  {"name": "MALLAIG","code": "MLG"},
  {"name": "MALTON","code": "MLT"},
  {"name": "MALVERN LINK","code": "MVL"},
  {"name": "MANCHESTER AIRPORT","code": "MIA"},
  {"name": "MANCHESTER OXFORD ROAD","code": "MCO"},
  {"name": "MANCHESTER PICCADILLY","code": "MAN"},
  {"name": "MANCHESTER UNITED FOOTBALL GROUND","code": "MUF"},
  {"name": "MANCHESTER VICTORIA","code": "MCV"},
  {"name": "MANEA","code": "MNE"},
  {"name": "MANNINGTREE","code": "MNG"},
  {"name": "MANOR PARK","code": "MNP"},
  {"name": "MANOR ROAD","code": "MNR"},
  {"name": "MANORBIER","code": "MRB"},
  {"name": "MANORS","code": "MAS"},
  {"name": "MANSFIELD","code": "MFT"},
  {"name": "MANSFIELD WOODHOUSE","code": "MSW"},
  {"name": "MARCH","code": "MCH"},
  {"name": "MARDEN (KENT)","code": "MRN"},
  {"name": "MARGATE","code": "MAR"},
  {"name": "MARKET HARBOROUGH","code": "MHR"},
  {"name": "MARKET RASEN","code": "MKR"},
  {"name": "MARKINCH","code": "MNC"},
  {"name": "MARKS TEY","code": "MKT"},
  {"name": "MARLOW","code": "MLW"},
  {"name": "MARPLE","code": "MPL"},
  {"name": "MARSDEN (YORKS)","code": "MSN"},
  {"name": "MARSKE","code": "MSK"},
  {"name": "MARSTON GREEN","code": "MGN"},
  {"name": "MARTIN MILL","code": "MTM"},
  {"name": "MARTINS HERON","code": "MAO"},
  {"name": "MARTON","code": "MTO"},
  {"name": "MARYHILL","code": "MYH"},
  {"name": "MARYLAND","code": "MYL"},
  {"name": "MARYPORT","code": "MRY"},
  {"name": "MATLOCK","code": "MAT"},
  {"name": "MATLOCK BATH","code": "MTB"},
  {"name": "MAULDETH ROAD","code": "MAU"},
  {"name": "MAXWELL PARK","code": "MAX"},
  {"name": "MAYBOLE","code": "MAY"},
  {"name": "MAZE HILL","code": "MZH"},
  {"name": "MEADOWHALL","code": "MHS"},
  {"name": "MELDRETH","code": "MEL"},
  {"name": "MELKSHAM","code": "MKM"},
  {"name": "MELTON (SUFFOLK)","code": "MES"},
  {"name": "MELTON MOWBRAY","code": "MMO"},
  {"name": "MENHENIOT","code": "MEN"},
  {"name": "MENSTON","code": "MNN"},
  {"name": "MEOLS","code": "MEO"},
  {"name": "MEOLS COP","code": "MEC"},
  {"name": "MEOPHAM","code": "MEP"},
  {"name": "MERRYTON","code": "MEY"},
  {"name": "MERSTHAM","code": "MHM"},
  {"name": "MERTHYR TYDFIL","code": "MER"},
  {"name": "MERTHYR VALE","code": "MEV"},
  {"name": "METHERINGHAM","code": "MGM"},
  {"name": "METROCENTRE","code": "MCE"},
  {"name": "MEXBOROUGH","code": "MEX"},
  {"name": "MICHELDEVER","code": "MIC"},
  {"name": "MICKLEFIELD","code": "MIK"},
  {"name": "MIDDLESBROUGH","code": "MBR"},
  {"name": "MIDDLEWOOD","code": "MDL"},
  {"name": "MIDGHAM","code": "MDG"},
  {"name": "MILFORD (SURREY)","code": "MLF"},
  {"name": "MILFORD HAVEN","code": "MFH"},
  {"name": "MILL HILL (LANCS)","code": "MLH"},
  {"name": "MILL HILL BROADWAY","code": "MIL"},
  {"name": "MILLBROOK (BEDS)","code": "MLB"},
  {"name": "MILLBROOK (HANTS)","code": "MBK"},
  {"name": "MILLIKEN PARK","code": "MIN"},
  {"name": "MILLOM","code": "MLM"},
  {"name": "MILLS HILL (MANCHESTER)","code": "MIH"},
  {"name": "MILNGAVIE","code": "MLN"},
  {"name": "MILTON KEYNES CENTRAL","code": "MKC"},
  {"name": "MINFFORDD","code": "MFF"},
  {"name": "MINSTER","code": "MSR"},
  {"name": "MIRFIELD","code": "MIR"},
  {"name": "MISTLEY","code": "MIS"},
  {"name": "MITCHAM EASTFIELDS","code": "MTC"},
  {"name": "MITCHAM JUNCTION","code": "MIJ"},
  {"name": "MOBBERLEY","code": "MOB"},
  {"name": "MONIFIETH","code": "MON"},
  {"name": "MONKS RISBOROUGH","code": "MRS"},
  {"name": "MONTPELIER","code": "MTP"},
  {"name": "MONTROSE","code": "MTS"},
  {"name": "MOORFIELDS","code": "MRF"},
  {"name": "MOORGATE","code": "MOG"},
  {"name": "MOORSIDE","code": "MSD"},
  {"name": "MOORTHORPE","code": "MRP"},
  {"name": "MORAR","code": "MRR"},
  {"name": "MORCHARD ROAD","code": "MRD"},
  {"name": "MORDEN SOUTH","code": "MDS"},
  {"name": "MORECAMBE","code": "MCM"},
  {"name": "MORETON (DORSET)","code": "MTN"},
  {"name": "MORETON (MERSEYSIDE)","code": "MRT"},
  {"name": "MORETON-IN-MARSH","code": "MIM"},
  {"name": "MORFA MAWDDACH","code": "MFA"},
  {"name": "MORLEY","code": "MLY"},
  {"name": "MORPETH","code": "MPT"},
  {"name": "MORTIMER","code": "MOR"},
  {"name": "MORTLAKE","code": "MTL"},
  {"name": "MOSES GATE","code": "MSS"},
  {"name": "MOSS SIDE","code": "MOS"},
  {"name": "MOSSLEY (MANCHESTER)","code": "MSL"},
  {"name": "MOSSLEY HILL","code": "MSH"},
  {"name": "MOSSPARK","code": "MPK"},
  {"name": "MOSTON","code": "MSO"},
  {"name": "MOTHERWELL","code": "MTH"},
  {"name": "MOTSPUR PARK","code": "MOT"},
  {"name": "MOTTINGHAM","code": "MTG"},
  {"name": "MOTTISFONT & DUNBRIDGE","code": "DBG"},
  {"name": "MOULDSWORTH","code": "MLD"},
  {"name": "MOULSECOOMB","code": "MCB"},
  {"name": "MOUNT FLORIDA","code": "MFL"},
  {"name": "MOUNT VERNON","code": "MTV"},
  {"name": "MOUNTAIN ASH","code": "MTA"},
  {"name": "MUIR OF ORD","code": "MOO"},
  {"name": "MUIREND","code": "MUI"},
  {"name": "MUSSELBURGH","code": "MUB"},
  {"name": "MYTHOLMROYD","code": "MYT"},
  {"name": "NAFFERTON","code": "NFN"},
  {"name": "NAILSEA & BACKWELL","code": "NLS"},
  {"name": "NAIRN","code": "NRN"},
  {"name": "NANTWICH","code": "NAN"},
  {"name": "NARBERTH","code": "NAR"},
  {"name": "NARBOROUGH","code": "NBR"},
  {"name": "NAVIGATION ROAD","code": "NVR"},
  {"name": "NEATH","code": "NTH"},
  {"name": "NEEDHAM MARKET","code": "NMT"},
  {"name": "NEILSTON","code": "NEI"},
  {"name": "NELSON","code": "NEL"},
  {"name": "NESTON","code": "NES"},
  {"name": "NETHERFIELD","code": "NET"},
  {"name": "NETHERTOWN","code": "NRT"},
  {"name": "NETLEY","code": "NTL"},
  {"name": "NEW BARNET","code": "NBA"},
  {"name": "NEW BECKENHAM","code": "NBC"},
  {"name": "NEW BRIGHTON","code": "NBN"},
  {"name": "NEW CLEE","code": "NCE"},
  {"name": "NEW CROSS","code": "NWX"},
  {"name": "NEW CROSS GATE","code": "NXG"},
  {"name": "NEW CUMNOCK","code": "NCK"},
  {"name": "NEW ELTHAM","code": "NEH"},
  {"name": "NEW HOLLAND","code": "NHL"},
  {"name": "NEW HYTHE","code": "NHE"},
  {"name": "NEW LANE","code": "NLN"},
  {"name": "NEW MALDEN","code": "NEM"},
  {"name": "NEW MILLS CENTRAL","code": "NMC"},
  {"name": "NEW MILLS NEWTOWN","code": "NMN"},
  {"name": "NEW MILTON","code": "NWM"},
  {"name": "NEW PUDSEY","code": "NPD"},
  {"name": "NEW SOUTHGATE","code": "NSG"},
  {"name": "NEWARK CASTLE","code": "NCT"},
  {"name": "NEWARK NORTH GATE","code": "NNG"},
  {"name": "NEWBRIDGE","code": "NBE"},
  {"name": "NEWBURY","code": "NBY"},
  {"name": "NEWBURY RACECOURSE","code": "NRC"},
  {"name": "NEWCASTLE","code": "NCL"},
  {"name": "NEWCOURT","code": "NCO"},
  {"name": "NEWCRAIGHALL","code": "NEW"},
  {"name": "NEWHAVEN HARBOUR","code": "NVH"},
  {"name": "NEWHAVEN TOWN","code": "NVN"},
  {"name": "NEWINGTON","code": "NGT"},
  {"name": "NEWMARKET","code": "NMK"},
  {"name": "NEWPORT (ESSEX)","code": "NWE"},
  {"name": "NEWPORT (SOUTH WALES)","code": "NWP"},
  {"name": "NEWQUAY","code": "NQY"},
  {"name": "NEWSTEAD","code": "NSD"},
  {"name": "NEWTON (LANARK)","code": "NTN"},
  {"name": "NEWTON ABBOT","code": "NTA"},
  {"name": "NEWTON AYCLIFFE","code": "NAY"},
  {"name": "NEWTON FOR HYDE","code": "NWN"},
  {"name": "NEWTON ST CYRES","code": "NTC"},
  {"name": "NEWTONGRANGE","code": "NEG"},
  {"name": "NEWTON-LE-WILLOWS","code": "NLW"},
  {"name": "NEWTONMORE","code": "NWR"},
  {"name": "NEWTON-ON-AYR","code": "NOA"},
  {"name": "NEWTOWN (POWYS)","code": "NWT"},
  {"name": "NINIAN PARK","code": "NNP"},
  {"name": "NITSHILL","code": "NIT"},
  {"name": "NORBITON","code": "NBT"},
  {"name": "NORBURY","code": "NRB"},
  {"name": "NORMANS BAY","code": "NSB"},
  {"name": "NORMANTON","code": "NOR"},
  {"name": "NORTH BERWICK","code": "NBW"},
  {"name": "NORTH CAMP","code": "NCM"},
  {"name": "NORTH DULWICH","code": "NDL"},
  {"name": "NORTH FAMBRIDGE","code": "NFA"},
  {"name": "NORTH LLANRWST","code": "NLR"},
  {"name": "NORTH QUEENSFERRY","code": "NQU"},
  {"name": "NORTH ROAD (DARLINGTON)","code": "NRD"},
  {"name": "NORTH SHEEN","code": "NSH"},
  {"name": "NORTH WALSHAM","code": "NWA"},
  {"name": "NORTH WEMBLEY","code": "NWB"},
  {"name": "NORTHALLERTON","code": "NTR"},
  {"name": "NORTHAMPTON","code": "NMP"},
  {"name": "NORTHFIELD","code": "NFD"},
  {"name": "NORTHFLEET","code": "NFL"},
  {"name": "NORTHOLT PARK","code": "NLT"},
  {"name": "NORTHUMBERLAND PARK","code": "NUM"},
  {"name": "NORTHWICH","code": "NWI"},
  {"name": "NORTON BRIDGE","code": "NTB"},
  {"name": "NORWICH","code": "NRW"},
  {"name": "NORWOOD JUNCTION","code": "NWD"},
  {"name": "NOTTINGHAM","code": "NOT"},
  {"name": "NUNEATON","code": "NUN"},
  {"name": "NUNHEAD","code": "NHD"},
  {"name": "NUNTHORPE","code": "NNT"},
  {"name": "NUTBOURNE","code": "NUT"},
  {"name": "NUTFIELD","code": "NUF"},
  {"name": "OAKENGATES","code": "OKN"},
  {"name": "OAKHAM","code": "OKM"},
  {"name": "OAKLEIGH PARK","code": "OKL"},
  {"name": "OBAN","code": "OBN"},
  {"name": "OCKENDON","code": "OCK"},
  {"name": "OCKLEY","code": "OLY"},
  {"name": "OKEHAMPTON","code": "OKE"},
  {"name": "OLD HILL","code": "OHL"},
  {"name": "OLD ROAN","code": "ORN"},
  {"name": "OLD STREET","code": "OLD"},
  {"name": "OLDFIELD PARK","code": "OLF"},
  {"name": "OLTON","code": "OLT"},
  {"name": "ORE","code": "ORE"},
  {"name": "ORMSKIRK","code": "OMS"},
  {"name": "ORPINGTON","code": "ORP"},
  {"name": "ORRELL","code": "ORR"},
  {"name": "ORRELL PARK","code": "OPK"},
  {"name": "OTFORD","code": "OTF"},
  {"name": "OULTON BROAD NORTH","code": "OUN"},
  {"name": "OULTON BROAD SOUTH","code": "OUS"},
  {"name": "OUTWOOD","code": "OUT"},
  {"name": "OVERPOOL","code": "OVE"},
  {"name": "OVERTON","code": "OVR"},
  {"name": "OXENHOLME LAKE DISTRICT","code": "OXN"},
  {"name": "OXFORD","code": "OXF"},
  {"name": "OXFORD PARKWAY","code": "OXP"},
  {"name": "OXSHOTT","code": "OXS"},
  {"name": "OXTED","code": "OXT"},
  {"name": "PADDOCK WOOD","code": "PDW"},
  {"name": "PADGATE","code": "PDG"},
  {"name": "PAIGNTON","code": "PGN"},
  {"name": "PAISLEY CANAL","code": "PCN"},
  {"name": "PAISLEY GILMOUR STREET","code": "PYG"},
  {"name": "PAISLEY ST JAMES","code": "PYJ"},
  {"name": "PALMERS GREEN","code": "PAL"},
  {"name": "PANGBOURNE","code": "PAN"},
  {"name": "PANNAL","code": "PNL"},
  {"name": "PANTYFFYNNON","code": "PTF"},
  {"name": "PAR","code": "PAR"},
  {"name": "PARBOLD","code": "PBL"},
  {"name": "PARK STREET","code": "PKT"},
  {"name": "PARKSTONE (DORSET)","code": "PKS"},
  {"name": "PARSON STREET","code": "PSN"},
  {"name": "PARTICK","code": "PTK"},
  {"name": "PARTON","code": "PRN"},
  {"name": "PATCHWAY","code": "PWY"},
  {"name": "PATRICROFT","code": "PAT"},
  {"name": "PATTERTON","code": "PTT"},
  {"name": "PEARTREE","code": "PEA"},
  {"name": "PECKHAM RYE","code": "PMR"},
  {"name": "PEGSWOOD","code": "PEG"},
  {"name": "PEMBERTON","code": "PEM"},
  {"name": "PEMBREY & BURRY PORT","code": "PBY"},
  {"name": "PEMBROKE","code": "PMB"},
  {"name": "PEMBROKE DOCK","code": "PMD"},
  {"name": "PENALLY","code": "PNA"},
  {"name": "PENARTH","code": "PEN"},
  {"name": "PENCOED","code": "PCD"},
  {"name": "PENGAM","code": "PGM"},
  {"name": "PENGE EAST","code": "PNE"},
  {"name": "PENGE WEST","code": "PNW"},
  {"name": "PENHELIG","code": "PHG"},
  {"name": "PENISTONE","code": "PNS"},
  {"name": "PENKRIDGE","code": "PKG"},
  {"name": "PENMAENMAWR","code": "PMW"},
  {"name": "PENMERE","code": "PNM"},
  {"name": "PENRHIWCEIBER","code": "PER"},
  {"name": "PENRHYNDEUDRAETH","code": "PRH"},
  {"name": "PENRITH (NORTH LAKES)","code": "PNR"},
  {"name": "PENRYN (CORNWALL)","code": "PYN"},
  {"name": "PENSARN (GWYNEDD)","code": "PES"},
  {"name": "PENSHURST","code": "PHR"},
  {"name": "PENTRE-BACH","code": "PTB"},
  {"name": "PEN-Y-BONT","code": "PNY"},
  {"name": "PENYCHAIN","code": "PNC"},
  {"name": "PENYFFORDD","code": "PNF"},
  {"name": "PENZANCE","code": "PNZ"},
  {"name": "PERRANWELL","code": "PRW"},
  {"name": "PERRY BARR","code": "PRY"},
  {"name": "PERSHORE","code": "PSH"},
  {"name": "PERTH","code": "PTH"},
  {"name": "PETERBOROUGH","code": "PBO"},
  {"name": "PETERSFIELD","code": "PTR"},
  {"name": "PETTS WOOD","code": "PET"},
  {"name": "PEVENSEY & WESTHAM","code": "PEV"},
  {"name": "PEVENSEY BAY","code": "PEB"},
  {"name": "PEWSEY","code": "PEW"},
  {"name": "PILNING","code": "PIL"},
  {"name": "PINHOE","code": "PIN"},
  {"name": "PITLOCHRY","code": "PIT"},
  {"name": "PITSEA","code": "PSE"},
  {"name": "PLEASINGTON","code": "PLS"},
  {"name": "PLOCKTON","code": "PLK"},
  {"name": "PLUCKLEY","code": "PLC"},
  {"name": "PLUMLEY","code": "PLM"},
  {"name": "PLUMPTON","code": "PMP"},
  {"name": "PLUMSTEAD","code": "PLU"},
  {"name": "PLYMOUTH","code": "PLY"},
  {"name": "POKESDOWN","code": "POK"},
  {"name": "POLEGATE","code": "PLG"},
  {"name": "POLESWORTH","code": "PSW"},
  {"name": "POLLOKSHAWS EAST","code": "PWE"},
  {"name": "POLLOKSHAWS WEST","code": "PWW"},
  {"name": "POLLOKSHIELDS EAST","code": "PLE"},
  {"name": "POLLOKSHIELDS WEST","code": "PLW"},
  {"name": "POLMONT","code": "PMT"},
  {"name": "POLSLOE BRIDGE","code": "POL"},
  {"name": "PONDERS END","code": "PON"},
  {"name": "PONTARDDULAIS","code": "PTD"},
  {"name": "PONTEFRACT BAGHILL","code": "PFR"},
  {"name": "PONTEFRACT MONKHILL","code": "PFM"},
  {"name": "PONTEFRACT TANSHELF","code": "POT"},
  {"name": "PONTLOTTYN","code": "PLT"},
  {"name": "PONTYCLUN","code": "PYC"},
  {"name": "PONT-Y-PANT","code": "PYP"},
  {"name": "PONTYPOOL & NEW INN","code": "PPL"},
  {"name": "PONTYPRIDD","code": "PPD"},
  {"name": "POOLE","code": "POO"},
  {"name": "POPPLETON","code": "POP"},
  {"name": "PORT GLASGOW","code": "PTG"},
  {"name": "PORT SUNLIGHT","code": "PSL"},
  {"name": "PORT TALBOT PARKWAY","code": "PTA"},
  {"name": "PORTCHESTER","code": "PTC"},
  {"name": "PORTH","code": "POR"},
  {"name": "PORTHMADOG","code": "PTM"},
  {"name": "PORTLETHEN","code": "PLN"},
  {"name": "PORTSLADE","code": "PLD"},
  {"name": "PORTSMOUTH & SOUTHSEA","code": "PMS"},
  {"name": "PORTSMOUTH ARMS","code": "PMA"},
  {"name": "PORTSMOUTH HARBOUR","code": "PMH"},
  {"name": "POSSILPARK & PARKHOUSE","code": "PPK"},
  {"name": "POTTERS BAR","code": "PBR"},
  {"name": "POULTON-LE-FYLDE","code": "PFY"},
  {"name": "POYNTON","code": "PYT"},
  {"name": "PREES","code": "PRS"},
  {"name": "PRESCOT","code": "PSC"},
  {"name": "PRESTATYN","code": "PRT"},
  {"name": "PRESTBURY","code": "PRB"},
  {"name": "PRESTON (LANCS)","code": "PRE"},
  {"name": "PRESTON PARK","code": "PRP"},
  {"name": "PRESTONPANS","code": "PST"},
  {"name": "PRESTWICK INTERNATIONAL AIRPORT","code": "PRA"},
  {"name": "PRESTWICK TOWN","code": "PTW"},
  {"name": "PRIESTHILL & DARNLEY","code": "PTL"},
  {"name": "PRINCES RISBOROUGH","code": "PRR"},
  {"name": "PRITTLEWELL","code": "PRL"},
  {"name": "PRUDHOE","code": "PRU"},
  {"name": "PULBOROUGH","code": "PUL"},
  {"name": "PURFLEET","code": "PFL"},
  {"name": "PURLEY","code": "PUR"},
  {"name": "PURLEY OAKS","code": "PUO"},
  {"name": "PUTNEY","code": "PUT"},
  {"name": "PWLLHELI","code": "PWL"},
  {"name": "PYE CORNER","code": "PYE"},
  {"name": "PYLE","code": "PYL"},
  {"name": "QUAKERS YARD","code": "QYD"},
  {"name": "QUEENBOROUGH","code": "QBR"},
  {"name": "QUEENS PARK (GLASGOW)","code": "QPK"},
  {"name": "QUEENS PARK (LONDON)","code": "QPW"},
  {"name": "QUEENS ROAD (PECKHAM)","code": "QRP"},
  {"name": "QUEENSTOWN ROAD (BATTERSEA)","code": "QRB"},
  {"name": "QUINTRELL DOWNS","code": "QUI"},
  {"name": "RADCLIFFE-ON-TRENT","code": "RDF"},
  {"name": "RADLETT","code": "RDT"},
  {"name": "RADLEY","code": "RAD"},
  {"name": "RADYR","code": "RDR"},
  {"name": "RAINFORD","code": "RNF"},
  {"name": "RAINHAM (ESSEX)","code": "RNM"},
  {"name": "RAINHAM (KENT)","code": "RAI"},
  {"name": "RAINHILL","code": "RNH"},
  {"name": "RAMSGATE","code": "RAM"},
  {"name": "RAMSGREAVE & WILPSHIRE","code": "RGW"},
  {"name": "RANNOCH","code": "RAN"},
  {"name": "RAUCEBY","code": "RAU"},
  {"name": "RAVENGLASS FOR ESKDALE","code": "RAV"},
  {"name": "RAVENSBOURNE","code": "RVB"},
  {"name": "RAVENSTHORPE","code": "RVN"},
  {"name": "RAWCLIFFE","code": "RWC"},
  {"name": "RAYLEIGH","code": "RLG"},
  {"name": "RAYNES PARK","code": "RAY"},
  {"name": "READING","code": "RDG"},
  {"name": "READING WEST","code": "RDW"},
  {"name": "RECTORY ROAD","code": "REC"},
  {"name": "REDBRIDGE","code": "RDB"},
  {"name": "REDCAR BRITISH STEEL","code": "RBS"},
  {"name": "REDCAR CENTRAL","code": "RCC"},
  {"name": "REDCAR EAST","code": "RCE"},
  {"name": "REDDISH NORTH","code": "RDN"},
  {"name": "REDDISH SOUTH","code": "RDS"},
  {"name": "REDDITCH","code": "RDC"},
  {"name": "REDHILL","code": "RDH"},
  {"name": "REDLAND","code": "RDA"},
  {"name": "REDRUTH","code": "RED"},
  {"name": "REEDHAM (NORFOLK)","code": "REE"},
  {"name": "REEDHAM (SURREY)","code": "RHM"},
  {"name": "REIGATE","code": "REI"},
  {"name": "RENTON","code": "RTN"},
  {"name": "RETFORD","code": "RET"},
  {"name": "RHIWBINA","code": "RHI"},
  {"name": "RHOOSE CARDIFF INTERNATIONAL AIRPORT","code": "RIA"},
  {"name": "RHOSNEIGR","code": "RHO"},
  {"name": "RHYL","code": "RHL"},
  {"name": "RHYMNEY","code": "RHY"},
  {"name": "RIBBLEHEAD","code": "RHD"},
  {"name": "RICE LANE","code": "RIL"},
  {"name": "RICHMOND (LONDON)","code": "RMD"},
  {"name": "RICKMANSWORTH","code": "RIC"},
  {"name": "RIDDLESDOWN","code": "RDD"},
  {"name": "RIDGMONT","code": "RID"},
  {"name": "RIDING MILL","code": "RDM"},
  {"name": "RISCA & PONTYMISTER","code": "RCA"},
  {"name": "RISHTON","code": "RIS"},
  {"name": "ROBERTSBRIDGE","code": "RBR"},
  {"name": "ROBY","code": "ROB"},
  {"name": "ROCHDALE","code": "RCD"},
  {"name": "ROCHE","code": "ROC"},
  {"name": "ROCHESTER","code": "RTR"},
  {"name": "ROCHFORD","code": "RFD"},
  {"name": "ROCK FERRY","code": "RFY"},
  {"name": "ROGART","code": "ROG"},
  {"name": "ROGERSTONE","code": "ROR"},
  {"name": "ROLLESTON","code": "ROL"},
  {"name": "ROMAN BRIDGE","code": "RMB"},
  {"name": "ROMFORD","code": "RMF"},
  {"name": "ROMILEY","code": "RML"},
  {"name": "ROMSEY","code": "ROM"},
  {"name": "ROOSE","code": "ROO"},
  {"name": "ROSE GROVE","code": "RSG"},
  {"name": "ROSE HILL MARPLE","code": "RSH"},
  {"name": "ROSYTH","code": "ROS"},
  {"name": "ROTHERHAM CENTRAL","code": "RMC"},
  {"name": "ROTHERHITHE","code": "ROE"},
  {"name": "ROUGHTON ROAD","code": "RNR"},
  {"name": "ROWLANDS CASTLE","code": "RLN"},
  {"name": "ROWLEY REGIS","code": "ROW"},
  {"name": "ROY BRIDGE","code": "RYB"},
  {"name": "ROYDON","code": "RYN"},
  {"name": "ROYSTON","code": "RYS"},
  {"name": "RUABON","code": "RUA"},
  {"name": "RUFFORD","code": "RUF"},
  {"name": "RUGBY","code": "RUG"},
  {"name": "RUGELEY TOWN","code": "RGT"},
  {"name": "RUGELEY TRENT VALLEY","code": "RGL"},
  {"name": "RUNCORN","code": "RUN"},
  {"name": "RUNCORN EAST","code": "RUE"},
  {"name": "RUSKINGTON","code": "RKT"},
  {"name": "RUSWARP","code": "RUS"},
  {"name": "RUTHERGLEN","code": "RUT"},
  {"name": "RYDE ESPLANADE","code": "RYD"},
  {"name": "RYDE PIER HEAD","code": "RYP"},
  {"name": "RYDE ST JOHNS ROAD","code": "RYR"},
  {"name": "RYDER BROW","code": "RRB"},
  {"name": "RYE (SUSSEX)","code": "RYE"},
  {"name": "RYE HOUSE","code": "RYH"},
  {"name": "SALFORD CENTRAL","code": "SFD"},
  {"name": "SALFORD CRESCENT","code": "SLD"},
  {"name": "SALFORDS (SURREY)","code": "SAF"},
  {"name": "SALHOUSE","code": "SAH"},
  {"name": "SALISBURY","code": "SAL"},
  {"name": "SALTAIRE","code": "SAE"},
  {"name": "SALTASH","code": "STS"},
  {"name": "SALTBURN","code": "SLB"},
  {"name": "SALTCOATS","code": "SLT"},
  {"name": "SALTMARSHE","code": "SAM"},
  {"name": "SALWICK","code": "SLW"},
  {"name": "SAMPFORD COURTENAY","code": "SMC"},
  {"name": "SANDAL & AGBRIGG","code": "SNA"},
  {"name": "SANDBACH","code": "SDB"},
  {"name": "SANDERSTEAD","code": "SNR"},
  {"name": "SANDHILLS","code": "SDL"},
  {"name": "SANDHURST (BERKS)","code": "SND"},
  {"name": "SANDLING","code": "SDG"},
  {"name": "SANDOWN","code": "SAN"},
  {"name": "SANDPLACE","code": "SDP"},
  {"name": "SANDWELL & DUDLEY","code": "SAD"},
  {"name": "SANDWICH","code": "SDW"},
  {"name": "SANDY","code": "SDY"},
  {"name": "SANKEY FOR PENKETH","code": "SNK"},
  {"name": "SANQUHAR","code": "SQH"},
  {"name": "SARN","code": "SRR"},
  {"name": "SAUNDERSFOOT","code": "SDF"},
  {"name": "SAUNDERTON","code": "SDR"},
  {"name": "SAWBRIDGEWORTH","code": "SAW"},
  {"name": "SAXILBY","code": "SXY"},
  {"name": "SAXMUNDHAM","code": "SAX"},
  {"name": "SCARBOROUGH","code": "SCA"},
  {"name": "SCOTSCALDER","code": "SCT"},
  {"name": "SCOTSTOUNHILL","code": "SCH"},
  {"name": "SCUNTHORPE","code": "SCU"},
  {"name": "SEA MILLS","code": "SML"},
  {"name": "SEAFORD (SUSSEX)","code": "SEF"},
  {"name": "SEAFORTH & LITHERLAND","code": "SFL"},
  {"name": "SEAHAM","code": "SEA"},
  {"name": "SEAMER","code": "SEM"},
  {"name": "SEASCALE","code": "SSC"},
  {"name": "SEATON CAREW","code": "SEC"},
  {"name": "SEER GREEN & JORDANS","code": "SRG"},
  {"name": "SELBY","code": "SBY"},
  {"name": "SELHURST","code": "SRS"},
  {"name": "SELLAFIELD","code": "SEL"},
  {"name": "SELLING","code": "SEG"},
  {"name": "SELLY OAK","code": "SLY"},
  {"name": "SETTLE","code": "SET"},
  {"name": "SEVEN KINGS","code": "SVK"},
  {"name": "SEVEN SISTERS","code": "SVS"},
  {"name": "SEVENOAKS","code": "SEV"},
  {"name": "SEVERN BEACH","code": "SVB"},
  {"name": "SEVERN TUNNEL JUNCTION","code": "STJ"},
  {"name": "SHADWELL","code": "SDE"},
  {"name": "SHALFORD (SURREY)","code": "SFR"},
  {"name": "SHANKLIN","code": "SHN"},
  {"name": "SHAWFAIR","code": "SFI"},
  {"name": "SHAWFORD","code": "SHW"},
  {"name": "SHAWLANDS","code": "SHL"},
  {"name": "SHEERNESS-ON-SEA","code": "SSS"},
  {"name": "SHEFFIELD","code": "SHF"},
  {"name": "SHELFORD (CAMBS)","code": "SED"},
  {"name": "SHENFIELD","code": "SNF"},
  {"name": "SHENSTONE","code": "SEN"},
  {"name": "SHEPHERD'S BUSH","code": "SPB"},
  {"name": "SHEPHERDS WELL","code": "SPH"},
  {"name": "SHEPLEY","code": "SPY"},
  {"name": "SHEPPERTON","code": "SHP"},
  {"name": "SHEPRETH","code": "STH"},
  {"name": "SHERBORNE","code": "SHE"},
  {"name": "SHERBURN-IN-ELMET","code": "SIE"},
  {"name": "SHERINGHAM","code": "SHM"},
  {"name": "SHETTLESTON","code": "SLS"},
  {"name": "SHIELDMUIR","code": "SDM"},
  {"name": "SHIFNAL","code": "SFN"},
  {"name": "SHILDON","code": "SHD"},
  {"name": "SHIPLAKE","code": "SHI"},
  {"name": "SHIPLEY (YORKS)","code": "SHY"},
  {"name": "SHIPPEA HILL","code": "SPP"},
  {"name": "SHIPTON","code": "SIP"},
  {"name": "SHIREBROOK","code": "SHB"},
  {"name": "SHIREHAMPTON","code": "SHH"},
  {"name": "SHIREOAKS","code": "SRO"},
  {"name": "SHIRLEY","code": "SRL"},
  {"name": "SHOEBURYNESS","code": "SRY"},
  {"name": "SHOLING","code": "SHO"},
  {"name": "SHOREDITCH HIGH STREET","code": "SDC"},
  {"name": "SHOREHAM (KENT)","code": "SEH"},
  {"name": "SHOREHAM-BY-SEA","code": "SSE"},
  {"name": "SHORTLANDS","code": "SRT"},
  {"name": "SHOTTON","code": "SHT"},
  {"name": "SHOTTS","code": "SHS"},
  {"name": "SHREWSBURY","code": "SHR"},
  {"name": "SIDCUP","code": "SID"},
  {"name": "SILEBY","code": "SIL"},
  {"name": "SILECROFT","code": "SIC"},
  {"name": "SILKSTONE COMMON","code": "SLK"},
  {"name": "SILVER STREET","code": "SLV"},
  {"name": "SILVERDALE","code": "SVR"},
  {"name": "SINGER","code": "SIN"},
  {"name": "SITTINGBOURNE","code": "SIT"},
  {"name": "SKEGNESS","code": "SKG"},
  {"name": "SKEWEN","code": "SKE"},
  {"name": "SKIPTON","code": "SKI"},
  {"name": "SLADE GREEN","code": "SGR"},
  {"name": "SLAITHWAITE","code": "SWT"},
  {"name": "SLATEFORD","code": "SLA"},
  {"name": "SLEAFORD","code": "SLR"},
  {"name": "SLEIGHTS","code": "SLH"},
  {"name": "SLOUGH","code": "SLO"},
  {"name": "SMALL HEATH","code": "SMA"},
  {"name": "SMALLBROOK JUNCTION","code": "SAB"},
  {"name": "SMETHWICK GALTON BRIDGE","code": "SGB"},
  {"name": "SMETHWICK ROLFE STREET","code": "SMR"},
  {"name": "SMITHY BRIDGE","code": "SMB"},
  {"name": "SNAITH","code": "SNI"},
  {"name": "SNODLAND","code": "SDA"},
  {"name": "SNOWDOWN","code": "SWO"},
  {"name": "SOLE STREET","code": "SOR"},
  {"name": "SOLIHULL","code": "SOL"},
  {"name": "SOMERLEYTON","code": "SYT"},
  {"name": "SOUTH ACTON","code": "SAT"},
  {"name": "SOUTH BANK","code": "SBK"},
  {"name": "SOUTH BERMONDSEY","code": "SBM"},
  {"name": "SOUTH CROYDON","code": "SCY"},
  {"name": "SOUTH ELMSALL","code": "SES"},
  {"name": "SOUTH GREENFORD","code": "SGN"},
  {"name": "SOUTH GYLE","code": "SGL"},
  {"name": "SOUTH HAMPSTEAD","code": "SOH"},
  {"name": "SOUTH KENTON","code": "SOK"},
  {"name": "SOUTH MERTON","code": "SMO"},
  {"name": "SOUTH MILFORD","code": "SOM"},
  {"name": "SOUTH RUISLIP","code": "SRU"},
  {"name": "SOUTH TOTTENHAM","code": "STO"},
  {"name": "SOUTH WIGSTON","code": "SWS"},
  {"name": "SOUTH WOODHAM FERRERS","code": "SOF"},
  {"name": "SOUTHALL","code": "STL"},
  {"name": "SOUTHAMPTON AIRPORT PARKWAY","code": "SOA"},
  {"name": "SOUTHAMPTON CENTRAL","code": "SOU"},
  {"name": "SOUTHBOURNE","code": "SOB"},
  {"name": "SOUTHBURY","code": "SBU"},
  {"name": "SOUTHEASE","code": "SEE"},
  {"name": "SOUTHEND AIRPORT","code": "SIA"},
  {"name": "SOUTHEND CENTRAL","code": "SOC"},
  {"name": "SOUTHEND EAST","code": "SOE"},
  {"name": "SOUTHEND VICTORIA","code": "SOV"},
  {"name": "SOUTHMINSTER","code": "SMN"},
  {"name": "SOUTHPORT","code": "SOP"},
  {"name": "SOUTHWICK","code": "SWK"},
  {"name": "SOWERBY BRIDGE","code": "SOW"},
  {"name": "SPALDING","code": "SPA"},
  {"name": "SPEAN BRIDGE","code": "SBR"},
  {"name": "SPITAL","code": "SPI"},
  {"name": "SPONDON","code": "SPO"},
  {"name": "SPOONER ROW","code": "SPN"},
  {"name": "SPRING ROAD","code": "SRI"},
  {"name": "SPRINGBURN","code": "SPR"},
  {"name": "SPRINGFIELD","code": "SPF"},
  {"name": "SQUIRES GATE","code": "SQU"},
  {"name": "ST ALBANS ABBEY","code": "SAA"},
  {"name": "ST ALBANS CITY","code": "SAC"},
  {"name": "ST ANDREWS ROAD","code": "SAR"},
  {"name": "ST ANNES-ON-SEA","code": "SAS"},
  {"name": "ST AUSTELL","code": "SAU"},
  {"name": "ST BEES","code": "SBS"},
  {"name": "ST BUDEAUX FERRY ROAD","code": "SBF"},
  {"name": "ST BUDEAUX VICTORIA ROAD","code": "SBV"},
  {"name": "ST COLUMB ROAD","code": "SCR"},
  {"name": "ST DENYS","code": "SDN"},
  {"name": "ST ERTH","code": "SER"},
  {"name": "ST GERMANS","code": "SGM"},
  {"name": "ST HELENS CENTRAL","code": "SNH"},
  {"name": "ST HELENS JUNCTION","code": "SHJ"},
  {"name": "ST HELIER (SURREY)","code": "SIH"},
  {"name": "ST IVES (CORNWALL)","code": "SIV"},
  {"name": "ST JAMES PARK (EXETER)","code": "SJP"},
  {"name": "ST JAMES STREET (WALTHAMSTOW)","code": "SJS"},
  {"name": "ST JOHNS (LONDON)","code": "SAJ"},
  {"name": "ST KEYNE WISHING WELL HALT","code": "SKN"},
  {"name": "ST LEONARDS WARRIOR SQUARE","code": "SLQ"},
  {"name": "ST MARGARETS (HERTS)","code": "SMT"},
  {"name": "ST MARGARETS (LONDON)","code": "SMG"},
  {"name": "ST MARY CRAY","code": "SMY"},
  {"name": "ST MICHAELS","code": "STM"},
  {"name": "ST NEOTS","code": "SNO"},
  {"name": "STAFFORD","code": "STA"},
  {"name": "STAINES","code": "SNS"},
  {"name": "STALLINGBOROUGH","code": "SLL"},
  {"name": "STALYBRIDGE","code": "SYB"},
  {"name": "STAMFORD (LINCS)","code": "SMD"},
  {"name": "STAMFORD HILL","code": "SMH"},
  {"name": "STANFORD-LE-HOPE","code": "SFO"},
  {"name": "STANLOW & THORNTON","code": "SNT"},
  {"name": "STANSTED AIRPORT","code": "SSD"},
  {"name": "STANSTED MOUNTFITCHET","code": "SST"},
  {"name": "STAPLEHURST","code": "SPU"},
  {"name": "STAPLETON ROAD","code": "SRD"},
  {"name": "STARBECK","code": "SBE"},
  {"name": "STARCROSS","code": "SCS"},
  {"name": "STAVELEY (CUMBRIA)","code": "SVL"},
  {"name": "STECHFORD","code": "SCF"},
  {"name": "STEETON & SILSDEN","code": "SON"},
  {"name": "STEPPS","code": "SPS"},
  {"name": "STEVENAGE","code": "SVG"},
  {"name": "STEVENSTON","code": "STV"},
  {"name": "STEWARTBY","code": "SWR"},
  {"name": "STEWARTON","code": "STT"},
  {"name": "STIRLING","code": "STG"},
  {"name": "STOCKPORT","code": "SPT"},
  {"name": "STOCKSFIELD","code": "SKS"},
  {"name": "STOCKSMOOR","code": "SSM"},
  {"name": "STOCKTON","code": "STK"},
  {"name": "STOKE MANDEVILLE","code": "SKM"},
  {"name": "STOKE NEWINGTON","code": "SKW"},
  {"name": "STOKE-ON-TRENT","code": "SOT"},
  {"name": "STONE (STAFFS)","code": "SNE"},
  {"name": "STONE CROSSING","code": "SCG"},
  {"name": "STONEBRIDGE PARK","code": "SBP"},
  {"name": "STONEGATE","code": "SOG"},
  {"name": "STONEHAVEN","code": "STN"},
  {"name": "STONEHOUSE","code": "SHU"},
  {"name": "STONELEIGH","code": "SNL"},
  {"name": "STOURBRIDGE JUNCTION","code": "SBJ"},
  {"name": "STOURBRIDGE TOWN","code": "SBT"},
  {"name": "STOW","code": "SOI"},
  {"name": "STOWMARKET","code": "SMK"},
  {"name": "STRANRAER","code": "STR"},
  {"name": "STRATFORD (LONDON)","code": "SRA"},
  {"name": "STRATFORD INTERNATIONAL","code": "SFA"},
  {"name": "STRATFORD-UPON-AVON","code": "SAV"},
  {"name": "STRATFORD-UPON-AVON PARKWAY","code": "STY"},
  {"name": "STRATHCARRON","code": "STC"},
  {"name": "STRAWBERRY HILL","code": "STW"},
  {"name": "STREATHAM (GREATER LONDON)","code": "STE"},
  {"name": "STREATHAM COMMON","code": "SRC"},
  {"name": "STREATHAM HILL","code": "SRH"},
  {"name": "STREETHOUSE","code": "SHC"},
  {"name": "STRINES","code": "SRN"},
  {"name": "STROMEFERRY","code": "STF"},
  {"name": "STROOD (KENT)","code": "SOO"},
  {"name": "STROUD (GLOUCS)","code": "STD"},
  {"name": "STURRY","code": "STU"},
  {"name": "STYAL","code": "SYA"},
  {"name": "SUDBURY & HARROW ROAD","code": "SUD"},
  {"name": "SUDBURY (SUFFOLK)","code": "SUY"},
  {"name": "SUDBURY HILL HARROW","code": "SDH"},
  {"name": "SUGAR LOAF","code": "SUG"},
  {"name": "SUMMERSTON","code": "SUM"},
  {"name": "SUNBURY","code": "SUU"},
  {"name": "SUNDERLAND","code": "SUN"},
  {"name": "SUNDRIDGE PARK","code": "SUP"},
  {"name": "SUNNINGDALE","code": "SNG"},
  {"name": "SUNNYMEADS","code": "SNY"},
  {"name": "SURBITON","code": "SUR"},
  {"name": "SURREY QUAYS","code": "SQE"},
  {"name": "SUTTON (SURREY)","code": "SUO"},
  {"name": "SUTTON COLDFIELD","code": "SUT"},
  {"name": "SUTTON COMMON","code": "SUC"},
  {"name": "SUTTON PARKWAY","code": "SPK"},
  {"name": "SWALE","code": "SWL"},
  {"name": "SWANLEY","code": "SAY"},
  {"name": "SWANSCOMBE","code": "SWM"},
  {"name": "SWANSEA","code": "SWA"},
  {"name": "SWANWICK","code": "SNW"},
  {"name": "SWAY","code": "SWY"},
  {"name": "SWAYTHLING","code": "SWG"},
  {"name": "SWINDERBY","code": "SWD"},
  {"name": "SWINDON (WILTS)","code": "SWI"},
  {"name": "SWINESHEAD","code": "SWE"},
  {"name": "SWINTON (MANCHESTER)","code": "SNN"},
  {"name": "SWINTON (SOUTH YORKS)","code": "SWN"},
  {"name": "SYDENHAM (LONDON)","code": "SYD"},
  {"name": "SYDENHAM HILL","code": "SYH"},
  {"name": "SYON LANE","code": "SYL"},
  {"name": "SYSTON","code": "SYS"},
  {"name": "TACKLEY","code": "TAC"},
  {"name": "TADWORTH","code": "TAD"},
  {"name": "TAFFS WELL","code": "TAF"},
  {"name": "TAIN","code": "TAI"},
  {"name": "TALSARNAU","code": "TAL"},
  {"name": "TALYBONT","code": "TLB"},
  {"name": "TAL-Y-CAFN","code": "TLC"},
  {"name": "TAME BRIDGE PARKWAY","code": "TAB"},
  {"name": "TAMWORTH","code": "TAM"},
  {"name": "TAPLOW","code": "TAP"},
  {"name": "TATTENHAM CORNER","code": "TAT"},
  {"name": "TAUNTON","code": "TAU"},
  {"name": "TAYNUILT","code": "TAY"},
  {"name": "TEDDINGTON","code": "TED"},
  {"name": "TEES-SIDE AIRPORT","code": "TEA"},
  {"name": "TEIGNMOUTH","code": "TGM"},
  {"name": "TELFORD CENTRAL","code": "TFC"},
  {"name": "TEMPLECOMBE","code": "TMC"},
  {"name": "TENBY","code": "TEN"},
  {"name": "TEYNHAM","code": "TEY"},
  {"name": "THAMES DITTON","code": "THD"},
  {"name": "THATCHAM","code": "THA"},
  {"name": "THATTO HEATH","code": "THH"},
  {"name": "THE HAWTHORNS","code": "THW"},
  {"name": "THE LAKES (WARKS)","code": "TLK"},
  {"name": "THEALE","code": "THE"},
  {"name": "THEOBALDS GROVE","code": "TEO"},
  {"name": "THETFORD","code": "TTF"},
  {"name": "THIRSK","code": "THI"},
  {"name": "THORNABY","code": "TBY"},
  {"name": "THORNE NORTH","code": "TNN"},
  {"name": "THORNE SOUTH","code": "TNS"},
  {"name": "THORNFORD","code": "THO"},
  {"name": "THORNLIEBANK","code": "THB"},
  {"name": "THORNTON ABBEY","code": "TNA"},
  {"name": "THORNTON HEATH","code": "TTH"},
  {"name": "THORNTONHALL","code": "THT"},
  {"name": "THORPE BAY","code": "TPB"},
  {"name": "THORPE CULVERT","code": "TPC"},
  {"name": "THORPE-LE-SOKEN","code": "TLS"},
  {"name": "THREE BRIDGES","code": "TBD"},
  {"name": "THREE OAKS","code": "TOK"},
  {"name": "THURGARTON","code": "THU"},
  {"name": "THURNSCOE","code": "THC"},
  {"name": "THURSO","code": "THS"},
  {"name": "THURSTON","code": "TRS"},
  {"name": "TILBURY TOWN","code": "TIL"},
  {"name": "TILE HILL","code": "THL"},
  {"name": "TILEHURST","code": "TLH"},
  {"name": "TIPTON","code": "TIP"},
  {"name": "TIR-PHIL","code": "TIR"},
  {"name": "TISBURY","code": "TIS"},
  {"name": "TIVERTON PARKWAY","code": "TVP"},
  {"name": "TODMORDEN","code": "TOD"},
  {"name": "TOLWORTH","code": "TOL"},
  {"name": "TON PENTRE","code": "TPN"},
  {"name": "TONBRIDGE","code": "TON"},
  {"name": "TONDU","code": "TDU"},
  {"name": "TONFANAU","code": "TNF"},
  {"name": "TONYPANDY","code": "TNP"},
  {"name": "TOOTING","code": "TOO"},
  {"name": "TOPSHAM","code": "TOP"},
  {"name": "TORQUAY","code": "TQY"},
  {"name": "TORRE","code": "TRR"},
  {"name": "TOTNES","code": "TOT"},
  {"name": "TOTTENHAM HALE","code": "TOM"},
  {"name": "TOTTON","code": "TTN"},
  {"name": "TOWN GREEN","code": "TWN"},
  {"name": "TRAFFORD PARK","code": "TRA"},
  {"name": "TREFFOREST","code": "TRF"},
  {"name": "TREFFOREST ESTATE","code": "TRE"},
  {"name": "TREHAFOD","code": "TRH"},
  {"name": "TREHERBERT","code": "TRB"},
  {"name": "TREORCHY","code": "TRY"},
  {"name": "TRIMLEY","code": "TRM"},
  {"name": "TRING","code": "TRI"},
  {"name": "TROED-Y-RHIW","code": "TRD"},
  {"name": "TROON","code": "TRN"},
  {"name": "TROWBRIDGE","code": "TRO"},
  {"name": "TRURO","code": "TRU"},
  {"name": "TULLOCH","code": "TUL"},
  {"name": "TULSE HILL","code": "TUH"},
  {"name": "TUNBRIDGE WELLS","code": "TBW"},
  {"name": "TURKEY STREET","code": "TUR"},
  {"name": "TUTBURY & HATTON","code": "TUT"},
  {"name": "TWEEDBANK","code": "TWB"},
  {"name": "TWICKENHAM","code": "TWI"},
  {"name": "TWYFORD","code": "TWY"},
  {"name": "TY CROES","code": "TYC"},
  {"name": "TY GLAS","code": "TGS"},
  {"name": "TYGWYN","code": "TYG"},
  {"name": "TYNDRUM LOWER","code": "TYL"},
  {"name": "TYSELEY","code": "TYS"},
  {"name": "TYWYN","code": "TYW"},
  {"name": "UCKFIELD","code": "UCK"},
  {"name": "UDDINGSTON","code": "UDD"},
  {"name": "ULCEBY","code": "ULC"},
  {"name": "ULLESKELF","code": "ULL"},
  {"name": "ULVERSTON","code": "ULV"},
  {"name": "UMBERLEIGH","code": "UMB"},
  {"name": "UNIVERSITY (BIRMINGHAM)","code": "UNI"},
  {"name": "UPHALL","code": "UHA"},
  {"name": "UPHOLLAND","code": "UPL"},
  {"name": "UPMINSTER","code": "UPM"},
  {"name": "UPPER HALLIFORD","code": "UPH"},
  {"name": "UPPER HOLLOWAY","code": "UHL"},
  {"name": "UPPER TYNDRUM","code": "UTY"},
  {"name": "UPPER WARLINGHAM","code": "UWL"},
  {"name": "UPTON (MERSEYSIDE)","code": "UPT"},
  {"name": "UPWEY","code": "UPW"},
  {"name": "URMSTON","code": "URM"},
  {"name": "UTTOXETER","code": "UTT"},
  {"name": "VALLEY","code": "VAL"},
  {"name": "VAUXHALL","code": "VXH"},
  {"name": "VIRGINIA WATER","code": "VIR"},
  {"name": "WADDON","code": "WDO"},
  {"name": "WADHURST","code": "WAD"},
  {"name": "WAINFLEET","code": "WFL"},
  {"name": "WAKEFIELD KIRKGATE","code": "WKK"},
  {"name": "WAKEFIELD WESTGATE","code": "WKF"},
  {"name": "WALKDEN","code": "WKD"},
  {"name": "WALLASEY GROVE ROAD","code": "WLG"},
  {"name": "WALLASEY VILLAGE","code": "WLV"},
  {"name": "WALLINGTON","code": "WLT"},
  {"name": "WALLYFORD","code": "WAF"},
  {"name": "WALMER","code": "WAM"},
  {"name": "WALSALL","code": "WSL"},
  {"name": "WALSDEN","code": "WDN"},
  {"name": "WALTHAM CROSS","code": "WLC"},
  {"name": "WALTHAMSTOW CENTRAL","code": "WHC"},
  {"name": "WALTHAMSTOW QUEEN'S ROAD","code": "WMW"},
  {"name": "WALTON (MERSEYSIDE)","code": "WAO"},
  {"name": "WALTON-ON-THAMES","code": "WAL"},
  {"name": "WALTON-ON-THE-NAZE","code": "WON"},
  {"name": "WANBOROUGH","code": "WAN"},
  {"name": "WANDSWORTH COMMON","code": "WSW"},
  {"name": "WANDSWORTH ROAD","code": "WWR"},
  {"name": "WANDSWORTH TOWN","code": "WNT"},
  {"name": "WANSTEAD PARK","code": "WNP"},
  {"name": "WAPPING","code": "WPE"},
  {"name": "WARBLINGTON","code": "WBL"},
  {"name": "WARE (HERTS)","code": "WAR"},
  {"name": "WAREHAM (DORSET)","code": "WRM"},
  {"name": "WARGRAVE","code": "WGV"},
  {"name": "WARMINSTER","code": "WMN"},
  {"name": "WARNHAM","code": "WNH"},
  {"name": "WARRINGTON BANK QUAY","code": "WBQ"},
  {"name": "WARRINGTON CENTRAL","code": "WAC"},
  {"name": "WARWICK","code": "WRW"},
  {"name": "WARWICK PARKWAY","code": "WRP"},
  {"name": "WATER ORTON","code": "WTO"},
  {"name": "WATERBEACH","code": "WBC"},
  {"name": "WATERINGBURY","code": "WTR"},
  {"name": "WATERLOO (MERSEYSIDE)","code": "WLO"},
  {"name": "WATFORD HIGH STREET","code": "WFH"},
  {"name": "WATFORD JUNCTION","code": "WFJ"},
  {"name": "WATFORD NORTH","code": "WFN"},
  {"name": "WATLINGTON","code": "WTG"},
  {"name": "WATTON-AT-STONE","code": "WAS"},
  {"name": "WAUN-GRON PARK","code": "WNG"},
  {"name": "WAVERTREE TECHNOLOGY PARK","code": "WAV"},
  {"name": "WEDGWOOD","code": "WED"},
  {"name": "WEELEY","code": "WEE"},
  {"name": "WEETON","code": "WET"},
  {"name": "WELHAM GREEN","code": "WMG"},
  {"name": "WELLING","code": "WLI"},
  {"name": "WELLINGBOROUGH","code": "WEL"},
  {"name": "WELLINGTON (SHROPSHIRE)","code": "WLN"},
  {"name": "WELSHPOOL","code": "WLP"},
  {"name": "WELWYN GARDEN CITY","code": "WGC"},
  {"name": "WELWYN NORTH","code": "WLW"},
  {"name": "WEM","code": "WEM"},
  {"name": "WEMBLEY CENTRAL","code": "WMB"},
  {"name": "WEMBLEY STADIUM","code": "WCX"},
  {"name": "WEMYSS BAY","code": "WMS"},
  {"name": "WENDOVER","code": "WND"},
  {"name": "WENNINGTON","code": "WNN"},
  {"name": "WEST ALLERTON","code": "WSA"},
  {"name": "WEST BROMPTON","code": "WBP"},
  {"name": "WEST BYFLEET","code": "WBY"},
  {"name": "WEST CALDER","code": "WCL"},
  {"name": "WEST CROYDON","code": "WCY"},
  {"name": "WEST DRAYTON","code": "WDT"},
  {"name": "WEST DULWICH","code": "WDU"},
  {"name": "WEST EALING","code": "WEA"},
  {"name": "WEST HAM","code": "WEH"},
  {"name": "WEST HAMPSTEAD","code": "WHD"},
  {"name": "WEST HAMPSTEAD THAMESLINK","code": "WHP"},
  {"name": "WEST HORNDON","code": "WHR"},
  {"name": "WEST KILBRIDE","code": "WKB"},
  {"name": "WEST KIRBY","code": "WKI"},
  {"name": "WEST MALLING","code": "WMA"},
  {"name": "WEST NORWOOD","code": "WNW"},
  {"name": "WEST RUISLIP","code": "WRU"},
  {"name": "WEST RUNTON","code": "WRN"},
  {"name": "WEST ST LEONARDS","code": "WLD"},
  {"name": "WEST SUTTON","code": "WSU"},
  {"name": "WEST WICKHAM","code": "WWI"},
  {"name": "WEST WORTHING","code": "WWO"},
  {"name": "WESTBURY (WILTS)","code": "WSB"},
  {"name": "WESTCLIFF","code": "WCF"},
  {"name": "WESTCOMBE PARK","code": "WCB"},
  {"name": "WESTENHANGER","code": "WHA"},
  {"name": "WESTER HAILES","code": "WTA"},
  {"name": "WESTERFIELD","code": "WFI"},
  {"name": "WESTERTON","code": "WES"},
  {"name": "WESTGATE-ON-SEA","code": "WGA"},
  {"name": "WESTHOUGHTON","code": "WHG"},
  {"name": "WESTON MILTON","code": "WNM"},
  {"name": "WESTON-SUPER-MARE","code": "WSM"},
  {"name": "WETHERAL","code": "WRL"},
  {"name": "WEYBRIDGE","code": "WYB"},
  {"name": "WEYMOUTH","code": "WEY"},
  {"name": "WHALEY BRIDGE","code": "WBR"},
  {"name": "WHALLEY (LANCS)","code": "WHE"},
  {"name": "WHATSTANDWELL","code": "WTS"},
  {"name": "WHIFFLET","code": "WFF"},
  {"name": "WHIMPLE","code": "WHM"},
  {"name": "WHINHILL","code": "WNL"},
  {"name": "WHISTON","code": "WHN"},
  {"name": "WHITBY","code": "WTB"},
  {"name": "WHITCHURCH (CARDIFF)","code": "WHT"},
  {"name": "WHITCHURCH (HANTS)","code": "WCH"},
  {"name": "WHITCHURCH (SHROPSHIRE)","code": "WTC"},
  {"name": "WHITE HART LANE","code": "WHL"},
  {"name": "WHITE NOTLEY","code": "WNY"},
  {"name": "WHITECHAPEL","code": "ZLW"},
  {"name": "WHITECRAIGS","code": "WCR"},
  {"name": "WHITEHAVEN","code": "WTH"},
  {"name": "WHITLAND","code": "WTL"},
  {"name": "WHITLEY BRIDGE","code": "WBD"},
  {"name": "WHITLOCKS END","code": "WTE"},
  {"name": "WHITSTABLE","code": "WHI"},
  {"name": "WHITTLESEA","code": "WLE"},
  {"name": "WHITTLESFORD PARKWAY","code": "WLF"},
  {"name": "WHITTON (LONDON)","code": "WTN"},
  {"name": "WHITWELL (DERBYSHIRE)","code": "WWL"},
  {"name": "WHYTELEAFE","code": "WHY"},
  {"name": "WHYTELEAFE SOUTH","code": "WHS"},
  {"name": "WICK","code": "WCK"},
  {"name": "WICKFORD","code": "WIC"},
  {"name": "WICKHAM MARKET","code": "WCM"},
  {"name": "WIDDRINGTON","code": "WDD"},
  {"name": "WIDNES","code": "WID"},
  {"name": "WIDNEY MANOR","code": "WMR"},
  {"name": "WIGAN NORTH WESTERN","code": "WGN"},
  {"name": "WIGAN WALLGATE","code": "WGW"},
  {"name": "WIGTON","code": "WGT"},
  {"name": "WILDMILL","code": "WMI"},
  {"name": "WILLESDEN JUNCTION","code": "WIJ"},
  {"name": "WILLIAMWOOD","code": "WLM"},
  {"name": "WILLINGTON","code": "WIL"},
  {"name": "WILMCOTE","code": "WMC"},
  {"name": "WILMSLOW","code": "WML"},
  {"name": "WILNECOTE (STAFFS)","code": "WNE"},
  {"name": "WIMBLEDON","code": "WIM"},
  {"name": "WIMBLEDON CHASE","code": "WBO"},
  {"name": "WINCHELSEA","code": "WSE"},
  {"name": "WINCHESTER","code": "WIN"},
  {"name": "WINCHFIELD","code": "WNF"},
  {"name": "WINCHMORE HILL","code": "WIH"},
  {"name": "WINDERMERE","code": "WDM"},
  {"name": "WINDSOR & ETON CENTRAL","code": "WNC"},
  {"name": "WINDSOR & ETON RIVERSIDE","code": "WNR"},
  {"name": "WINNERSH","code": "WNS"},
  {"name": "WINNERSH TRIANGLE","code": "WTI"},
  {"name": "WINSFORD","code": "WSF"},
  {"name": "WISHAW","code": "WSH"},
  {"name": "WITHAM","code": "WTM"},
  {"name": "WITLEY","code": "WTY"},
  {"name": "WITTON (WEST MIDLANDS)","code": "WTT"},
  {"name": "WIVELSFIELD","code": "WVF"},
  {"name": "WIVENHOE","code": "WIV"},
  {"name": "WOBURN SANDS","code": "WOB"},
  {"name": "WOKING","code": "WOK"},
  {"name": "WOKINGHAM","code": "WKM"},
  {"name": "WOLDINGHAM","code": "WOH"},
  {"name": "WOLVERHAMPTON","code": "WVH"},
  {"name": "WOLVERTON","code": "WOL"},
  {"name": "WOMBWELL","code": "WOM"},
  {"name": "WOOD END","code": "WDE"},
  {"name": "WOOD STREET","code": "WST"},
  {"name": "WOODBRIDGE","code": "WDB"},
  {"name": "WOODGRANGE PARK","code": "WGR"},
  {"name": "WOODHALL","code": "WDL"},
  {"name": "WOODHOUSE","code": "WDH"},
  {"name": "WOODLESFORD","code": "WDS"},
  {"name": "WOODLEY","code": "WLY"},
  {"name": "WOODMANSTERNE","code": "WME"},
  {"name": "WOODSMOOR","code": "WSR"},
  {"name": "WOOL","code": "WOO"},
  {"name": "WOOLSTON","code": "WLS"},
  {"name": "WOOLWICH ARSENAL","code": "WWA"},
  {"name": "WOOLWICH DOCKYARD","code": "WWD"},
  {"name": "WOOTTON WAWEN","code": "WWW"},
  {"name": "WORCESTER FOREGATE STREET","code": "WOF"},
  {"name": "WORCESTER PARK","code": "WCP"},
  {"name": "WORCESTER SHRUB HILL","code": "WOS"},
  {"name": "WORKINGTON","code": "WKG"},
  {"name": "WORKSOP","code": "WRK"},
  {"name": "WORLE","code": "WOR"},
  {"name": "WORPLESDON","code": "WPL"},
  {"name": "WORSTEAD","code": "WRT"},
  {"name": "WORTHING","code": "WRH"},
  {"name": "WRABNESS","code": "WRB"},
  {"name": "WRAYSBURY","code": "WRY"},
  {"name": "WRENBURY","code": "WRE"},
  {"name": "WRESSLE","code": "WRS"},
  {"name": "WREXHAM CENTRAL","code": "WXC"},
  {"name": "WREXHAM GENERAL","code": "WRX"},
  {"name": "WYE","code": "WYE"},
  {"name": "WYLAM","code": "WYM"},
  {"name": "WYLDE GREEN","code": "WYL"},
  {"name": "WYMONDHAM","code": "WMD"},
  {"name": "WYTHALL","code": "WYT"},
  {"name": "YALDING","code": "YAL"},
  {"name": "YARDLEY WOOD","code": "YRD"},
  {"name": "YARM","code": "YRM"},
  {"name": "YATE","code": "YAE"},
  {"name": "YATTON","code": "YAT"},
  {"name": "YEOFORD","code": "YEO"},
  {"name": "YEOVIL JUNCTION","code": "YVJ"},
  {"name": "YEOVIL PEN MILL","code": "YVP"},
  {"name": "YETMINSTER","code": "YET"},
  {"name": "YNYSWEN","code": "YNW"},
  {"name": "YOKER","code": "YOK"},
  {"name": "YORK","code": "YRK"},
  {"name": "YORTON","code": "YRT"},
  {"name": "YSTRAD MYNACH","code": "YSM"},
  {"name": "YSTRAD RHONDDA","code": "YSR" }
];

class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      str: '', suggestions: []
    }
    this.change = this.change.bind(this);
    this.keyDown = this.keyDown.bind(this);
  }
  change(e) {
    let { value } = e.target;
    value = value.toUpperCase();
    let suggestions = [];
    if (value.length > 0) {
      suggestions = stations.filter(item => item.name.indexOf(value) == 0).map(item => item.name).concat(stations.filter(item => item.name.indexOf(value) > 0).map(item => item.name));
      let code = stations.find(item => item.name == value);
      this.props.callback(code?code:false);
    } else this.props.callback(false);
    this.setState({ str: value, suggestions });
  }
  keyDown() {
    
  }
  render() {
    return <div>
      {this.props.label} 
      <input
        value={this.state.str}
        onChange={this.change}
        onKeyDown={this.keyDown}
        type="text"
        style={{marginLeft:8}}
      />
      <div style={{border:'1px solid white', marginTop:8, height:300, overflowY:'scroll' }}>
        {this.state.suggestions.map((item,i) => <p key={i} style={{ margin: 0 }}>{item==this.state.str?item:<a onClick={() => this.setState({ str: item }, () => this.change({ target: { value: item } }))} style={{ fontStyle: 'italic' }}>{item}</a>}</p>)}
      </div>
    </div>
  }
}


function FuckOff({ }) {
  let navigate = useNavigate();
  useEffect(() => {
    navigate('/');
  }, [navigate]);
  return;
}

let that;

class Secret extends Component {
  constructor(props) {
    super(props);
    that = this;
    let d = new Date();
    let y = d.getFullYear()+'';
    let m = d.getMonth()+ 1;
    m = m + '';
    let da = d.getDate()+'';
    while (m.length < 2) m = '0' + m;
    while (da.length < 2) da = '0' + da;
      d = y + '-' + m + '-' + da;
      let basket = JSON.parse(localStorage.getItem('b'));
      if (!basket) basket = [];
      let allowed = secretItems(basket) ;
    this.state = {
      a: true, b: false, d, s1: false, s2: false, g: false, r: false, n: false, allowed:allowed?true:false, download:false
    }
    // document.body.style.backgroundImage = "url(http://static2.grsites.com/archive/textures/red/red007.jpg)";
    this.change = this.change.bind(this);
    this.generate = this.generate.bind(this);
    this.sketch = this.sketch.bind(this);
  }

  change(e) {
    let { value } = e.target;
    this.setState({ d: value });
  }

  componentDidMount() {
    let { allowed } = this.state;
    if (allowed) {
      document.body.style.backgroundColor = "red";
      localStorage.setItem('b', '[]');
    }
  }

    componentWillUnmount() {
        document.body.style.backgroundColor = "rgb(234,61,128)";
    }

  async generate() {
      this.setState({ g: true });
  }

  sketch(p) {
      let w = 682, h = 2050 - 180;
      let noise = that.state.n;
      let can;

      let s = 1;

      function rect(x, y, w, h) {
            p.rect(x*s, y*s,w*s,h*s)
      }
      
      function strokeWeight(x) {
          p.strokeWeight(x * s);
      }

      function ellipse(x, y, w, h) {
          if (h === undefined) h = w;
          p.ellipse(x * s, y * s, w * s, h * s);
      }

      function line(x0, y0, x1, y1) {
          p.line(x0 * s, y0 * s, x1 * s, y1 * s);
      }

      function quad(x0, y0, x1, y1, x2, y2, x3, y3) {
          p.quad(x0 * s, y0 * s, x1 * s, y1 * s, x2 * s, y2 * s, x3 * s, y3 * s);
      }

      function textSize(x) {
          p.textSize(x * s);
      }

      function text(t, x, y,x1,y1) {
          p.text(t, x * s, y * s,x1?x1*s:x1,y1?y1*s:y1);
      }

      function arc(x, y, w, h, r0, r1) {
          p.arc(x * s, y * s, w * s, h * s, r0, r1);
      }

      function set() {
        p.textFont("font");
          
        can = p.createCanvas(w, h);
        p.loadPixels();
        let l0 = p.pixels.length / 4;
        let l1 = w * h;
        s = l1 / l0;
          s = Math.sqrt(s);
          if (s != 1) p.resizeCanvas(w*s, h*s);
      }

      function drawit() {
          p.fill(255);
          p.noStroke();
          rect(0, 0, w, h);

          strokeWeight(4);
          p.stroke(197);
          rect(2, 2, w - 4, h - 4);

          p.noStroke();
          rect(0, h - 48, 48, 48);
          rect(w - 48, h - 48, 48, 48);

          p.fill(4, 60, 51);
          ellipse(48 + 2, h - 48 - 2, 48 * 2);
          ellipse(w - 48 - 2, h - 48 - 2, 48 * 2);

          p.stroke(197);
          ellipse(48 + 2, h - 48 - 2, 48 * 2);
          ellipse(w - 48 - 2, h - 48 - 2, 48 * 2);
      
          p.noStroke();
          p.fill(31, 191, 169);
          rect(4, 1857 - 180, w - 8, h - (1857 - 180) - 60);
          p.fill(4, 60, 51);
          rect(4, 1875 - 180, w - 8, h - (1875 - 180) - 49);
          rect(49, 1900 - 180, w - 49 * 2, h - (1900 - 180) - 4);

          p.fill(255, 247, 242);
          p.stroke(197);
          rect(2, 2, w - 4, 921 - 2);

          p.fill(250);
          rect(2, 921 - 2, w - 4, 1486 - 2 - (921 - 2) + 3 - 30);

          strokeWeight(6);
          line(0, 921, w, 921);
          strokeWeight(4);
      
          p.fill(255);
          p.stroke(248, 202, 185);
          rect((w - 384) / 2, 37, 384, 390);

          p.noStroke(0);
          p.fill(0);

          let grid = [];
          for (let i = 0; i < 45; i++) {
              let tmp = [];
              for (let i = 0; i < 45; i++) {
                  tmp.push(Math.random() > 0.5 ? 1 : 0);
              }
              grid.push(tmp);
          }

          for (let i = 16; i <= 28; i++) {
              for (let j = 16; j <= 28; j++) {
                  grid[i][j] = 1;
              }
          }
          for (let i = 17; i <= 27; i++) {
              for (let j = 17; j <= 27; j++) {
                  grid[i][j] = 0;
              }
          }
          for (let i = 18; i <= 26; i++) {
              for (let j = 18; j <= 26; j++) {
                  grid[i][j] = 1;
              }
          }
          for (let i = 19; i <= 25; i++) {
              for (let j = 19; j <= 25; j++) {
                  grid[i][j] = 0;
              }
          }
          for (let i = 20; i <= 24; i++) {
              for (let j = 20; j <= 24; j++) {
                  grid[i][j] = 1;
              }
          }
          for (let i = 21; i <= 23; i++) {
              for (let j = 21; j <= 23; j++) {
                  grid[i][j] = 0;
              }
          }
          grid[22][22] = 1;

          let sx = Math.floor((w - (45 * 7)) / 2);
          let sy = 54;

          for (let i = 0; i < 45; i++) {
              for (let j = 0; j < 45; j++) {
                  let x = i * 7 + sx;
                  let y = j * 7 + sy;
                  if (grid[i][j] > 0) {
                      rect(x, y, 7, 7);
                  }
              }
          }

          let str = 'TTF';
          let alph = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
          for (let i = 0; i < 8; i++) {
              str += alph[Math.floor(Math.random() * alph.length)];
          }

          let tn = str;

          rect(51, 468, 60, 8);
          rect(51, 481, 60, 8);

          quad(65, 460, 77, 460, 93, 468, 81, 468);
          quad(67, 481, 80, 481, 91, 476, 78, 476);
          quad(70, 489, 82, 489, 98, 497, 86, 497);

          // p.textFont('Public Sans')
          p.stroke(0);
          strokeWeight(0.5);

          p.textAlign(p.CENTER);
          textSize(35);
          p.textStyle(p.BOLD);
          text(str, w / 2, 414);

          let arr = that.state.d.split('-');
          str = arr[2];
          let str2 = str;
          if (arr[1] == '01') str += ' Jan '
          else if (arr[1] == '02') str += ' Feb '
          else if (arr[1] == '03') str += ' Mar '
          else if (arr[1] == '04') str += ' Apr '
          else if (arr[1] == '05') str += ' May '
          else if (arr[1] == '06') str += ' Jun '
          else if (arr[1] == '07') str += ' Jul '
          else if (arr[1] == '08') str += ' Aug '
          else if (arr[1] == '09') str += ' Sep '
          else if (arr[1] == '10') str += ' Oct '
          else if (arr[1] == '11') str += ' Nov '
          else if (arr[1] == '12') str += ' Dec '
          if (arr[1] == '01') str2 += ' January '
          else if (arr[1] == '02') str2 += ' Febuary '
          else if (arr[1] == '03') str2 += ' March '
          else if (arr[1] == '04') str2 += ' April '
          else if (arr[1] == '05') str2 += ' May '
          else if (arr[1] == '06') str2 += ' June '
          else if (arr[1] == '07') str2 += ' July '
          else if (arr[1] == '08') str2 += ' August '
          else if (arr[1] == '09') str2 += ' September '
          else if (arr[1] == '10') str2 += ' October '
          else if (arr[1] == '11') str2 += ' November '
          else if (arr[1] == '12') str2 += ' December '
          str += arr[0];
          str2 += arr[0];

          let { s1, s2 } = that.state;


          textSize(32);
          p.textAlign(p.LEFT);
          text(str, 170, 487);
          p.textAlign(p.RIGHT);
          text('Out: ' + s1.code + ' - ' + s2.code, 631, 487);

          p.noStroke();

      
          p.fill(110);
          textSize(21);
          p.textAlign(p.LEFT);
          text(s1.name, 51, 578);
          text('TICKET TYPE', 51, 734);
          text('ADULT', 51, 815);
          p.textAlign(p.RIGHT);
          text(s2.name, 631, 578);
          text('ROUTE', 631, 734);
          text('VALID UNTIL', 631, 815);

          strokeWeight(0.5);
          textSize(25);
          p.fill(0);
          p.textAlign(p.LEFT);
          text('Anytime '+(that.state.r?'Return':'Single'), 51, 734 + 30);
          text('-', 51, 815 + 30);
          text('Ticket Details:', 63, 992);
          p.textAlign(p.RIGHT);
          text('Any Permitted', 631, 734 + 30);
          text(str, 631, 815 + 30);
          p.noStroke();

          textSize(72);
          p.textAlign(p.LEFT);
          text(s1.code, 51, 645);
          p.textAlign(p.RIGHT);
          text(s2.code, 631, 645);

          quad(318, 588, 336, 588, 364, 615, 346, 615);
          quad(318, 642, 336, 642, 364, 615, 346, 615);
          rect(286, 608, 65, 14);
      
          textSize(25);
          p.fill(0);
          p.textAlign(p.LEFT);
          p.textStyle(p.NORMAL);
          let pr = '£';
          let ran = Math.floor(Math.random() * 30) + 20;
          let ran2 = Math.floor(Math.random() * 10);
          pr += ran + '.' + ran2 + '0';
          text(`Information relating to compensation in the event of disruption can be found here

This ticket has been issued subject to our website's Terms & Conditions and National Rail Conditions of Travel

For refund information contact the retailer of your ticket
    
Ticket Number ${tn}
Price ${pr}
Purchased on ${str2}`, 63, 1026, (w - 63 * 2), 1480);
          p.fill(0);
      
          p.textAlign(p.CENTER);
          text('Use this ticket on your phone or print it', w / 2, 1544 - 30)
          text(tn[0] + '   ' + tn[1] + '   ' + tn[2] + '   ' + tn[3] + '   ' + tn[4] + '   ' + tn[5] + '   ' + tn[6] + '   ' + tn[7] + '   ' + tn[8] + '   ' + tn[9] + '   ' + tn[10], w / 2, 1674 - 30)
      
          p.fill(80);
          rect(289, 1576 - 30, 32, 10);
          rect(277, 1598 - 30, 55, 16);
          rect(285, 1590 - 30, 39, 15);
          rect(362, 1574 - 30, 40, 52);
          rect(369, 1567 - 30, 26, 66);
          ellipse(369, 1574 - 30, 14);
          ellipse(369 + 26, 1574 - 30, 14);
          ellipse(369, 1574 + 52 - 30, 14);
          ellipse(369 + 26, 1574 + 52 - 30, 14);
          ellipse(285, 1598 - 30, 16);
          ellipse(285 + 39, 1598 - 30, 16);
      
          p.fill(255);
          ellipse(285 + 39, 1598 - 30, 5);
          ellipse(382, 1625 - 30, 8);
          rect(368, 1576 - 30, 27, 42);
      
          p.stroke(80);
          strokeWeight(5);
          rect(291, 1604 - 30, 28, 18);

          let x = 182;
          let y = 1735;

          p.noFill();
          p.stroke(255);
          strokeWeight(7);

          let pi = p.PI

          arc(x + 17.869, y + 17.468, 25.1, 25.1, pi * 0.75, pi * 1.7);
          arc(x + 48.272, y + 17.468, 25.1, 25.1, pi * 1.25, pi * 2.25);
          line(x + 8.993,y + 26.344,x + 24.043,y + 41.394);
          line(x + 24.043,y + 41.394,x + 48.033,y + 17.403);
          line(x + 39.396,y + 8.593,x + 24.375,y + 23.613);
          line(x + 24.375,y + 23.613,x + 18.182,y + 17.42);
          line(x + 32.912, y + 50.579, x + 57.147, y + 26.344);
          
          p.noStroke();
          p.fill(255);
          p.textAlign(p.LEFT);
          textSize(65);
          p.textStyle(p.BOLD);
          text("trainline", 269, 1783);

          p.textAlign(p.CENTER);
          textSize(25);
          p.textStyle(p.NORMAL);
          text("Issued by the Railway Club", w/2, 1823);
        
          p.loadPixels();
          let pts = [
              {x0:355,y0:1055,x1:411,y1:1081},
              {x0:168,y0:1148,x1:391,y1:1175},
              {x0:441,y0:1148,x1:587,y1:1175},
              {x0:59,y0:1179,x1:286,y1:1204},
          ]
          
          pts.forEach(({ x0, y0, x1, y1 }) => {
            for (let x = x0; x < x1; x++) {
                for (let y = y0; y < y1; y++) {
                    let i = (x + y * w) * 4;
                    let dark = (p.pixels[i]/250)*255;
                    dark = dark / 255;
                    let r = p.lerp(69, 250, dark);
                    let g = p.lerp(131, 250, dark);
                    let b = p.lerp(254, 250, dark);
                    p.pixels[i] = r;
                    p.pixels[i+1] = g;
                    p.pixels[i+2] = b;
                }
            }
          })



          p.updatePixels();
          
          if (noise) {
              let lines = [
                  {weight:20, min:65,max:355},
                  {weight:20, min:65,max:355},
                  {weight:10, min:20,max:455},
                  {weight:10, min:20,max:455},
                  {weight:5, min:20,max:455},
                  {weight:5, min:20,max:455},
                  {weight:5, min:20,max:455},
                  {weight:2, min:20,max:455},
                  {weight:2, min:20,max:455},
                  {weight:2, min:20,max:455},
                  {weight:1, min:20,max:455},
                  {weight:1, min:20,max:455},
                  {weight:1, min:20,max:455},
                  {weight:1, min:20,max:455},
                  {weight:1, min:20,max:455},
                  {weight:1, min:20,max:455},
                  {weight:1, min:20,max:455},
                  {weight:1, min:20,max:455},
                  {weight:1, min:20,max:455},
                  {weight:1, min:20,max:455},
                  {weight:1, min:20,max:455},
                  {weight:1, min:20,max:455},
                  
                  {weight:5, min:555,max:649},
                  {weight:2, min:555,max:649},
                  {weight:1, min:555,max:649},
                  {weight:2, min:490,max:735},
                  {weight:2, min:490,max:735},
                  {weight:2, min:490,max:735},
                  {weight:2, min:490,max:735},
                  {weight:2, min:490,max:735},
                  {weight:2, min:490,max:735},
                  {weight:2, min:490,max:735},
                  {weight:2, min:490,max:735},
                  {weight:2, min:490,max:735},
                  {weight:1, min:490,max:735},
                  {weight:1, min:490,max:735},
                  {weight:1, min:490,max:735},
                  {weight:1, min:490,max:735},
                  {weight:1, min:490,max:735},
                  {weight:1, min:490,max:735},
                  { weight: 1, min: 490, max: 735 },
                  
                  {weight:20, min:859,max:1859},
                  {weight:20, min:859,max:1859},
                  {weight:20, min:859,max:1859},
                  {weight:20, min:859,max:1859},
                  {weight:10, min:859,max:1859},
                  {weight:10, min:859,max:1859},
                  {weight:10, min:859,max:1859},
                  {weight:10, min:859,max:1859},
                  {weight:10, min:859,max:1859},
                  {weight:10, min:859,max:1859},
                  {weight:10, min:859,max:1859},
                  {weight:5, min:859,max:1859},
                  {weight:5, min:859,max:1859},
                  {weight:5, min:859,max:1859},
                  {weight:5, min:859,max:1859},
                  {weight:5, min:859,max:1859},
                  {weight:5, min:859,max:1859},
                  {weight:5, min:859,max:1859},
                  {weight:3, min:859,max:1859},
                  {weight:3, min:859,max:1859},
                  {weight:3, min:859,max:1859},
                  {weight:3, min:859,max:1859},
                  {weight:3, min:859,max:1859},
                  {weight:3, min:859,max:1859},
                  {weight:3, min:859,max:1859},
                  {weight:2, min:859,max:1859},
                  {weight:2, min:859,max:1859},
                  {weight:2, min:859,max:1859},
                  {weight:2, min:859,max:1859},
                  {weight:2, min:859,max:1859},
                  {weight:2, min:859,max:1859},
                  {weight:2, min:859,max:1859},
                  {weight:2, min:859,max:1859},
                  {weight:2, min:859,max:1859},
                  {weight:2, min:859,max:1859},
                  {weight:2, min:859,max:1859},
                  {weight:2, min:859,max:1859},
                  {weight:2, min:859,max:1859},
                  {weight:1, min:859,max:1859},
                  {weight:1, min:859,max:1859},
                  {weight:1, min:859,max:1859},
                  {weight:1, min:859,max:1859},
                  {weight:1, min:859,max:1859},
                  {weight:1, min:859,max:1859},
                  {weight:1, min:859,max:1859},
                  {weight:1, min:859,max:1859},
                  {weight:1, min:859,max:1859},
                  {weight:1, min:859,max:1859},
                  {weight:1, min:859,max:1859},
                  {weight:1, min:859,max:1859},
                  {weight:1, min:859,max:1859},
                  {weight:1, min:859,max:1859},
                  {weight:1, min:859,max:1859},
                  {weight:1, min:859,max:1859},
                  {weight:1, min:859,max:1859},
                  {weight:1, min:859,max:1859},
                  {weight:1, min:859,max:1859},
                  {weight:1, min:859,max:1859},
                  {weight:1, min:859,max:1859},
                  {weight:1, min:859,max:1859},
                  {weight:1, min:859,max:1859},
                  {weight:1, min:859,max:1859},
                  {weight:1, min:859,max:1859},
              ]
              lines.forEach(({ weight, min, max }) => {
                let y = p.random(min, max);
                p.stroke(255);
                strokeWeight(weight);
                line(0, y, w, y);
              })

          }

      document.getElementById("defaultCanvas0").style.width = w/3+'px';
      document.getElementById("defaultCanvas0").style.height = h/3+'px';
    
      
      }

      p.setup = () => {
          set();
          drawit();
      }

      let download = that.state.download;
  
      p.draw = function () {
          if (noise != that.state.n) {
              noise = that.state.n;
              drawit();
          }
          if (download != that.state.download) {
              download = that.state.download;
              p.saveCanvas(can, that.state.s1.code +'-'+that.state.s2.code+' '+that.state.d, 'jpg');
          }
    }
  }

  render() {
      let mw = 32;
      let { allowed } = this.state;
      if (allowed) {

    return <div className="back train">
      <div style={{textAlign:'center',margin:20}}>
        <img src={ad} alt="ad" style={{cursor:'pointer'}}/>
      </div>
      <div style={{
        width: '800px',
        margin: '0 auto',
        border: '2px solid black',
        padding:'2px'
      }}>
        <div style={{
          width: '734px',
          backgroundColor: 'black',
          border: '1px solid black',
          borderTop:'none',
          paddingBottom: '8px',
          paddingRight: '32px',
          paddingLeft:'32px'
        }}>
          <div style={{textAlign:'center',paddingTop:22}}><img src={fire} alt="logo"/></div>

          {this.state.b ? this.state.g ? <div>

            <div style={{textAlign:'center'}}>
              <ReactP5Wrapper sketch={this.sketch}/>
            </div>
            <input type="checkbox" id="Noise" name="noise" checked={this.state.n} onChange={ e=>this.setState({n:e.target.checked})}/>
                        <label for="Noise" >Add noise</label>
<br/>
            <p className="p">Here is your ticket. Download it to your phone, and remember to not use it at the train station, because it won't work anyway.</p>
                    <a onClick={() => { this.setState({ download: !this.state.download }) }} style={{marginRight:'1rem'}}>Download ticket</a>
            <a onClick={()=>{this.setState({s1:false,s2:false,g:false})}}>Generate another</a>
            <div className="thicc"/>
          </div>:<div>
            
            <div style={{ width: 350, marginRight:20, float: 'left' }}>
              <Dropdown label="From:" callback={s=>this.setState({s1:s})}/>
            </div>
            <div style={{ width: 350, float: 'left' }}>
            <Dropdown label="To:" callback={s=>this.setState({s2:s})}/>
            </div>
            <div style={{ clear: 'both' }}></div>
                        <br />
                        <input type="checkbox" id="Return" name="ticket" checked={this.state.r} onChange={ e=>this.setState({r:e.target.checked})}/>
                        <label for="Return" >Return</label>
<br/><br/>
            Date:
            <input type="date" style={{ marginLeft:8 }} onChange={this.change} value={this.state.d}></input>

            <div style={{ textAlign: 'center', position:'relative' }}>
              <img src={pent} style={{ marginTop: -30, zIndex: -1 }} />
                <div style={{ position: 'absolute', left: '0px', top: '0px', width: '100%', display: this.state.s1 && this.state.s2 ? null : 'none' }}><a onClick={this.generate}style={{ fontSize: 'xx-large' }}>GENERATE</a></div>
            </div>
                </div> : <div>
                        <h1>YOU HAVE ENTERED THE INNER CIRCLE</h1>
                        <p className="p">This page is exclusively for members of the Railway Club. If you are not an official member, please leave immediately.</p>
        <h2>Rules of Railway Club:</h2>
        
          <ol>
            <li>No snitches, pagans, or prudes.</li>
            <li>Invite your friends (unless they're a snitch, pagan, or prude.)</li>
            <li>DO NOT SHARE ON SOCIAL MEDIA!!!</li>
          </ol>

        <p className="p">Members of the inner circle have access to our exclusive members' package, which includes the use of our <b style={{color:'yellow'}}>PROP</b> train ticket generator!</p>
        <p className="p">DISCLAIMER: These <b style={{color:'yellow'}}>PROP</b> train tickets are for use as <b style={{color:'yellow'}}>PROPS</b> only. Don't try to get on a train with them, that would be IMMORAL.</p>
                        <a style={{ fontSize: 'x-large' }} onClick={() => this.setState({ b: true })}>TAKE ME TO THE TRAIN TICKET GENERATOR</a>
                        <div className="thicc"/>
          </div>
          }
        </div>
      </div>
      <div style={{textAlign:'center',margin:20}}>
        <img src={ad2} alt="ad" style={{cursor:'pointer'}}/>
      </div>
    </div>
      } else {
          return <FuckOff/>
      }
    }
}

export default Secret;
