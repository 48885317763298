import React, { useContext, useEffect, useState } from 'react';
import { AdminContext, p } from '../../hooks';
import axios from 'axios';
import { useParams } from 'react-router';

export default function IpTrack({  }) {
    let { admin, setBackgroundColour } = useContext(AdminContext);
    let params = useParams();
    let path = params.path;
    let [visitors, setVisitors] = useState([]);

    async function giga() {
        if (admin) {
            let { data } = await axios.post('/api/chad', { path, p: p() });
            setVisitors(data);
        } else { 
            await axios.post('/api/giga',{path});
        }
    }

    useEffect(() => {
        setBackgroundColour("Admin");
        giga();
        return () => setBackgroundColour(false);
    }, [admin])

    if (admin) {
        return <div className="FileServer">
            <h1>Who's been visiting?</h1>
            <h2>{path ? path : "All paths"}</h2>
            <div className="visitors">
                {visitors.map((v, i) => <div className="visitor" key={i}>
                    <div>{v.path}</div>
                    <div>{v.ip}</div>
                    <div>{new Date(v.stamp).toDateString()}</div>
                </div>)}
            </div>
        </div>
    }

    return <div className="FileServer">
        <h1>404</h1>
        <h2>Not Found</h2>
        <p>The resource requested could not be found on this server.</p>
    </div>
}