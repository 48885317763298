import React, { Component } from 'react';

import Thumbnails from './Thumbnails';

class Items extends Component {
    constructor(props) {
        super(props);
        let { items, k } = props;
        items.forEach(item => {
            item.path = item.images[0].path;
            item.bigText = '£' + item.price.toFixed(2);
            item.link = '/item/' + item.title.replace(/ /g, '_');
        })
        this.state = {
            items, k
        }
    }
    render() {
        let { items, k } = this.state;
        return <Thumbnails items={items} k={k} />
    }
}

export default Items;