import React, { useReducer, useState } from 'react';
import { injectStripe, CardNumberElement, CardCVCElement, CardExpiryElement } from 'react-stripe-elements';
import { useNavigate } from "react-router-dom";
import axios from 'axios';

import Dropdown from './Dropdown';
import Checkbox from './Checkbox';
import { useChange, useMount } from '../../hooks';

let nearestPence = x => Math.round(x * 100) / 100;

function CheckoutForm({ fromBasket, back, history, stripe, disappear, charge, invoiceId }) {
    let [force, forceRender] = useReducer(f => f + 1, 0);
    let { componentDidMount } = useMount();
    let [needAddress, setNeedAddress] = useState();
    let [needPhone, setNeedPhone] = useState();
    let [postageOptions, setPostageOptions] = useState();
    let [needPostage, setNeedPostage] = useState();
    let [loading, setLoading] = useState(true);
    let [progress, setProgress] = useState(1);

    let navigate = useNavigate();

    let [Firstname, setFirstname] = useState('');
    let [Lastname, setLastname] = useState('');
    let [PhoneNumber, setPhoneNumber] = useState('');
    let [Email2, setEmail2] = useState('');
    let [Address2, setAddress2] = useState('');
    let [City2, setCity2] = useState('');
    let [Zip2, setZip2] = useState('');
    let [Country2, setCountry2] = useState('');
    let [message, setMessage] = useState('');
    let [code, setCode] = useState('');
    let [voucher, setVoucher] = useState();

    let [FirstnameB, setFirstnameB] = useState('');
    let [LastnameB, setLastnameB] = useState('');
    let [Address2B, setAddress2B] = useState('');
    let [City2B, setCity2B] = useState('');
    let [Zip2B, setZip2B] = useState('');
    let [Country2B, setCountry2B] = useState('');
    let [sameAsPostage, setSameAsPostage] = useState(true);
    let [discount, setdiscount] = useState(0);

    let [errors, setErrors] = useState({});
    let [postage, setPostage] = useState();
    let [showErrors, setShowErrors] = useState([false, false, false, false]);
    let [basket, setBasket] = useState([]);
    let [localBasket, setLocalBasket] = useState([]);
    let [total, setTotal] = useState(0);

    let [complete, setComplete] = useState(false);
    let [changed, setChanged] = useState(false);
    let [finished, setFinished] = useState(false);
    let [error, setError] = useState(false);
    let [CardNumber, setCardNumber] = useState();
    let [CardExpiry, setCardExpiry] = useState();
    let [CardCVC, setCardCVC] = useState();
    let [orderID, setOrderID] = useState();
    let [success, setSuccess] = useState(false);



    componentDidMount(async () => {
        if (!invoiceId) {
            let {basket} = await updateBasket();
            let postage = Math.max(...basket.map(item => item.postage)) / 2;
            postage += basket.reduce((a, b) => a + b.postage, 0) / 2;
            let postageOptions = postage > 0 ? [
                { title: 'UK', cost: nearestPence(postage) },
                { title: 'Europe', cost: nearestPence(postage * 1.9) },
                { title: 'Other', cost: nearestPence(postage * 2.5) },
            ] : null;
            let needAddress = basket.reduce((a, b) => a || b.needAddress, false);
            let needPhone = basket.reduce((a, b) => a || b.needPhone, false);
            let needPostage = postage > 0;
            setNeedAddress(needAddress);
            setNeedPhone(needPhone);
            setPostageOptions(postageOptions);
            setNeedPostage(needPostage);
        }
        setLoading(false);
    })

    function calculateTotal() {
        if (invoiceId) {
            setTotal(charge);
            setdiscount(0);
        } else {
            let total = basket.reduce((a, b) => a + b.calculatedPrice, 0);
            let discount = voucher ? total * voucher.discount : 0;
            discount = nearestPence(discount);
            total = nearestPence(total);
            total -= discount;
            if (needPostage) total += postage.cost;
            setTotal(nearestPence(total))
            setdiscount(discount)
        }
    }

    function selectPostage(postage) {
        setPostage(postage);
    }

    useChange(() => {
        calculateTotal();
        checkErrors1();
    },[postage])

    async function updateBasket() {
        let basket = JSON.parse(sessionStorage.getItem('b'));
        if (!basket) basket = [];
        let country = sessionStorage.getItem('country');
        if (!country) {
            let { data } = await axios.get('/api/country');
            country = data;
            sessionStorage.setItem('country', country);
        }
        let { data } = await axios.post('/api/basket', { basket, country });
        let changed = data.changed;
        data = data.basket;
        for (let i = data.length - 1; i >= 0; i--) {
            if (data[i].print) {
                basket[i].print = true;
                basket[i].number = data[i].number;
            }
            if (data[i].deleted) {
                data.splice(i, 1);
                basket.splice(i, 1);
            }
        }
        basket.forEach((item, i) => {
            item.variations = data[i].variations;
        })
        sessionStorage.setItem('b', JSON.stringify(basket));
        setBasket(data);
        setLocalBasket(basket);
        setChanged(changed);
        return { basket, localBasket };
    }

    async function handleSubmit(e) {
        e.preventDefault();
        setLoading(true)
        let payload = await stripe.createToken({
            name: FirstnameB + (LastnameB ? ' ' + LastnameB : ''),
            address_city: City2B,
            address_country: Country2B,
            address_line1: Address2B,
            address_zip: Zip2B
        });
        if (!payload.error) {
            let customer = {
                name: Firstname+(Lastname?' '+Lastname:''),
                email: Email2,
                phone: needPhone ? PhoneNumber : '',
                address: Address2 +'\r\n' + City2 + ' ' + Zip2 + '\r\n' + Country2,
                ip: payload.token.client_ip
            }
            let country = sessionStorage.getItem('country');
            if (!country) {
                let { data } = await axios.get('/api/country');
                country = data;
                sessionStorage.setItem('country', country);
            }
            let { data } = invoiceId ? await axios.post('/api/pay', { id:invoiceId, customer, country, charge }) : await axios.post('/api/buy', { basket, customer, country, price: total, needPostage, postage:needPostage ? postage : null, message, voucher });
            let { error, success, id, key } = data;
            
            if (success) {
                const result = await stripe.confirmCardPayment(key, {
                    payment_method: {
                        card: {token: payload.token.id},
                        billing_details: {
                        name: FirstnameB + (LastnameB ? ' ' + LastnameB : '')
                        },
                    }
                });
                if (result.error) {
                    error = result.error.message;
                } else {
                    if (fromBasket) localStorage.removeItem('b');
                }
            }
            setComplete(true);
            setError(error);
            setSuccess(success);
            if (id) setOrderID(id);
            setLoading(false);
        } else {
            let errors = { card: payload.error.message };
            showErrors[progress] = true;
            setErrors(errors);
            setShowErrors(showErrors);
            forceRender();
        }
        setLoading(false);
    };

    function unError() {
        showErrors[progress] = false;
        setShowErrors(showErrors);
        forceRender();
    }

    useChange(checkErrors1,[Firstname,Lastname,PhoneNumber,Email2,Address2,City2,Zip2,Country2,message,code])
    useChange(checkErrors2,[FirstnameB,LastnameB,Address2B,City2B,Zip2B,Country2B])

    function checkErrors1() {
        let errors = { }
        if (Firstname.length == 0) errors.name = 'Please give your name!';
        if (needPhone && PhoneNumber.length == 0) errors.phone = 'Please give your phone number!';
        if (Email2.length == 0) {
            errors.email = 'Please give your email address!';
        } else {
            let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(Email2)) errors.email = 'This email address is invalid!';
        }
        if (Address2.length == 0 ||
            City2.length == 0 ||
            Zip2.length == 0 ||
            Country2.length == 0 ) errors.address = 'Please provide an address!';
        if (!postage && needPostage) errors.postage = 'Please select a postage option!';
        setErrors(errors);
        forceRender();
        return errors;
    }

    function checkErrors2() {
        let errors = { }
        if (FirstnameB.length == 0) errors.nameB = 'Please give your name!';
        if (Address2B.length == 0 ||
            City2B.length == 0 ||
            Zip2B.length == 0 ||
            Country2B.length == 0) errors.addressB = 'Please provide an address!';
        setErrors(errors);
        forceRender();
        return (errors);
    }

    async function next() {
        if (progress == 1) {
            let errors = checkErrors1();
            let error = errors.name || errors.email || errors.address || errors.postage;

            if (error) {
                showErrors[progress] = true;
                setShowErrors(showErrors);
                forceRender();
            } else {
                if (needAddress) {
                    setProgress(2);
                } else {
                    setFirstnameB(Firstname);
                    setLastnameB(Lastname);
                    setAddress2B(Address2);
                    setCity2B(City2);
                    setZip2B(Zip2);
                    setCountry2B(Country2);
                    setProgress(invoiceId ? 5 : 3);
                }
            }
        } else if (progress == 2) {
            if (sameAsPostage) {
                setFirstnameB(Firstname);
                setLastnameB(Lastname);
                setAddress2B(Address2);
                setCity2B(City2);
                setZip2B(Zip2);
                setCountry2B(Country2);
                setProgress(3);
            } else {
                let errors = checkErrors2();
                let error = errors.nameB || errors.addressB;
    
                if (error) {
                    showErrors[progress] = true;
                    setShowErrors(showErrors);
                    forceRender();
                } else {
                    setProgress(3);
                }
            }
        } else if (progress == 3) {
            let errors = {};
            if (code.length > 0) {
                setLoading(true);
                let { data } = await axios.post('/api/validatevoucher/', { code });
                if (data.error) {
                    showErrors[progress] = true;
                    errors.code = data.error;
                    setShowErrors(showErrors);
                    setErrors(errors);
                    setLoading(false);
                    setVoucher(null);
                    setdiscount(0);
                } else {
                    setVoucher(data);
                    setLoading(false);
                }
            } else {
                setVoucher(null);
            }
            if (!errors.code) {
                calculateTotal();
                setProgress(4);
            }
        } else{
            setProgress(progress + 1);
        }

    }

    function prev() {
        setProgress(progress == 5 && invoiceId ? 1 : progress == 3 && !needAddress ? 1 : progress - 1);
    }

    function errorClick() {
        disappear();
        navigate('/basket');
        window.location.reload();
    }

    
        return ( <div>{!changed?!complete?<div style={{display:!loading?null:'none'}}>
            <form onSubmit={handleSubmit}>



                <div style={{ display: progress != 1 ? 'none' : null }}>
                    {needAddress ? <h1>Postage details</h1> : <h1>Order details</h1>}

                    <label>Name</label>
                    <div className="flex">
                        <input
                            className="flex1"
                            type="text"
                            name="Firstname"
                            placeholder="First name"
                            value={Firstname}
                            onChange={e=>setFirstname(e.target.value)}
                        />
                        <input
                            className="flex2"
                            type="text"
                            name="Lastname"
                            placeholder="Last name"
                            value={Lastname}
                            onChange={e=>setLastname(e.target.value)}
                        />
                    </div>
                    {showErrors[progress] ? errors.name ? <div className="required">{errors.name}</div> : null : null}
                    <br />

                    <label>Email</label>
                    <input
                        type="text"
                        name="Email2"
                        value={Email2}
                        onChange={e=>setEmail2(e.target.value)}
                    />
                    {showErrors[progress] ? errors.email ? <div className="required">{errors.email}</div> : null : null}
                    <br />

                    {needPhone ? <>
                        <label>Phone number</label>
                        <input
                            type="text"
                            name="PhoneNumber"
                            value={PhoneNumber}
                            onChange={e=>setPhoneNumber(e.target.value)}
                        />
                        {showErrors[progress] ? errors.phone ? <div className="required">{errors.phone}</div> : null : null}
                        <br />
                    </>:null}

                    

                    {needPostage ? <>
                        <label>Postage</label>
                        {postageOptions?<Dropdown options={postageOptions} left={postageOptions.map(o=>o.title)} right={postageOptions.map(o=>'£'+o.cost.toFixed(2))} callback={selectPostage} k='suck my balls'/>:null}
                        {showErrors[progress] ? errors.postage ? <div className="required">{errors.postage}</div> : null : null}
                        <br />
                    </>
                    : null}

                    <label>Address</label>
                    <input
                        type="text"
                        name="Address2"
                        placeholder="Address"
                        value={Address2}
                        onChange={e=>setAddress2(e.target.value)}
                    />
                    <div className="flex">
                        <input
                            className="flex1"
                            type="text"
                            name="City2"
                            placeholder="Town"
                            value={City2}
                            onChange={e=>setCity2(e.target.value)}
                        />
                        <input
                            className="flex2"
                            type="text"
                            name="Zip2"
                            placeholder="Post code"
                            value={Zip2}
                            onChange={e=>setZip2(e.target.value)}
                        />
                    </div>
                    <input
                        type="text"
                        name="Country2"
                        placeholder = "Country"
                        value={Country2}
                        onChange={e=>setCountry2(e.target.value)}
                    />
                    {showErrors[progress] ? errors.address ? <div className="required">{errors.address}</div> : null : null}
                    <br />
                </div>
                



                <div style={{ display: progress != 2 ? 'none' : null }}>
                    <h1>Invoice address</h1>

                    <Checkbox label="Use postage address" initial={true} callback={sameAsPostage=>setSameAsPostage(sameAsPostage)}/>
                    <br/>
                    <label className={sameAsPostage?'disabled':null}>Name</label>
                    <div className="flex">
                        <input
                            className="flex1"
                            type="text"
                            name="Firstname"
                            placeholder="Birthname"
                            value={sameAsPostage?Firstname:FirstnameB}
                            onChange={e=>setFirstnameB(e.target.value)}
                            disabled={sameAsPostage}
                        />
                        <input
                            className="flex2"
                            type="text"
                            name="Lastname"
                            placeholder="Surname"
                            value={sameAsPostage?Lastname:LastnameB}
                            onChange={e=>setLastnameB(e.target.value)}
                            disabled={sameAsPostage}
                        />
                    </div>
                    {showErrors[progress] && !sameAsPostage ? errors.nameB ? <div className="required">{errors.nameB}</div> : null : null}
                    <br />
                    
                    <label className={sameAsPostage?'disabled':null}>Address</label>
                    <input
                        type="text"
                        name="Address2"
                        placeholder = "Address"
                        value={sameAsPostage?Address2:Address2B}
                        onChange={e=>setAddress2B(e.target.value)}
                        disabled={sameAsPostage}
                    />
                    <div className="flex">
                        <input
                            className="flex1"
                            type="text"
                            name="City2"
                            placeholder = "Town"
                            value={sameAsPostage?City2:City2B}
                            onChange={e=>setCity2B(e.target.value)}
                            disabled={sameAsPostage}
                        />
                        <input
                            className="flex2"
                            type="text"
                            name="Zip2"
                            placeholder = "Post code"
                            value={sameAsPostage?Zip2:Zip2B}
                            onChange={e=>setZip2B(e.target.value)}
                            disabled={sameAsPostage}
                        />
                    </div>
                    <input
                        type="text"
                        name="Country2"
                        placeholder = "Country"
                        value={sameAsPostage?Country2:Country2B}
                        onChange={e=>setCountry2B(e.target.value)}
                        disabled={sameAsPostage}
                    />
                    {showErrors[progress] && !sameAsPostage ? errors.addressB ? <div className="required">{errors.addressB}</div> : null : null}
                    <br />
                </div>



                <div style={{ display: progress != 3 ? 'none' : null }}>
                    <h1>Extra details</h1>
                    <label>Message for Kai (optional)</label>
                    <textarea name='message' onChange={e=>setMessage(e.target.value)} />
                    <br />
                    
                    <label>Discount code (optional)</label>
                    <input
                        type="text"
                        name="code"
                        value={code}
                        onChange={e=>setCode(e.target.value)}
                    />
                    {showErrors[progress] ? errors.code ? <div className="required">{errors.code}</div> : null : null}
                    <br />
                </div>



                <div style={{ display: progress != 4 ? 'none' : null }}>
                    <h1>Confirm details</h1>
                    {basket.map((item, i) => <div className="flex" key={item.title + ' ' + i}>
                        <div className="flex1">
                            <h4>{item.title + (item.quantity>1?' × ' + item.quantity:'')}</h4>
                            {item.variations.map((v,i)=><div className="variations" key={'variation '+v.title+' '+i}>
                                {v.title+': '+v.selected.title}
                            </div>)}
                        </div>
                        <h4>£{item.calculatedPrice.toFixed(2)}</h4>
                    </div>)}
                    <br />
                    {voucher?<div className="flex">
                        <h4 className="flex1">Discount</h4>
                        <h4>-£{discount.toFixed(2)}</h4>
                    </div> : null}
                    {needPostage ? <div className="flex">
                        <h4 className="flex1">Postage</h4>
                        <h4>£{postage ? postage.cost.toFixed(2) : ''}</h4>
                    </div> : null}
                    <div className="flex" style={{paddingTop:'0.25rem'}}>
                        <h3 className="flex1">Total</h3>
                        <h3>£{total.toFixed(2)}</h3>
                    </div>
                </div>




                <div style={{ display: progress != 5 ? 'none' : null }}>
                    <h1>Card details</h1>
                    <label>
                        Card number
                        <div className="box"><CardNumberElement onChange={unError} style={{ base: { fontSize: '18px' } }}/></div>
                    </label>
                    <br />
                    <div className="flex">
                        <label className="flex1">
                            Expiration date
                        </label>
                        <label className="flex2">
                            CVC
                        </label>
                    </div>
                    <div className="flex">
                            <div className="box flex1"><CardExpiryElement onChange={unError} style={{ base: { fontSize: '18px' } }}/></div>
                            <div className="box flex2"><CardCVCElement onChange={unError} style={{ base: { fontSize: '18px' } }}/></div>
                    </div>
                    {showErrors[progress] ? errors.card ? <div className="required">{errors.card}</div> : null : null}
                    <br/>
                </div>
                <button id="gogogo" style={{ display: 'none' }}>Confirm order</button>
                



            </form>
            <div >
                <button className={progress > 1 || back ? null : 'disabled'} onClick={progress > 1 ? prev : back} >Back</button>
                {progress < 5 ? <button className={progress < 5 ? null : 'disabled'} onClick={progress < 5 ? next : () => { }} >Next</button> :
                    <button className="green" onClick={()=>document.getElementById('gogogo').click()}>Pay now</button>}
            </div>
        </div> : <div style={{display:!loading?null:'none'}}>
            <h1>{error ? 'Error' : 'Success'}</h1>
                {error ? <div><div className="error" onClick={() => {
                    setProgress(1);
                    setError(false);
                    setSuccess(false);
                    setComplete(false);
                }}>
                <div>{error}</div>
                <div>You have not been charged. Click here to try again!</div>
            </div><br/><div>If this problem persists, please email me so I can investigate: <a href="mailto:kai@ambigr.am">kai@ambigr.am</a></div></div>:<div>
                {invoiceId? <> 
                    <div>Thank you!</div>
                    <br/>
                    <button onClick={back}>Back</button> 
                </>: <>
                    <div>Thank you for your order.</div>
                    <div>You will be sent an email (check your spam folder!)</div>
                    <br/>
                    <button className="green" onClick={()=>navigate('/receipt/'+orderID+'/')}>View receipt</button>  
                </>}
            </div>}
        </div> : <div style={{display:!loading?null:'none'}}>
            <h1>Error</h1>
                <div className="error" onClick={errorClick}>
                <div>The items in your basket have changed since you started your order.</div>
                <div>You have not been charged. Click here to review your basket!</div>
            </div>
            </div>}
            {loading?<div className="center"><div className="spinner"></div></div>:null}
        </div>);
    }


export default injectStripe(CheckoutForm);