import React, { Component } from 'react';
import axios from 'axios';

import { NavLink, useNavigate } from "react-router-dom";
import { secretItems } from '../../hooks';

export default function FunctionalWrapper(props) {
    let navigate = useNavigate();
    return <ModalBasket {...props} navigate={navigate} />
}

class ModalBasket extends Component {
    constructor(props) {
        super(props);
        this.state = {
            basket: [], localBasket:[], loading: true, opacity:0, bigWidth:null, useBig:true, smallWidth:500, noVariations:false
        }
        this.updateBasket = this.updateBasket.bind(this);
        this.resize = this.resize.bind(this);
        this.buy = this.buy.bind(this);
        this.getBigWidth = this.getBigWidth.bind(this);
    }

    async componentDidMount() {
        window.addEventListener('resize', this.resize, true);
        let basket = this.updateBasket();
        let country = sessionStorage.getItem('country');
        if (!country) {
            let { data } = await axios.get('/api/country');
            country = data;
            sessionStorage.setItem('country', country);
        }
        let { data } = await axios.post('/api/basket', { basket, country });
        data = data.basket;
        for (let i = data.length - 1; i >= 0; i--) {
            if (data[i].deleted) {
                data.splice(i, 1);
                basket.splice(i, 1);
            }
        }
        basket.forEach((item, i) => {
            item.variations = data[i].variations;
        })
        localStorage.setItem('b', JSON.stringify(basket));
        let noVariations = true;
        data.forEach(item => {
            if (item.variations.length > 0) noVariations = false;
        })
        this.setState({ basket: data, loading: false, noVariations, localBasket:basket },this.getBigWidth);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }

    resize() {
        let { bigWidth } = this.state;
        this.setState({ useBig: (window.innerWidth - bigWidth > 50), smallWidth:window.innerWidth });
    }

    getBigWidth() {
        let d = document.getElementById('bigBox');
        if (d) {
            let bigWidth = d.getBoundingClientRect().width;
            this.setState({ bigWidth }, this.resize);
        } else setTimeout(this.getBigWidth, 1);
    }

    updateBasket() {
        let basket = JSON.parse(localStorage.getItem('b'));
        if (!basket) basket = [];
        return (basket);
    }

    buy() {
        if (secretItems(this.state.localBasket)) {
            this.props.navigate('/railway-club');
        } else {
            sessionStorage.setItem('b', localStorage.getItem('b'));
            this.props.navigate('/buy');
        }
    }

    render() {
        let { basket, useBig, smallWidth, noVariations, bigWidth, loading } = this.state;
        let total = basket.reduce((a, b) => a + b.calculatedPrice, 0);
        return <div className="box" id="bigBox" style={{maxWidth:!useBig?500:null,width:!useBig?'calc('+smallWidth+'px - 3rem)':null,overflowY:bigWidth?'auto':'visible',overflowX:bigWidth?'hidden':'visible'}}>
                <div className="cross">
                    <button onClick={this.props.disappear}>×</button>
                </div>
                <h1>Basket</h1>
            {loading ? <div className="center"><div className="spinner"></div></div> : <div>
                    {useBig ? <table><tbody>
                        {basket.map((item, i) => <tr key={'item ' + i}>
                            <td className="first">
                                <NavLink onClick={this.props.disappear} to={'/item/'+item.title.replace(/ /g,'_')}>
                                    <div className="image" style={{ backgroundImage: 'url(/api/pictures/' + encodeURIComponent(item.path) + ')' }} />
                                </NavLink>
                            </td>
                            <td className="second" style={{maxWidth:noVariations?800:null,minWidth:noVariations?200:null}}>
                                <NavLink className="black" onClick={this.props.disappear} to={'/item/' + item.title.replace(/ /g, '_')}>
                                    <h4>{item.title + (item.quantity > 1 ? ' × ' + item.quantity : '')}</h4>
                                </NavLink>
                            </td>
                            {noVariations ? null : <td className="third">
                                {item.variations.map((v, i) => <div key={i}>{v.title + ': ' + v.selected.title}</div>)}
                            </td>}
                            <td className="fourth">
                                <h4>£{item.calculatedPrice.toFixed(2)}</h4>
                            </td>
                        </tr>)}
                        <tr>
                            <td className="first"></td>
                            <td className="second" style={{maxWidth:noVariations?800:null,minWidth:noVariations?200:null}}></td>
                            {noVariations ? null : <td className="third" style={{ textAlign: 'right' }}></td>}
                            <td className="fourth">
                                <h4>Total: £{total.toFixed(2)}</h4>
                            </td>
                        </tr>
                    </tbody></table> : <div>
                            {basket.map((item, i) => <div className="basketItem" key={'item ' + i} >
                                <div className="flex">
                                    <div className="first">
                                        <NavLink onClick={this.props.disappear} to={'/item/'+item.title.replace(/ /g,'_')}>
                                            <div
                                                className="image"
                                                style={{ backgroundImage: 'url(/api/pictures/' + encodeURIComponent(item.path) + ')' }}
                                            />
                                        </NavLink>
                                    </div>
                                    <div className="second">
                                        <div className="flex">
                                            <div className="title">
                                                <NavLink className="black" onClick={this.props.disappear} to={'/item/' + item.title.replace(/ /g, '_')}>
                                                    <h4>{item.title + (item.quantity > 1 ? ' × ' + item.quantity : '')}</h4>
                                                </NavLink>
                                            </div>
                                            <div className="price"><h5>£{item.calculatedPrice.toFixed(2)}</h5></div>
                                        </div>
                                        {item.variations.map((v, i) => <div className="variations" key={i}>{v.title + ': ' + v.selected.title}</div>)}
                                    </div>
                                </div>

                            </div>)}
                            <div className="price">
                                <h4>Total: £{total.toFixed(2)}</h4>
                            </div>
                        </div>}
                    <div className="center">
                        <button onClick={() => this.props.navigate('/basket')}>Edit basket</button>
                        <button onClick={this.buy} className="green">Buy now</button>
                    </div></div>}
            </div>
    }
}
