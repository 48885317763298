import React, { Component } from 'react';

import {ReactP5Wrapper, P5Instance} from 'react-p5-wrapper';


export default class AnimatedBackground extends Component {
    constructor(props) {
        super(props);
        this.state = {w: window.innerWidth, h: window.innerHeight}
        this.resize = this.resize.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.resize, true);
        this.resize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }

    resize() {
        this.setState({w:window.innerWidth,h:window.innerHeight})
    }

    sketch(p) {
        let w = window.innerWidth, h = window.innerHeight;
        let area = w * h;
        let minArea = 500 * 500;

        let bg1 = 234, bg2 = 61, bg3= 128;
        let fg1 = 244, fg2 = 179, fg3 = 33;
        
        let grid = 100;
        let grid2 = 66;

        let snakeLength = 5;
        let snakeWidth = 5;

        p.frameRate(20);

        let lookupSin = {};
        let lookupCos = {};

        let sin = a => {
            if (lookupSin[a]) return lookupSin[a];
            lookupSin[a] = Math.sin(a);
            return lookupSin[a];
        }

        let cos = a => {
            if (lookupCos[a]) return lookupCos[a];
            lookupCos[a] = Math.cos(a);
            return lookupCos[a];
        }

        let calculateSnake = (s, ww) => {
            if (!ww) ww = snakeWidth;
            let gr = ww == 1 ? grid2 : grid;
            let { start, end, px, py } = s;

            let ox;
            let oy;

            let rOffset;
            let flip;

            if (start == 0) {
                oy = py - gr/2;
                ox = end == 1 ? px + gr / 2 : px - gr / 2;
                rOffset = end == 1 ? 3 : 0;
                flip = end != 1;
            }
            if (start == 1) {
                ox =  px +gr/2;
                oy = end == 0 ? py - gr / 2 : py + gr / 2;
                rOffset = end == 2 ? 2 : 1;
                flip = end != 2;
            }
            if (start == 2) {
                oy = py + gr/2;
                ox = end == 1 ? px + gr / 2 : px - gr / 2;
                rOffset = end == 3 ? 1 : 2;
                flip = end != 3;
            }
            if (start == 3) {
                ox = px - gr/2;
                oy = end == 0 ? py - gr / 2 : py + gr / 2;
                rOffset = end == 0 ? 0 : 3;
                flip = end != 0;
            }

            let ps = [];

            for (let i = 0; i < ww; i++) {
                let d = gr * ((i + 1) / (ww + 1));
                let pss = []
                for (let i = 0; i <= 1; i += 0.1) {
                    let a = 3.1415/2 * (flip?1-(i + rOffset):i + rOffset);
                    let x = (sin(a) * d) + ox;
                    let y = (cos(a) * d) + oy;
                    pss.push([x, y]);
                }
                ps.push(pss);
            }

            s.ps = ps;
            return s;
        }

        let ax = [], ay = [];
        
        setInterval(() => {
            if (Math.random() < 0.5) {
                ax[2] = Math.random() * w;
                ay[2] = Math.random() < 0.5 ? -grid : h + grid;
            } else {
                ay[2] = Math.random() * h;
                ax[2] = Math.random() < 0.5 ? -grid : w + grid;
            }
        },400)

        let createSnake = (i, ww) => {
            if (!ww) ww = snakeWidth;
            let gr = ww == 1 ? grid2 : grid;
            let d = Math.max(w, h) * 2;
            let r = Math.floor(Math.random() * 4);
            let start = r;
            let end = Math.random() < 0.5 ? start + 1 : start - 1;
            end = end % 4;
            if (end < 0) end += 4;
            let px, py;

            if (start == 0) {
                px = Math.floor(Math.random() * w);
                py = -gr / 2;
                ax[i] = i==0 ? area<minArea?Math.random()<0.5?px + d:px - d: px + d : i==1? area<minArea?Math.random()<0.5?px + d:px - d:px - d: w/2
                ay[i] = py + d;
            } else
                
            if (start == 1) {
                py = Math.floor(Math.random() * h);
                px = w + (gr / 2);
                ax[i] = px-d;
                ay[i] = i==1 ? area<minArea?Math.random()<0.5?py + d:py - d:py + d : i==0? area<minArea?Math.random()<0.5?py + d:py - d:py - d: h/2;
            } else
                
            if (start == 2) {
                px = Math.floor(Math.random() * w);
                py = h + (gr / 2);
                ax[i] = i==1 ? area<minArea?Math.random()<0.5?px + d:px - d:px + d : i==0?area<minArea?Math.random()<0.5?px + d:px - d: px - d:w/2;
                ay[i] = py-d;
            } else
                
            if (start == 3) {
                py = Math.floor(Math.random() * h);
                px = -gr / 2;
                ax[i] = px+d;
                ay[i] = i==0 ? area<minArea?Math.random()<0.5?py + d:py - d:py + d : i==1?area<minArea?Math.random()<0.5?py + d:py - d:py - d:h/2;
            }

            return calculateSnake({ py, px, start, end }, ww);
        }

        let pushSnake = (snake, i) => {
            let gr = i == 2 ? grid2 : grid;
            let check2 = () => {
                if (snake.length < snakeLength-1) return true;
                for (let j = 0; j < snake.length; j++) {
                    if (
                        snake[j].py + (gr / 2) > 0 &&
                        snake[j].py - (gr / 2) < h &&
                        snake[j].px + (gr / 2) > 0 &&
                        snake[j].px - (gr / 2) < w
                    ) {
                        return true;
                    }
                }
                return false;
            }

            let refresh = false;

            if (!check2()) {
                snake = [createSnake(i, i == 2 ? 1 : snakeWidth)];
                refresh = true;
            }

            let prev = snake[snake.length - 1];
            let start = (prev.end + 2) % 4;
            let end, py, px;

            py = prev.end == 0 ? prev.py - gr : prev.end == 2 ? prev.py + gr : prev.py;
            px = prev.end == 1 ? prev.px + gr : prev.end == 3 ? prev.px - gr : prev.px;

            if (prev.end == 0 || prev.end == 2) {
                end = ax[i]<px?3:1;
            } else {
                end = ay[i]<py?0:2;
            }

            if (end < 0) end += 4;

            return {refresh: refresh?snake:false,append:calculateSnake({
                start, end, py, px
            }, i == 2 ? 1 : snakeWidth)};
        }

        let drawSnake = (snake,c) => {
            snake.forEach((s, i) => {
                s.ps.forEach(ps => {
                    if (i == snake.length - 1) {
                        for (let i = 0; i < ps.length * c; i++) {
                            if (i > 0) p.line(ps[i - 1][0], ps[i - 1][1], ps[i][0], ps[i][1])
                        }
                    } else if (i == 0) {
                        for (let i = Math.floor(ps.length * c); i < ps.length; i++) {
                            if (i > 0) p.line(ps[i - 1][0], ps[i - 1][1], ps[i][0], ps[i][1]);
                        }
                    } else for (let i = 1; i < ps.length; i++) {
                        p.line(ps[i - 1][0], ps[i - 1][1], ps[i][0], ps[i][1])
                    }
                })
            })
        }

        let snakes = [[createSnake(0)], [createSnake(1)]];
        let snake2 = [createSnake(1, 1)];

        let snakeC = 0;
        let snakeI = 0.4;

        let snakeC2 = 0;
        let snakeI2 = 1;

        let partX = Math.random() * w;
        let partY = Math.random() * h;

        let partC = 0;
        let partI = 0.1;

        let partMax = 2;

        let partLength = 50;
        let partLength2 = Math.sqrt(partLength * partLength + partLength * partLength);

        let partFlip = false;

        p.updateWithProps = function (props) {
            w = props.w;
            h = props.h;
            area = w * h;
            p.resizeCanvas(w, h);
        };

        let sw = (grid / (snakeWidth + 1)) / 2;
    
        p.setup = function () {
            p.createCanvas(w, h);
            p.background(bg1, bg2, bg3);
            p.fill(bg1, bg2, bg3);
            p.stroke(fg1, fg2, fg3);
            p.strokeWeight(sw);
        }

        let toMouse = (x,y) => {
            ax[0] = snakes[0][snakes[0].length-1].px+(x - snakes[0][snakes[0].length-1].px)*w*h;
            ax[1] = snakes[1][snakes[1].length-1].px+(x - snakes[1][snakes[1].length-1].px)*w*h;
            ay[0] = snakes[0][snakes[0].length-1].py+(y - snakes[0][snakes[0].length-1].py)*w*h;
            ay[1] = snakes[1][snakes[1].length-1].py+(y - snakes[1][snakes[1].length-1].py)*w*h;
        }

        p.mousePressed = function () {
            toMouse();
            let x = p.mouseX;
            let y = p.mouseY
            toMouse(x, y);
            setTimeout(()=>toMouse(x,y), 500);
            setTimeout(()=>toMouse(x,y), 1000);
            setTimeout(()=>toMouse(x,y), 1500);
            setTimeout(()=>toMouse(x,y), 2000);
            setTimeout(()=>toMouse(x,y), 2500);
            setTimeout(()=>toMouse(x,y), 3000);
        }

        let sex = 0;
    
        p.draw = function () {
            if (sex++ % 1000 == 0) {
                p.background(bg1, bg2, bg3);
            }
            p.noStroke();

            snakes.forEach(snake => {
                snake.forEach(s => {
                    p.rect(s.px - grid / 2-sw, s.py - grid / 2-sw, grid+sw*2, grid+sw*2);
                })
            })
            snake2.forEach(s => {
                p.rect(s.px - grid2 / 2-sw, s.py - grid2 / 2-sw, grid2+sw*2, grid2+sw*2);
            })

            if (partFlip) {
                p.rect(partX-partLength2-sw, partY-partLength2-sw, partLength2*2+sw*2, partLength2*2+sw*2);
            } else {
                p.rect(partX-partLength-sw, partY-partLength-sw, partLength*2+sw*2, partLength*2+sw*2);
            }

            p.stroke(fg1, fg2, fg3);

            snakeC += snakeI;
            snakeC2 += snakeI2;

            partC += partI;

            if (partC > partMax) {
                partC = 0;
                partX = Math.random() * w;
                partY = Math.random() * h;
                partFlip = !partFlip;
            }

            let partC2 = partC - partI * 4;
            if (partC2 < 0) partC2 = 0;

            let partD, partD2;

            let curve = (a) => {
                let angle = a < 1 ? a * 3.1415 : 3.1415;
                let c = ((1 - cos(angle))) / 2;
                let s = a < 0.5 ? 0 : 1;
                return p.lerp(c, s, 0.33);
            }

            if (!partFlip) {
                partD = partC < 1 ?curve(partC)*partLength: partLength;
                partD2 = partC2 < 1 ?curve(partC2)*partLength: partLength;
            } else {
                partD = partC < 1 ?curve(partC)*partLength2: partLength2;
                partD2 = partC2 < 1 ?curve(partC2)*partLength2: partLength2;
            }

            
            if (partC < 1 || partC2 < 1) {
                if (partFlip) {
                    p.line(partX, partY + partD, partX, partY + partD2);
                    p.line(partX + partD, partY, partX + partD2, partY);
                    p.line(partX, partY - partD, partX, partY - partD2);
                    p.line(partX - partD, partY, partX - partD2, partY);
                } else {
                    p.line(partX + partD, partY + partD, partX + partD2, partY + partD2);
                    p.line(partX + partD, partY - partD, partX + partD2, partY - partD2);
                    p.line(partX - partD, partY + partD, partX - partD2, partY + partD2);
                    p.line(partX - partD, partY - partD, partX - partD2, partY - partD2);
                }
            }

            if (snakeC2 >= 1) {
                snakeC2 -= 1;
                if (snake2.length >= snakeLength) snake2.shift();
                let orders = pushSnake(snake2, 2);
                if (orders.refresh) snake2 = orders.refresh;
                snake2.push(orders.append);
            }

            if (snakeC >= 1) {
                snakeC = 0;
                for (let sn = 0; sn < (area<minArea?1:snakes.length); sn++) {
                    if (snakes[sn].length >= snakeLength) snakes[sn].shift();
                    let orders = pushSnake(snakes[sn], sn);
                    if (orders.refresh) snakes[sn] = orders.refresh;
                    snakes[sn].push(orders.append);
                }
            }
            drawSnake(snake2, snakeC2);
            for (let sn = 0; sn < (area<minArea?1:snakes.length); sn++) {
                drawSnake(snakes[sn], snakeC);
            }
        }
    }

    render() {
        let { w, h } = this.state;
        return <div className="background" >
            <ReactP5Wrapper sketch={this.sketch} w={w} h={h}/>
        </div>
    }
}