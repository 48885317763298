import React, { Component } from 'react';

class TextInput extends Component {
    constructor(props) {
        super(props);
        let { title, width } = props;
        this.state = {
            title, clicked: false, text:'', width
        }
        this.onChange = this.onChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.submit = this.submit.bind(this);
    }

    submit() {
        this.props.callback(this.state.text);
        this.setState({ clicked: false, text: '' });
    }

    onChange(e) {
        let { value } = e.target;
        this.setState({ text: value });
    }

    onKeyDown(e) {
        if (e.key == 'Enter') this.submit();
    }

    render() {
        let { text, clicked, title, width } = this.state;
        return !clicked?<div className='inline'>
            <a onClick={()=>this.setState({clicked:true})}>{title}</a>
        </div> : <div className='inline'>
            <div className = 'mr' style={{marginRight:this.props.mini ? 0 : null}}>
                <input type="text" value={text} onChange={this.onChange} onKeyDown={this.onKeyDown} style={{width}} />
            </div>
            {!this.props.mini ? <div className='inline'>
                <div className='mr'>
                    <a onClick={this.submit}>Submit</a>
                </div>
                <div className = 'mr'>
                    <a onClick={() => this.setState({ clicked: false, text:''})}>Cancel</a>
                </div>
            </div> : null}

        </div>
    }
}

export default TextInput;