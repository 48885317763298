import React, { Component } from 'react';

import TextInput from './TextInput';
import AreYouSure from './AreYouSure';

class TitleOptions extends Component {
    constructor(props) {
        super(props);
        let { title, options, fontSize } = props;
        if (!fontSize) fontSize = 'medium';
        this.state = {
            title, options, hovered:false, fontSize
        }
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
    }

    onMouseEnter() {
        this.setState({ hovered: true });
    }

    onMouseLeave() {
        this.setState({ hovered: false });
    }

    render() {
        let { title, hovered, options, fontSize } = this.state;
        return <div className='titleOptions' onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave} style={{paddingBottom:'0.5rem'}}>
            <div className="mr" style={{fontSize}}>
                <b>{title}</b>
            </div>
            {options.map((item, i)=><div key={'options '+title+' '+i} className="mr" style={{visibility:hovered?null:'hidden'}}>
                {
                    item.style == 'TextInput' ? <TextInput title={item.title} callback={item.onClick} mini /> :
                    item.style == 'AreYouSure' ? <AreYouSure title={item.title} callback={item.onClick} /> :
                    <a onClick={item.onClick}>{item.title}</a>
                }
            </div>)}
        </div>
    }
}

export default TitleOptions;