import React, { useState, useEffect, useReducer, useRef, useContext } from 'react';
import AdminTabs from './AdminTabs';
import Tabs from './Tabs';
import Pages from './Pages';
import {DisplayDate, DateSelect} from './DateSelect';
import axios from 'axios';
import Paragraph from '../Paragraph';
import { AdminContext, p, useClickOutside, useMount } from '../../hooks';
import Q from './Q';
import A from './A';
import Check from './C';
import { useNavigate } from 'react-router-dom';

export default function Invoices() {
    let [tab, setTab] = useState('Unpaid');
    let [page, setPage] = useState(0);
    let [pages, setPages] = useState(0);
    let [invoices, setInvoices] = useState([]);
    let [clients, setClients] = useState([]);
    let [loading, setLoading] = useState(false);
    let [expandInvoice, setExpandInvoice] = useState(false);
    let [expandClient, setExpandClient] = useState(false);

    let getBg = useRef();

    useEffect(() => {
        setBackgroundColour("Admin");
        return () => setBackgroundColour(false);
    }, [])

    useEffect(() => {
        setPage(0);
    },[tab])

    useEffect(() => {
        if (tab == 'Clients') {
            (async () => {
                setLoading(true);
                let { data } = await axios.post('/api/clients', { p:p(), page: page });
                setPages(data.pages);
                let clients = data.clients;
                setClients(clients);
                setLoading(false);
            })()
        } else {
            (async () => {
                setLoading(true);
                let { data } = await axios.post('/api/invoices', { p:p(), paid: tab == "Paid", page: page });
                setPages(data.pages);
                let invoices = data.invoices;
                setInvoices(invoices);
                setLoading(false);
            })()
        }
    }, [tab, page]);

    let { admin, setBackgroundColour } = useContext(AdminContext);
    if (!admin) return null;
    
    return <div className="Invoices" ref={getBg}>
        {expandInvoice ? <ExpandedInvoice
            close={() => setExpandInvoice(false)}
            addInvoice={v => {
                invoices.unshift(v);
                setInvoices(invoices.slice());
            }}
        /> : null}
        {expandClient ? <ExpandedClient
            addClient={c => {
                console.log(c);
                clients.unshift(c);
                setClients(clients.slice());
            }}
            close={() => setExpandClient(false)}
        /> : null}
        <AdminTabs tab="Invoices" />
        <h1>Invoices</h1>
        <Tabs tab={tab} setTab={setTab} tabs={['Unpaid', 'Paid','Clients']} />
        {tab == 'Clients' ? <>
            <div className="addButtons">
                <A onClick={() => setExpandClient(true)}>Add client</A>
            </div>
            <Pages page={page} pages={pages} setPage={setPage} />
            {loading ? <div className="i">Loading...</div> : clients.length==0 ? <div className="i">Nothing here...</div> : clients.map((o, i) => <Client
                key={tab + ' ' + page + ' ' + i}
                client={o}
                deleteMe={() => {
                    clients.splice(i, 1);
                    setClients(clients.slice());
                }}
            />)}
        </> : <>
            <div className="addButtons">
                <A onClick={() => setExpandInvoice(true)}>New invoice</A>
            </div>
            <Pages page={page} pages={pages} setPage={setPage} />
            {loading ? <div className="i">Loading...</div> : invoices.length==0 ? <div className="i">Nothing here...</div> : invoices.map((o, i) => <Invoice
                key={tab + ' ' + page + ' ' + i}
                invoice={o}
                deleteMe={() => {
                    invoices.splice(i, 1);
                    setInvoices(invoices.slice());
                }}
            />)}
        </>}
    </div>
}



function Client({ client, deleteMe }) {
    let [expand, setExpand] = useState(false);
    return <>
        {expand ? <ExpandedClient client={client} close={() => setExpand(false)} deleteMe={deleteMe}/> : null}
        <div className="client"  onClick={() => { setExpand(true) }}>
            <div className="title">
                <div className="largeBit">
                    {client.name}
                </div>
                <div>
                    {client.description}
                </div>
            </div>
        </div>
    </>
}

function ExpandedClient({ client={name:'',description:'',phone:'',email:'',address:''}, close, deleteMe, addClient }) {
    let [name, setName] = useState(client.name);
    let [description, setDescription] = useState(client.description);
    let [phone, setPhone] = useState(client.phone);
    let [email, setEmail] = useState(client.email);
    let [address, setAddress] = useState(client.address);

    return <div className="expanded">
        <div className="window">
            <div className="modalHeading">
                <div className="heading">Client</div>
                <button onClick={close}>╳</button>
            </div>
            <div className="itemSplit">
                <div className="left">
                    Name
                </div>
                <div className="right">
                    <input type="text" value={name} onChange={e=>setName(e.target.value)} />
                </div>
            </div>
            <div className="itemSplit">
                <div className="left">
                    Description
                </div>
                <div className="right">
                    <textarea value={description} onChange={e=>setDescription(e.target.value)} />
                </div>
            </div>
            <div className="itemSplit">
                <div className="left">
                    Phone
                </div>
                <div className="right">
                    <input type="text" value={phone} onChange={e=>setPhone(e.target.value)} />
                </div>
            </div>
            <div className="itemSplit">
                <div className="left">
                    Email
                </div>
                <div className="right">
                    <input type="text" value={email} onChange={e=>setEmail(e.target.value)} />
                </div>
            </div>
            <div className="itemSplit">
                <div className="left">
                    Address
                </div>
                <div className="right">
                    <textarea value={address} onChange={e=>setAddress(e.target.value)} />
                </div>
            </div>
            <div className="buts">
                <Q className="but"
                    disabled={name.length < 3}
                    callback={async () => {
                        client.name = name;
                        client.description = description;
                        client.phone = phone;
                        client.email = email;
                        client.address = address;
                        if (addClient) {
                            let { data } = await axios.post('/api/createclient', { p:p(), client });
                            addClient(data);
                        } else {
                            let { data } = await axios.post('/api/updateclient', { p:p(), client });
                        }
                        close();
                    }}
                >Save</Q>
                {deleteMe?<Q className="but" callback={async () => {
                    deleteMe();
                    axios.post('/api/deleteclient', { p:p(), client });
                    close();
                }}>Delete</Q>:null}
            </div>
        </div>
    </div>
}

function Invoice({ invoice, deleteMe }) {
    let [age, setAge] = useState('');
    let [expand, setExpand] = useState(false);

    useEffect(() => {
        let d = new Date(invoice.date);
        let now = new Date().getTime();
        let age = now - d.getTime();
        age = Math.round(age / (1000 * 60 * 60 * 24));
        if (age == 0) {
            age = 'Today';
        } else if (age == 1) {
            age = 'Yesterday';
        } else {
            age = age + ' days old';
        }
        setAge(age);
    }, [expand]);
    
    return <>
        {expand ? <ExpandedInvoice invoice={invoice} deleteMe={deleteMe} close={() => setExpand(false)}/> : null}
        <div className={invoice.paid?"invoice":"invoice red"}  onClick={() => { setExpand(true) }}>
            <div className="title">
                <div className="left">
                    {invoice.client.name}
                </div>
                <div>
                    {age}
                </div>
            </div>
            <div className="indent">
                {invoice.description.length > 0 ? <div className="mb">
                    <Paragraph text={invoice.description} noGap />
                </div> : null}

                <div className="items">
                    {invoice.hours.map((item, i) =>
                        <div key={i} className="item">
                            <div className="left">{item.title}</div>
                            <div className="right">
                                <div><b>{'£'}{item.charge.toFixed(2)}</b></div>
                            </div>
                        </div>
                    )}
                    <div className="item itemTotal mt">
                        <div className="left">Total</div>
                        <div className="right">
                            <div><b>{'£'}{invoice.hours.reduce((a,b)=>a+b.charge,0).toFixed(2)}</b></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

function ClientList({ query, setClient, newClient }) {
    let [clients, setClients] = useState([]);
    let queryRef = useRef();
    let delay = useRef()

    async function searchClients(query) {
        if (query.length == 0) {
            setClients([]);
            return;
        }
        let { data } = await axios.post('/api/searchClients', { p:p(), query });
        if (query == queryRef.current) setClients(data);
    }
    
    useEffect(() => {
        queryRef.current = query;
        clearTimeout(delay.current);
        delay.current = setTimeout(() => {
            searchClients(query);
        }, 250);
    }, [query])

    return <div className="clientList">
        {clients.map((c, i) => <div key={i} className="clientListItem"><A onClick={() => setClient(c)}>{c.name}</A></div>)}
        {query.length >= 3 ? <div><A onClick={newClient}>New client</A></div> : null}
    </div>
}

function HoursInner({ title, charge, setTitle, setCharge, addHour, deleteMe, uneditable }) {
    let ref = useRef();
    let { componentDidMount } = useMount();
    componentDidMount(() => {
        if (ref.current) ref.current.focus()
    });
    return <div className="hoursInner">
        <div className="hoursLeft">
            {uneditable ? title : <input
                type="text"
                ref={ref}
                value={title}
                onChange={e => setTitle(e.target.value)}
                onKeyDown={e => {
                    if (e.key == 'Enter') addHour();
                }}
            />}
        </div>
        <div className="hoursRight">
            £{uneditable ? charge.toFixed(2) : <input
                type="number" min={0} step="any"
                value={charge}
                onChange={e => setCharge(parseFloat(e.target.value))}
                onKeyDown={e => {
                    if (e.key == 'Enter') addHour();
                }}
            />}
        </div>
        {uneditable ? null : <div className="x">
            <A onClick={deleteMe}>✖</A>
        </div>}
    </div>
}

function Hours({ hours, setHours, uneditable }) {
    function addHour() {
        hours.push({ title: '', charge: 10 });
        setHours(hours.slice())
    }
    return <div className="hours">
        {hours.map((h, i) => <HoursInner
            key={i}
            title={h.title}
            charge={h.charge}
            addHour={addHour}
            uneditable={uneditable}
            setTitle={d => {
                h.title = d;
                setHours(hours.slice());
            }}
            setCharge={c => {
                h.charge = c;
                setHours(hours.slice());
            }}
            deleteMe={() => {
                hours.splice(i, 1);
                setHours(hours.slice());
            }}
        />)}
        {hours.length > 0 ? <div className="hoursInner total mt">
            <div className="hoursLeft">
                Total
            </div>
            <div className="hoursRight">
                £{hours.reduce((a,b)=>a+b.charge,0).toFixed(2)}
            </div>
            {uneditable ? null : <div className="x"></div>}
        </div> : null}
        {uneditable ? null :<div className="mt"><A  onClick={addHour}>Add new</A></div>}
    </div>
}

function ExpandedInvoice({ invoice = { client:{name:'',_id:''}, date:new Date().getTime(), description:'', hours:[], paid:0, showAddress:false}, close, deleteMe, addInvoice }) {
    let [clientId, setClientId] = useState(invoice.client._id);
    let [clientName, setClientName] = useState(invoice.client.name);
    let [description, setDescription] = useState(invoice.description);
    let [date, setDate] = useState(invoice.date);
    let [hours, setHours] = useState(invoice.hours);
    let [newClient, setNewClient] = useState(false);
    let [paid, setPaid] = useState(invoice.paid);
    let [showAddress, setShowAddress] = useState(invoice.showAddress);

    let [uneditable] = useState(paid && !addInvoice);

    let queryRef = useRef();

    let [queryClient, setQueryClient] = useState(clientName.length == 0)

    useEffect(() => {
        if (queryClient && queryRef.current) {
            queryRef.current.focus();
            queryRef.current.setSelectionRange(0, clientName.length);
            setClientId('');
        }
    }, [queryClient])
    let navigate = useNavigate();

    return <div className="expanded expandedInvoice">
        <div className="window">
            <div className="modalHeading">
                <div className="heading">Invoice</div>
                <button onClick={close}>╳</button>
            </div>
            {invoice._id?<div className="itemSplit">
                <div className="left">ID</div>
                <div className="right">
                    <a href={"/invoice/"+invoice._id}>{invoice._id}</a>
                </div>
            </div>:null}
            <div className="itemSplit">
                <div className="left">Client</div>
                <div className="right">
                    {
                        uneditable?clientName: newClient ? <>
                            <A onClick={()=>setNewClient(false)}>{clientName}</A>
                        </> : queryClient ? <>
                            <input ref={queryRef} type="text" value={clientName} onChange={e => setClientName(e.target.value)} />
                            <ClientList query={clientName} newClient={()=>setNewClient(true)} setClient={c => {
                                setClientName(c.name);
                                setClientId(c._id);
                                setQueryClient(false);
                            }} />
                        </> : <>
                            <A onClick={()=>setQueryClient(true)}>{clientName}</A>
                        </>
                    }
                </div>
            </div>
            {!uneditable ? <div className="itemSplit">
                <div className="left">Paid</div>
                <div className="right">
                    <Check check={paid} setCheck={setPaid}/>
                </div>
            </div> : null}
            <div className="itemSplit">
                <div className="left">Date</div>
                <div className="right">
                    {uneditable ? <DisplayDate date={date}/> : <DateSelect date={date} setDate={setDate}/>}
                </div>
            </div>
            <div className="itemSplit">
                <div className="left">Description</div>
                <div className="right">
                    {uneditable ? <Paragraph text={description}/>:<textarea value={description} onChange={e=>setDescription(e.target.value)} />}
                </div>
            </div>
            <div className="itemSplit">
                <div className="left">Hours</div>
                <div className="right">
                    <Hours hours={hours} setHours={setHours} uneditable={uneditable} />
                </div>
            </div>
            <div className="itemSplit">
                <div className="left">Show Address</div>
                <div className="right">
                    <Check check={showAddress} setCheck={setShowAddress}/>
                </div>
            </div>
            <div className="itemSplit">
                <div className="left">Stripe</div>
                <div className="right"><a target="_blank" rel="noopener noreferrer" href={'https://dashboard.stripe.com/payments/'+invoice.stripe}>{invoice.stripe}</a></div>
            </div>
            <div className="buts">
                {!invoice.paid?<Q
                    className="but"
                    disabled={hours.length == 0 || (clientId.length == 0 && !newClient)}
                    callback={async () => {
                        if (newClient) {
                            let { data } = await axios.post('/api/createclient',{p:p(),client:{name:clientName,description:'',phone:'',email:'',address:''}});
                            clientId = data._id;
                        }
                        invoice.client = {_id: clientId, name:clientName};
                        invoice.date = date;
                        invoice.description = description;
                        invoice.hours = hours;
                        invoice.paid = paid ? new Date().getTime() : 0;
                        invoice.showAddress = showAddress;
                        if (addInvoice) {
                            let { data } = await axios.post('/api/createinvoice', { p:p(), invoice });
                            addInvoice(data);
                            navigate('/invoice/' + data._id);
                        } else {
                            let { data } = await axios.post('/api/updateinvoice', { p: p(), invoice });
                            close();
                        }
                    }}
                >Save</Q> : null}
                {deleteMe && !invoice.paid?<Q className="but" callback={async () => {
                    deleteMe();
                    axios.post('/api/deleteinvoice', { p:p(), invoice });
                    close();
                }}>Delete</Q>:null}
            </div>
        </div>
    </div>
}
