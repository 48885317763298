import React, { Component } from 'react';
import axios from 'axios';

import Banner from './Banner';
import Items from './Items';
import { useParams } from 'react-router-dom';

export default function FunctionalWrapper(props) {
    let params = useParams();
    return <Category {...props} match={{params}} />
}

class Category extends Component {
    constructor(props) {
        super(props);
        let { title } = props.match.params;
        title = title.replace(/_/g, ' ');
        this.state = {
            title, categories: []
        }
    }

    async componentDidMount() {
        let { title } = this.state;
        let categories = (await axios.post('/api/subcategories', { title })).data;
        categories.forEach(category => {
            category.items.forEach(item => {
                item.path = item.images[0].path;
                item.bigText = '£' + item.price.toFixed(2);
                item.link = '/item/'+ item.title.replace(/ /g, '_');
            })
        })
        this.setState({ categories });
    }

    render() {
        let { title, categories } = this.state;
        return <div className="Store">
            <Banner />
            <div className="bg2">
                <div className="content">
                    <h1>{title}</h1>
                    {categories.map((category, i) => <div key={'items '+category.title+' '+i}>
                        <h3>{category.title}</h3>
                        <Items items={category.items} k={category._id} />
                    </div>)}
                </div>
            </div>
            
        </div>
    }
}
