import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useHistory } from "react-router-dom";
import AnimatedBackground from './AnimatedBackground';
import Title from './Title';

function Modal({hide}) {
    let [opacity, setOpacity] = useState(0);
    let [bigWidth, setBigWidth] = useState();
    let [useBig, setUseBig] = useState(true);
    let [smallWidth, setSmallWidth] = useState(500);

    let bigWidth1 = useRef();

    useEffect(() => {
        setTimeout(() => setOpacity(1), 100);
        window.addEventListener('resize', resize);
        getBigWidth();
        return () => {
            window.removeEventListener('resize', resize);
        }
    },[])

    function disappear() {
        setOpacity(0);
        setTimeout(hide, 1000 * 0.3);
    }

    function resize() {
        setUseBig(window.innerWidth - bigWidth1.current > 50);
        setSmallWidth(window.innerWidth);
    }

    let bigBox = useRef();

    useEffect(() => {
        if (bigWidth) {
            bigWidth1.current = bigWidth;
            resize();
        }
    }, [bigWidth])

    function getBigWidth() {
        let d = bigBox.current;
        if (d) {
            let bigWidth = d.getBoundingClientRect().width;
            setBigWidth(bigWidth);
        } else setTimeout(getBigWidth, 1);
    }

    return <div className="modal" style={{opacity}}>
        <div className="transparency" onClick={disappear}></div>
        <div className="box" ref={bigBox} style={{maxWidth:!useBig?500:null,width:!useBig?'calc('+smallWidth+'px - 3rem)':null,overflowY:bigWidth?'auto':'visible',overflowX:bigWidth?'hidden':'visible'}}>
            <div className="cross">
                <button onClick={disappear}>×</button>
            </div>
            <h1>Order a custom ambigram!</h1>
            <p>Hello! I have 3 tiers of ambigrams for people with different needs:</p>
            <ol>
                <li>
                    <h4>Basic ambigram</h4>
                    <p>This is for a nice solid black and white ambigram with no flashy details. 1 revision - perfect for a nice gift!</p>
                </li>
                <li>
                    <h4>Standard ambigram</h4>
                    <p>I will send you sketches first to ensure you like the direction the design is going in. Then I'll finish it off in high resolution vector format with any fancy flourishes and colours that are required to make it both legible and beautiful. Perfect for prints or tattoos!</p>
                </li>
                <li>
                    <h4>Commercial ambigram</h4>
                    <p>Same as the last one, but you can use it on products or merchandise.</p>
                </li>
            </ol>
        </div>
    </div>
}

export default function LinkTree({ }) {
    let [small, setSmall] = useState(false);
    let contentRef = useRef();
    let [ambigram, setAmbigram] = useState(false);

    useEffect(() => {
        window.addEventListener('resize', resize, true);
        resize();
        return () => window.removeEventListener('resize', resize);
    },[])

    function resize() {
        if (contentRef.current) {
            let small = Math.max(document.documentElement.clientHeight, window.innerHeight || 0) < contentRef.current.clientHeight;
            setSmall(small);
        }
    }

    return <div className="Store LinkTree">
        {/* {ambigram ? <Modal  hide={()=>setAmbigram(false)}/>:null} */}
        <div className="bigBanner" style={{height: '100vh',alignItems:small?'flex-start':null}}>
            <AnimatedBackground/>
            <div className="content" ref={contentRef}>
                <div  style={{overflow:'hidden'}}>
                    <div className="sub"><h1>Hello! What are you looking for?</h1></div>
                </div>
                <div className="sub options">
                    <NavLink className="option" to="/ambigram">
                        <div className="pic">
                            <svg viewBox="0 0 150 150">
                                <g id="XMLID_129_" className="hello">
                                    <path className="blue" d="M75,148c-40.3,0-73-32.7-73-73C2,34.7,34.7,2,75,2c40.3,0,73,32.7,73,73C148,115.3,115.3,148,75,148z"/>
                                    <path d="M75,4c39.1,0,71,31.9,71,71s-31.9,71-71,71S4,114.1,4,75S35.9,4,75,4 M75,0C33.6,0,0,33.6,0,75s33.6,75,75,75s75-33.6,75-75S116.4,0,75,0L75,0z"/>
                                </g>
                                <path className="black" d="M124,44.2v19.7l-10.1,13.1l-13.8-32.7h-4.9h-14h-0.3l-9.8,24.2l-11.8-2.5l-3.6,5L53,74.5l-6.2-15.4l11.5-15H43.1L26,67.4V44.2H10.4v61.6H26V86.2l10.1-13.1l13.8,32.7h4.9h14h0.3l9.8-24.2l11.8,2.5l3.6-5l2.7-3.6l6.2,15.4l-11.5,15h15.3l17-23.3v23.3h15.6V44.2H124z M83.3,70.9l6-14.7l7,17.4L83.3,70.9z M53.7,76.4l13,2.7l-6,14.7L53.7,76.4z"/>
                            </svg>
                        </div>
                        <div className="caption">
                            Order a custom ambigram!
                        </div>
                    </NavLink>
                    <a className="option" href="https://ambigr.am/tutorials">
                        <div className="pic">
                            <svg viewBox="-222 345.9 150 150">
                                <g>
                                    <path className="blue" d="M-147,493.9c-40.3,0-73-32.7-73-73s32.7-73,73-73s73,32.7,73,73S-106.7,493.9-147,493.9z"/>
                                    <path d="M-147,349.9c39.1,0,71,31.9,71,71s-31.9,71-71,71s-71-31.9-71-71S-186.1,349.9-147,349.9 M-147,345.9
                                        c-41.4,0-75,33.6-75,75s33.6,75,75,75s75-33.6,75-75S-105.6,345.9-147,345.9L-147,345.9z"/>
                                </g>
                                <path className="black" d="M-85.5,393.4l1.5-7.3l-2.4-0.9c-1.1-0.4-25.9-9.5-35.4-11.4c-7.1-1.5-12.8-0.3-15.6,0.5c-2.2-1.9-7-5.2-14.1-6.7c-9.4-1.9-35.9-3.5-37-3.5l-2.6-0.1l-1.5,7.3l-7.5-1.6l-16.2,78.3l122.1,25.2L-78,395L-85.5,393.4z M-90.9,389.9l-13.5,65.5c-7.3-2.6-24.6-8.7-32.1-10.2c-5-1-9.3-0.8-12.4-0.2l13.4-65c2.1-0.6,6.7-1.5,12.5-0.3C-115.7,381.1-97.5,387.6-90.9,389.9zM-186.2,370.2c7,0.4,26.2,1.7,33.5,3.3c5.8,1.2,9.6,3.8,11.3,5.3l-13.4,65c-2.6-1.7-6.4-3.6-11.5-4.7c-7.5-1.5-25.8-2.8-33.5-3.3L-186.2,370.2z M-98.8,466.1l-110.4-22.8l13.7-66.5l1.7,0.3l-13.3,64.2l3.5,0.2c0.3,0,27.1,1.6,36.1,3.4c8,1.7,12.3,6.1,12.4,6.2l1.5,1.6l2-0.9c0.1,0,5.7-2.5,13.8-0.8c9,1.9,34.3,11.1,34.5,11.2l3.3,1.2l13.3-64.2l1.7,0.3L-98.8,466.1z"/>
                                <path className="black" d="M-157.4,428.7c-4.4-1.9-9-3-13.8-3.4l-20.1-1.6l-1.2,5.9l20.9,1.7c4.5,0.4,8.9,1.5,13,3.4L-157.4,428.7z"/>
                                <path className="black" d="M-155,417c-4.4-1.9-9-3-13.8-3.4l-20.1-1.6l-1.2,5.9l20.9,1.7c4.5,0.4,8.9,1.5,13,3.4L-155,417z"/>
                                <path className="black" d="M-152.5,405.3c-4.4-1.9-9-3-13.8-3.4l-20.1-1.6l-1.2,5.9l20.9,1.7c4.5,0.4,8.9,1.5,13,3.4L-152.5,405.3z"/>
                                <path className="black" d="M-150.1,393.6c-4.4-1.9-9-3-13.8-3.4l-20.1-1.6l-1.2,5.9l20.9,1.7c4.5,0.4,8.9,1.5,13,3.4L-150.1,393.6z"/>
                                <path className="black" d="M-147.7,381.8c-4.4-1.9-9-3-13.8-3.4l-20.1-1.6l-1.2,5.9l20.9,1.7c4.5,0.4,8.9,1.5,13,3.4L-147.7,381.8z"/>
                                <path className="black" d="M-140.6,432.2c4.8,0,9.5,0.8,14,2.3l19.1,6.5l-1.2,5.9l-19.8-6.7c-4.3-1.5-8.8-2.1-13.3-2L-140.6,432.2z"/>
                                <path className="black" d="M-138.1,420.5c4.8,0,9.5,0.8,14,2.3l19.1,6.5l-1.2,5.9l-19.8-6.7c-4.3-1.5-8.8-2.1-13.3-2L-138.1,420.5z"/>
                                <path className="black" d="M-135.7,408.7c4.8,0,9.5,0.8,14,2.3l19.1,6.5l-1.2,5.9l-19.8-6.7c-4.3-1.5-8.8-2.1-13.3-2L-135.7,408.7z"/>
                                <path className="black" d="M-133.3,397c4.8,0,9.5,0.8,14,2.3l19.1,6.5l-1.2,5.9l-19.8-6.7c-4.3-1.5-8.8-2.1-13.3-2L-133.3,397z"/>
                                <path className="black" d="M-130.9,385.3c4.8,0,9.5,0.8,14,2.3l19.1,6.5l-1.2,5.9l-19.8-6.7c-4.3-1.5-8.8-2.1-13.3-2L-130.9,385.3z"/>
                            </svg>
                        </div>
                        <div className="caption">
                            Ambigram tutorials!
                        </div>
                    </a>
                    <a className="option" href="https://www.youtube.com/c/weirdside">
                        <div className="pic">
                            <svg viewBox="0 0 150 150">
                                <g>
                                    <path id="XMLID_124_" className="blue" d="M75,148c-40.3,0-73-32.7-73-73C2,34.7,34.7,2,75,2c40.3,0,73,32.7,73,73C148,115.3,115.3,148,75,148z"/>
                                    <path id="XMLID_21_" d="M75,4c39.1,0,71,31.9,71,71s-31.9,71-71,71S4,114.1,4,75S35.9,4,75,4 M75,0C33.6,0,0,33.6,0,75s33.6,75,75,75s75-33.6,75-75S116.4,0,75,0L75,0z"/>
                                </g>
                                <path className="black" d="M43.7,50.4l-6.9,28.5l-8.2,2.8L24,70.1l-0.4,0.1l-4.2,12.4L10.8,84L3.6,58.6l9.2-3.9l3.6,15.9l0.4-0.1l3.2-12.2l7.4-1.2l3.4,11.1l0.4-0.1l3.1-16.2L43.7,50.4z"/>
                                <path className="black" d="M65.1,74.9l-19.7,3.3L46,50l19.4-3.2l-0.7,7.3l-10.6,1.8l0,3.2l9.4-1.6l0.3,7L54,66.1l0,3.4L65.5,68L65.1,74.9z"/>
                                <path className="black" d="M67.7,67.7l4.3-0.7l0.2-14.7l-4.5,0.5l0.1-6.8l17.2-2.5L84.7,50l-4.3,1.3l0.2,14.2l4.3-0.7l0,6.8L68,74.4L67.7,67.7z"/>
                                <path className="black" d="M88.4,71l-0.1-28.1l9.8-2c9.4-2,14.1,0.9,14,7.5c0,5.4-5.5,8.8-5.5,8.8l0,0.4l7.4,7.6l-9,3.9l-7.2-8.5l-0.8,0.1l0.1,8.8L88.4,71z M96.7,54.8c4.7-0.8,6.6-1.9,6.7-4.5c0-2.6-1.9-2.7-5.5-2.1l-1.4,0.2L96.7,54.8z"/>
                                <path className="black" d="M115.6,38.4l11.1-1.9c8.8-1.5,13.2,3.4,13.1,12.3c-0.1,8.9-5.4,15.4-13.4,16.7c-4.1,0.7-8,0.9-11.1,1L115.6,38.4z M123.6,59.1l2.3-0.4c4.3-0.7,5.1-4.5,5.1-8.5c0-2.4-0.3-7.6-5-6.8l-2,0.3L123.6,59.1z"/>
                                <path className="black" d="M50.4,96.5c0,0-3.1-2.3-5.8-1.8c-1.6,0.3-2.4,1.2-2.4,2c0,3,12.5,0.1,12.4,9.2c0,4.7-4.4,10.2-12.7,11.6c-6.5,1.1-11.1-3-11.1-3l4.4-6.8c0,0,4.3,2.7,7,2.2c2.2-0.4,2.9-1.5,2.9-2.1c0-3.1-12.3,0.2-12.2-9.6c0-4.4,4-9.7,11.8-11c5.5-0.9,9.8,2.4,9.8,2.4L50.4,96.5z"/>
                                <path className="black" d="M55.9,107.4l4.3-0.7L60.4,92l-4.5,0.5l0.1-6.8l17.2-2.5l-0.2,6.5L68.6,91l0.2,14.2l4.3-0.7l0,6.8l-16.8,2.8L55.9,107.4z"/>
                                <path className="black" d="M76.5,82.7l11.1-1.9c8.8-1.5,13.2,3.4,13.1,12.3c-0.1,8.9-5.4,15.4-13.4,16.7c-4.1,0.7-8,0.9-11.1,1L76.5,82.7z M84.5,103.4l2.3-0.4c4.3-0.7,5.1-4.5,5.1-8.5c0-2.4-0.3-7.6-5-6.8l-2,0.3L84.5,103.4z"/>
                                <path className="black" d="M123.5,103l-19.7,3.3l0.6-28.2l19.4-3.2l-0.7,7.3l-10.6,1.8l0,3.2l9.4-1.6l0.3,7l-9.8,1.6l0,3.4l11.5-1.5L123.5,103z"/>
                            </svg>
                        </div>
                        <div className="caption">
                            Visit my other YouTube channel!
                        </div>
                    </a>
                    {/* <NavLink className="option" to="/shop">
                        <div className="pic">
                            <svg viewBox="0 0 150 150" >
                                <g>
                                    <path className="blue" d="M75,148c-40.3,0-73-32.7-73-73S34.7,2,75,2s73,32.7,73,73S115.3,148,75,148z"/>
                                    <path d="M75,4c39.1,0,71,31.9,71,71s-31.9,71-71,71S4,114.1,4,75S35.9,4,75,4 M75,0C33.6,0,0,33.6,0,75s33.6,75,75,75s75-33.6,75-75S116.4,0,75,0L75,0z"/>
                                </g>
                                <path className="black" d="M95,131.2c-2,2.7-4.6,4-7.9,4c-1.9,0-3.8-0.5-5.5-1.4c-1.7-0.9-3.3-2-4.7-3.2c-0.8,1.2-1.7,2.2-2.8,2.9c-0.8,0.5-1.7,1-2.6,1.3c-0.9,0.3-1.9,0.5-2.8,0.5c-2.6,0-4.7-0.8-6.4-2.5c-1.6-1.7-2.5-3.8-2.5-6.3c0-2.4,0.9-4.4,2.6-6.2c0.9-0.9,2-1.5,3.1-1.9c1.1-0.4,2.4-0.6,3.7-0.6c1.3,0,2.4,0.2,3.6,0.7c0-0.6-0.1-1.2-0.2-1.7c-0.1-0.6-0.2-1.2-0.4-1.8c-0.2-0.5-0.4-1.1-0.5-1.6c-0.1-0.5-0.3-1-0.5-1.5H60.5v-7.9H68c-0.3-0.7-0.6-1.3-0.8-2c-0.2-0.7-0.5-1.3-0.7-1.9c-0.7-2.2-1.1-4.5-1.1-6.8c0-3.8,1.3-7,3.9-9.6c1.4-1.4,2.9-2.4,4.7-3c1.8-0.6,3.7-0.9,5.9-0.9c2.2,0,4.2,0.3,6,0.9c1.8,0.6,3.4,1.6,4.7,2.9c1.4,1.4,2.4,3,3,4.9c0.6,1.9,1,4.5,1,7.8h-8.1c-0.1-2.2-0.3-3.8-0.5-4.8c-0.3-1-0.7-1.7-1.2-2.3c-0.5-0.5-1.1-1-1.8-1.3c-0.7-0.3-1.7-0.5-2.9-0.5c-1.9,0-3.4,0.5-4.6,1.5c-0.5,0.4-0.9,0.9-1.2,1.6c-0.3,0.7-0.4,1.5-0.4,2.5c0,0.7,0.1,1.5,0.2,2.4c0.1,0.9,0.4,1.9,0.8,3c0.3,0.9,0.7,1.8,1,2.7c0.3,0.9,0.7,1.8,1.1,2.7h10.3v7.9h-7.6c0.5,2,0.8,4.1,0.8,6.1c0,1.3-0.1,2.4-0.2,3.4c0,0.3-0.1,0.6-0.1,0.9c0,0.3-0.1,0.6-0.2,1c0.7,0.6,1.4,1.2,2.1,1.7c0.7,0.5,1.3,1,1.9,1.4c0.6,0.4,1.2,0.7,1.7,0.9c0.5,0.2,1,0.3,1.5,0.3c0.6,0,1.1-0.1,1.4-0.3c0.3-0.2,0.6-0.4,0.8-0.8c0.1-0.2,0.3-0.5,0.4-0.8c0.1-0.3,0.2-0.8,0.3-1.3c0.1-0.6,0.2-1.3,0.3-2.1c0.1-0.9,0.1-1.9,0.2-3.2h7.5v3C97.8,125.6,96.8,128.7,95,131.2z M70.2,125.1c-0.3-0.1-0.7-0.2-1.1-0.2c-0.6,0-1.1,0.2-1.7,0.6c-0.1,0.1-0.2,0.2-0.3,0.4c-0.1,0.1-0.1,0.4-0.1,0.7c0,0.2,0,0.4,0.1,0.5c0.1,0.1,0.2,0.3,0.3,0.4c0.2,0.2,0.6,0.4,1.1,0.4c0.2,0,0.5,0,0.8-0.1c0.3-0.1,0.5-0.3,0.8-0.7c0.4-0.4,0.8-0.9,1.2-1.5C70.9,125.4,70.6,125.2,70.2,125.1z"/>
                                <path className="black" d="M48.6,125.1c-2.6,2.1-5.5,2.7-8.7,1.8c-1.9-0.5-3.5-1.4-4.9-2.8c-1.4-1.4-2.7-2.8-3.7-4.3c-1.1,1-2.2,1.7-3.5,2.1c-0.9,0.3-1.9,0.5-2.9,0.6c-1,0.1-1.9,0-2.9-0.3c-2.5-0.7-4.3-2-5.6-4c-1.2-2.1-1.4-4.3-0.8-6.8c0.6-2.3,2-4.1,4.1-5.3c1.1-0.6,2.3-1,3.5-1.1c1.2-0.1,2.4,0,3.7,0.3c1.2,0.3,2.3,0.8,3.3,1.5c0.1-0.6,0.2-1.1,0.2-1.7c0-0.6,0.1-1.2,0.1-1.8c-0.1-0.6-0.1-1.1-0.1-1.6c0-0.5,0-1.1-0.1-1.6l-10.2-2.7l2-7.7l7.2,1.9c-0.1-0.7-0.2-1.4-0.3-2.1c-0.1-0.7-0.1-1.3-0.2-2c-0.1-2.3,0.1-4.6,0.7-6.8c1-3.7,3.1-6.4,6.3-8.3c1.7-1,3.4-1.5,5.3-1.7c1.9-0.2,3.9,0,6,0.6c2.1,0.6,4,1.4,5.5,2.4c1.6,1,2.8,2.4,3.8,4.1c1,1.7,1.5,3.5,1.6,5.5c0.1,2-0.2,4.6-1,7.8l-7.9-2.1c0.5-2.2,0.7-3.8,0.7-4.8c0-1-0.2-1.9-0.6-2.6c-0.3-0.6-0.8-1.2-1.4-1.7c-0.6-0.5-1.5-0.9-2.7-1.2c-1.8-0.5-3.4-0.4-4.8,0.3c-0.6,0.2-1.1,0.6-1.6,1.2c-0.4,0.6-0.8,1.3-1.1,2.3c-0.2,0.7-0.3,1.4-0.4,2.3c-0.1,0.9-0.1,1.9,0,3.1c0.1,1,0.2,1.9,0.2,2.9C37.9,92,38,93,38.1,94l9.9,2.6l-2,7.7l-7.3-1.9c0,2.1-0.3,4.1-0.8,6.1c-0.3,1.2-0.7,2.3-1.1,3.2c-0.1,0.3-0.2,0.5-0.3,0.8c-0.1,0.3-0.2,0.6-0.4,0.9c0.5,0.8,1.1,1.5,1.6,2.2c0.5,0.7,1,1.3,1.4,1.8c0.5,0.6,1,1,1.5,1.3c0.5,0.3,0.9,0.5,1.4,0.6c0.6,0.2,1.1,0.2,1.4,0.1c0.3-0.1,0.7-0.3,0.9-0.5c0.2-0.1,0.4-0.4,0.6-0.7c0.2-0.3,0.4-0.7,0.6-1.2c0.2-0.5,0.5-1.2,0.8-2c0.3-0.8,0.6-1.8,1-3l7.2,1.9l-0.8,2.9C52.8,120.4,51.1,123.1,48.6,125.1z M26.3,112.8c-0.3-0.2-0.6-0.4-1-0.5c-0.6-0.1-1.2-0.1-1.8,0.1c-0.1,0.1-0.2,0.2-0.4,0.3c-0.1,0.1-0.2,0.3-0.3,0.7c-0.1,0.2-0.1,0.4,0,0.6c0,0.1,0.1,0.3,0.1,0.5c0.2,0.3,0.5,0.5,1,0.6c0.2,0.1,0.5,0.1,0.8,0.1c0.3,0,0.6-0.2,1-0.4c0.5-0.3,1-0.7,1.5-1.2C26.9,113.3,26.6,113,26.3,112.8z"/>
                                <path className="black" d="M98.9,66.8c-2.6,2.1-5.5,2.7-8.7,1.9c-1.9-0.5-3.5-1.4-5-2.7c-1.4-1.3-2.7-2.8-3.7-4.3c-1.1,1-2.2,1.7-3.5,2.1c-0.9,0.3-1.9,0.5-2.9,0.6c-1,0.1-1.9,0-2.9-0.2c-2.5-0.6-4.4-2-5.6-4c-1.2-2.1-1.5-4.3-0.8-6.7c0.6-2.3,1.9-4.1,4.1-5.3c1.1-0.6,2.3-1,3.5-1.1c1.2-0.1,2.4,0,3.7,0.3c1.2,0.3,2.3,0.8,3.3,1.5c0.1-0.6,0.2-1.1,0.2-1.7c0-0.6,0.1-1.2,0.1-1.8c-0.1-0.6-0.1-1.1-0.1-1.6c0-0.5-0.1-1.1-0.1-1.6l-10.2-2.6l2-7.7l7.3,1.9c-0.1-0.7-0.2-1.4-0.3-2.1c-0.1-0.7-0.1-1.3-0.2-2c-0.2-2.3,0.1-4.6,0.6-6.8c0.9-3.7,3-6.4,6.2-8.3c1.7-1,3.4-1.6,5.3-1.7c1.9-0.2,3.9,0,6,0.6c2.1,0.5,4,1.3,5.6,2.4c1.6,1,2.9,2.4,3.8,4c1,1.7,1.5,3.5,1.7,5.5c0.1,2-0.2,4.6-0.9,7.8l-7.9-2c0.5-2.2,0.7-3.8,0.7-4.8c0-1-0.2-1.9-0.6-2.6c-0.3-0.6-0.8-1.2-1.4-1.7c-0.6-0.5-1.5-0.9-2.7-1.2c-1.8-0.5-3.4-0.4-4.8,0.3c-0.6,0.2-1.1,0.6-1.6,1.2c-0.4,0.6-0.8,1.3-1,2.3c-0.2,0.7-0.3,1.4-0.4,2.3c-0.1,0.9-0.1,1.9,0,3.1c0.1,1,0.2,1.9,0.3,2.9c0.1,0.9,0.2,1.9,0.3,2.9l9.9,2.5l-2,7.7l-7.3-1.9c0,2.1-0.2,4.1-0.7,6.1c-0.3,1.2-0.7,2.3-1.1,3.3c-0.1,0.3-0.2,0.5-0.3,0.8c-0.1,0.3-0.2,0.6-0.4,0.9c0.5,0.8,1.1,1.5,1.6,2.2c0.5,0.7,1,1.3,1.4,1.8c0.5,0.6,1,1,1.5,1.3c0.5,0.3,0.9,0.5,1.4,0.6c0.6,0.2,1.1,0.2,1.4,0.1c0.3-0.1,0.7-0.3,0.9-0.6c0.2-0.2,0.4-0.4,0.6-0.7c0.2-0.3,0.4-0.7,0.6-1.2c0.2-0.5,0.5-1.2,0.8-2c0.3-0.8,0.6-1.8,1-3.1l7.3,1.9l-0.7,2.9C103.1,62.1,101.4,64.9,98.9,66.8z M76.5,54.8c-0.3-0.2-0.6-0.4-1-0.4c-0.6-0.1-1.2-0.1-1.8,0.1c-0.1,0.1-0.2,0.2-0.4,0.3c-0.1,0.1-0.2,0.3-0.3,0.7C73,55.7,73,55.9,73,56c0,0.1,0.1,0.3,0.1,0.4c0.2,0.3,0.5,0.5,1,0.6c0.2,0.1,0.5,0.1,0.8,0c0.3,0,0.6-0.2,1-0.4c0.5-0.3,1-0.7,1.5-1.2C77.1,55.2,76.8,55,76.5,54.8z"/>
                                <path className="black" d="M140.5,105.7c-1.1,3.1-3.2,5.2-6.3,6.2c-1.8,0.6-3.7,0.7-5.7,0.3c-1.9-0.4-3.7-0.9-5.4-1.7c-0.4,1.4-1,2.6-1.8,3.6c-0.6,0.8-1.3,1.4-2.1,2c-0.8,0.6-1.6,1-2.6,1.3c-2.5,0.8-4.7,0.6-6.8-0.4c-2.1-1.1-3.5-2.9-4.3-5.3c-0.7-2.3-0.5-4.5,0.6-6.7c0.6-1.1,1.4-2,2.4-2.8c1-0.7,2.1-1.3,3.3-1.7c1.2-0.4,2.4-0.5,3.6-0.5c-0.2-0.5-0.5-1.1-0.7-1.6c-0.3-0.5-0.6-1-0.9-1.6c-0.3-0.4-0.7-0.9-1-1.3c-0.3-0.4-0.6-0.9-0.9-1.3l-10.1,3.2l-2.4-7.6l7.1-2.3c-0.5-0.6-0.9-1.1-1.4-1.7c-0.4-0.6-0.8-1.1-1.2-1.6c-1.4-1.9-2.4-3.9-3.1-6.1c-1.1-3.6-0.9-7.1,0.8-10.3c0.9-1.7,2.1-3.1,3.6-4.3c1.5-1.1,3.3-2,5.4-2.7c2.1-0.7,4.1-1,6-0.9c1.9,0,3.7,0.5,5.4,1.4c1.7,0.9,3.2,2.2,4.3,3.8c1.2,1.7,2.3,4,3.3,7.1l-7.8,2.5c-0.8-2.1-1.4-3.6-2-4.4c-0.5-0.8-1.2-1.5-1.8-1.9c-0.6-0.4-1.3-0.6-2.1-0.7c-0.8-0.1-1.8,0.1-2.9,0.4c-1.8,0.6-3.1,1.5-3.9,2.8c-0.4,0.5-0.6,1.2-0.7,1.9c-0.1,0.7,0,1.6,0.3,2.5c0.2,0.6,0.5,1.4,0.9,2.2c0.4,0.8,1,1.7,1.7,2.6c0.6,0.8,1.2,1.5,1.8,2.3c0.6,0.8,1.2,1.5,1.8,2.3l9.8-3.1l2.4,7.6l-7.2,2.3c1.1,1.8,2,3.6,2.6,5.6c0.4,1.2,0.7,2.3,0.8,3.3c0,0.3,0.1,0.6,0.2,0.9c0.1,0.3,0.1,0.6,0.1,1c0.9,0.4,1.7,0.7,2.5,1c0.8,0.3,1.5,0.6,2.2,0.8c0.7,0.2,1.4,0.3,1.9,0.3c0.6,0,1.1-0.1,1.5-0.2c0.6-0.2,1-0.4,1.3-0.7c0.2-0.3,0.4-0.6,0.5-1c0.1-0.2,0.1-0.5,0.1-0.9c0-0.4-0.1-0.8-0.1-1.4c-0.1-0.6-0.2-1.3-0.4-2.1c-0.2-0.9-0.5-1.9-0.8-3.1l7.1-2.3l0.9,2.8C141.5,99.6,141.5,102.8,140.5,105.7z M115.1,107.4c-0.4,0-0.7,0-1.1,0.2c-0.6,0.2-1,0.5-1.4,1.1c-0.1,0.1-0.1,0.3-0.2,0.4c0,0.2,0,0.4,0.1,0.7c0.1,0.2,0.2,0.4,0.3,0.5c0.1,0.1,0.2,0.2,0.4,0.3c0.3,0.2,0.7,0.2,1.2,0c0.2-0.1,0.5-0.2,0.7-0.4c0.2-0.2,0.4-0.5,0.6-0.9c0.3-0.5,0.5-1.1,0.6-1.8C115.8,107.5,115.4,107.4,115.1,107.4z"/>
                                <path className="black" d="M60.2,63.3c-2,2.7-4.6,4-7.9,4c-1.9,0-3.8-0.5-5.5-1.4c-1.7-0.9-3.3-2-4.7-3.2c-0.8,1.2-1.7,2.2-2.8,2.9c-0.8,0.5-1.7,1-2.6,1.3c-0.9,0.3-1.9,0.5-2.8,0.5c-2.6,0-4.7-0.8-6.4-2.5C25.8,63.1,25,61,25,58.5c0-2.4,0.9-4.4,2.6-6.2c0.9-0.9,2-1.5,3.1-1.9c1.1-0.4,2.4-0.6,3.7-0.6c1.3,0,2.4,0.2,3.6,0.7c0-0.6-0.1-1.2-0.2-1.7c-0.1-0.6-0.2-1.2-0.4-1.8c-0.2-0.5-0.4-1.1-0.5-1.6c-0.1-0.5-0.3-1-0.5-1.5H25.8V36h7.5c-0.3-0.7-0.6-1.3-0.8-2c-0.2-0.7-0.5-1.3-0.7-1.9c-0.7-2.2-1.1-4.5-1.1-6.8c0-3.8,1.3-7,3.9-9.6c1.4-1.4,2.9-2.4,4.7-3c1.8-0.6,3.7-0.9,5.9-0.9c2.2,0,4.2,0.3,6,0.9c1.8,0.6,3.4,1.6,4.7,2.9c1.4,1.4,2.4,3,3,4.9c0.6,1.9,1,4.5,1,7.8h-8.1c-0.1-2.2-0.3-3.8-0.5-4.8c-0.3-1-0.7-1.7-1.2-2.3c-0.5-0.5-1.1-1-1.8-1.3c-0.7-0.3-1.7-0.5-2.9-0.5c-1.9,0-3.4,0.5-4.6,1.5c-0.5,0.4-0.9,0.9-1.2,1.6c-0.3,0.7-0.4,1.5-0.4,2.5c0,0.7,0.1,1.5,0.2,2.4c0.1,0.9,0.4,1.9,0.8,3c0.3,0.9,0.7,1.8,1,2.7c0.3,0.9,0.7,1.8,1.1,2.7h10.3v7.9h-7.6c0.5,2,0.8,4.1,0.8,6.1c0,1.3-0.1,2.4-0.2,3.4c0,0.3-0.1,0.6-0.1,0.9c0,0.3-0.1,0.6-0.2,1c0.7,0.6,1.4,1.2,2.1,1.7c0.7,0.5,1.3,1,1.9,1.4c0.6,0.4,1.2,0.7,1.7,0.9c0.5,0.2,1,0.3,1.5,0.3c0.6,0,1.1-0.1,1.4-0.3c0.3-0.2,0.6-0.4,0.8-0.8c0.1-0.2,0.3-0.5,0.4-0.8c0.1-0.3,0.2-0.8,0.3-1.3c0.1-0.6,0.2-1.3,0.3-2.1c0.1-0.9,0.1-1.9,0.2-3.2h7.5v3C63.1,57.8,62.1,60.8,60.2,63.3z M35.5,57.2c-0.3-0.1-0.7-0.2-1.1-0.2c-0.6,0-1.1,0.2-1.7,0.6c-0.1,0.1-0.2,0.2-0.3,0.4c-0.1,0.1-0.1,0.4-0.1,0.7c0,0.2,0,0.4,0.1,0.5c0.1,0.1,0.2,0.3,0.3,0.4c0.2,0.2,0.6,0.4,1.1,0.4c0.2,0,0.5,0,0.8-0.1c0.3-0.1,0.5-0.3,0.8-0.7c0.4-0.4,0.8-0.9,1.2-1.5C36.2,57.5,35.8,57.4,35.5,57.2z"/>
                            </svg>
                        </div>
                        <div className="caption">
                            Purchase some goods!
                        </div>
                    </NavLink> */}
                    {/* <a className="option" href="https://discord.gg/tGH2SwtVuv">
                        <div className="pic">
                            <svg viewBox="0 0 150 150" >
                                <g>
                                    <path className="blue" d="M75,148c-40.3,0-73-32.7-73-73S34.7,2,75,2s73,32.7,73,73S115.3,148,75,148z"/>
                                    <path d="M75,4c39.1,0,71,31.9,71,71s-31.9,71-71,71S4,114.1,4,75S35.9,4,75,4 M75,0C33.6,0,0,33.6,0,75s33.6,75,75,75s75-33.6,75-75S116.4,0,75,0L75,0z"/>
                                </g>
                                <path className="black" d="M148,75.2c0,0-32.7-40.8-73-40.8S2,75.2,2,75.2S34.7,116,75,116c0,0,0,0,0,0s0,0,0,0C115.3,116,148,75.2,148,75.2z M76.8,109.6c-0.6,0-1.2,0-1.8,0s-1.2,0-1.8,0c-7.8-0.4-14.9-3.4-20.6-8.2c0.2,0.1,0.3,0.2,0.3,0.2s5.2-1.2,5.6-4c0.3-2.8-4.2-7.3-2.3-10.8c2.2-4.3,13.5,0.7,15.4-4.5c1-2.8-0.3-7.8-5.8-9.7c-5.5-1.8,1.9-9.6-4-13.2c-6.7-4.1-12.3,7.7-18.5,8.2c0,0-1.1,0.1-2.3,0.6c3-15.3,16.2-27,32.2-27.9c0.6,0,1.2,0,1.8,0c0.5,0,1,0,1.5,0c-3.2,0.8-4,2-4,2c-3,3.7,2,10.2,6.7,11.8c4.7,1.7,8.2,7.3,7.3,9.2s-5,9.2-1.7,13.2c3.3,4,14.7,4,14.5-3.2c-0.2-7.2,3.2-9.1,6-10.2c0.8-0.3,1.3-0.8,1.6-1.6c1.7,4.1,2.7,8.7,2.7,13.4C109.7,93.5,95.1,108.7,76.8,109.6z M10,75c5.7-6.1,18.2-18.3,34.2-26.5C38,55.6,34.3,64.9,34.3,75c0,10.1,3.7,19.4,9.8,26.5C28.2,93.3,15.7,81.1,10,75z M105.8,101.5c6.1-7.1,9.8-16.4,9.8-26.5c0-10.1-3.7-19.4-9.8-26.5c16,8.2,28.5,20.4,34.2,26.5C134.3,81.1,121.8,93.3,105.8,101.5z"/>
                                <path className="black" d="M92.2,90.3c-0.3-4-8.2-6.8-10.5-4.3c-2.3,2.5-3.3,9.3-5.8,9.8s-5.5,4.3-2.2,5.8c3.3,1.5,11-0.3,12.7-2C88,98,92.5,94.5,92.2,90.3z"/>
                            </svg>
                        </div>
                        <div className="caption">
                            Join my death cult!
                        </div>
                    </a> */}
                </div>
            </div>
        </div>
    </div>
}