import React, { useState, useEffect, useReducer, useRef, useContext, useCallback, createContext } from 'react';
import AdminTabs from './AdminTabs';
import Tabs from './Tabs';
import Pages from './Pages';
import {ReactP5Wrapper, P5Instance} from 'react-p5-wrapper';
import axios from 'axios';
import { AdminContext, p, useChange, useClickOutside, useGetState, useMount } from '../../hooks';
import A from './A';
import Q from './Q';
import { toDateString } from './DateSelect';
import { useLocation } from 'react-router';
import { generateTikTokCaption, generateYouTubeShortsTitleAndDescription, generateTikTokLinkInBio } from './AmbigramCaptions.js';

let TabContext = createContext();
let QueryContext = createContext();
let HeadingContext = createContext();
let ExpandedContext = createContext();
let FullScreenLoadingContext = createContext();
let ForceRenderContext = createContext();

// function ReactP5Wrapper({ }) {
//     return <div>STUPID SHIT</div>
// }

export default function Ambigrams({  }) {
    // let [tab, setTab] = useState('Upload');
    let [tab, setTab] = useState('');
    let [page, setPage] = useState(0);
    let [pages, setPages] = useState(0);
    let [projects, setProjects] = useState([]);
    let [loading, setLoading] = useState(false);
    let [expandProject, setExpandProject] = useState(false);
    let [centre, setCentre] = useState(true);
    let [counts, setCounts] = useState({});
    let [expanded, setExpanded] = useState(false);

    let [showFullScreenLoading, setShowFullScreenLoading] = useState(false);

    let [query, setQuery] = useState({});
    let { password, id, confirmUpload } = query;

    let location = useLocation();

    let renderedTabs = ['All', 'Uploaded', 'Not uploaded'];
    let [renderedTab, setRenderedTab] = useState('Not uploaded');
    let platformTabs = ['YouTube', 'TikTok', 'Both'];
    let [platformTab, setPlatformTab] = useState('Both');

    let [force, forceRender] = useReducer(f => f + 1, 0);

    useEffect(() => {
        let { search } = location;
        if (search) {
            search = search.split('?');
            search.shift();
            let query = {};
            search.forEach(s => {
                let [key, value] = s.split('=');
                query[key] = value;
            })
            setQuery(query);
            if (query.password) {
                localStorage.setItem('p', query.password);
                document.getElementsByTagName("body")[0].style = 'overflow:hidden';
            }
            if (query.confirmUpload) {
                setTab('confirmUpload');
            } else setTab('New')
        } else setTab('Rendered')
    }, [])

    useEffect(() => {
        setPage(0);
    }, [tab])

    useEffect(() => {
        if (expandProject) {
            setExpanded(true);
            return () => setExpanded(false);
        }
    },[expandProject])

    useEffect(() => {
        if (tab != 'Upload') (async () => {
            setLoading(true);
            let { data } = await axios.post('/api/ambigramprojects', { p: p(), page: page, tab:tab==''?'New':tab, count:query.password });
            setPages(data.pages);
            setProjects(data.projects);
            if (password) setCounts(data.counts);
            setLoading(false);
        })();
    }, [tab, page]);

    let mainRef = useRef();
    let projectsListRef = useRef();

    let{admin,setBackgroundColour}=useContext(AdminContext);useEffect(()=>{setBackgroundColour("Admin");return()=>setBackgroundColour(false);},[]);if(!admin)return null;    
    let tabs = ['New', 'Illustrated', 'Rendered'];

    function add() {
        setTab('New');
        setExpandProject(true);
    }

    function showProject(project) {
        if (tab != 'Rendered') return true;
        if (renderedTab == 'All') return true;
        if (platformTab == 'Both') return !!project.videos.find(v => !!v.uploaded['youTube'] || !!v.uploaded['tikTok']) == (renderedTab == 'Uploaded');
        return !!project.videos.find(v => !!v.uploaded[uncapitalise(platformTab)]) == (renderedTab == 'Uploaded');
    }

    let count = tab == 'Rendered' ? projects.filter((o) => showProject(o)).length : null;

    return <div className={`Ambigrams ${password ? 'app' : ''}`}>
        {showFullScreenLoading?<div className="fullScreenLoading">Please wait...</div>:null}
        <ExpandedContext.Provider value={[expanded, setExpanded]}><FullScreenLoadingContext.Provider value={[showFullScreenLoading, setShowFullScreenLoading]}><QueryContext.Provider value={{password, id}}><ForceRenderContext.Provider value={{forceRender}}>
            {password ? <Sidebar tab={tab} setTab={setTab} tabs={tabs} add={add} id={id} counts={counts} /> : <>
                <AdminTabs tab="Ambigrams" />
                <h1>Ambigrams</h1>
                <Tabs tab={tab} setTab={setTab} tabs={tabs} />
                {tab == 'Rendered' ? <div className="tabSplit">
                    <Tabs tab={platformTab} tabs={platformTabs} setTab={setPlatformTab} />
                    <Tabs tab={renderedTab} tabs={renderedTabs} setTab={setRenderedTab} />
                </div> : null}
                {count ? <h2>{count} projects:</h2>:null}
                <div className="addButtons">
                    <A onClick={add}>New project</A>
                </div>
            </>}
            <div className={`Mainbit ${centre?'centre':''}`} ref={mainRef}>
                <TabContext.Provider value={[tab, setTab]}>
                    {expandProject ? <ExpandedProject
                        close={() => setExpandProject(false)}
                        addProject={v => {
                            projects.unshift(v);
                            setProjects(projects.slice());
                        }}
                    /> : null}
                    {tab == 'Upload' ? <UploadTab /> : tab == 'confirmUpload' ? <ConfirmUploadTab _id={confirmUpload} />: <div className="projectsList" ref={projectsListRef}>
                        <Pages page={page} pages={pages} setPage={setPage} />
                        {loading ? <div className="i">Loading...</div> : projects.length==0 ? <div className="i">Nothing here...</div> : projects.map((o, i) => 
                        showProject(o)?<Project
                            key={tab + '_' + page + '_' + i}
                                project={o}
                                i={i}
                            deleteMe={() => {
                                projects.splice(i, 1);
                                setProjects(projects.slice());
                            }}
                        />:null)}
                    </div>}
                </TabContext.Provider>
            </div>
        </ForceRenderContext.Provider></QueryContext.Provider></FullScreenLoadingContext.Provider></ExpandedContext.Provider>
    </div>
}

function capitalise(str) {
    return str[0].toUpperCase() + str.substring(1, str.length);
}
function uncapitalise(str) {
    return str[0].toLowerCase() + str.substring(1, str.length);
}

function ConfirmUploadTab({ _id }) {
    let [thumb, setThumb] = useState();
    let [links, setLinks] = useState();
    let [failed, setFailed] = useState();
    let [project, setProject] = useState();
    let [showFullScreenLoading, setShowFullScreenLoading] = useContext(FullScreenLoadingContext);
    useEffect(() => {
        (async () => {
            let { data } = await axios.post('/api/ambigramproject', { p: p(), _id });

            let thumbs = data.videos.filter(v => v.dimensions[1] == 1920).map(v => v.thumb);
            if (thumbs.length == 0) thumbs = data.videos.map(v => v.thumb);
            setThumb(thumbs[0])

            let links = [];
            let failed = [];
            data.videos.forEach(v => {
                Object.keys(v.uploaded).forEach(key => {
                    if (v.uploaded[key]) {
                        if (v.uploaded[key]!==true) links.push({ key, url:v.uploaded[key] });
                    } else {
                        failed.push(key);
                    }
                })
            })
            setLinks(links);
            setFailed(failed);
            setProject(data);
        })()
    },[])
    return <div className="ConfirmUpload">
        {links&&failed?<div className="deets">
            <div className="thumbbit">
                <img alt="thumb" src={'/api/pics/'+thumb}/>
            </div>
            <div className="rightbit">
                {links.length > 0 ? <div className="list">
                    <h2>Successful uploads:</h2>
                    {links.map(({ key, url }, i) => <div key={i}>✔ <a target="_blank" rel="noopener" href={url}>{capitalise(key)}</a></div>)}
                </div> : null}
                {failed.length > 0 ? <>
                    <div className="list">
                        <h2>Failed uploads:</h2>
                        {failed.map((key, i) => <div key={i}>✘ {capitalise(key)}</div>)}
                    </div>
                    <h2><Q callback={async () => {
                        let words = [];
                        project.ambigrams.forEach(a => {
                            words.push(a.word0)
                            // if (a.word1) words.push(a.word1);
                        })
                        let { data } = await axios.post('/api/k/addupdate', {
                            p: p(),
                            command: 'Upload ambigram videos',
                            data: {
                                videos: project.videos,
                                words,
                                ambigrams: project.ambigrams.map(a =>
                                    a.fake ? ({ word0: a.word0, word1: a.word1, fake:true })
                                    : ({ word0: a.word0, word1: a.word1, commentURL: a.commentURL, username: a.username, fake: false })
                                ),
                                _id: project._id
                            }
                        })
                        setShowFullScreenLoading(true);
                    }}>Retry upload</Q></h2>
                </> : null}
            </div>
        </div>: null}
    </div>
}

function VideoThumb({project, big, setDragData}) {
    let [thumb, setThumb] = useState();
    let [info, setInfo] = useState([]);
    useEffect(() => {
        let thumbs = project.videos.filter(v => v.dimensions[1] == 1920).map(v => v.thumb);
        if (thumbs.length == 0) thumbs = project.videos.map(v => v.thumb);
        setThumb(thumbs[0])
        if (big) {
            setInfo(project.ambigrams.map(a=>a.word1?`${a.word0}/${a.word1}`:a.word0).map((n,i)=>i==project.ambigrams.length-1?n:n+', '))
        }
    }, [project])

    return <div className="VideoThumb">
        <img
            alt="thumbnail"
            src={'/api/pics/_' + thumb}
            onDragStart={!big ? null : e => {
                e.dataTransfer.setData('_id', project._id);
                setDragData({...project,thumb});
            }}
        />
        {big ? <div className="info">{info.map((i, j) => <span key={j}>{i}</span>)}</div>:null}
    </div>
}

function UploadTab({ }) {
    let [uploaded, setUploaded, getUploaded] = useGetState([]);
    let [notUploaded, setNotUploaded] = useState([]);
    let topBitRef = useRef();
    let [numberOfTopBitThumbsShown, setNumberOfTopBitThumbsShown] = useState(0);
    let [dotdotdotWidth, setDotDotDotWidth] = useState();
    let [dragData, setDragData] = useState();
    let [emptyThumb, setEmptyThumb] = useState();
    let [showFullScreenLoading, setShowFullScreenLoading] = useContext(FullScreenLoadingContext);

    useEffect(() => {
        (async () => {
            let { data } = await axios.post('/api/ambigramprojectsuploadtab', { p: p() });
            let { uploaded, notUploaded } = data;
            setUploaded(uploaded);
            setNotUploaded(notUploaded);
        })()
    }, [])
    useEffect(() => {
        function resize(e) {
            let topBitRect = topBitRef.current.getBoundingClientRect();
            let w = topBitRect.width;
            let notbts = Math.min(Math.floor((w-20) / 108),getUploaded().length);
            let dotdotdotWidth = w - notbts * 108;
            setDotDotDotWidth(dotdotdotWidth);
            setNumberOfTopBitThumbsShown(notbts); 
        }
        resize();
        window.addEventListener('resize', resize);
        return () => {
            window.removeEventListener('resize', resize);
        }
    },[uploaded])
    return <div className="UploadTab" onDragOver={e=>e.preventDefault()}>
        <div className="topBit" ref={topBitRef}> 
            <div className="videoThumbs">
                <div
                    className="emptyThumb"
                    onDragEnter={e => {
                        setEmptyThumb(dragData?.thumb)
                    }}
                    onDragLeave={e => {
                        setEmptyThumb();
                    }}
                    onDrop={async e => {
                        let data = e.dataTransfer.getData("_id");
                        if (data) {
                            setTimeout(async () => {
                                let { ambigrams, videos, _id } = dragData;
                                let words = [];
                                ambigrams.forEach(a => {
                                    words.push(a.word0)
                                    // if (a.word1) words.push(a.word1);
                                })
                                let { data } = await axios.post('/api/k/addupdate', {
                                    p: p(),
                                    command: 'Upload ambigram videos',
                                    data: {
                                        videos,
                                        words,
                                        ambigrams: ambigrams.map(a =>
                                            a.fake ? ({ word0: a.word0, word1: a.word1, fake: true })
                                            : ({ word0: a.word0, word1: a.word1, commentURL: a.commentURL, username: a.username, fake: false })
                                        ),
                                        _id
                                    }
                                })
                                setShowFullScreenLoading(true);
                            },500)
                        }
                    }}
                >{emptyThumb ? <img alt="drop!" src={'/api/pics/_' + emptyThumb} />:<div>+</div>}</div>
                {uploaded.map((p, i) => i < numberOfTopBitThumbsShown-1 ? <VideoThumb key={i} project={p} /> : null)}
                <div className="dotdotdot" style={{width:dotdotdotWidth}}>...</div>
            </div>
        </div>
        <div className="bottomBit">
            <div className="videoThumbs">
                {notUploaded.map((p, i) => <VideoThumb setDragData={setDragData} big={true} key={i} project={p} />)}
            </div>
        </div>
    </div>
}

function PopupComps({ }) {
    let [clicked, setClicked] = useState(false)
    let [comps, setComps] = useState();
    useEffect(() => {(async () => {
        if (clicked) {
            let { data } = await axios.post('/api/ambigramgetComps', { p: p(), n: clicked });
            setComps(data);
        }
    })()},[clicked])
    return <div className="popupComps">
        {!comps ? <>
            <div className="soyboy">How many comps?</div>
            <div className={`compbtns ${clicked?'faded clicked':''}`}>
                <div className="compbtn" onClick={()=>{setClicked(1)}}>1</div>
                <div className="compbtn" onClick={()=>{setClicked(2)}}>2</div>
                <div className="compbtn" onClick={()=>{setClicked(3)}}>3</div>
            </div>
        </> : <div>
            <div className="compList"><div className="lil" >A:</div>{comps.ambigramComps.map(a => <div className="ambigramComp" key={a}>{a}</div>)}</div>
            <div className="compList"><div className="lil" >C:</div>{comps.commentComps.map(a => <div className="ambigramComp" key={a}>{a}</div>)}</div>
        </div>}
    </div>
}

function PopupBG({ }) {
    let [colour, setColour] = useState();
    useEffect(() => {(async () => {
        let { data } = await axios.post('/api/ambigramgetbg', { p: p() });
        setColour(data);
    })()},[])
    return <div className="popupBG">
        {colour ? <div className={`swatch ${colour.name=='Yellow'?'yellow':''}`} style={{ backgroundColor: colour.colour }}>{colour.name}</div> :null}
    </div>
}

function PopupMusic({ }) {
    let [track, setTrack] = useState();
    let [clicked, setClicked] = useState();
    useEffect(() => {(async () => {
        let { data } = await axios.post('/api/ambigramgetmusic', { p: p() });
        setTrack(data);
    })()},[])
    return <div className="popupMusic">
        {track ? <A className={clicked?"faded":''} onClick={() => {
            setClicked(true);
            axios.post('/api/k/addupdate', {p: p(),command: 'Select file', data:'F:\\speedy ambigram\\music\\'+track})
        }}>{track}</A>:null}
    </div>
}

function elapsed(stamp) {
    let d = new Date().getTime();
    let e = d - stamp;
    let days = Math.round(e / (1000 * 60 * 60 * 24));
    return days == 0 ? 'today' : days == 1 ? 'yesterday' : days+' days ago'
}

function PopupSearchNames({ }) {
    let [query, setQuery] = useState('')
    let [search, setSearch] = useState();
    let [data, setData] = useState();
    let ref = useRef();
    useEffect(() => {
        ref.current.focus();
    },[])
    useEffect(() => {(async () => {
        if (query) {
            let { data } = await axios.post('/api/searchambigramname', { p: p(), query:search });
            setData(data);
        }
    })()},[search])
    return <div className="popupQuery">
        {!data ? <>
            <div className="queryLabel">Name</div>
            <input
                ref={ref}
                type="text"
                value={query}
                onChange={e => setQuery(e.target.value)}
                onKeyDown={e => { if (e.keyCode == 13) setSearch(query) }}
                disabled={search}
            />
        </> : <>
            {data.found==0?<div>
                Not found!
            </div>:<>
                <div>Found {data.found}</div>
                {data.uploaded?
                    <div>Uploaded {elapsed(data.uploaded)}</div> :
                    <div>Last edited {elapsed(data.edited)}</div>
                }
            </>}
        </>}

    </div>
}

function Tool({ children, popup }) {
    let [showPopup0, setShowPopup0] = useState(false);
    let [showPopup1, setShowPopup1] = useState(false);
    let timeout = useRef();
    useEffect(() => {
        if (showPopup0) {
            timeout.current = setTimeout(() => setShowPopup1(true), 200)
            function mousemove(e) {
                if (e.pageX > 16 * 16) {
                    if (popupRef.current) {
                        let box = popupRef.current.getBoundingClientRect();
                        if (e.pageY < box.top || e.pageY > box.bottom || e.pageX > box.right) setShowPopup0(false);
                    } else {
                        setShowPopup0(false);
                    }
                }
            }
            let click = e => {
                if (popupRef.current && !popupRef.current.contains(e.target)) setShowPopup0(false);
            }
            document.addEventListener('mousedown', click);
            document.addEventListener('mousemove', mousemove);
            return () => {
                clearTimeout(timeout.current);
                document.removeEventListener('mousedown', click);
                document.removeEventListener('mousemove', mousemove);
            }
        } else {
            setShowPopup1(false);
        }
    }, [showPopup0])
    let toolRef = useRef();
    let popupRef = useRef();
    return <div
        ref = {toolRef}
        className="tab tool"
        onClick={() => {
            let box = toolRef.current.getBoundingClientRect();
            if (!showPopup0) setShowPopup0({top:box.y+box.height/2})
        }}
    >
        {children}
        {showPopup0?<div className="Popup" style={{top:showPopup0.top}}><div className="popup" ref={popupRef}>{showPopup1?popup:null}</div></div>:null}
    </div>
}

function Sidebar({ tab, setTab, tabs, add, id, counts }) {
    let [expanded] = useContext(ExpandedContext);
    return <div className="sidebar">
        <div className={`ambigramTabs`}>
            {tabs.map(t => <div className={`tab ${expanded?'faded':''} ${tab == t ? 'selected' : ''} `} key={t} onClick={expanded ? null : () => setTab(t)}>{t}<span>({counts[t]})</span></div> )}
        </div>

        <div className="tools">
            <Tool popup={<PopupSearchNames />}>Search</Tool>
            {id == 'Desktop' ? <>
                <Tool popup={<PopupBG />}>Get bg colour</Tool>
                <Tool popup={<PopupComps />}>Get comps</Tool>
                <Tool popup={<PopupMusic />}>Get music</Tool>
                <div className="tab" onClick={async () => {
                    await axios.post('/api/k/addupdate', { p: p(), command: 'Open folder', data: 'F:\\speedy ambigram' })
                }}>Open folder</div>
            </> : null}
        </div>

        <div>
            <div className={`tab ${expanded?'faded':''} ${tab == 'Upload' ? 'selected' : ''}`} onClick={expanded ? null : () => setTab('Upload')}>Upload</div>
        </div>
        
        <div className="addButton" onClick={add}>+</div>
    </div>
}

function Project({ project, deleteMe,i }) {
    let [expand, setExpand] = useState(false);
    useEffect(() => {
        let uploaded = {};
        project.videos.forEach(v => {
            Object.keys(v.uploaded).forEach(k => uploaded[k] = true);
        })
    }, [])
    
    let [expanded, setExpanded] = useContext(ExpandedContext);

    useEffect(() => {
        if (expand) {
            setExpanded(true);
            return () => setExpanded(false);
        }
    },[expand])

    return <>
        {expand ? <ExpandedProject project={project} deleteMe={deleteMe} close={() => setExpand(false)}/> : null}
        <div className={`project ${i==0?'first':''}`}  onClick={() => { setExpand(true) }}>
            <div className="title">
                {/* {project.ambigrams.map(a=>a.word1?a.word0+'+'+a.word1:a.word0).join(', ')} */}
                {project.ambigrams.map((a, i) => <div key={`${a.word0}/${i}`}>
                    {a.word1 ? <div className="titleSplit" ><div>{a.word0}</div><div>{a.word1}</div></div> : <div className="tit">{a.word0}</div>}
                    {!a.ambigram ? null : <div key={a.ambigram}><img alt="ambigram" src={'/api/pics/_' + a.ambigram}/></div>}
                </div>)}
            </div>
            {project.complete?<div>
                Uploaded: {toDateString(project.complete)}
            </div>:null}
        </div>
    </>
}

function blobToDataURL(blob) {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onerror = reject;
        reader.onload = e => resolve(reader.result);
        reader.readAsDataURL(blob);
    })
}



function P5Paint({ dataURL, crop, setImg = () => { } }) {
    let [reset, setReset, getReset] = useGetState(false);
    let [brush, setBrush, getBrush] = useGetState(30);
    let sketch = useCallback(p => {
        let W = crop[2];
        let H = crop[3];
        let img;
        let x0, x1, y0, y1;
        let drawing = false;
        let canvas;
        let ratio = 1;
        p.preload = () => {
            img = p.loadImage(dataURL);
            canvas = p.createGraphics(W, H);
        }
        p.setup = () => {
            p.createCanvas(W, H);
            canvas.image(img, -crop[0], -crop[1]);

            let WW = Math.min(590, W);
            ratio = WW == W ? 1 : (590 / W);
            let HH = WW == W ? H : H * ratio;
            document.getElementById("defaultCanvas0").style.width = WW+'px';
            document.getElementById("defaultCanvas0").style.height = HH + 'px';
        }
        p.mousePressed = () => {
            if (x1 >= 0 && x1 < W && y1 >= 0 && y1 < H) {
                drawing = true;
                let f = () => {
                    drawing = false;
                    document.removeEventListener('mouseup', f);
                }
                document.addEventListener('mouseup', f);
            }
        }
        p.draw = () => {
            x1 = p.mouseX;
            y1 = p.mouseY;
            if (getReset()) {
                canvas.image(img, -crop[0], -crop[1]);
                setReset(false);
            }
            let brush = getBrush();
            if (drawing) {
                canvas.stroke(255);
                canvas.strokeWeight(brush/ratio);
                canvas.line(x0, y0, x1, y1);
            }
            p.image(canvas, 0, 0);

            p.fill(255);
            p.stroke(255,0,0);
            p.strokeWeight(1/ratio);
            p.ellipse(x1, y1, brush / ratio, brush / ratio);
            
            x0 = x1;
            y0 = y1;
        }
    }, [])
    async function save() {
        let canvas = document.getElementById("defaultCanvas0").toDataURL('image/jpeg');
        setImg(canvas);
    }
    let brushSizes = [5, 10, 20, 30, 40];
    return <div className="P5Paint">
        <div className="brushes">
            {brushSizes.map(b => <div className="brush" key={b} onClick={() => setBrush(b)}><div className={b==brush?'selected':''} style={{width:b,height:b,borderRadius:b}}/></div>)}
        </div>
        <div className="canvasContainer">
            <ReactP5Wrapper sketch={sketch}/>
        </div>
        <div className="buttons">
            <A onClick={()=>setReset(true)}>Reset</A>
            <A onClick={save}>Save</A>
        </div>
    </div>

}

let ScreenshotContext = createContext();
let ShrinkContext = createContext();

function PasteButton({next}) {
    let [clipboard, setClipboard] = useState(false);
    let [screenshot, setScreenshot] = useState(false);

    useEffect(() => {
        if (clipboard) {(async () => {
            if (clipboard[0].types[0] == 'image/png') {
                let blob = await clipboard[0].getType('image/png');
                let dataURL = await blobToDataURL(blob);
                setScreenshot(dataURL);
            }
        })()} else {
        }
    }, [clipboard])

    if (screenshot) {
        return <ScreenshotContext.Provider
            value={{
                screenshot,
                close: () => {
                    setClipboard(false);
                    setScreenshot(false);
                }
            }}
        >
            {next}
        </ScreenshotContext.Provider>
    }

    return <div className="pasteButton" onClick={async () => setClipboard(await navigator.clipboard.read())}>Paste</div>
}

function CommentPaster({ finish }) {
    let {close, screenshot} = useContext(ScreenshotContext);
    let [screenDimensions, setScreenDimensions] = useState([0, 0]);
    let [dimensions, setDimensions] = useState([0, 0]);
    let [screenPos, setScreenPos] = useState([0, 0]);
    let [dragging, setDragging] = useState(false);
    let [p0, setP0] = useState(false);
    let [p1, setP1] = useState(false);
    let [rect, setRect] = useState(false);
    let [crop, setCrop] = useState(false);
    let [img, setImg] = useState();
    let { setShrink } = useContext(ShrinkContext);

    useEffect(() => {
        setShrink(true);
        setTimeout(() => {
            let rect = screenshotRef.current.getBoundingClientRect();
            setScreenDimensions([rect.width, rect.height]);
            setScreenPos([rect.left, rect.top]);
            let img = new Image();
            img.src = screenshot;
            img.onload = () => setDimensions([img.width, img.height]);
        },1)
        return () => setShrink(false);
    }, [])
    
    useEffect(() => {
        if (dragging) {
            let mouseup = () => {
                setDragging(false);
            }
            let mousemove = e => {
                setP1([e.pageX - screenPos[0], e.pageY - screenPos[1]]);
            }
            document.addEventListener('mouseup', mouseup);
            document.addEventListener('mousemove', mousemove);
            return () => {
                document.removeEventListener('mousemove', mousemove);
                document.removeEventListener('mouseup', mouseup);
            }
        }
    }, [dragging])

    useEffect(() => {
        if (p1) {
            let x = 0, y = 0, w = 0, h = 0;
            if (p0[0] < p1[0]) {
                x = p0[0];
                w = p1[0] - p0[0];
            } else {
                x = p1[0];
                w = p0[0] - p1[0]; 
            }
            if (p0[1] < p1[1]) {
                y = p0[1];
                h = p1[1] - p0[1];
            } else {
                y = p1[1];
                h = p0[1] - p1[1]; 
            }
            setRect([x, y, w, h]);
        }
    }, [p1])

    let [shrinkPic, setShrinkPic] = useState(false);

    useEffect(() => {
        if (img) {
            (async () => {
                setTimeout(() => setShrinkPic(true),10)
                setTimeout(() => finish(img), 410);
            })()
        }
    }, [img])
    
    function selectCrop() {
        let ratio = dimensions[0] / screenDimensions[0];
        setCrop(rect.map(r => r * ratio));
    }

    let screenshotRef = useRef();
    let heading = useContext(HeadingContext);
    return <div className="CommentPaster unshrinkable">
        <div className="expanded nofade">
            <div className="window">
                <div className="modalHeading">
                    <div className="heading">{heading}</div>
                    <button onClick={close}>╳</button>
                </div>
            {!crop?<div>
                <div className="screenshot">
                    <img src={screenshot} alt="screenshot" ref={screenshotRef} />
                    <div
                        className="dragger"
                        style={{ width: screenDimensions[0], height: screenDimensions[1] }}
                        onMouseDown={e => {
                            setP0([e.clientX - screenPos[0], e.clientY - screenPos[1]]);
                            setDragging(true);
                        }}
                    >
                        {rect ? <>
                            <div className="rect">
                                <div style={{right:screenDimensions[0]-rect[0]}} /> 
                                <div style={{left:rect[0]+rect[2]}} /> 
                                <div style={{bottom:screenDimensions[1]-rect[1]}} /> 
                                <div style={{top:rect[1]+rect[3]}} /> 
                            </div>
                            <div className="border" style={{
                                left: rect[0],
                                top: rect[1],
                                width: rect[2],
                                height: rect[3]
                            }}/>
                        </> : null}
                        </div>
                    </div>
                    <div className="buttons">
                        <A
                            disabled={!(rect && rect[2] > 20 && rect[3] > 20)}
                            onClick={selectCrop}
                        >Select crop</A>
                    </div>
                </div>: !img ? <div>
                    <P5Paint dataURL={screenshot} crop={crop} setImg={setImg} />
                </div> : <div className="img">
                    <img className={shrinkPic?'shrinky':''}src={img} alt="img"/>
                </div>}
            </div>
        </div>
    </div>
}

function AmbigramPaster({ finish }) {
    let { setShrink } = useContext(ShrinkContext);
    let { close, screenshot } = useContext(ScreenshotContext);
    let [img, setImg] = useState();
    let [shrinky, setShrinky] = useState(false);
    let [dimensions, setDimensions, getDimensions] = useGetState();
    let [painted, setPainted] = useState(false);
    useEffect(() => {
        setShrink(true);
        let img = new Image();
        img.src = screenshot;
        img.onload = () => setDimensions([img.width, img.height]);
        return () => setShrink(false);
    }, [])
    useEffect(() => {
        if (img) {
            (async () => {
                setTimeout(() => setShrinky(true),10)
                setTimeout(() => finish(img), 410);
            })()
        }
    }, [img])
    useEffect(() => {
        if (painted) {
            let canvas = document.getElementById("defaultCanvas0").toDataURL('image/jpeg');
            setImg(canvas);
        }
    },[painted])
    let sketch = useCallback(p => {
        let img;
        p.preload = () => {
            img = p.loadImage(screenshot);
        }
        p.setup = () => {
            let dimensions = getDimensions();
            let W = dimensions[0], H = dimensions[1];
            p.createCanvas(W, H);
            p.image(img, 0, 0);

            let WW = Math.min(590, W);
            let ratio = WW == W ? 1 : (590 / W);
            let HH = WW == W ? H : H * ratio;
            document.getElementById("defaultCanvas0").style.width = WW+'px';
            document.getElementById("defaultCanvas0").style.height = HH + 'px';
        }
        p.draw = () => {
            setPainted(true);
        }
    }, [])
    let heading = useContext(HeadingContext);

    return <div className="AmbigramPaster unshrinkable">
        <div className="expanded nofade">
            <div className="window">
                <div className="modalHeading">
                    <div className="heading">{heading}</div>
                    <button onClick={close}>╳</button>
                </div>
                <div className="img">
                    {img?<img className={shrinky?'shrinky':''} src={screenshot} alt="ambigram" />:dimensions?<ReactP5Wrapper sketch={sketch}/>:null}
                </div>
            </div>
        </div>
    </div>
}

function ForgeComment({ username, comment, time, likes, pfp, finish }) {
    let [img, setImg] = useState();
    let [shrinky, setShrinky] = useState(false);

    useEffect(() => {
        if (img) {
            (async () => {
                setTimeout(() => setShrinky(true),10)
                setTimeout(() => finish(img), 410);
            })()
        }
    },[img])

    let sketch = useCallback(p => {
        let proxima, sofia, img, mask, heart,reply;
        let W = 1200, H = 1200;
        let pad = 20;
        let canvas, ctx;
        p.preload = () => {
            proxima = p.loadFont('https://kaisboatfund.co.uk/api/pictures/Proxima-Nova-Regular.ttf');
            sofia = p.loadFont('https://kaisboatfund.co.uk/api/pictures/sofiapro-bold.ttf');
            img = p.loadImage(pfp);
            mask = p.loadImage('https://kaisboatfund.co.uk/api/pictures/pfpmask.png');
            heart = p.loadImage('https://kaisboatfund.co.uk/api/pictures/tiktokhreart.png');
            reply = p.loadImage('https://kaisboatfund.co.uk/api/pictures/tiktokreply.png');
        }

        function fillText(string,x,y) {
            ctx.fillText(string, x, y);
        }

        function textHeight(text, w,x,y,leading) {
            let words = text.split(' ');
            let line = '';
            let h = 0;
            for (let i = 0; i < words.length; i++) {
                let testLine = line + words[i] + ' ';
                let testWidth = p.drawingContext.measureText(testLine).width;
                if (testWidth > w && i > 0) {
                    if (x) fillText(line,x,y+h*leading)
                    line = words[i] + ' ';
                    h ++;
                } else {
                    line = testLine;
                }
            }
            if (line.trim().length > 0) {
                if (x) fillText(line,x,y+h*leading)
                h ++;
            }
            return h;
        }

        p.setup = () => {
            p.textFont(proxima);
            p.textSize(16 * 4);
            p.textLeading(22 * 4);
            let lines = textHeight(comment, W - 208);
            let y = lines * 88 + 186;
            H = y - 48 + heart.height + pad*2;

            p.createCanvas(W + pad * 2, H);
            
            canvas = document.getElementById("defaultCanvas0");
            ctx = canvas.getContext("2d");

            p.background(255);
            p.image(img, 0+pad, 0+pad);
            p.image(mask, 0+pad, 0+pad);
            p.textFont(sofia);
            p.textSize(18 * 4);

            ctx.font = `${18*4}px "sofia pro"`;

            p.fill(22,24,35);
            fillText(username, 208+pad, 79+pad)
            p.textFont(proxima);
            p.textSize(16 * 4);
            p.textLeading(22 * 4);

            ctx.font = `${16*4}px "proxima nova"`;

            textHeight(comment, W - 208, 208+pad, 168+pad, 88);
            p.textSize(14 * 4);

            ctx.font = `${14*4}px "proxima nova"`;
            
            p.image(heart, 496+pad, y - 48+pad);
            p.image(reply, 720+pad, y - 38+pad);
            fillText(likes,587+pad,y+pad)
            p.fill(138, 149, 145)
            fillText(time + ' ago', 208 + pad, y + pad);

            W = W + pad * 2;
            let WW = 598;
            let HH = H / W * WW;

            canvas.style.width = WW+'px';
            canvas.style.height = HH + 'px';
            setImg(canvas.toDataURL('image/jpeg'))
        }
    }, [])
    return <div className="ForgeComment">{img ? <img className={shrinky?'shrinky':''} alt="comment" src={img} /> : <ReactP5Wrapper sketch={sketch} />}</div>
}

function TextArea({ value, onChange, className }) {
    let ref = useRef();
    let [height, setHeight] = useState(16);
    useEffect(() => {
        let rect = ref.current.getBoundingClientRect();
        setHeight(rect.height);
    },[value])
    return <>
        <div className="sneaky">
            <div ref={ref} className="textarea">{value}</div>
        </div>
        <textarea className={className} value={value} onChange={onChange} style={{ height }} />
    </>
}

function FakeWindow({close, finish}) {
    let [username, setUsername] = useState('');
    let [pfp, setPfp] = useState();
    let [comment, setComment] = useState("My name is impossible...");
    let [time, setTime] = useState('')
    let [likes, setLikes] = useState(0);
    // let [scale, setScale] = useState(4);
    let [click, setClick] = useState(0);

    let [forge, setForge] = useState(false);
    let { setShrink } = useContext(ShrinkContext);

    useEffect(() => {
        setShrink(true);
        let likes = 0;
        for (let i = 0; i < 6; i++) {
            if (Math.random() < 0.75) break;
            likes++;
        }
        let timeFormats = [
            {t:'s',min:1,max:59,},
            {t:'m',min:1,max:59,},
            {t:'h',min:1,max:23,},
            {t:'d',min:1,max:3,},
        ]
        let time = timeFormats[Math.floor(Math.random() * timeFormats.length)];
        time = Math.floor(Math.random() * (time.max - time.min) + time.min) + time.t;
        setTime(time);
        setLikes(likes);
        (async () => {
            let { data } = await axios.post('/api/generatetiktokusername', { p: p() });
            setUsername(data);
        })()
        return () => {
            setShrink(false);
        }
    }, [])

    useEffect(() => {
        if (!pfp) {
            (async () => {
                let { data } = await axios.post('/api/randomProfilePicture', { p: p() });
                setPfp(data);
                // setForge(true);
            })()
        }
    }, [pfp])

    return <div className="FakeWindow unshrinkable">
        <div className="expanded nofade">
            <div className="window">
                <div className="modalHeading">
                    <div className="heading">Forge</div>
                    <button onClick={close}>╳</button>
                </div>
                <div className="fakeWindow">
                    {forge ? <ForgeComment
                        username={username}
                        comment={comment}
                        time={time}
                        likes={likes}
                        pfp={pfp}
                        finish={finish}
                    /> : <>
                        <div className="FAKE">
                            <div className="pfp">{pfp?<img src={pfp} alt="pfp" onClick={()=>setPfp()}/>:<div className="nopfp"/>}</div>
                            
                            <div className="fakeBody">
                                <div className="fakeUsername" onClick={async ()=>{
                                    let stamp = new Date().getTime();
                                    setClick(stamp);
                                    if (stamp - click < 333) {
                                        let { data } = await axios.post('/api/generatetiktokusername', { p: p() });
                                        setUsername(data);
                                    }
                                }}>
                                    <input type="text" value={username} onChange={e => setUsername(e.target.value)} />
                                </div>
                                <div className="fakeComment"><TextArea value={comment} onChange={e=>setComment(e.target.value)} /></div>
                                <div className="fakeFooter">
                                    <div className="fakeTime">{time} ago</div>
                                    <div className="fakeHeart">
                                        <svg width="20" height="20" viewBox="0 0 48 48"><path d="M24 9.01703C19.0025 3.74266 11.4674 3.736 6.67302 8.56049C1.77566 13.4886 1.77566 21.4735 6.67302 26.4016L22.5814 42.4098C22.9568 42.7876 23.4674 43 24 43C24.5326 43 25.0432 42.7876 25.4186 42.4098L41.327 26.4016C46.2243 21.4735 46.2243 13.4886 41.327 8.56049C36.5326 3.736 28.9975 3.74266 24 9.01703ZM21.4938 12.2118C17.9849 8.07195 12.7825 8.08727 9.51028 11.3801C6.16324 14.7481 6.16324 20.214 9.51028 23.582L24 38.1627L38.4897 23.582C41.8368 20.214 41.8368 14.7481 38.4897 11.3801C35.2175 8.08727 30.0151 8.07195 26.5062 12.2118L26.455 12.2722L25.4186 13.3151C25.0432 13.6929 24.5326 13.9053 24 13.9053C23.4674 13.9053 22.9568 13.6929 22.5814 13.3151L21.545 12.2722L21.4938 12.2118Z" /></svg>
                                        <div>{likes}</div>
                                    </div>
                                    <div className="fakeReply">Reply</div>
                                </div>
                            </div>
                        </div>
                        <A onClick={()=>setForge(true)}>Forge</A>
                    </>}

                </div>
            </div>
        </div>
    </div>
}

function SpinPic({ src }) {
    let [rot, setRot] = useState(0);
    return <img className="spinpic" src={src} alt="spinny" onClick={() => setRot(rot + 180)} style={{transform:`rotate(${rot}deg)`}} />
}

function AmbigramItem({ _id,ambigram: Ambigram, setAmbigram:setAmbigram1, i, ambigramsLength, deleteme }) {
    let [word0, setWord0] = useState(Ambigram.word0);
    let [word1, setWord1] = useState(Ambigram.word1);
    let [commentURL, setCommentURL] = useState(Ambigram.commentURL);
    let [username, setUsername] = useState(Ambigram.username);
    let [comment, setComment] = useState(Ambigram.comment);
    let [commentDataURL, setCommentDataURL] = useState();
    let [ambigram, setAmbigram] = useState(Ambigram.ambigram);
    let [fake, setFake] = useState(Ambigram.fake);
    let [ambigramURL, setAmbigramURL] = useState();
    let [fakeWindow, setFakeWindow] = useState(false);
    let [already, setAlready] = useState(false);

    useChange(() => {
        if (word1 ||
            word0 ||
            commentURL ||
            username ||
            comment ||
            ambigram ||
            ambigramURL ||
            commentDataURL)
                setAmbigram1({
                    word1,
                    word0,
                    commentURL,
                    username,
                    comment, ambigram,
                    ambigramURL,
                    commentDataURL,
                    fake
                });
    }, [word1, word0, commentURL, username, comment, ambigram, ambigramURL, commentDataURL, fake])
    
    let timeout = useRef();

    useChange(() => {
        setAlready(false);
        clearTimeout(timeout.current);
        if (!word1) {
            timeout.current = setTimeout(async () => {
                let { data } = await axios.post('/api/searchambigramname', { p: p(), query:word0.trim(), ignore:_id });
                if (data.found) {
                    if (data.uploaded) {
                        setAlready(`Found ${data.found} uploaded ${elapsed(data.uploaded)}`);
                    } else {
                        setAlready(`Found ${data.found} edited ${elapsed(data.edited)}`);
                    }
                } else {
                    setAlready(false);
                }
            },1000)
        } else {
            setAlready(false);
        }
    },[word0, word1])

    useEffect(() => {
        if (username && username.indexOf(' ') < 0 && username.indexOf('@') > 0 && username.substring(0, 23) == 'https://www.tiktok.com/') {
            let u = username.substring(23, username.length);
            let i = u.indexOf('?');
            if (i >= 0) u = u.substring(0, i);
            setUsername(u);
        }
    }, [username])
    
    let {password, id} = useContext(QueryContext);
    
    return <div className="ambigram">
        <div className="itemSplitSplit">
            <div className="leftleft">{ambigramsLength > 1 ? <div className="number">{i}.</div> : null}</div>
            <div className="rightright">
                <div className="itemSplit">
                    <div className="left">Word 0</div>
                    <div className="right word0">
                        <input type="text" value={word0} onChange={e => setWord0(e.target.value)} />
                        {already ? <div>{already}</div>:null}
                    </div>
                </div>
                <div className="itemSplit">
                    <div className="left">Word 1</div>
                    <div className="right"><input type="text" value={word1} onChange={e => setWord1(e.target.value)} /></div>
                </div>
                {fake?null:<div className="itemSplit">
                    <div className="left">Username</div>
                    <div className="right"><input type="text" value={username} onChange={e => setUsername(e.target.value)} /></div>
                </div>}
                {fake?null:<div className="itemSplit">
                    <div className="left">Comment URL</div>
                    <div className="right"><input type="text" value={commentURL} onChange={e => setCommentURL(e.target.value)} /></div>
                </div>}
                <div className="itemSplit">
                    <div className="left">Comment</div>
                    <div className="right commentRight">
                        {comment || commentDataURL ? <div className="preview">
                            <img src={commentDataURL||'/api/pics/' + comment} alt="comment" />
                            <Q callback={() => {
                                setComment();
                                setCommentDataURL();
                                setFake(false);
                            }}>Remove</Q>
                        </div> : <>
                            <PasteButton next={<CommentPaster finish={c => {
                                setComment();
                                setCommentDataURL(c);
                            }} />} />
                            <div className="fakeButton" onClick={()=>setFakeWindow(true)}>Forge</div>
                            {fakeWindow?<FakeWindow 
                                    close={() => setFakeWindow(false)}
                                    finish={c => {
                                        setFakeWindow(false);
                                        setComment();
                                        setCommentURL('');
                                        setUsername('');
                                        setFake(true);
                                        setCommentDataURL(c);
                                    }}
                            />:null}
                        </>}
                    </div>
                </div>
                <div className="itemSplit">
                    <div className="left">Ambigram</div>
                    <div className="right">
                        {ambigram || ambigramURL ? <div className="preview">
                            <SpinPic src={ambigramURL||'/api/pics/' + ambigram}/>
                            <Q callback={() => {
                                setAmbigram();
                                setAmbigramURL();
                            }}>Remove</Q>
                        </div> : <PasteButton next={<AmbigramPaster finish={c => {
                            setAmbigramURL(c);
                            setAmbigram()
                        }} />} />}
                    </div>
                </div>
                {ambigramsLength > 1 ? <div className="removeAmbigram"><Q callback={deleteme}>Remove ambigram</Q></div> : null}
            </div>
        </div>
    </div>
}

function getVideoDetails(dataURL, seekTo = 0.5) {
    return new Promise((resolve, reject) => {
        let videoPlayer = document.createElement('video');
        videoPlayer.setAttribute('src', dataURL);
        videoPlayer.load();
        videoPlayer.addEventListener('error', (ex) => {
            reject("error when loading video file", ex);
        });
        videoPlayer.addEventListener('loadedmetadata', () => {
            videoPlayer.currentTime = videoPlayer.duration * seekTo;
            videoPlayer.addEventListener('seeked', () => {
                let canvas = document.createElement("canvas");
                canvas.width = videoPlayer.videoWidth;
                canvas.height = videoPlayer.videoHeight;
                let ctx = canvas.getContext("2d");
                ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
                resolve({
                    thumbURL: ctx.canvas.toDataURL("image/jpeg"),
                    duration: videoPlayer.duration,
                    dimensions: [canvas.width,canvas.height]
                });
            });
        });
    });
}

function Seeker({ value, setValue }) {
    let [left, setLeft] = useState(value*600);
    let [drag, setDrag] = useState(false);

    let ref = useRef();
    return <div
        className="Seeker"
        ref={ref}
        onMouseDown={() => {
            setDrag(true);
            let rect = ref.current.getBoundingClientRect();
            let x = rect.left;
            let w = rect.width;
            let mouseup = () => {
                document.removeEventListener('mouseup', mouseup);
                document.removeEventListener('mousemove', mousemove);
                setDrag(false);
            }
            let mousemove = e => {
                let pageX = e.pageX;
                let left = (pageX - x) / w;
                left = left < 0 ? 0 : left > 1 ? 1 : left;
                setLeft(left * w);
                setValue(left);
            }
            document.addEventListener('mouseup', mouseup);
            document.addEventListener('mousemove', mousemove);
        }}
    >
        <div className="bobbin" style={{left:left-4}} /> 
    </div>
}

function SetThumbnail({ video, close }) {
    let { setShrink } = useContext(ShrinkContext);
    let videoRef = useRef();
    let canvasRef = useRef();
    let [seek, setSeek] = useState(video.thumbPos);
    let [loading, setLoading] = useState(true)
    let [thumb, setThumb] = useState();
    let [seeking, setSeeking] = useState(false);
    useEffect(() => {
        setShrink(true);
        canvasRef.current = document.createElement("canvas");
        videoRef.current = document.createElement('video');
        videoRef.current.setAttribute('src', video.dataURL||'/api/pics/'+video.path);
        videoRef.current.load();
        videoRef.current.addEventListener('loadedmetadata', () => {
            canvasRef.current.width = video.dimensions[0];
            canvasRef.current.height =video.dimensions[1];
            videoRef.current.addEventListener('seeked', () => {
                setSeeking(true);
                let ctx = canvasRef.current.getContext("2d");
                ctx.drawImage(videoRef.current, 0, 0, video.dimensions[0], video.dimensions[1]);
                setSeeking(false);
                setThumb(ctx.canvas.toDataURL("image/jpeg"));
            });
            setLoading(false);
        });
        return () => setShrink(false);
    }, [])
    useEffect(() => {
        videoRef.current.currentTime = seek * video.duration;
    },[seek])
    let heading = useContext(HeadingContext);
    return <div className="SetThumbnail unshrinkable">
        <div className="expanded nofade">
            <div className="window">
                <div className="modalHeading">
                    <div className="heading">{heading}</div>
                    <button onClick={()=>close(false)}>╳</button>
                </div>
                <div className="setThumbnail">
                    <div className="thumbPreview">
                        {!loading ? <img className={seeking?'seeking':''} src={thumb} alt={thumb} /> : <i>Loading...</i>}
                    </div>
                    {!loading ? <>
                        <Seeker value={seek} setValue={setSeek} />
                        <div className="buts">
                            <A onClick={() => {
                                video.thumbPos = seek;
                                video.thumbURL = thumb;
                                close(true);
                            }}>Save</A>
                        </div>
                    </> : null}
                </div>
            </div>
        </div>
    </div>
}

function UploadVideo({ video, update, updateVideos, ambigrams, _id, close }) {
    let { setShrink } = useContext(ShrinkContext);
    let [platform, setPlatform] = useState(false);
    let [clipboard, setClipboard] = useState(false);

    let [text, setText] = useState(false);

    let { forceRender } = useContext(ForceRenderContext);

    async function clip() {
        let clipboard = await navigator.clipboard.readText();
        setClipboard(clipboard)
    }

    useEffect(() => {
        setShrink(true);
        let interval = setInterval(clip,555)
        return () => {
            setShrink(false);
            clearInterval(interval);
        }
    }, [])

    useEffect(() => {
        if (platform == 'youTube') setText(generateYouTubeShortsTitleAndDescription(ambigrams.map(a=>a.word0)));
        if (platform == 'tikTok') setText(generateTikTokCaption(ambigrams.map(a=>a.word0)));
    }, [platform])
    
    async function download() {
        let response = await axios({
            url: '/api/pics/'+video.path,
            method: 'GET',
            responseType: 'blob'
        })
        let url = window.URL.createObjectURL(new Blob([response.data]));
        let link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${ambigrams.map(a=>a.word0).join(', ')} ambigrams.mp4`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    
    return <div className="UploadVideo unshrinkable">
        <div className="expanded nofade">
            <div className="window">
                <div className="modalHeading">
                    <div className="heading">Upload</div>
                    <button onClick={()=>close(false)}>╳</button>
                </div>
                <div className="uploadVideo">
                    {platform ? <div className="UploadForm">
                        <div className="itemSplit">
                            <div className="left">URL</div>
                            <div className="right"><A onClick={async () => {
                                await navigator.clipboard.writeText('https://kaisboatfund.co.uk/api/pics/'+video.path);
                                await clip();
                            }}><div className={`texts ${clipboard=='https://kaisboatfund.co.uk/api/pics/'+video.path?'clipped':''}`}>{'kaisboatfund.co.uk/api/pics/'+video.path}</div></A></div>
                        </div>
                        {platform == "youTube" ? <>
                            <div className="itemSplit">
                                <div className="left">Title</div>
                                <div className="right"><A onClick={async () => {
                                    await navigator.clipboard.writeText(text[0]);
                                    await clip();
                                }}><div className={`texts ${clipboard==text[0]?'clipped':''}`}>{text[0]}</div></A></div>
                            </div>
                            <div className="itemSplit">
                                <div className="left">Description</div>
                                <div className="right"><A onClick={async () => {
                                    await navigator.clipboard.writeText(text[1]);
                                    await clip();
                                }}><div className={`texts ${clipboard==text[1]?'clipped':''}`}>{text[1]}</div></A></div>
                            </div>
                        </> : <>
                            <div className="itemSplit">
                                <div className="left">Description</div>
                                <div className="right"><A onClick={async () => {
                                    await navigator.clipboard.writeText(text);
                                    await clip();
                                }}><div className={`texts ${clipboard == text ? 'clipped' : ''}`}>{text}</div></A></div>
                            </div>
                        </>}
                        <div className="buts">
                            <A onClick={download}>Download</A>
                            <Q callback={async () => {
                                await axios.post('/api/ambigramvideouploaded', { p: p(), _id, video: video, website: platform, url: true });
                                video.uploaded[platform] = true;
                                update();
                                updateVideos();
                                forceRender();
                                setPlatform(false);
                            }}>Uploaded</Q>
                            <A onClick={()=>setPlatform(false)}>Cancel</A>
                        </div>
                    </div> : <div className="YouTubeOrTikTok">
                        {video.uploaded['youTube'] ? <div className="queryBtn"><Q callback={async () => {
                            await axios.post('/api/ambigramvideouploaded', { p: p(), _id, video: video, website: 'youTube', url: false });
                            video.uploaded['youTube'] = false;
                            update();
                            updateVideos();
                            forceRender();
                            close();
                        }}>Unupload YouTube</Q></div>:<A onClick={()=>setPlatform("youTube")}><div className="platformBtn">YouTube</div></A>}
                        {video.uploaded['tikTok'] ? <div className="queryBtn"><Q callback={async () => {
                            await axios.post('/api/ambigramvideouploaded', { p: p(), _id, video: video, website: 'tikTok', url: false });
                            video.uploaded['tikTok'] = false;
                            update();
                            updateVideos();
                            forceRender();
                            close();
                        }}>Unupload TikTok</Q></div>:<A onClick={()=>setPlatform("tikTok")}><div className="platformBtn">TikTok</div></A>}
                    </div>}
                </div>
            </div>
        </div>
    </div>
}

function VideoThumbnail({ video, ambigrams, _id, update, updateVideos, deleteMe }) {
    let [height, setHeight] = useState(0);
    let [loaded, setLoaded] = useState(false);
    let [setThumbnail, setSetThumbnail] = useState(false);
    let [upload, setUpload] = useState(false);
    let [links, setLinks] = useState();
    useEffect(() => {
        if (loaded) setHeight(ref.current.getBoundingClientRect().height)
    }, [loaded])
    function updateLinks() {
        let links = [];
        Object.keys(video.uploaded).forEach(key => {
            if (video.uploaded[key]) links.push({ key, url:video.uploaded[key]!==true?video.uploaded[key]:null })
        })
        setLinks(links);
    }
    useEffect(() => {
        updateLinks();
    },[])
    let ref = useRef();
    if (setThumbnail) return <SetThumbnail video={video} close={u => {
        setSetThumbnail(false);
        if (u) update();
    }} />
    if (upload) return <UploadVideo video={video} update={async () => {
        await update()
        updateLinks();
    }} ambigrams={ambigrams} _id={_id} close={u => {
        setUpload(false);
        if (u) update();
    }} updateVideos={updateVideos}/>
    return <div className="videoThumb" onClick={(e) => {
        if (_id) setUpload(true);
        // setSetThumbnail(true)
    }}>
        {loaded ? <div className="innerThumb" style={{ height }}>
            <div className="thumbUploaded">
                {links.map(({ key, url }, i) => <div key={i}>{url ? <a
                    className="A"
                    target="_blank"
                    rel="noopener"
                    href={url}
                    onClick={e => e.stopPropagation()}
                >
                    ✔ {capitalise(key)}
                </a> : <div className="a" key={i} onClick={e => e.stopPropagation()}>✔ {capitalise(key)}</div>}</div>)}
            </div>
            <div className="thumbButtons">
                <div><Q callback={deleteMe}>Remove</Q></div>
                {/* <div><A onClick={e => {
                    e.stopPropagation();
                    setUpload(true);
                }}>Upload</A></div> */}
            </div>
        </div>:null}
        <img src={video.thumbURL||'/api/pics/'+video.thumb} alt="thumb" ref={ref} onLoad={()=>setLoaded(true)}/>
    </div>
}

function Videos({ videos, setVideos, getVideos, updateVideos, _id, ambigrams }) {
    let [hover, setHover] = useState(false);
    let ref = useRef();
    let [loading, setLoading] = useState(false);
    let [dimensions, setDimensions] = useState([0, 0]);
    useEffect(() => {
        let dragenter = e => {
            e.preventDefault();
            setHover(true);
        }
        let dragleave = e => {
            e.preventDefault();
            setHover(false);
        }
        let preventDefault = e => {
            e.preventDefault();
        }
        let drop = async e => {
            e.preventDefault();
            setHover(false);
            let videoFiles = e.dataTransfer.files;
            let found = false;
            for (let i = 0; i < videoFiles.length; i++) {
                if (videoFiles[i].type != "video/mp4") {
                    found = true;
                    break
                }
            }
            if (!found) {
                setLoading(true);
                let newVideos = 0;
                for (let i = 0; i < videoFiles.length; i++) {
                    let reader = new FileReader();
                    reader.onload = async e => {
                        let dataURL = reader.result;
                        let {thumbURL, dimensions, duration} = await getVideoDetails(dataURL);
                        newVideos++;
                        setVideos([...getVideos(), {dataURL, thumbURL, dimensions, duration, thumbPos:0.5, uploaded:{}, complete:false}]);
                        if (newVideos >= videoFiles.length) {
                            setLoading(false);
                        }
                    }
                    reader.readAsDataURL(videoFiles[i]);
                }
            }
        }
        let refCurrent = ref.current;
        refCurrent.addEventListener('dragenter', dragenter);
        refCurrent.addEventListener('dragleave', dragleave);
        refCurrent.addEventListener('dragstart', preventDefault);
        refCurrent.addEventListener('dragend', preventDefault);
        refCurrent.addEventListener('dragover', preventDefault);
        refCurrent.addEventListener('drag', preventDefault);
        refCurrent.addEventListener('drop', drop);
        return () => {
            refCurrent.removeEventListener('dragenter', dragenter);
            refCurrent.removeEventListener('dragleave', dragleave);
            refCurrent.removeEventListener('dragstart', preventDefault);
            refCurrent.removeEventListener('dragend', preventDefault);
            refCurrent.removeEventListener('dragover', preventDefault);
            refCurrent.removeEventListener('drag', preventDefault);
            refCurrent.removeEventListener('drop', drop);
        }
    })
    useEffect(() => {
        if (loading) {
            let rect = ref.current.getBoundingClientRect();
            setDimensions([rect.width, rect.height]);
        }
    },[loading])
    return <div
        className={`Videos ${hover ? 'hover' : ''} ${videos.length==0 ? 'noVideos' : ''}`}
        ref={ref}
    >
        {videos.length==0&&!loading?'Nothing here...':null}
        {videos.map((video, i) => <VideoThumbnail key={i} video={video} _id={_id} ambigrams={ambigrams} update={() => {
            setVideos(videos.slice())
        }} deleteMe={() => {
            videos.splice(i, 1);
            setVideos(videos.slice());
        }} updateVideos={updateVideos} />)}
        {loading?<div className="loadingVideos" style={{width:dimensions[0],height:dimensions[1]}}><i>Loading...</i></div>:null}
    </div>
}

function Comments({ ambigrams, setAmbigrams }) {
    let refs = useRef({});
    let [comments, setComments] = useState(ambigrams.map(a => a.comment));
    let [dropPositions, setDropPositions] = useState();
    let [drag, setDrag] = useState(-1);

    function getDropPositions() {
        let positions = [];
        for (let i = 0; i < ambigrams.length; i++) {
            let box = refs.current[i].getBoundingClientRect();
            positions.push({
                down: box.top*0.3+box.bottom*0.7,
                up: box.top*0.7+box.bottom*0.3
            });
        }
        setDropPositions(positions);
    }

    let lastY = useRef();
    let direction = useRef();

    return <div className="Comments">
        {comments.map((c, i) => <div
            className="comment"
            key={c}
            ref={r=>refs.current[i]=r}
            onDragOver={e => {
                e.preventDefault();
                if (drag >= 0) {
                    let comments = ambigrams.map(a => a.comment);
                    let position = ambigrams.length;
                    let y = e.pageY;
                    if (y > lastY.current) direction.current = 'down';
                    if (y < lastY.current) direction.current = 'up';
                    for (let i = dropPositions.length - 1; i >= 0; i--) {
                        if (e.pageY < dropPositions[i][direction.current]) {
                            position = i;
                        }
                    }
                    comments.splice(drag, 1);
                    comments.splice(position, 0, ambigrams[drag].comment);
                    setComments(comments);
                    lastY.current = y;
                }
            }}
            onDrop={() => {
                let newAmbigrams = [];
                for (let comment of comments) {
                    let i = ambigrams.findIndex(a => a.comment == comment);
                    newAmbigrams.push(ambigrams[i]);
                }
                setAmbigrams(newAmbigrams);
                setDropPositions();
                setDrag(-1);
            }}
        >
            <img
                alt="comment"
                src={'/api/pics/' + c}
                onDragStart={e => {
                    e.dataTransfer.setData('i', i);
                    direction.current = 'down';
                    lastY.current = e.pageY;
                    getDropPositions();
                    setDrag(i);
                }}
            />
        </div>)}
    </div>
}

function ExpandedProject({ project = {
    ambigrams: [{ word0: '', word1: '', username:'', commentURL: '', comment: null, ambigram: null, fake:false }],
    videos: [],
    complete: false,
    stamp: new Date().getTime(),
}, close: close0, deleteMe, addProject }) {
    let [ambigrams, setAmbigrams] = useState(JSON.parse(JSON.stringify(project.ambigrams)));
    let [videos, setVideos, getVideos] = useGetState(JSON.parse(JSON.stringify(project.videos)));
    let [stamp, setStamp] = useState(project.stamp);
    let [shrink, setShrink] = useState(false);
    let [tab, setTab] = useState('Ambigrams');
    let [loading, setLoading] = useState(false);
    let [changed, setChanged] = useState(false);
    let [tab0, setTab0] = useContext(TabContext);
    let [tab1, setTab1] = useState(tab0);
    let {password, id} = useContext(QueryContext);
    let [downloadLoading, setDownloadLoading] = useState(false)
    let [downloadFailed, setDownloadFailed] = useState(false)
    let [heading, setHeading] = useState('Project');
    let [force, forceRender] = useReducer(f => f + 1, 0);

    let [showFullScreenLoading, setShowFullScreenLoading] = useContext(FullScreenLoadingContext);


    useChange(() => setChanged(true),[ambigrams, videos])

    function close() {
        if (tab0 != tab1) setTab0(tab1);
        close0();
    }

    useEffect(() => {
        setHeading(ambigrams.length == 0 ? 'New project' : ambigrams.map(a=>a.word1?a.word0+'+'+a.word1:a.word0).join(', '))
    }, [ambigrams])
    
    async function updateVideos() {
        project.videos = videos;
    }

    async function save(closeAfter) {
        setLoading(true);
        setChanged(false);
        for (let ambigram of ambigrams) {
            ambigram.word0 = ambigram.word0.trim();
            ambigram.word1 = ambigram.word1.trim();
            if (ambigram.commentDataURL) {
                let { data } = await axios.post('/api/saveambigramdataurl', { p: p(), dataURL: ambigram.commentDataURL, extension: 'jpg', thumb:false });
                ambigram.comment = data.filename;
            }
            if (ambigram.ambigramURL) {
                let { data } = await axios.post('/api/saveambigramdataurl', { p: p(), dataURL: ambigram.ambigramURL, extension: 'jpg', thumb: true });
                ambigram.ambigram = data.filename;
            }
            delete ambigram.commentDataURL;
            delete ambigram.ambigramURL;
        }
        for (let video of videos) {
            if (video.thumbURL) {
                let { data } = await axios.post('/api/saveambigramdataurl', { p: p(), dataURL: video.thumbURL, extension: 'jpg', thumb: 150 });
                video.thumb = data.filename;
            }
            if (video.dataURL) {
                let max = 50000000;
                let url = video.dataURL;
                if (video.dataURL.length > max) {
                    let first = true;
                    while (url.length > max) {
                        let tmp = url.substring(0, max);
                        url = url.substring(max, url.length);
                        await axios.post('/api/savebigambigramdataurl', { p: p(), dataURL: tmp, first });
                        first = false;
                    }
                    let { data } = await axios.post('/api/savebigambigramdataurl', { p: p(), dataURL: url, extension: 'mp4', thumb: false, final:true });
                    video.path = data.filename;
                } else {
                    let { data } = await axios.post('/api/saveambigramdataurl', { p: p(), dataURL: video.dataURL, extension: 'mp4', thumb: false });
                    video.path = data.filename;
                }
            }
            delete video.dataURL;
            delete video.thumbURL;
        }
        project.ambigrams = ambigrams;
        project.stamp = stamp;
        project.videos = videos;
        if (addProject) {
            let { data } = await axios.post('/api/createambigramproject', { p:p(), project });
            addProject(data);
        } else {
            let { data } = await axios.post('/api/updateambigramproject', { p:p(), project });
        }
        let illustrated = !ambigrams.find(a => !a.ambigram);
        let rendered = videos.length > 0;
        let tab = rendered ? 'Rendered' : illustrated ? 'Illustrated' : 'New';
        setTab1(tab);
        if (closeAfter) {
            if (tab0 != tab) setTab0(tab);
            close();
        } else {
            setLoading(false);
            forceRender();
        }
    }

    let tabs = ['Ambigrams', 'Videos'];
    if (ambigrams.length > 0 && ambigrams.reduce((a, b) => a && b.comment, true)) {
        tabs.push('Comments');
    }

    let saveDisabled = ambigrams.find(a=>a.fake?!a.word0||(!a.comment&&!a.commentDataURL):!a.word0||!a.commentURL||(!a.comment&&!a.commentDataURL))
    // let saveDisabled = ambigrams.find(a=>a.fake?!a.word0||(!a.comment&&!a.commentDataURL):!a.word0||!a.commentURL||!a.username||(!a.comment&&!a.commentDataURL))

    return <div className="expanded expandedProject"> 
        <div className={"window"}>
            <div className="modalHeading">
                <div className="heading">{heading}</div>
                <button onClick={close}>╳</button>
            </div>
            <div className={`shrinkable ${shrink ? 'shrink' : ''} ${loading?'loadingWindow':''}`}>
                <ShrinkContext.Provider value={{ setShrink }}><HeadingContext.Provider value={heading}>
                    <Tabs tab={tab} setTab={setTab} tabs={tabs} />
                    {tab == 'Ambigrams' ? <div key={force}>
                        <div className="ambigrams">
                            {ambigrams.map((a, i) => <AmbigramItem
                                key={i}
                                i={i}
                                _id={project._id}
                                ambigramsLength={ambigrams.length}
                                ambigram={a}
                                setAmbigram={a => {
                                    ambigrams[i] = a;
                                    setAmbigrams(ambigrams.slice());
                                }}
                                deleteme={() => {
                                    ambigrams.splice(i, 1);
                                    setAmbigrams(ambigrams.slice());
                                    forceRender();
                                }}
                            />)}
                        </div>
                        <div className="addAmbigramBut buts">
                            <A onClick={() => {
                                setAmbigrams([...ambigrams, { word0: '', word1: '', username:'', commentURL: '', comment: null, ambigram: null }]);
                            }}>Add ambigram</A>
                        </div>
                    </div> : tab == 'Videos' ? <div key={force}>
                        <Videos videos={videos} setVideos={setVideos} getVideos={getVideos} updateVideos={updateVideos} _id={project._id} ambigrams={ambigrams} />
                    </div> : tab = 'Comments' ? <div key={force}>
                        <Comments ambigrams={ambigrams} setAmbigrams={setAmbigrams}/>
                    </div> : null}
                    
                    <div className="buts">
                        {changed?<>
                            <Q className="but"
                                disabled={saveDisabled}
                                callback={async () => {
                                    await save(true);
                                }}
                            >Save</Q>
                            {deleteMe && !saveDisabled?<A className="but"
                                onClick={async () => {
                                    await save()
                                }}
                            >Update</A>:null}
                        </>:null}
                        {id=='Desktop' && !ambigrams.find(a => !a.ambigram || !a.comment) ? <A
                            className={`but ${downloadLoading?'faded':''} ${downloadFailed?'red':''}`}
                            onClick={async () => {
                                setDownloadLoading(true);
                                setDownloadFailed(false);
                                let { data } = await axios.post('/api/k/addupdate', {
                                    p: p(),
                                    command: 'Download ambigram images',
                                    data: {
                                        folder: ambigrams.map(a=>a.word1?a.word0+'+'+a.word1:a.word0).join(', '),
                                        ambigrams: ambigrams.map(a => `https://kaisboatfund.co.uk/api/pics/${a.ambigram}`),
                                        comments: ambigrams.map(a => `https://kaisboatfund.co.uk/api/pics/${a.comment}`)
                                    },
                                    wait:true
                                })
                                if (!data.success) setDownloadFailed(true);
                                setDownloadLoading(false);
                            }}
                        >{downloadFailed ? "Failed to download!" : "Prepare AE"}</A> : null}
                        
                        {/* {deleteMe && videos.length > 0 && !videos.find(a => !a.path || !a.thumb) ? <Q className="but" callback={async () => {
                            let words = [];
                            ambigrams.forEach(a => {
                                words.push(a.word0)
                                // if (a.word1) words.push(a.word1);
                            })
                            let { data } = await axios.post('/api/k/addupdate', {
                                p: p(),
                                command: 'Upload ambigram videos',
                                data: {
                                    videos,
                                    words,
                                    ambigrams: ambigrams.map(a =>
                                        a.fake ? ({
                                            word0: a.word0,
                                            word1: a.word1,
                                            fake: true,
                                        })
                                            : ({
                                                word0: a.word0,
                                                word1: a.word1,
                                                commentURL: a.commentURL,
                                                username: a.username,
                                                fake:false
                                            })
                                        ),
                                    _id: project._id
                                }
                            })
                            if (password) setShowFullScreenLoading(true);
                        }}>Upload videos</Q> : null} */}
                        
                        {deleteMe?<Q className="but" callback={async () => {
                            deleteMe();
                            axios.post('/api/deleteambigramproject', { p:p(), project });
                            close();
                        }}>Delete</Q> : null}
                    </div>
                </HeadingContext.Provider></ShrinkContext.Provider>
            </div>
        </div>
    </div>
}
