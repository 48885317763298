import React, { useContext, useEffect, useRef, useState } from 'react';
import { AdminContext, useGetState } from '../../hooks';

export default function Calligraphy({ }) {
    let { setBackgroundColour } = useContext(AdminContext);
    let [width, setWidth] = useState();
    let [height, setHeight] = useState();
    let [bgLoaded, setBgLoaded] = useState(false);
    useEffect(() => {
        setBackgroundColour("Black");
        function OnImageLoaded (img) {
			alert ("The image has been loaded: " + img.src);
		}

        let img = new Image ();
        img.onload = () => setBgLoaded(true);
        img.src = "https://kaisboatfund.co.uk/api/pictures/calligrabg.jpg";

        function resize() {
            let padding = window.innerWidth<720?16:32;
            let vr = 1280 / 720;
            let w = window.innerWidth - padding * 2;
            let h = window.innerHeight - padding * 2;
            let wr = w / h;
            let width, height;
            if (vr > wr) {
                width = w;
                height = width / vr;
            } else {
                height = h;
                width = height * vr;
            }
            if (width > 1280) {
                setWidth(1280);
                setHeight(720);
            } else {
                setWidth(width);
                setHeight(height);
            }
        }
        resize();
        window.addEventListener('resize', resize);
        return () => {
            setBackgroundColour(false);
            window.removeEventListener('resize', resize);
        }
    }, [])


    return <div className="Calligraphy">
        <div
            className={`Calligrabg ${bgLoaded ? 'showBg' : ''}`}
        />
        <div className="drop0" style={{ width, height }}>
            <div className="drop1" style={{ width, height }}>
                <div className="Video" style={{width,height}}>
                    {width ?
                        <iframe
                            src="https://fast.wistia.net/embed/iframe/go3mp2as8f?videoFoam=true"
                            title="Calligraphy showreel video"
                            allow="autoplay; fullscreen"
                            frameBorder="0"
                            scrolling="no"
                            width={width}
                            height={height}
                        />
                    : null}
                </div>
            </div>
        </div>
    </div>
}