import React, { Component } from 'react'

export default class Slideshow extends Component {
    constructor(props) {
        super(props);
        let { images } = props;
        this.state = {
            images, imgs:[], w:0
        }
        this.loadImg = this.loadImg.bind(this);
        this.resize = this.resize.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.resize, true);
        let { images } = this.state;
        let imgs = [];
        images.forEach(path => {
            imgs.push({
                path,
                w: 0,
                h: 0,
                o: 0
            })
        })
        imgs[Math.floor(Math.random() * imgs.length)].o = 1;
        if (imgs.length>1) setInterval(() => {
            let r = Math.floor(Math.random() * imgs.length);
            while (imgs[r].o == 1) r = Math.floor(Math.random() * imgs.length);
            imgs.forEach(img => img.o = 0);
            imgs[r].o = 1;
            this.setState({ imgs });
        },3000)
        this.setState({ imgs }, this.resize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }

    resize() {
        let box = document.getElementById('slideshow');
        if (box) {
            let { imgs } = this.state;
            let w = box.clientWidth;
            box.style.height = 'calc(' + w + 'px - 2rem)';
            imgs.forEach((img, i) => {
                if (img.w == 0 || img.h == 0) this.loadImg(i);
                let div = document.getElementById('div' + i)
                if (div) {
                    div.style.width = 'calc(' + w + 'px - 2rem)';
                    div.style.height = 'calc(' + w + 'px - 2rem)';
                }
            })
            this.setState({ w });
        }
    }

    loadImg(i) {
        let img = document.getElementById('img' + i);
        let { imgs } = this.state;
        imgs[i].w = img.clientWidth;
        imgs[i].h = img.clientHeight;
        this.setState({ imgs });
    }

    render() {
        let { imgs, w } = this.state;
        return <div className="slideshow">
            {imgs.map((image, i) => <div
                className="flex"
                id={"div" + i}
                key={"img" + i}
            ><img
                id={"img"+i}
                src={"/api/pictures/" + image.path}
                onLoad={()=>this.loadImg(i)}
                style={{
                    opacity: image.o,
                    width: image.w > 0 && image.w >= image.h ? 'calc(' + w + 'px - 2rem)' : null,
                    height: image.h > 0 && image.h > image.w ? 'calc(' + w + 'px - 2rem)' : null,
                    // left: image.w>0?(w+image.w)/2:null,
                    // top: image.h>0?(h+image.h)/2:null,
                }}
            /></div>)}
        </div>
    }
}