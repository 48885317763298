import React, { useState, useEffect, useRef, useContext } from 'react';
import AdminTabs from './AdminTabs';
import { useMount, useClickOutside, p, AdminContext, useGetState } from '../../hooks';
import Tabs from './Tabs';
import A from './A';
import axios from 'axios';

export default function Secret() {
    let [secret, setSecret] = useState();
    let [password, setPassword, getPassword] = useGetState('');

    let getBg = useRef();

    useEffect(() => {
        setBackgroundColour("Admin");
        return () => setBackgroundColour(false);
    }, [])

    useEffect(() => {
        let f = async e => {
            if (e.keyCode == 13) {
                let password = getPassword();
                setPassword('');
                let { data } = await axios.post('/api/secret', { p: p(), password });
                if (data) {
                    setSecret(data);
                    setTimeout(() => {
                        setSecret()
                    },1000*60*20)
                }
            }
        }
        if (!secret) window.addEventListener('keypress', f);
        return () => window.removeEventListener('keypress', f);
    },[secret])

    let { admin, setBackgroundColour } = useContext(AdminContext);
    if (!admin) return null;

    return <div className="Secret" ref={getBg}>
        <AdminTabs tab="Secret" />
        {secret ? <div className="secretItems">
            {secret.map(({ label, type, data }) => <div key={label} className="secretItem">
                <h3>{label}</h3>
                {
                    type == "image" ? <img src={data} alt={label}/> :
                    type == "string" ? data.split('\n').map(d => <p>{d}</p>) : null
                }
            </div>)}
        </div> : <input
            className="secretInput"
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
        />
        }
    </div>
}