import React,{useState, useEffect} from 'react';
import A from './A';

let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export function toDateString(date) {
    let D = new Date(date);
    let y = D.getFullYear();
    let m = D.getMonth();
    let d = D.getDate();
    return `${d}${d>=10&&d<20?'th':['th','st','nd','rd'][d%10]??'th'} ${months[m]} ${y}`;
}

export function DisplayDate({ date }) {
    let [str] = useState(toDateString(date))
    return str;
}

// export function DisplayDate({ date }) {
//     let [y] = useState(new Date(date).getFullYear());
//     let [m] = useState(new Date(date).getMonth());
//     let [d] = useState(new Date(date).getDate());

//     return (d < 10 ? '0' + d : d) + ' ' + months[m] + ' ' + y;
// }

export function DateSelect({ date, setDate }) { 
    let [y, setY] = useState(new Date(date).getFullYear());
    let [m, setM] = useState(new Date(date).getMonth());
    let [d, setD] = useState(new Date(date).getDate());

    useEffect(() => {
        let d = new Date(date);
        setY(d.getFullYear())
        setM(d.getMonth());
        setD(d.getDate());
    }, [date])

    return <div className="dateSelect">
        <div className="dateInner">
            <div className="arrow"><A onClick={() => {
                let newDate = date + (1000 * 60 * 60 * 24);
                setDate(newDate);
            }}>▲</A></div>
            <div className="n">{d < 10 ? '0' + d : d}</div>
            <div className="arrow"><A onClick={() => {
                let newDate = date - (1000 * 60 * 60 * 24);
                setDate(newDate);
            }}>▼</A></div>
        </div>
        <div className="dateInner">
            <div className="arrow"><A onClick={() => {
                m++;
                if (m >= 12) {
                    m = 0;
                    y++;
                }
                let date = new Date(y,m,d).getTime()
                setDate(date);
            }}>▲</A></div>
            <div className="n">{months[m]}</div>
            <div className="arrow"><A onClick={() => {
                m--;
                if (m < 0) {
                    m = 11;
                    y--;
                }
                let date = new Date(y,m,d).getTime()
                setDate(date);
            }}>▼</A></div>
        </div>
        <div className="dateInner">
            <div className="arrow"><A onClick={() => {
                let date = new Date(y+1,m,d).getTime()
                setDate(date);
            }}>▲</A></div>
            <div className="n">{y}</div>
            <div className="arrow"><A onClick={() => {
                let date = new Date(y-1,m,d).getTime()
                setDate(date);
            }}>▼</A></div>
        </div>
    </div>
}