import React, { Component } from 'react';
import axios from 'axios';

export default class Contact extends Component {
    constructor(props) {
        super(props);
        let { hire } = this.props;
        this.state = {
            name: '', email: '', message: '', opacity: 0, sent: false, loading: false, error:false, hire, showErrors:false, errors:{}
        }
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.disappear = this.disappear.bind(this);
        this.checkErrors = this.checkErrors.bind(this);
    }

    componentDidMount() {
        setTimeout(() => this.setState({ opacity: 1 }), 100);
    }

    disappear() {
        this.setState({ opacity: 0 });
        setTimeout(this.props.hide, 1000 * 0.3);
    }

    onChange(e) {
        let { name, value } = e.target;
        this.setState({ [name]: value }, this.checkErrors);
    }

    checkErrors() {
        let errors = {};
        let { name, email, message } = this.state;
        let found = false;
        if (name.length == 0) {
            errors.name = 'Please provide a name!';
            found = true;
        }
        if (email.length == 0) {
            errors.email = 'Please provide an email!';
            found = true;
        } else {
            let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(email)) {
                errors.email = 'This email address is invalid!';
                found = true;
            }
        }
        if (message.length == 0) {
            errors.message = 'Please provide a message!';
            found = true;
        }
        this.setState({ errors });
        return found;
    }

    async onSubmit(e) {
        e.preventDefault();
        let { name, email, message } = this.state;
        let errors = this.checkErrors();
        if (errors) {
            this.setState({ showErrors:true });
        } else {
            this.setState({ loading: true });
            let { data } = await axios.post('/api/message/', { name, email, message });
            if (data.error) {
                this.setState({ error: true, loading:false, });
            } else {
                this.setState({ loading: false, sent:true });
            }
        }
    }

    render() {
        let { name, email, hire, opacity, error, sent, loading, errors, showErrors } = this.state;
        return <div className="modal" style={{opacity}}>
            <div className="transparency" onClick={this.disappear} ></div>
            <div className="box contact">
                <div className="cross">
                    <button onClick={this.disappear}>×</button>
                </div>
                {!loading ? sent ? <div>
                    <h1>Sent!</h1>
                    Thank you, your message has been sent.
                </div> : error ? <div>
                    <h1>Error!</h1>
                    There was an error! Maybe it would be simpler if you just sent me an email: <a href="mailto:kai@ambigr.am">kai@ambigr.am</a>

                </div>:<div><h1>Contact</h1>
                {hire?<div><p>I do front and back end web development using react, node.js, and mongo. </p><p>I also do motion graphics, animation and video editing. </p><p>I also do a bit of graphic design, with experience in after effects, premiere pro, photoshop and illustrator.</p><p>Send me a message!</p></div>:null}
                <form onSubmit={this.onSubmit}>
                    <label>Name</label>
                    <input
                        type="text"
                        name="name"
                        value={name}
                        onChange={this.onChange}
                    />
                    {showErrors && errors.name ? <div className="required">{errors.name}</div> : null}
                    <br/>
                    <label>Email</label>
                    <input
                        type="text"
                        name="email"
                        value={email}
                        onChange={this.onChange}
                    />
                    {showErrors && errors.email ? <div className="required">{errors.email}</div> : null}
                    <br/>
                    <label>Message</label>
                    <textarea
                        name="message"
                        onChange={this.onChange}
                    />
                    {showErrors && errors.message ? <div className="required">{errors.message}</div> : null}
                    <br />
                    <button className="green">Send</button>
                </form></div>:<div className="center"><div className="spinner"></div></div>}
            </div>
        </div>

    }
}