import React, { useState, useEffect, useRef, useContext } from 'react';
import AdminTabs from './AdminTabs';
import { useMount, useClickOutside, p, AdminContext } from '../../hooks';
import Tabs from './Tabs';
import A from './A';
import axios from 'axios';

export default function Vouchers() {
    let { componentDidMount } = useMount();
    let [vouchers, setVouchers] = useState();
    let [expired, setExpired] = useState();
    let [tab, setTab] = useState('Valid');
    let [expand, setExpand] = useState(false);
    let { admin, setBackgroundColour } = useContext(AdminContext);

    let getBg = useRef();
    
    useEffect(() => {
        if (admin) (async () => {
            let vouchers =  (await axios.post('/api/vouchers', { p:p(), expired: false })).data;
            let expired = (await axios.post('/api/vouchers', { p:p(), expired: true })).data;
            setVouchers(vouchers);
            setExpired(expired);
        })()
    }, [admin])

    useEffect(() => {
        setBackgroundColour("Admin");
        return () => setBackgroundColour(false);
    }, [])

    function getDate(stamp) {
        let d = new Date(stamp);
        let dd = d.getDate();
        if (dd < 10) dd = '0' + dd;
        let mm = d.getMonth() + 1;
        if (mm < 10) mm = '0' + mm;
        let yyyy = d.getFullYear();
        return dd + '/' + mm + '/' + yyyy;
    }

    if (!admin) return null;

    return <div className="Vouchers" ref={getBg}>
        {expand ? <Expanded
            close={() => setExpand(false)}
            addVoucher={v => {
                vouchers.unshift(v);
                setVouchers(vouchers.slice());
            }}
        /> : null}
        <AdminTabs tab="Vouchers" />
        <h1>Vouchers</h1>
        {vouchers && expired ? <>
            <Tabs tab={tab} setTab={setTab} tabs={['Valid', 'Expired']} />
            <div className="addVoucher">
                <A onClick={() => setExpand(true)}>Add voucher</A>
            </div>
            {(tab == 'Valid' ? vouchers : expired).length == 0 ? <div className="i">Nothing here...</div>: (tab == 'Valid' ? vouchers : expired).map((v, i) => <div key={i} className="voucher">
                <h2>{v.code}</h2>
                <h4>{v.description}</h4>
                <div className="voucherSplit">
                    <div className="left">
                        Discount:
                    </div>
                    <div className="right">
                        {v.discount * 100}%
                    </div>
                </div>
                <div className="voucherSplit">
                    <div className="left">
                        Expires:
                    </div>
                    <div className="right">
                    {getDate(v.expires)}
                    </div>
                </div>
                <div className="voucherSplit">
                    <div className="left">
                        Uses:
                    </div>
                    <div className="right">
                        {v.uses}
                    </div>
                </div>
            </div>)}
        </>:null}
    </div>
}

function Expanded({ close, addVoucher }) {
    let [ref, onClickOutside] = useClickOutside();
    let { componentDidMount } = useMount();
    let [description, setDescription] = useState('');
    let [discount, setDiscount] = useState(25);
    let [uses, setUses] = useState(1);
    let [expires, setExpires] = useState(7);

    onClickOutside(close)

    return <div className="expanded">
        <div className="window" ref={ref}>
            <div className="modalHeading">
                <div className="heading">Voucher</div>
                <button onClick={close}>╳</button>
            </div>
            <div className="voucherSplit">
                <div className="left">
                    Description:
                </div>
                <div className="right">
                    <input
                        type="text"
                        value={description}
                        onChange={e=>setDescription(e.target.value)}
                    />
                </div>
            </div>
            <div className="voucherSplit">
                <div className="left">
                    Discount:
                </div>
                <div className="right">
                    <input
                        type="text"
                        value={discount}
                        onChange={e=>setDiscount(parseInt(e.target.value))}
                    />
                </div>
            </div>
            <div className="voucherSplit">
                <div className="left">
                    Uses:
                </div>
                <div className="right">
                    <input
                        type="text"
                        value={uses}
                        onChange={e=>setUses(parseInt(e.target.value))}
                    />
                </div>
            </div>
            <div className="voucherSplit">
                <div className="left">
                    Expires after:
                </div>
                <div className="right">
                    <input
                        type="text"
                        value={expires}
                        onChange={e=>setExpires(parseInt(e.target.value))}
                    />
                </div>
            </div>
            <div className="buts">
                <A onClick={async () => {
                    discount = discount / 100;
                    let { data } = await axios.post('/api/createvoucher', { p:p(), description, discount, uses, expires });
                    addVoucher({ description, discount, uses, expires: new Date().getTime() + expires * 1000 * 60 * 60 * 24, code: data, stamp: new Date().getTime() });
                    close();
                }}>Create</A>
            </div>
        </div>
    </div>
}