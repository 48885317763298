import React, { useContext, useEffect, useRef, useState } from 'react';
import { AdminContext, useGetState } from '../../hooks';

import ImgDeclanLogan from '../../pics/declan logan.jpg';
import ImgAtlantis from '../../pics/Atlantis.jpg';
import ImgMingus from '../../pics/mingus.jpg';
import ImgEverything from '../../pics/everything.jpg';
import ImgHaiku from '../../pics/haikus.png';
import ImgPoem1 from '../../pics/Fire & Water.png';
import ImgPoem0 from '../../pics/Sun & Moon.jpg';
import ImgArchie from '../../pics/archie.jpg';

function Ambi({ src, alt }) {
    let [wiggle, setWiggle, getWiggle] = useGetState(0);
    let [tooltip, setTooltip] = useState(false);
    let [clicks, setClicks] = useState(false);
    let [spinning, setSpinning] = useState(false);
    function wigglewiggle() {
        if (getWiggle()==0) {
            let direction = Math.random() < 0.5 ? -1 : 1;
            setWiggle(1 * direction);
            setTimeout(() => {
                direction *= -1;
                setWiggle(2 * direction);
            }, 120)
            setTimeout(() => {setWiggle(0)}, 240)
        }
    }
    useEffect(() => {
        setSpinning(true);
        clearTimeout(timeout1.current);
        timeout1.current = setTimeout(() => setSpinning(false), 1000);
    },[clicks])
    let timeout0 = useRef();
    let timeout1 = useRef();
    return <div className={`ambibox0 ${spinning?"spinning":''}`}>
        <div className={`tooltip ${tooltip && clicks == 0?"visible":''}`}>Click to rotate!</div>
        <div
            className="ambibox1"
            onMouseEnter={wigglewiggle}
            style={{ transform: `rotate(${wiggle}deg)` }}
        >
            <img
                className='ambigram'
                alt={alt}
                src={src}
                onMouseEnter={() => {
                    if (clicks == 0) {
                        clearTimeout(timeout0.current);
                        timeout0.current = setTimeout(() => {
                            setTooltip(true);
                        },300)
                    }
                }}
                onMouseLeave={() => {
                    clearTimeout(timeout0.current);
                    setTooltip(false);
                }}
                onClick={() => {
                    setClicks(clicks + 1);
                }}
                style={{ transform: `rotate(${clicks * 180}deg)` }}
            />
        </div>
    </div>
}

export default function Ambigram({ }) {
    let { setBackgroundColour } = useContext(AdminContext);
    useEffect(() => {
        setBackgroundColour("White");
        return () => setBackgroundColour(false);
    }, [])

    return <div className="Ambigram">
        <div className="inner">
            <h1>Order an ambigram!</h1>
            <p>Hello there! My name is Kai, and I've been making ambigrams since I was a wee child. Because of this, I've become pretty good at it!</p>
            <p>I charge <b>£50</b> for a basic ambigram, and <b>£200</b> for a super-refined, high-res, deluxe ambigram!</p>
            <p>For <b>£50</b>, I will spend maybe an hour or two on the ambigram. This will result in something presentable and legible. I realise a lot of people just want a simple gift for a friend or a little novelty piece to put on the fridge - this is the option for you!</p>

            {/* <p>Here is an ambigram of 'Archie' to show the kind of quality you can expect for £50:</p>
            <Ambi alt="Archie ambigram" src={ImgArchie} />

            <p>Not too bad, eh?</p> */}
            <p>However, if you are after a special piece of art to celebrate a wedding, or you want to get a tattoo, you will probably be after something a little more refined. In that case, for <b>£200</b> I will work on the ambigram until it is as good as it can be, sending you regular updates and supplying a high resolution image at the end of it.</p>

            <p>So, if you are interested in a commission, get in touch! This is my email address: <a href="mailto:kai@ambigr.am">kai@ambigr.am</a></p>

            <p>In your email, please make sure you specify clearly whether you want the <b>£50</b> or the <b>£200</b> option.</p>

            <p>And here are some of my pieces to show what kind of quality you can expect for that money. Keep in mind that some words work better than others - just click on any one to see what it looks like upside down!</p>

            <h2>Atlantis</h2>
            <p>This one came first place in an <a target="_blank" rel="noopener noreferrer" href="https://ambigr.am/contest/places">ambigr.am contest</a> in 2019.</p>
            <Ambi alt="Atlantis ambigram" src={ImgAtlantis} />

            <h2>Everything is significant</h2>
            <p>In one orientation it reads 'Everything', then turned upside-down it reads 'Significant'. Maybe there's a metaphor in there!</p>
            <Ambi alt="Everything is significant" src={ImgEverything} />

            <h2>Logan/Declan</h2>
            <p>This one was for a very happy client, who had it tattoed!</p>
            <Ambi alt="Logan/Declan ambigram" src={ImgDeclanLogan} />
            
            <h2>Mingus</h2>
            <p>A reference to the famous band leader - this ambigram came first place in an <a target="_blank" rel="noopener noreferrer" href="https://ambigr.am/contest/jazz">ambigr.am contest</a> in 2020!</p>
            <Ambi alt="Mingus ambigram" src={ImgMingus} />
            
            <h2>Two haikus</h2>
            <p>Something I've always wanted to try - two poems hidden in one paragraph. If you turn it over, the poem changes!</p>
            <Ambi alt="Two haikus" src={ImgHaiku} />
            <p>The poems read:</p>
            <ol>
                <li>
                    Tired of this age, deep valleys rested, awaiting livelier times.
                </li>
                <li>
                    Passed train windows, grave stones race, too fast to read.
                </li>
            </ol>

            <h2>Sun and Moon</h2>
            <p>This is the best piece of art I've ever made. Two poems in one ambigram - to be clear, in order to make this ambigram I had to write each poem word by word, choosing each word carefully so that it not only made sense in the context of the poem, but makes a nice ambigram with its corresponding word in the other poem (which, of course, would have taken just as much thought to choose.)</p>
            <Ambi alt="Sun & Moon poems" src={ImgPoem0} />
            <p>The poems read:</p>
            <ol className="noSpaces">
                <li>
                    <p>Kiss the sun whilst she pinnacles,</p>
                    <p>return sweetly the sun's tender crush.</p>
                    <p>Retire there in a blazing romance scene</p>
                    <p>and trust, she'll by evening blush.</p>
                </li>
                <li>
                    <p>Stars glittered wet tears while the moon bottomed, </p>
                    <p>capsizing in an ocean wide.</p>
                    <p>Clean winds sung the moon's lament,</p>
                    <p>forgetting his rising like the tide.</p>
                </li>
            </ol>

            <h2>Fire and Water</h2>
            <p>And now for my crowning achievement, the longest ambigram in the world! When turned upside down, you reveal a second poem! Now keep in mind, this ain't easy, so don't be too hard on me if you can't read some of the words!</p>
            <Ambi alt="Two poems" src={ImgPoem1} />
            <p>The poems read:</p>
            <ol className="noSpaces">
                <li>
                    <p>Fire cleaves the river bank,</p>
                    <p>its cry prophetical, a wild</p>
                    <p>lyrical din staining the sky a</p>
                    <p>sacred red - the flames' first</p>
                    <p className="mb">ritualistic dance, primitive and free.</p>
                    <p>Lands retire to history, dreams</p>
                    <p>are tiptoed away. Autumnal rain</p>
                    <p>curtains summer, yet still all</p>
                    <p>the while, the restless flames</p>
                    <p>never cease their dancing.</p>
                </li>
                <li>
                    <p>Through chilly dales, clear</p>
                    <p>rivers wander day after day,</p>
                    <p>the last few trickling streams</p>
                    <p>unite, rendering rustic poetry in</p>
                    <p className="mb">cursive streams on alpine scapes.</p>
                    <p>Only the gnarliest trees remember</p>
                    <p>that ancient fire - the silence</p>
                    <p>is wild and mindless, the sunset</p>
                    <p>sky a flirtatious red, as</p>
                    <p>winter leaves the river side.</p>
                </li>
            </ol>

            <p>Here is my email address again if you want to get in touch: <a href="mailto:kai@ambigr.am">kai@ambigr.am</a></p>
        </div>
    </div>
}