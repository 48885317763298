import React, { Component } from 'react';
import { NavLink } from "react-router-dom";

import Contact from './Contact';

class Banner extends Component {
    constructor(props) {
        super(props);
        let logo1 = require('../../logo/logo.svg');
        let logo2 = require('../../logo/logo2.svg');
        this.state = {
            hover:false, logo1, logo2, contact:false
        }
    }

    render() {
        let { hover, logo1, logo2, contact } = this.state;
        return <div className="banner">
            {contact ? <Contact hide={()=>this.setState({contact:false})}/>:null}
            <div className="content">
                <NavLink className="a" to='/'>
                    <svg
                        viewBox="-320 587.3 608.9 76.7"
                        className={`logo ${hover ? 'hover' : null}`}
                        onMouseEnter={()=>this.setState({hover:true})}
                        onMouseLeave={() => this.setState({ hover: false })}
                    >
                        <rect x="-320" y="587.3" width="608.9" height="76.7"/>
                        <path d="M-291.5,657.9v-64.5h7.2v14.3l14.3-14.3h14.3v21.5h-21.4c-3.9,0-7.2,3.2-7.2,7.2v7.2
                            c0,4,3.1,7.2,7.2,7.2h21.5V658H-270l-14.3-14.5v14.3L-291.5,657.9L-291.5,657.9z"/>
                        <path d="M-248.5,657.9v-57.4c0-3.9,3.2-7.2,7.2-7.2h21.5c3.9,0,7.2,3.4,7.2,7.2v57.3h-7.2v-28.6h-21.5
                            v28.7C-241.3,657.9-248.5,657.9-248.5,657.9z M-219.8,622c0-3.8-3.4-7.2-7.2-7.2h-7.2c-3.9,0-7.2,3.2-7.2,7.2H-219.8z"/>
                        <path d="M-205.5,657.9v-21.5h7.2v-21.5h-7.2v-21.5h21.5v21.5h-7.2v21.5h7.2v21.5H-205.5z"/>
                        <path d="M-176.8,587.3h7.2v14.3c0,4-3.2,7.2-7.2,7.2V587.3z"/>
                        <path d="M-162.5,657.9v-21.5h25.1c2,0,3.6-1.7,3.6-3.6s-1.7-3.6-3.6-3.6h-17.9c-4,0-7.2-3.1-7.2-7.2
                            v-21.5c0-3.9,3.2-7.2,7.2-7.2h28.7v21.5h-25.2c-2,0.2-3.5,1.6-3.5,3.6c0,2.1,1.5,3.6,3.6,3.6h17.9c3.9,0,7.2,3.4,7.2,7.2v21.5
                            c0,4-3.2,7.2-7.2,7.2H-162.5z"/>
                        <path d="M-98,657.9v-64.5h28.7c3.9,0,7.2,3.4,7.2,7.2v21.5c0,1.9-1.6,3.6-3.6,3.6
                            c1.9,0,3.6,1.7,3.6,3.6v21.5c0,4-3.2,7.2-7.2,7.2H-98V657.9z M-72.9,622c2,0,3.6-1.7,3.6-3.6c0-1.9-1.7-3.6-3.6-3.6h-17.9v7.2H-72.9
                            z M-72.9,636.4c2,0,3.6-1.7,3.6-3.6s-1.7-3.6-3.6-3.6h-17.9v7.2C-90.8,636.4-72.9,636.4-72.9,636.4z"/>
                        <path d="M-47.8,657.9c-4,0-7.2-3.1-7.2-7.2v-50.2c0-3.9,3.2-7.2,7.2-7.2h21.5c3.9,0,7.2,3.4,7.2,7.2
                            v50.2c0,4-3.2,7.2-7.2,7.2H-47.8z M-33.5,636.4c3.9,0,7.2-3.2,7.2-7.2V622c0-3.8-3.4-7.2-7.2-7.2h-7.2c-3.9,0-7.2,3.2-7.2,7.2v7.2
                            c0,4,3.1,7.2,7.2,7.2H-33.5z"/>
                        <path d="M-12,657.9v-57.4c0-3.9,3.2-7.2,7.2-7.2h21.5c3.9,0,7.2,3.4,7.2,7.2v57.3h-7.2v-28.6H-4.8v28.7
                            C-4.8,657.9-12,657.9-12,657.9z M16.7,622c0-3.8-3.4-7.2-7.2-7.2H2.3c-3.9,0-7.2,3.2-7.2,7.2H16.7z"/>
                        <path d="M45.4,657.9v-43H31.1v-21.5h35.8v21.5H52.6v43H45.4z"/>
                        <path d="M95.6,657.9v-64.5h35.8v21.5h-28.7v7.2h21.5v7.2h-21.5V658h-7.1V657.9z"/>
                        <path d="M145.7,657.9c-4,0-7.2-3.1-7.2-7.2v-57.3h7.2v35.8c0,4,3.1,7.2,7.2,7.2h7.2
                            c3.9,0,7.2-3.2,7.2-7.2v-35.8h7.2v57.3c0,4-3.2,7.2-7.2,7.2H145.7z"/>
                        <path d="M181.6,657.9v-64.5h14.3c3.9,0,7.2,3.4,7.2,7.2v32.3c0,2.1,1.5,3.6,3.6,3.6
                            c2,0,3.6-1.7,3.6-3.6v-39.5h7.2v64.5h-14.3c-4,0-7.2-3.1-7.2-7.2v-32.2c0-1.9-1.7-3.6-3.6-3.6c-1.9,0-3.6,1.6-3.6,3.6v39.4
                            C188.8,657.9,181.6,657.9,181.6,657.9z"/>
                        <path d="M224.6,657.9v-64.5h28.7c3.9,0,7.2,3.4,7.2,7.2v50.2c0,4-3.2,7.2-7.2,7.2h-28.7V657.9z
                            M246.1,636.4c3.9,0,7.2-3.2,7.2-7.2V622c0-3.8-3.4-7.2-7.2-7.2h-14.3v21.5L246.1,636.4L246.1,636.4z"/>
                    </svg>
                </NavLink>
                <div className='buttons'>
                    <NavLink className="a button" to='/shop/'>Shop</NavLink>
                    <NavLink className="a button" to='/basket/'>Basket</NavLink>
                    <div className="button" onClick={()=>this.setState({contact:true})}>Contact</div>
                </div>
            </div>
        </div>
    }
}

export default Banner;