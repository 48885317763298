import React, { createContext, useContext, useEffect, useReducer, useRef, useState } from 'react';
import { AdminContext, p, useGetState } from '../../hooks';
import imgLogo from '../../pics/portfolio/logo.gif';
import imgLion from '../../pics/portfolio/lion.gif';
import imgFlourish0 from '../../pics/portfolio/flourish0.gif';
import imgFlourish1 from '../../pics/portfolio/flourish1.gif';
import imgFlourish2 from '../../pics/portfolio/flourish2.gif';
import imgStar from '../../pics/portfolio/star.gif';
import imgBanner from '../../pics/portfolio/banner.gif';
import imgAdobe from '../../pics/portfolio/adobe.gif';
import imgX0 from '../../pics/portfolio/x.gif';
import imgX1 from '../../pics/portfolio/x1.gif';
import imgX2 from '../../pics/portfolio/x2.gif';
import imgPlay from '../../pics/portfolio/play.gif';
import imgPause from '../../pics/portfolio/pause.gif';
import { useParams } from 'react-router';
import { NavLink, useNavigate } from 'react-router-dom';

import img0 from '../../pics/portfolio/counter/0.png';
import img1 from '../../pics/portfolio/counter/1.png';
import img2 from '../../pics/portfolio/counter/2.png';
import img3 from '../../pics/portfolio/counter/3.png';
import img4 from '../../pics/portfolio/counter/4.png';
import img5 from '../../pics/portfolio/counter/5.png';
import img6 from '../../pics/portfolio/counter/6.png';
import img7 from '../../pics/portfolio/counter/7.png';
import img8 from '../../pics/portfolio/counter/8.png';
import img9 from '../../pics/portfolio/counter/9.png';
import imgViews from '../../pics/portfolio/counter/views.png';

import webpM0 from '../../pics/portfolio/dance/m0.webp'
import pngM0 from '../../pics/portfolio/dance/m0.png'
import webpM1 from '../../pics/portfolio/dance/m1.webp'
import pngM1 from '../../pics/portfolio/dance/m1.png'
import webpL0 from '../../pics/portfolio/dance/l0.webp'
import pngL0 from '../../pics/portfolio/dance/l0.png'
import webpL1 from '../../pics/portfolio/dance/l1.webp'
import pngL1 from '../../pics/portfolio/dance/l1.png'
import webpR0 from '../../pics/portfolio/dance/r0.webp'
import pngR0 from '../../pics/portfolio/dance/r0.png'
import webpR1 from '../../pics/portfolio/dance/r1.webp'
import pngR1 from '../../pics/portfolio/dance/r1.png'
import webpU0 from '../../pics/portfolio/dance/u0.webp'
import pngU0 from '../../pics/portfolio/dance/u0.png'
import webpU1 from '../../pics/portfolio/dance/u1.webp'
import pngU1 from '../../pics/portfolio/dance/u1.png'
import song from '../../pics/portfolio/dance/song.mp3'

import webpWally from '../../pics/portfolio/wally.webp';
import pngWally from '../../pics/portfolio/wally.png';
import webpHitler from '../../pics/portfolio/hitler.webp';
import pngHitler from '../../pics/portfolio/hitler.png';
import webpDeadHitler from '../../pics/portfolio/dead hitler.webp';
import pngDeadHitler from '../../pics/portfolio/dead hitler.png';
import webpMoustache from '../../pics/portfolio/moustache.webp';
import pngMoustache from '../../pics/portfolio/moustache.png'
import webpHitlerMoustache from '../../pics/portfolio/hitler moustache.webp';
import pngHitlerMoustache from '../../pics/portfolio/hitler moustache.png';
import webpLittleWally from '../../pics/portfolio/little wally.webp';
import pngLittleWally from '../../pics/portfolio/little wally.png';

import imgBook from '../../pics/portfolio/book.gif';
import imgButterfly from '../../pics/portfolio/butterfly.gif';
import imgCalligraphy from '../../pics/portfolio/calligraphy.gif';
import imgClock from '../../pics/portfolio/clock.gif';
import imgCoin from '../../pics/portfolio/coin.gif';
import imgEarth from '../../pics/portfolio/earth.gif';
import imgKevin from '../../pics/portfolio/kevin.gif';
import imgMachine from '../../pics/portfolio/machine.gif';
import imgStatue from '../../pics/portfolio/statue.gif';

import webpPage0 from '../../pics/portfolio/page0.webp';
import jpgPage0 from '../../pics/portfolio/page0.jpg';
import webpPage1 from '../../pics/portfolio/page1.webp';
import jpgPage1 from '../../pics/portfolio/page1.jpg';
import webpPage2 from '../../pics/portfolio/page2.webp';
import jpgPage2 from '../../pics/portfolio/page2.jpg';
import webpPage3 from '../../pics/portfolio/page3.webp';
import jpgPage3 from '../../pics/portfolio/page3.jpg';
import webpWar from '../../pics/portfolio/war.webp';
import jpgWar from '../../pics/portfolio/war.jpg';
import webpMuscleMan from '../../pics/portfolio/muscle man.webp';
import jpgMuscleMan from '../../pics/portfolio/muscle man.jpg';
import imgJoolz0 from '../../pics/portfolio/joolz0.jpg';
import imgJoolz1 from '../../pics/portfolio/joolz1.jpg';
import imgJoolz2 from '../../pics/portfolio/joolz2.jpg';
import imgStatue0 from '../../pics/portfolio/statue0.jpg';
import webpStatue1 from '../../pics/portfolio/statue1.webp';
import jpgStatue1 from '../../pics/portfolio/statue1.jpg';
import webpStatue2 from '../../pics/portfolio/statue2.webp';
import jpgStatue2 from '../../pics/portfolio/statue2.jpg';

import thumbTimeMachine from '../../pics/portfolio/thumbs/time machine.jpg';
import thumbAnimation from '../../pics/portfolio/thumbs/animation.jpg';
import thumbMorph from '../../pics/portfolio/thumbs/morph.jpg';
import thumbCalligraphy from '../../pics/portfolio/thumbs/calligraphy.jpg';
import thumbKevin from '../../pics/portfolio/thumbs/kev.jpg';
import thumbLorraine from '../../pics/portfolio/thumbs/lorraine.jpg';
import thumbJoolz from '../../pics/portfolio/thumbs/joolz.jpg';

import webpGun from '../../pics/portfolio/gun.webp';
import pngGun from '../../pics/portfolio/gun.png';
import webpCauldron from '../../pics/portfolio/cauldron.webp';
import pngCauldron from '../../pics/portfolio/cauldron.png';
import webpLavender from '../../pics/portfolio/lavender.webp';
import pngLavender from '../../pics/portfolio/lavender.png';
import webpNewt from '../../pics/portfolio/newt.webp';
import pngNewt from '../../pics/portfolio/newt.png';
import webpBubbles0 from '../../pics/portfolio/bubbles0.webp';
import pngBubbles0 from '../../pics/portfolio/bubbles0.png';
import webpBubbles1 from '../../pics/portfolio/bubbles1.webp';
import pngBubbles1 from '../../pics/portfolio/bubbles1.png';
import webpBubbles2 from '../../pics/portfolio/bubbles2.webp';
import pngBubbles2 from '../../pics/portfolio/bubbles2.png';
import webpBubbles3 from '../../pics/portfolio/bubbles3.webp';
import pngBubbles3 from '../../pics/portfolio/bubbles3.png';
import webpFlames0 from '../../pics/portfolio/flames0.webp';
import pngFlames0 from '../../pics/portfolio/flames0.png';
import webpFlames1 from '../../pics/portfolio/flames1.webp';
import pngFlames1 from '../../pics/portfolio/flames1.png';
import webpFlames2 from '../../pics/portfolio/flames2.webp';
import pngFlames2 from '../../pics/portfolio/flames2.png';

import webpFlames3 from '../../pics/portfolio/flames3.webp';
import pngFlames3 from '../../pics/portfolio/flames3.png';
import webpCheese0 from '../../pics/portfolio/cheese0.webp';
import pngCheese0 from '../../pics/portfolio/cheese0.png';
import webpCheese1 from '../../pics/portfolio/cheese1.webp';
import pngCheese1 from '../../pics/portfolio/cheese1.png';
import webpPleaseKnock from '../../pics/portfolio/please knock.webp';
import pngPleaseKnock from '../../pics/portfolio/please knock.png';
import webpMouse from '../../pics/portfolio/mouse.webp';
import pngMouse from '../../pics/portfolio/mouse.png';
import webpLilMouse from '../../pics/portfolio/lil mouse.webp';
import pngLilMouse from '../../pics/portfolio/lil mouse.png';
import webpDoor0 from '../../pics/portfolio/door0.webp';
import pngDoor0 from '../../pics/portfolio/door0.png';
import webpDoor1 from '../../pics/portfolio/door1.webp';
import pngDoor1 from '../../pics/portfolio/door1.png';
import webpDoor2 from '../../pics/portfolio/door2.webp';
import pngDoor2 from '../../pics/portfolio/door2.png';
import webpHour from '../../pics/portfolio/hour hand.webp';
import pngHour from '../../pics/portfolio/hour hand.png';
import webpMinute from '../../pics/portfolio/minute hand.webp';
import pngMinute from '../../pics/portfolio/minute hand.png';
import webpHitlerHour from '../../pics/portfolio/hitler hour hand.webp';
import pngHitlerHour from '../../pics/portfolio/hitler hour hand.png';
import webpHitlerMinute from '../../pics/portfolio/hitler minute hand.webp';
import pngHitlerMinute from '../../pics/portfolio/hitler minute hand.png';
import webpHitlerSecond from '../../pics/portfolio/hitler second hand.webp';
import pngHitlerSecond from '../../pics/portfolio/hitler second hand.png';
import webpHitlerClock from '../../pics/portfolio/hitler clock.webp';
import jpgHitlerClock from '../../pics/portfolio/hitler clock.jpg';
import webpSecond from '../../pics/portfolio/second hand.webp';
import pngSecond from '../../pics/portfolio/second hand.png';
import webpClock0 from '../../pics/portfolio/clock0.webp';
import jpgClock0 from '../../pics/portfolio/clock0.jpg';
import webpClock1 from '../../pics/portfolio/clock1.webp';
import jpgClock1 from '../../pics/portfolio/clock1.jpg';
import webpClock2 from '../../pics/portfolio/clock2.webp';
import jpgClock2 from '../../pics/portfolio/clock2.jpg';
import webpClock3 from '../../pics/portfolio/clock3.webp';
import jpgClock3 from '../../pics/portfolio/clock3.jpg';
import webpOn from '../../pics/portfolio/on.webp';
import jpgOn from '../../pics/portfolio/on.jpg';
import webpDark from '../../pics/portfolio/dark.webp';
import jpgDark from '../../pics/portfolio/dark.jpg';
import webpOff from '../../pics/portfolio/off.webp';
import jpgOff from '../../pics/portfolio/off.jpg';
import webpCoin0 from '../../pics/portfolio/coin0.webp';
import jpgCoin0 from '../../pics/portfolio/coin0.jpg';
import webpCoin1 from '../../pics/portfolio/coin1.webp';
import jpgCoin1 from '../../pics/portfolio/coin1.jpg';
import imgAmbigram from '../../pics/portfolio/ambigram.jpg';
import imgKaisboatfund from '../../pics/portfolio/kaisboatfund.jpg';
import imgPdouglashammond from '../../pics/portfolio/pdouglashammond.jpg';

import gif0 from '../../pics/portfolio/old gifs/biscuit.gif'
import gif1 from '../../pics/portfolio/old gifs/blob.gif'
import gif3 from '../../pics/portfolio/old gifs/EAT.gif'
import gif4 from '../../pics/portfolio/old gifs/fllg.gif'
import gif5 from '../../pics/portfolio/old gifs/Jesus.gif'
import gif6 from '../../pics/portfolio/old gifs/KingKong.gif'
import gif7 from '../../pics/portfolio/old gifs/Michael.gif'
import gif9 from '../../pics/portfolio/old gifs/Wolverine.gif'

import axios from 'axios';

let webp = {};
webp.L1 = [webpL1, pngL1];
webp.L0 = [webpL0, pngL0];
webp.M0 = [webpM0,pngM0];
webp.M1 = [webpM1,pngM1];
webp.R0 = [webpR0,pngR0];
webp.R1 = [webpR1,pngR1];
webp.U0 = [webpU0,pngU0];
webp.U1 = [webpU1,pngU1];
webp.Wally = [webpWally,pngWally];
webp.Hitler = [webpHitler,pngHitler];
webp.DeadHitler = [webpDeadHitler,pngDeadHitler];
webp.Moustache = [webpMoustache,pngMoustache];
webp.HitlerMoustache = [webpHitlerMoustache,pngHitlerMoustache];
webp.LittleWally = [webpLittleWally,pngLittleWally];
webp.Page0 = [webpPage0,jpgPage0];
webp.Page1 = [webpPage1,jpgPage1];
webp.Page2 = [webpPage2,jpgPage2];
webp.Page3 = [webpPage3,jpgPage3];
webp.War = [webpWar,jpgWar];
webp.MuscleMan = [webpMuscleMan,jpgMuscleMan];
webp.Statue1 = [webpStatue1,jpgStatue1];
webp.Statue2 = [webpStatue2,jpgStatue2];
webp.Gun = [webpGun,pngGun];
webp.Cauldron = [webpCauldron,pngCauldron];
webp.Lavender = [webpLavender,pngLavender];
webp.Newt = [webpNewt,pngNewt];
webp.Bubbles0 = [webpBubbles0,pngBubbles0];
webp.Bubbles1 = [webpBubbles1,pngBubbles1];
webp.Bubbles2 = [webpBubbles2,pngBubbles2];
webp.Bubbles3 = [webpBubbles3,pngBubbles3];
webp.Flames0 = [webpFlames0,pngFlames0];
webp.Flames1 = [webpFlames1,pngFlames1];
webp.Flames2 = [webpFlames2,pngFlames2];
webp.Flames3 = [webpFlames3,pngFlames3];
webp.Cheese0 = [webpCheese0,pngCheese0];
webp.Cheese1 = [webpCheese1,pngCheese1];
webp.PleaseKnock = [webpPleaseKnock,pngPleaseKnock];
webp.Mouse = [webpMouse,pngMouse];
webp.LilMouse = [webpLilMouse,pngLilMouse];
webp.Door0 = [webpDoor0,pngDoor0];
webp.Door1 = [webpDoor1,pngDoor1];
webp.Door2 = [webpDoor2,pngDoor2];
webp.Hour = [webpHour,pngHour];
webp.Minute = [webpMinute,pngMinute];
webp.HitlerHour = [webpHitlerHour, pngHitlerHour];
webp.HitlerMinute = [webpHitlerMinute, pngHitlerMinute];
webp.HitlerSecond = [webpHitlerSecond,pngHitlerSecond];
webp.HitlerClock  = [webpHitlerClock ,jpgHitlerClock ];
webp.Second = [webpSecond,pngSecond];
webp.Clock0 = [webpClock0,jpgClock0];
webp.Clock1 = [webpClock1,jpgClock1];
webp.Clock2 = [webpClock2,jpgClock2];
webp.Clock3 = [webpClock3,jpgClock3];
webp.On = [webpOn,jpgOn];
webp.Dark = [webpDark,jpgDark];
webp.Off = [webpOff,jpgOff];
webp.Coin0 = [webpCoin0,jpgCoin0];
webp.Coin1 = [webpCoin1, jpgCoin1];


let imgCounter = [img0, img1, img2, img3, img4, img5, img6, img7, img8, img9];

let pics = {
    "logo": {img: imgLogo, w:348, h:272},
    "star": {img: imgStar, w:200, h:200},
    "lion": {img: imgLion, w:118, h:118},
    "flourish0": {img: imgFlourish0, w:102, h:128},
    "flourish1": {img: imgFlourish1, w:182, h:148},
    "flourish2": {img: imgFlourish2, w:216, h:124},
    "adobe": {img: imgAdobe, w:198, h:77},
    "banner": { img: imgBanner, w: 806, h: 162 },
    "link": {w: 0, h:0}
}

function lerp(min, max, value) {
    return (max - min) * value + min;
}

function superLerp(from, to, value) {
    if (value < from[0]) return to[0];
    if (value >= from[from.length - 1]) return to[to.length - 1];
    for (let i = 1; i < from.length; i++) {
        if (value < from[i]) {
            let min = to[i-1];
            let max = to[i];
            let v = (value - from[i-1]) / (from[i] - from[i-1]);
            return lerp(min, max, v);
        }
    }
}

function Modal({ heading, className, children, close, maxWidth=500 }) {
    let [small, setSmall] = useState(false);
    let [hovered, setHovered] = useState(false);
    let [click, setClick] = useState(false);
    useEffect(() => {
        let x1 = new Image();
        let x2 = new Image();
        x1.src = imgX1;
        x2.src = imgX2;
    },[])
    useEffect(() => {
        if (click) {
            setTimeout(()=>setClick(false),200)
        }
    }, [click])

    let { VH } = useContext(VHContext);

    return <div className="FullScreen" style={{height:VH}}>
        <div className={`modal ${className} ${small?'small':''}`} style={{maxWidth}}>
            <div className="modalHeading"><div className="heading">{heading}</div><img
                className="X"
                onClick={() => {
                    if (!small) {
                        setSmall(true);
                        setTimeout(close, 1000 * 0.25);
                    }
                }}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                onMouseDown={() => setClick(true)}
                alt="x" src={small?imgX0:click?imgX2:hovered?imgX1:imgX0} /></div>
            <div className="modalBody" style={{maxHeight:VH - 8 * 16}}>{children}</div>
        </div>
    </div>
}

function Counter({ count }) {
    if (!count) return null;
    let string = count + '';
    while (string.length < 4) string = '0' + string;
    string = string.split('');
    return <div className="PageCounter">
        {string.map((n, i) => <img src={imgCounter[n]} key={`${i}_${n}`} alt={n} />)}
        <img className="views" alt="Page views" src={imgViews}/>
    </div>
}

let GameContext = createContext();
let PhoneContext = createContext();
let VHContext = createContext();

export default function App({ }) {
    let [loaded, setLoaded] = useState(false);
    useEffect(() => {
        function CheckWebp(feature, callback) {
            let kTestImages = {
                lossy: "UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA",
                lossless: "UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==",
                alpha: "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==",
                animation: "UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA"
            };
            let img = new Image();
            img.onload = function () {
                let result = (img.width > 0) && (img.height > 0);
                callback(feature, result);
            };
            img.onerror = function () {
                callback(feature, false);
            };
            img.src = "data:image/webp;base64," + kTestImages[feature];
        }
        CheckWebp('lossy', function (feature, isSupported) {
            // console.log(`webP ${isSupported?'is':'is not'} supported!`)
            if (isSupported) {
                for (let k in webp) {
                    if (typeof webp[k] != 'string') webp[k] = webp[k][0];
                }
            } else {
                for (let k in webp) {
                    if (typeof webp[k] != 'string') webp[k] = webp[k][1];
                }
            }
            setLoaded(true);
        });
    })
    if (!loaded) return null;
    return <GraphicArt/>
}

function GraphicArt({ }) {
    let { setBackgroundColour } = useContext(AdminContext);
    let params = useParams();
    let route = params.route;
    if (route) route = route.toLowerCase();
    let navigate = useNavigate();
    let [counter, setCounter] = useState();
    let [past, setPast] = useState();
    let [viewed, setViewed] = useState({});
    let [viewedAll, setViewedAll] = useState(false);
    let [showViewedAllMessage, setShowViewedAllMessage] = useState(true);

    let [hitlered, setHitlered] = useState(false);
    let [cheesed, setCheesed] = useState(false);
    let [moused, setMoused] = useState(false);
    let [sataned, setSataned] = useState(false);
    let [fin, setFin] = useState(false);
    let [phone, setPhone] = useState(false);

    let [hitlerEscapedDialog, setHitlerEscapedDialog] = useState(false);

    let [VH, setVH] = useState(window.innerHeight);

    useEffect(() => {
        if (sataned) {
            setTimeout(()=>setHitlerEscapedDialog(true), 500)
        }
    }, [sataned])

    useEffect(() => {
        if (!route || route == 'gallery') setPast(route);
        viewed[route] = true;
        setViewed({ ...viewed });
        let viewedAll = true;
        port.forEach(({ url }) => {
            if (!viewed[url]) viewedAll = false;
        })
        setViewedAll(viewedAll);
    },[route])

    async function getCount() {
        let { data } = await axios.get('/api/portfolioCount');
        setCounter(data);
    }
    
    useEffect(() => {
        let img = new Image();
        img.src = "https://kaisboatfund.co.uk/api/pictures/heading.gif";
        setBackgroundColour("Stars");
        getCount();
        function touchstart() {
            setPhone(true);
            document.removeEventListener('touchstart', touchstart);
        }
        document.addEventListener('touchstart',touchstart);
        function resize() {
            setVH(window.innerHeight);
        }
        window.addEventListener('resize', resize);
        return () => {
            setBackgroundColour(false);
            document.removeEventListener('touchstart', touchstart);
            window.removeEventListener('resize', resize);
        }
    }, [])
    
    return <VHContext.Provider value={{VH}}><PhoneContext.Provider value={{phone}}><GameContext.Provider value={{hitlered, setHitlered, cheesed, setCheesed, moused, setMoused, sataned, setSataned, fin, setFin}}><div className="GraphicArt">

        {route == 'about' ? <Modal heading="About me!" className={route} close={() => navigate(`/portfolio${past ? '/' + past : ''}`)} >
            <p>My name is Kai, I live in East London and I have a degree in Computer Science from Bristol University, which has served me well in many areas of life.</p>
            <p>I hope you enjoy animated gifs - I put so many in this portfolio as they demonstrate a wide range of skills: creativity, visual design, and half the adobe suite.</p>
            <p>I'm good with my hands. In my portfolio, you will find experience in a decent selection of art mediums: pen & ink, book binding, animation, 3d printing, pewter casting, film making, and more!</p>
            <p>This is because of my insatiable urge to make things, which has inflicted me since childhood.</p>
            <p>I was born in 1993.</p>
        </Modal> : null}
        {route == 'contact' ? <Modal heading="Contact me!" className={route} close={() => navigate(`/portfolio${past?'/'+past:''}`)} >
            <p>Send me an email: <a href='mailto:kai@ambigr.am'>kai@ambigr.am</a></p>
        </Modal> : null}

        
        {route == 'manuscript' ? <Modal heading="Old English manuscript" className={`${route} portfolioItem`} close={() => navigate(`/portfolio/gallery`)} >
            <PortManuscript/>
        </Modal> : null}
        
        {route == 'animation' ? <Modal heading="Animation & motion graphics" className={`${route} portfolioItem`} close={() => navigate(`/portfolio/gallery`)} >
            <PortAnimation/>
        </Modal> : null}
        
        {route == 'calligraphy' ? <Modal heading="Calligraphy & hand doubling" className={`${route} portfolioItem`} close={() => navigate(`/portfolio/gallery`)} >
            <PortCalligraphy/>
        </Modal> : null}
        
        {route == 'clock' ? <Modal heading="Mickey Mouse clock" className={`${route} portfolioItem`} close={() => navigate(`/portfolio/gallery`)} >
            <PortClock/>
        </Modal> : null}
        
        {route == 'pink-eye' ? <Modal heading="Pewter coin" className={`${route} portfolioItem`} close={() => navigate(`/portfolio/gallery`)} >
            <PortCoin/>
        </Modal> : null}
        
        {route == 'websites' ? <Modal heading="Web development" className={`${route} portfolioItem`} close={() => navigate(`/portfolio/gallery`)} >
            <PortWebsites/>
        </Modal> : null}
        
        {route == 'films' ? <Modal heading="Film making" className={`${route} portfolioItem`} close={() => navigate(`/portfolio/gallery`)} >
            <PortFilms/>
        </Modal> : null}
        
        {route == 'time-machine' ? <Modal heading="Time machine" className={`${route} portfolioItem`} close={() => navigate(`/portfolio/gallery`)} >
            <PortTimeMachine/>
        </Modal> : null}
        
        {route == 'statue' ? <Modal heading="Plaster statue" className={`${route} portfolioItem`} close={() => navigate(`/portfolio/gallery`)} >
            <PortStatue/>
        </Modal> : null}


        <Fader show={route == 'gallery'}><Portfolio viewed={viewed} route={route} close={() => navigate('/')} /></Fader>
        <Fader show={!route}><AnimatedHeading /></Fader>
        <Fader show={!route||route == 'gallery'}><Counter count={counter} show={!route} /></Fader>
        
        {hitlerEscapedDialog?<Dialog hitlerMouseGun tree={[
            {text:"Oh no! Hitler escaped!",buttons:[["Oh no!",1]], mouse:true}, // 0
            {text:"Don't worry, he can't have gone far. Will you help me find him?",buttons:[["Yes!",2],["NO!",3]], mouse:true}, // 1
            {text:"Thank you. Here, have a gun.",buttons:[["OK",4]], mouse:true}, // 2
            {text:"HEY! This is your duty as a moral agent - we can't let Hitler escape, what's wrong with you?",buttons:[["Oh ok.",2]], mouse:true}, // 3
            {text:"[You took the gun from the mouse.]", italic:true ,buttons:[["Cool, a gun!",-1]], gun:true}, // 4
        ]} close={() => {
            setHitlerEscapedDialog(false);
        }} />:null}

    </div></GameContext.Provider></PhoneContext.Provider></VHContext.Provider>
}

function Fader({ show, children }) {
    let [visible, setVisible] = useState(false);
    let [render, setRender] = useState(false);
    useEffect(() => {
        if (show) {
            setVisible(true);
            setRender(true);
        } else {
            setVisible(false);
            setTimeout(() => setRender(false), 800);
        }
    }, [show])
    return <div className={`Fader ${!visible?'invisible':''}`}>
        {render?children:null}
    </div>
}

function AnimatedHeading({ }) {
    let [pos, setPos] = useState([0, 0]);
    let [starLoaded, setStarLoaded] = useState(false);
    let [showStar, setShowStar] = useState(true);
    let [win, setWin, getWin] = useGetState([window.innerWidth,window.innerHeight]);
    let [scale, setScale, getScale] = useGetState(1);
    let [bannerScale, setBannerScale, getBannerScale] = useGetState(1);
    let [force, forceRender] = useReducer(f => f + 1, 0);

    let maxScale = 560;

    useEffect(() => {
        function resize() {
            let rect = background.current.getBoundingClientRect();
            let w = rect.width, h = rect.height;
            setWin([w, h]);
            if (w < maxScale) {
                setScale(w / maxScale);
            } else {
                setScale(1);
            }
            if (w < pics.banner.w) {
                setBannerScale(w / pics.banner.w);
            } else {
                setBannerScale(1);
            }
        }
        resize();
        window.addEventListener('resize', resize);
        return () => {
            window.removeEventListener('resize', resize);
        }
    }, [])

    function getLeft(x, img = "logo", scale) {
        if (!scale) scale = getScale();
        return (getWin()[0] / 2) + (x - pics[img].w / 2) * scale;
    }
    function getTop(y, img = "logo", scale) {
        if (!scale) scale = getScale();
        return (y - pics[img].h / 2 + offsetY + (headingHeight / 2)) * scale;
    }
    function getLinkLeft(x, route) {
        let w = linksRef.current[route] ? linksRef.current[route].getBoundingClientRect().width : 0;
        return (getWin()[0] / 2) + (x * scale - w / 2) ;
    }
    function getLinkTop(y, route) {
        let h = linksRef.current[route] ? linksRef.current[route].getBoundingClientRect().height : 0;
        return (y + offsetY + (headingHeight / 2)) * scale - h / 2;
    }

    function randomiseStarPos() {
        let details = pics.logo;
        let { x, y } = gifs[0];
        let left = getLeft(x);
        let top = getTop(y);
        let scale = getScale();
        let w = pics.star.w * scale;
        let pos = [left + Math.floor(Math.random() * details.w*scale) - w/2, top + Math.floor(Math.random() * details.h*scale) - w/2];
        setShowStar(false);
        setTimeout(() => {
            setPos(pos);
            setTimeout(() => {
                setShowStar(true);
                setTimeout(randomiseStarPos,3000);
            }, 10);
        }, 10);
    }

    useEffect(() => {
        if (starLoaded) randomiseStarPos();
    }, [starLoaded])

    let background = useRef();

    let headingHeight = 520;
    let offsetY = -22;

    let gifs = [
        { img: "logo", x: -2, y: -1, },
        { img: "lion", x: 224, y: 6, },
        { img: "lion", x: -224, y: 6, flip:true },
        { img: "flourish0", x:165, y:-116 },
        { img: "flourish0", x: -165, y: -116, flip:true },
        { img: "flourish1", x: 183, y: 140, flip:true },
        { img: "flourish1", x: -183, y: 140, },
        { img: "flourish2", x: 0, y: 211, },
        { img: "adobe", x: 0, y: -184, },
        { img: "banner", x: 0, y: superLerp( [560, 666, 806], [450, 330, 225], win[0]) },
    ]

    let links = [
        {
            title: 'About me',
            x: superLerp(
                [300,400],
                [-160,-123],
                win[0]),
            y: superLerp(
                [400,560],
                [278,258],
                win[0]),
            route: 'about'
        },
        {
            title: 'Contact',
            x: -0, y: 278,
            route: 'contact'
        },
        {
            title: 'Portfolio',
            x: superLerp(
                [300,400],
                [160,122],
                win[0]),
            y: superLerp(
                [400,560],
                [278,258],
                win[0]),
            route: 'gallery'
        },
    ]
    let linksRef = useRef({});
    
    let AnimatedHeadingHeight = headingHeight * scale;
    let { VH } = useContext(VHContext);

    return <div className={`Heading`} style={{height:VH}} ref={background}>
        <div className="AnimatedHeading" style={{height:AnimatedHeadingHeight}}>
            {gifs.map(({ img, x, y, flip }, i) => <img
                key={`${img}_${i}_${win.join(',')}`}
                className={`${img} abs ${flip ? 'flip' : ''} ${pics[img].loaded?'':'unloaded'}`}
                alt="logo" src={pics[img].img}
                style={{
                    left: getLeft(x,img,img=='banner'?bannerScale:scale),
                    top: getTop(y, img,img=='banner'?bannerScale:scale),
                    width: pics[img].w*(img=='banner'?bannerScale:scale)
                    // transform: scale == 1 ? null : `scale(${scale})`
                }}
                onLoad={() => {
                    pics[img].loaded = true;
                    forceRender();
                }}
            />)}
            <img className={showStar ? `star abs` : 'HIDDEN'} alt="star" src={showStar ? imgStar : null} style={{ left: pos[0], top: pos[1], width: pics.star.w*scale }} onLoad={() => setStarLoaded(true)} />
            {links.map(({ title, x, y, route }) => <div className={`link abs`} key={route} ref={r=>linksRef.current[route] = r} style={{
                left: getLinkLeft(x,route),
                top: getLinkTop(y,route)
            }}><NavLink to={`/portfolio/${route}`}>{title}</NavLink></div>)}
        </div>
    </div>
}

function PortImg({ viewed,className, src, alt, style, onClick, onMouseEnter, onMouseLeave, scale }) {
    let [loaded, setLoaded] = useState(false);
    let [hover, setHover] = useState(false);
    let { phone } = useContext(PhoneContext);
    return <div className="portImg" style={style} onClick={onClick} onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)}>
        <img className={`${className} abs ${!loaded ? 'unloaded' : ''}`} src={src} alt={alt ? alt : src} onLoad={() => setLoaded(true)} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} />
        <div className="viewed" style={{ transform: `scale(${1 / scale})` }}><div className={`title ${!phone&&!hover?'invisible':''}`}>{alt}</div>{viewed?<div className="v">Viewed!</div>:null}</div>
    </div>
}

let port = [
    {src:imgButterfly, url:'animation', alt:"Animation", w:160, h:163},
    {src:imgBook, url:'manuscript', alt:"Manuscript", w:180, h:141},
    {src:imgCalligraphy, url:'calligraphy', alt:"Calligraphy", w:118, h:160},
    {src:imgClock, url:'clock', alt:"Mickey mouse clock", w:131, h:160},
    {src:imgCoin, url:'pink-eye', alt:"Pink Eye coin", w:140, h:140},
    {src:imgEarth, url:'websites', alt:"Websites", w:102, h:102},
    {src:imgMachine, url:'time-machine', alt:"Time machine", w:164, h:164},
    {src:imgKevin, url:'films', alt:"Films", w:124, h:140},
    {src:imgStatue, url:'statue', alt:"Statue", w:108, h:160},
]

function calcMag() {
    let W = window.innerWidth;
    let w = W<500?W/2-40:W * 0.33;
    let h = window.innerHeight * 0.33;
    w = Math.min(w, 350);
    h = Math.min(h, 250);
    return [w, h];
}
function calcS() {
    let w = window.innerWidth * 0.33;
    let h = window.innerHeight * 0.33;
    let m = Math.min(w,h)
    let s = superLerp([88, 250], [0.5, 1], m);
    return s;
}

function Portfolio({ viewed: viewed0 }) {
    let [xy, setXy] = useState(port.map(p=>[0,0]));
    let [wh, setWh] = useState([window.innerWidth, window.innerHeight]);
    let [s, setS] = useState(calcS(calcMag()));
    let [mag, setMag, getMag] = useGetState(calcMag());
    let r = useRef(0);
    let speed = Math.PI * 0.02;
    let [tooltip, setTooltip] = useState();
    let [mouse, setMouse] = useState();
    let [viewed] = useState({...viewed0})
        
    useEffect(() => {
        // shuffle(port);
        function resize() {
            let w = window.innerWidth;
            let h = window.innerHeight;
            let mag = calcMag();
            setMag(mag);
            setS(calcS(mag));
            setWh([w, h]);
        }
        // function mousemove(e) {
        //     setMouse([e.pageX, e.pageY]);
        // }
        function getXy() {
            r.current += speed;
            let mag = getMag();
            setXy(port.map((p, i) => {
                let a = r.current + (i/port.length) * (Math.PI * 2);
                let x = Math.cos(a) * mag[0];
                let sin = Math.sin(a);
                let y = Math.sin(a) * mag[1];
                let z = Math.floor((sin + 1) * 100) + 100;
                return [x, y, z];
            }))
        }
        getXy();
        resize();
        window.addEventListener('resize', resize);
        // window.addEventListener('mousemove', mousemove);
        let interval = setInterval(getXy, 1000 * 0.5);
        return () => {
            clearInterval(interval);
            window.removeEventListener('resize', resize);
            // window.removeEventListener('mousemove', mousemove);
        }
    }, [])
    let { phone } = useContext(PhoneContext);
    let { VH } = useContext(VHContext);
    
    return <div>
        <div className={`Portfolio`} style={{height:VH}}>
            <div className="ontop buttons">
                <div><NavLink to="/portfolio">Home</NavLink></div>
                <div><NavLink to="/portfolio/about">About me</NavLink></div>
                <div><NavLink to="/portfolio/contact">Contact</NavLink></div>
            </div>
            {port.map(({ src, alt, w, h, url },i) => <NavLink className="byebye" key={url} to={`/portfolio/${url}`}><PortImg
                src={src} alt={alt}
                style={{
                    left: wh[0]/2 + xy[i][0] - w / 2,
                    top: wh[1] / 2 + xy[i][1] - h / 2,
                    zIndex: xy[i][2],
                    transform:`scale(${s})`
                }}
                scale={s}
                onMouseEnter={()=>setTooltip(alt)}
                onMouseLeave={() => setTooltip()}
                viewed={viewed[url]}
            /></NavLink>)}
            {/* {tooltip ? <div className="tooltip" style={{
                left: mouse[0]+32,
                top: mouse[1]
            }}>{tooltip}</div>:null} */}
        </div>
    </div>
}

function Thumbnails({ imgs, ratio, noMarginBottom, row=2 }) {
    let [select, setSelect] = useState(-1);
    let [invisible, setInvisible] = useState(false);
    let [dimensions, setDimensions] = useState();
    let [size, setSize] = useState();
    let gap = 8;
    function close() {
        setInvisible(true);
        setTimeout(() => {
            setSelect(-1);
            setInvisible(false);
        }, 250)
    }
    useEffect(() => {
        let dimensions = {};
        let t = 0;
        function pushDimensions(i,d) {
            dimensions[i] = d;
            t++;
            if (t == imgs.length) {
                setDimensions(dimensions);
            }
        }
        imgs.forEach(({ src, alt }, i) => {
            let img = new Image();
            img.src = src;
            img.onload = () => pushDimensions(i, [img.width, img.height]);
        })
    }, [])
    useEffect(() => {
        setSize();
        if (select >= 0 && dimensions) {
            let d = dimensions[select];
            function resize() {
                let maxW = window.innerWidth - 16 * 3;
                let maxH = window.innerHeight - 16 * 6;

                let w0 = Math.min(maxW, d[0]);
                let h0 = (w0 / d[0]) * d[1];

                let h1 = Math.min(maxH, d[1]);
                let w1 = (h1 / d[1]) * d[0];

                setSize(h0>maxH?w1:w0);
            }
            resize();
            setTimeout(resize,500);
            window.addEventListener('resize', resize);
            return () => window.removeEventListener('resize', resize);
        }
    }, [dimensions, select])
    
    useEffect(() => {
        if (select >= 0) {
            let mousedown = e => {
                if (e.button == 0 && e.target.nodeName != 'BUTTON') {
                    close();
                }
            }
            document.addEventListener('mousedown', mousedown);
            return () => document.removeEventListener('mousedown',mousedown)
        }
    }, [select])
    let { VH } = useContext(VHContext);
    
    return <>
        <div className={`thumbnails ${noMarginBottom?'noMarginBottom':''}`}>
            {imgs.map(({ src, alt }, i) => <div key={`${i} ${src}`} style={{ width: `calc((${ratio?ratio[i]*100:100}% - ${gap * (row - 1)}px) / ${row})` }}><img src={src} alt={alt} onClick={()=>setSelect(i)} /></div>)}
        </div>
        {select >= 0 ? <div className={`FullScreen  ${invisible ? 'invisible' : ''}`} style={{height:VH}}>
            <div className="starry imageScreen" style={{height:VH}}>
                <button className="X" onClick={close}><svg viewBox="0 0 32 32" > <rect x="-3.6" y="13" transform="matrix(0.7071 0.7071 -0.7071 0.7071 15.9995 -6.6272)" width="39.3" height="6"/> <rect x="-3.6" y="13" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 38.6263 15.9995)" width="39.3" height="6"/> </svg></button>
                
                {size ? <div className="image">
                    <div className="img" style={{ width: size }}><img key={select} src={imgs[select].src} alt={imgs[select].alt} onClick={close}/></div>
                    <div className="caption">
                        <div className="caption">
                            <button disabled={select <= 0} onClick={()=>setSelect(s=>s-1)}><svg viewBox="0 0 32 32" > <polygon points="32,13 11.5,13 19.6,4.9 15.4,0.6 0,16 15.4,31.4 19.6,27.1 11.5,19 32,19 "/> </svg></button>
                            {imgs[select].alt}
                            <button disabled={select >= imgs.length-1} onClick={()=>setSelect(s=>s+1)}><svg viewBox="0 0 32 32" > <polygon points="0,19 20.5,19 12.4,27.1 16.6,31.4 32,16 16.6,0.6 12.4,4.9 20.5,13 0,13 " /> </svg></button>
                        </div>
                    </div>
                </div>:null}
            </div>
        </div> : null}
    </>
}

function ImageThumbnail({ src, alt, noMarginBottom, maxWidth }) {
    let [expanded, setExpanded] = useState(false);
    let [dimensions, setDimensions] = useState();
    let [size, setSize] = useState();
    let [invisible, setInvisible] = useState(false);

    useEffect(() => {
        let img = new Image();
        img.src = src;
        img.onload = () => {
            let height = img.height;
            let width = img.width;
            setDimensions([width, height])
        }
    }, [])

    function close() {
        setInvisible(true);
        setTimeout(() => {
            setExpanded(false);
            setInvisible(false);
        }, 250)
    }

    useEffect(() => {
        if (dimensions) {
            function resize() {
                let maxW = window.innerWidth - 16 * 3;
                let maxH = window.innerHeight - 16 * 4;

                let w0 = Math.min(maxW, dimensions[0]);
                let h0 = (w0 / dimensions[0]) * dimensions[1];

                let h1 = Math.min(maxH, dimensions[1]);
                let w1 = (h1 / dimensions[1]) * dimensions[0];

                setSize(h0>maxH?w1:w0);
            }
            resize();
            setTimeout(resize,500);
            window.addEventListener('resize', resize);
            return () => window.removeEventListener('resize', resize);
        }
    }, [dimensions])
    let { VH } = useContext(VHContext);

    return <>
        <div className={`centre ${noMarginBottom?'noMarginBottom':''} thumbnail`}>
            <img src={src} alt={alt} onClick={() => setExpanded(true)} style={{maxWidth}} />
        </div>
        
        {expanded ? <div className={`FullScreen ${invisible ? 'invisible' : ''}`} onClick={close} style={{height:VH}}>
            <div className="starry imageScreen" style={{height:VH}}>
                <button className="X"><svg viewBox="0 0 32 32" > <rect x="-3.6" y="13" transform="matrix(0.7071 0.7071 -0.7071 0.7071 15.9995 -6.6272)" width="39.3" height="6"/> <rect x="-3.6" y="13" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 38.6263 15.9995)" width="39.3" height="6"/> </svg></button>
                
                {size?<img src={src} alt={alt} onClick={close} style={{width:size}} />:null}
            </div>
        </div> : null}
    </>
}

function Video({ id, thumb, title, noMarginBottom }) {
    let [dimensions, setDimensions] = useState([720,1280]);
    let [expanded, setExpanded] = useState(false);
    let [invisible, setInvisible] = useState(false);
    let [hover, setHover] = useState(false);
    let ref = useRef();
    useEffect(() => {
        let img = new Image();
        img.src = imgPlay;
        function resize() {
            let w = window.innerWidth - 16 * 4;
            w = Math.min(w, 1280);
            let h = (w / 16) * 9;
            setDimensions([w, h]);
        }
        resize();
        setTimeout(resize,500);
        window.addEventListener('resize', resize);
        return () => window.removeEventListener('resize', resize);
    }, [])

    function CLOSE() {
        setInvisible(true);
        setTimeout(() => {
            setExpanded(false);
            setInvisible(false);
        }, 250)
    }
    let { VH } = useContext(VHContext);

    return <>
        <div className={`Video ${noMarginBottom?'noMarginBottom':''}`} ref={ref} onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)} onClick={()=>setExpanded(true)}>
            <img src={thumb} alt="title" />
            <img className="playButton" src={hover?imgPlay:imgPause} alt="play"/>
        </div>
        {expanded ? <div className={`FullScreen ${invisible ? 'invisible' : ''}`} onClick={CLOSE} style={{height:VH}}>
            <div className="starry videoScreen" style={{height:VH}}>
                <button className="X"><svg viewBox="0 0 32 32" > <rect x="-3.6" y="13" transform="matrix(0.7071 0.7071 -0.7071 0.7071 15.9995 -6.6272)" width="39.3" height="6"/> <rect x="-3.6" y="13" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 38.6263 15.9995)" width="39.3" height="6"/> </svg></button>
                
                <div className="video" style={{width:dimensions[0],height:dimensions[1]}}><iframe
                    src={`https://fast.wistia.net/embed/iframe/${id}?videoFoam=true`}
                    title={title}
                    allow="autoplay; fullscreen"
                    frameBorder="0"
                    scrolling="no"
                    width={dimensions[0]}
                    height={dimensions[1]}
                /></div>
            </div>
        </div> : null}
    </>
}


function calcModalW() {
    let w = Math.min(448, window.innerWidth - 68);
    return w;
}

function PortManuscript({ }) {
    let { phone } = useContext(PhoneContext);
    return <div>
        <h2>Old English manuscript</h2>
        <p>I created 4 pages of an Old Enligh manuscript, with illustrations depicting the story of the rise and fall of an evil king with a kalashnikov.</p>
        <ImageThumbnail src={webp.War} alt="Kalashnikov"/>
        <p>The calligraphy is all hand-written, scanned into the computer and edited to make it look like parts of the ink had been lost with age.</p>
        <p>I then printed the pages and aged them using tea. These pictures are the scanned results.</p>
        <p>You may {phone?'tap':'click'} an image to view it in full screen!</p>
        <Thumbnails row={2} imgs={[
            {src: webp.Page0, alt: "The king and his lover."},
            {src: webp.Page1, alt: "The great battle!"},
            {src: webp.Page2, alt: "Betrayal!"},
            {src: webp.Page3, alt: "Regret."},
        ]}/>
        <p>I am also into book binding, and have bound a few books as a hobby.</p>
        <p>My friend Jools makes tour guide films about London, where he often needs to spout off interesting facts.</p>
        <p>For his birthday I made him a book with a hidden compartment inside for an iPhone, so he can be seen on screen reading from the book, when he's actually got his notes up on his phone!</p>
        <p>Here it is in action:</p>
        <Video id="16noesnq71" thumb={thumbJoolz} title="Joolz guides" />
        <p>It was designed to look like an old lino print book cover. The pages are from a book I got from the antique shop, but I took off the cover and bound it with my own!</p>
        <p>Here are some pictures - {phone?'tap':'click'} to view them in full screen:</p>
        <Thumbnails noMarginBottom row={3} imgs={[
            {src: imgJoolz0, alt: "Front cover"},
            {src: imgJoolz1, alt: "Hidden compartment"},
            {src: imgJoolz2, alt: "Back cover"},
        ]}/>
    </div>
}

function PortAnimation({ }) {
    return <div>
        <h2>Animation & motion graphics</h2>
        <p>I used to do a lot of animation and motion graphics, mostly for musicians and music videos. I used a range of techniques such as hand-drawn animation, digital compositing and stop-motion.</p>
        <p>Here is the showreel I sent to musicians - all of the work is mine, including the original music:</p>

        <Video id="6uz3b9eav2" thumb={thumbAnimation} title="Animation showreel"/>

        <p>In 2015 I came 1<sup>st</sup> place in an Aardman Animations competition to make a short film featuring their character Morph. They sent me a golden morph statue!</p>

        <Video id="6ozbvmaq2d" thumb={thumbMorph} title="Morph plays with clay"/>

        <p>And just for fun, I found some animated gifs on an old hard drive that I made when I was a teenager, for use as MSN Messenger profile pictures:</p>
        <div className="flexwrap">
            <img src={gif0} alt="gif0"/>
            <img src={gif1} alt="gif1"/>
            <img src={gif3} alt="gif3"/>
            <img src={gif4} alt="gif4"/>
            <img src={gif5} alt="gif5"/>
            <img src={gif6} alt="gif6"/>
            <img src={gif7} alt="gif7"/>
            <img src={gif9} alt="gif9"/>
        </div>
    </div>
}

function PortCalligraphy({ }) {
    let { phone } = useContext(PhoneContext);

    return <div>
        <h2>Calligraphy & hand doubling</h2>
        <p>I've been into calligraphy and period handwriting since I was a teenager. I've done work as a hand double for period dramas. Here is my video showreel - {phone?'tap':'click'} it to watch:</p>

        <Video noMarginBottom id="34ghgmc29p" thumb={thumbCalligraphy} title="Calligraphy showreel"/>
    </div>
}

function PortFilms({ }) {
    return <div>
        <h2>Film making</h2>
        <p>In 2017 I had a shot at making my own short film. I enjoyed it so much that I continued and have made about a dozen now, to varying degrees of quality.</p>
        <p>My friend Nim operates the camera but I also do some camera work, and I edit them and compose all the music!</p>

        <h4>Disabled Kevin</h4>
        <p>Here is one of my favourites, and a good candidate for this portfolio, as it is quite stylised. It follows a quadriplegic man called Disabled Kevin doing a nude performance for his master's degree. Don't worry, it is only 5 minutes long and the nudity is tasteful!</p>

        <Video id="t1ngcok55e" thumb={thumbKevin} title="Disabled Kevin"/>

        <h4>Lorraine Bowen</h4>
        <p>If you're not into naked quadriplegic men, maybe you will enjoy this one about Britain's got Talent's semi-finalist Lorraine Bowen and her career writing songs about crumble. It's a bit more fun and not so serious.</p>

        <Video id="hd5p51sq7d" thumb={thumbLorraine} title="Lorraine Bowen"/>

        <p>If you enjoyed my films, I would be honoured if you followed me on YouTube! Here is the link: <a href="https://www.youtube.com/@WeirdSide" target="_blank" rel="noreferrer">youtube.com/@WeirdSide</a></p>
        <p className="noMarginBottom">Don't forget to like and subscribe!</p>
    </div>
}

function PortTimeMachine({ }) {
    let [w, setW] = useState(calcModalW());
    let ref = useRef();
    let [passwordEntered, setPasswordEntered] = useState(false);
    let [showHitler, setShowHitler] = useState(false);
    let [disappear, setDisappear] = useState(false);
    let [flash, setFlash] = useState(false);
    let {hitlered, setHitlered} = useContext(GameContext);

    useEffect(() => {
        function resize() {
            let w = ref.current.getBoundingClientRect().width;
            setW(w);
        }
        setTimeout(resize,500);
        window.addEventListener('resize', resize);
        return () => window.removeEventListener('resize', resize);
    }, [])

    useEffect(() => {
        if (passwordEntered) setTimeout(()=>setShowHitler(true),3333);
    }, [passwordEntered])
    
    useEffect(() => {
        if (disappear) {
            setFlash(true);
            setHitlered(true);
            setTimeout(() => setFlash(false), 1000);
        }
    }, [disappear])

    let { phone } = useContext(PhoneContext);
    let { VH } = useContext(VHContext)

    return <div ref={ref}>
        <h2>Time machine</h2>
        <p>This time machine is made out of wood with various greeblies and electronics attached to give it authenticity. The screen composited on - the red and blue lights are real so that they can create legitimate highlights on the scene around it, but I did accentuate it in post.</p>
        <p>It is designed to work well with the visual effects, but to also function as a prop on its own. The wood was salvaged from an old poker chest, so I did not have to do any further ageing on it.</p>
        <p>Here is a video to show how it can transport the user through time and space!</p>
        <Video id="3utri788cv" thumb={thumbTimeMachine} title="Time machine"/>
        <p>Here's the full image of the time machine:</p>
        <TimeMachine w={w} setPasswordEntered={setPasswordEntered} disappear={hitlered} />
        <LilWally showWally={!passwordEntered && !hitlered} />
        {showHitler?<Dialog tree={[
            {text:"Wow, you are a genius!",buttons:[["I know",1],["Aww, thanks!",1]]}, // 0
            {text:"And now it is time for you to learn the truth... This is not a real moustache!",buttons:[["OK...",2]]}, // 1
            {text:`[${phone?'Tap':'Click'} the moustache to remove it]`, removeMoustache:true, italic:true}, // 2
            {text:"That's right! In reality, I am Adolf Hitler - evil führer of the third reich, and now I have a time machine!!!",buttons:[["Oh shit!",4]]}, // 3
            {text:"Now I shall travel back to 1945 and finish what I started. HAHAHAHAHA!!!",buttons:[["ok :(",-1]]}, // 4
        ]} close={() => {
            setShowHitler(false);
            setTimeout(()=>setDisappear(true),500);
        }} /> : null}
        {disappear&&flash?<div className="FLASH" style={{height:VH}}></div>:null}
    </div>
}

function TimeMachine({ w, setPasswordEntered, disappear }) {
    let [lights, setLights] = useState(false);
    let [on, setOn] = useState(false);
    let { cheesed, setCheesed } = useContext(GameContext);
    let [timeMachineLoaded, setTimeMachineLoaded] = useState(0);
    let [cheeseMessage, setCheeseMessage] = useState(false);
    useEffect(() => {
        if (on) {
            let f = () => setLights(l => !l);
            let interval = setInterval(f, 600);
            f();
            setPasswordEntered(true);
            return () => clearInterval(interval);
        }
    }, [on])
    
    let imgD = [428, 433];
    let W = Math.min(w, imgD[0]);

    let s = W / imgD[0];
    let d = imgD.map(x => x * s);

    return <>
        <div className={`TimeMachine`} style={{width:w, height:d[1]}}>
            <div className={`timeMachine0 ${disappear?'invisible':''}`} style={{width:d[0], height:d[1], left:(w-imgD[0])/2, top:(d[1]-imgD[1])/2}}>
                <div className="timeMachine1" style={{width:imgD[0], height:imgD[1],transform:`scale(${s})`}}>
                    <img className="tm off" src={webp.Off} alt="time machine" onLoad={()=>setTimeMachineLoaded(l=>l+1)} />
                    <img className={`tm on ${on ? '' : 'invisible'}`} src={webp.Dark} alt="time machine" onLoad={()=>setTimeMachineLoaded(l=>l+1)}/>
                    <img className={`tm on ${lights ? '' : 'invisible'}`} src={webp.On} alt="time machine" onLoad={()=>setTimeMachineLoaded(l=>l+1)}/>
                    <TimeMachineScreen setOn={setOn}/>
                </div>
            </div>
            {timeMachineLoaded==3?<img onClick={()=>setCheeseMessage(true)} className={`cheese ${cheesed?'invisible':''}`} src={webp.Cheese0} alt="cheese!"/>:null}
        </div>
        {cheeseMessage ? <Dialog cheese tree={[
            {text:"There was a slice of cheese behind the time machine!",buttons:[["Wow!",1]]}, // 0
            {text:"You put the cheese in your pocket.",buttons:[["OK",-1]]}, // 1
        ]} close={() => {
            setCheeseMessage(false);
            setCheesed(true);
        }} /> : null}
    </>
}

function LilWally({ showWally }) {
    let [hover, setHover] = useState(false);
    let [click, setClick] = useState(false);
    return <>
        <div className={`lilWally ${showWally ? hover || click?'hover':'' : 'hide'}`}>
            <img src={webp.LittleWally} alt="wally" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={()=>setClick(true)} />
        </div>
        {click ? <Dialog tree={[
            {text:"Hi! My name's Wa... erm - I mean Willy! Do you like my real moustache?",buttons:[["Yes",1],["NO!",2]]}, // 0
            {text:"Thank you, I grew it myself!",buttons:[["OK",3]]}, // 1
            {text:"Huh - maybe I should try it in a different style...",buttons:[["OK",3]]}, // 2
            {text:"I say, have you seen that fascinating time machine? It needs a password to operate it - do you know the password?",buttons:[["Yes",4],["No",5]]}, // 3
            {text:"Excellent! Apparently the password is the creator's year of birth. I say, could you please enter the password into the machine?",buttons:[["OK!",6],["No, piss off!",7]]}, // 4
            {text:"Ah that is a shame. Apparently the password is the creator's year of birth, but I don't know where to find that information!",buttons:[["OK.",8]]}, // 5
            {text:"You're a star!",buttons:[["OK dude.",-1]]}, // 6
            {text:"Hrmph. You're no fun!",buttons:[["OK dude.",-1]]}, // 7
            {text:"If you ever find the password, could you please enter it into the machine for me?",buttons:[["OK!",6],["No, piss off!",7]]}, // 8
        ]} close={()=>setClick(false)}/>:null}
    </>
}

function Dialog({ tree, close, mouse, cheese, callback, hitlerMouseGun }) {
    let [i, setI] = useState(0);
    let [invisible, setInvisible] = useState(false);
    let [text, setText] = useState('');
    let [loadedProfile, setLoadedProfile] = useState(false);
    let [hitler, setHitler] = useState(false);

    function CLOSE() {
        if (!invisible) {
            setInvisible(true);
            setTimeout(close, 1000 * 0.25);
        }
    }

    function CALLBACK() {
        if (!invisible) {
            setInvisible(true);
            setTimeout(callback, 1000 * 0.25);
        }
    }

    useEffect(() => {
        if (loadedProfile) {
            let t = 0;
            let interval = setInterval(() => {
                t++;
                if (t <= tree[i].text.length) {
                    setText(tree[i].text.substring(0,t))
                } else {
                    clearInterval(interval);
                }
            },10)
            return ()=>clearInterval(interval)
        }
    }, [i, loadedProfile])

    useEffect(() => {
        if (hitler) {
            setTimeout(() => {
                setI(i + 1);
            },1000)
        }
    }, [hitler])

    let { VH } = useContext(VHContext);

    return <div className={`FullScreen ${invisible?'invisible':''}`} style={{height:VH}}>
        <div className="Dialog">
            {hitlerMouseGun ? <HitlerMouseGunPicture setI={setI} mouse={i>=0 ? tree[i].mouse : tree[tree.length-1].mouse} hitler={i>=0 ? tree[i].hitler : tree[tree.length-1].hitler} gun={i>=0 ? tree[i].gun : tree[tree.length-1].gun} deadHitler={i>=0?tree[i].deadHitler:false} shootable={i>=0?tree[i].shootable:false} setLoadedProfile={setLoadedProfile}/>:cheese?<ProfilePicture src={webp.Cheese1} setLoadedProfile={setLoadedProfile}/>:mouse?<ProfilePicture src={webp.Mouse} setLoadedProfile={setLoadedProfile}/>:<WallyProfilePicture
                setLoadedProfile={setLoadedProfile}
                removeMoustache={tree[i].removeMoustache}
                useHitler={[hitler, setHitler]}
            />}
            <div className="dialogBody">
                <div className="text">
                    {tree[i].italic ? <i>{text}</i>:tree[i].bold ? <b>{text}</b>:text}
                </div>
                {text.length == tree[i].text.length && tree[i].buttons?<div className="buttons">
                    {tree[i].buttons.map(([text, j],k) => <button onClick={()=>j == -1 ?CLOSE():j == -2 ? CALLBACK() :setI(j)} key={`${i} ${k}`}>{text}</button>)}
                </div>:null}
            </div>
        </div>
    </div>
}

function ProfilePicture({ setLoadedProfile, src }) {
    let [loaded, setLoaded] = useState(false);
    let [visible, setVisible] = useState(false);
    let [s, setS] = useState(1);

    useEffect(() => {
        if (loaded) setTimeout(() => {
            setVisible(true)
            setTimeout(()=>setLoadedProfile(true),500)
        }, 100);
    }, [loaded])

    useEffect(() => {
        function resize() {
            setS(superLerp([400, 600], [0.5, 1], window.innerWidth));
        }
        resize();
        window.addEventListener('resize', resize);
        return () => window.removeEventListener('resize', resize);
    },[])
    
    let h = 213;
    let w = 212;

    return <div className="wallyProfilePicture" style={{width:w*s, height:h*s}}>
        <div className={`wallyContainer ${visible ? '' : 'invisible'}`}>
            <img src={src} alt="mouse" onLoad={() => setLoaded(true)} style={{ width: w * s }} />
        </div>
    </div>
}

function HitlerMouseGunPicture({ setLoadedProfile, hitler, gun, mouse, deadHitler, shootable, setI }) {
    let [loaded, setLoaded] = useState(0);
    let [visible, setVisible] = useState(false);
    let [s, setS] = useState(1);
    let [dead, setDead] = useState(false);

    useEffect(() => {
        if (loaded == 4) setTimeout(() => {
            setVisible(true)
            setTimeout(()=>setLoadedProfile(true),500)
        }, 100);
    }, [loaded])

    useEffect(() => {
        function resize() {
            setS(superLerp([400, 600], [0.5, 1], window.innerWidth));
        }
        resize();
        window.addEventListener('resize', resize);
        return () => window.removeEventListener('resize', resize);
    },[])
    
    let h = 213;
    let w = 212;

    return <div className="wallyProfilePicture" style={{width:w*s, height:h*s}}>
        <div className={`wallyContainer ${visible ? '' : 'invisible'} ${shootable && !dead ? 'shootable' : ''}`} style={{width:w, height:h}} onClick={shootable ? () => {
            setDead(true);
            setTimeout(() => setI(i => i + 1), 2000);
        } : null}>
            <img src={webp.Mouse} alt="mouse" onLoad={() => setLoaded(l=>l+1)} style={{ width: w * s, opacity:mouse?1:0 }} />
            <img src={webp.Hitler} alt="hitler" onLoad={() => setLoaded(l=>l+1)} style={{ width: w * s, opacity:hitler&&!dead?1:0}} />
            <img src={webp.Gun} alt="gun" onLoad={() => setLoaded(l=>l+1)} style={{ width: w * s, opacity:gun?1:0}} />
            <img src={webp.DeadHitler} alt="hitler" onLoad={() => setLoaded(l => l + 1)} style={{ width: w * s, opacity: deadHitler || (hitler && dead) ? 1 : 0 }} />
            {dead ? <div className="flash" style={{width:w, height:h}}/>:null}
        </div>
    </div>
}

function WallyProfilePicture({ setLoadedProfile, removeMoustache, useHitler }) {
    let [wiggle, setWiggle] = useState(0);
    let [loaded, setLoaded] = useState(0);
    let [visible, setVisible] = useState(false);
    let [s, setS] = useState(1);
    let [clickable, setClickable] = useState(true);
    let [hitler, setHitler] = useHitler;
    let [animating, setAnimating] = useState(false);

    useEffect(() => {
        if (loaded >= 2) setTimeout(() => {
            setVisible(true)
            setTimeout(()=>setLoadedProfile(true),500)
        }, 100);
    }, [loaded])

    useEffect(() => {
        function resize() {
            setS(superLerp([400, 600], [0.5, 1], window.innerWidth));
        }
        resize();
        window.addEventListener('resize', resize);
        return () => window.removeEventListener('resize', resize);
    },[])
    
    let h = 213;
    let w = 212;

    let mw = 96;
    let mx = 64;
    let my = 103;

    let hw = 33;
    let hx = 93;
    let hy = 60;

    return <div className="wallyProfilePicture" style={{width:w*s, height:h*s}}>
        <div className={`wallyContainer ${visible ? '' : 'invisible'}`}  onClick={clickable? () => {
                setClickable(false);
                setWiggle(8);
                setAnimating(true);
                setTimeout(() => {
                    setWiggle(-8);
                    setTimeout(() => {
                        setWiggle(8);
                        setTimeout(() => {
                            setWiggle(-8);
                            setTimeout(() => {
                                setWiggle(0);
                                if (removeMoustache) {
                                    setHitler(true);
                                    setTimeout(() => setAnimating(false), 500);
                                } else {
                                    setClickable(true);
                                }
                            }, 100);
                        }, 100);
                    }, 100);
                }, 100);
            } : null}>
            <img src={webp.Wally} alt="wally" onLoad={() => setLoaded(l => l + 1)} style={{ width: w * s }} />
            <img src={webp.HitlerMoustache} alt="hitler" className={`hitler ${hitler ? '' : 'invisible'}`} style={{width:hw*s, left:hx*s, top:hy*s}} />
            <img src={webp.Moustache} alt="moustache" onLoad={() => setLoaded(l => l + 1)} className={`moustache ${animating?'animating':''}`} style={{ transform: `rotate(${wiggle}deg)`, left: mx * s, top: hitler? h * s :my * s, width: mw * s }} />
        </div>
    </div>
}

function TimeMachineScreen({setOn}) {
    let [password, setPassword] = useState('');
    let [display, setDisplay] = useState(true);
    let [block, setBlock] = useState(false);
    useEffect(() => {
        if (password.length == 4) {
            setBlock(true);
            let interval = setInterval(() => setDisplay(d => !d), 200);
            let timeout = setTimeout(() => {
                clearInterval(interval);
                setDisplay(true);
            }, 1200)
            let timeout2 = setTimeout(() => {
                if (password == '1993') {
                    setOn(true);
                } else {
                    setBlock(false);
                    setPassword('')
                }
            }, 1600)
            return () => {
                clearInterval(interval);
                clearTimeout(timeout);
                clearTimeout(timeout2);
            }
        }
    },[password])
    return <div className={`scr ${block ? 'block' : ''}`}>
        <div className="enterPassword">PASSWORD:</div>
        <div className="pass">
            <div className="p">{display && password.length > 0 ? password[0]:null}</div>
            <div className="p">{display && password.length > 1 ? password[1]:null}</div>
            <div className="p">{display && password.length > 2 ? password[2]:null}</div>
            <div className="p">{display && password.length > 3 ? password[3]:null}</div>
        </div>
        <div className="numPad">
            <div className="btn" onClick={()=>setPassword(password+'0')}>0</div>
            <div className="btn" onClick={()=>setPassword(password+'1')}>1</div>
            <div className="btn" onClick={()=>setPassword(password+'2')}>2</div>
            <div className="btn" onClick={()=>setPassword(password+'3')}>3</div>
            <div className="btn" onClick={()=>setPassword(password+'4')}>4</div>
            <div className="btn" onClick={()=>setPassword(password+'5')}>5</div>
            <div className="btn" onClick={()=>setPassword(password+'6')}>6</div>
            <div className="btn" onClick={()=>setPassword(password+'7')}>7</div>
            <div className="btn" onClick={()=>setPassword(password+'8')}>8</div>
            <div className="btn" onClick={()=>setPassword(password+'9')}>9</div>
        </div>
    </div>
}

function PortWebsites({ }) {
    let { phone } = useContext(PhoneContext);

    return <div>
        <h2>Web development</h2>
        <p>In 2017 I made my first website, and since then I have made a few (including this one). I do full stack development which means I design both the front end, and the back end on the server.</p>
        <p>I miss the aesthetic of early 2000's web design which was messy, distracting, but full of character! I try to revive this character using modern web technologies.</p>
        <h4>www.ambigr.am</h4>
        <p>This is a social media site for sharing a kind of typographic art called 'ambigrams'. I hold monthly competitions which get around 50 entries each time! Since it is a social media site, the design is minimalist and inoffensive.</p>
        <p>You may {phone?'tap':'click'} the image to view the website</p>
        <div className="centre">
            <a href="https://ambigr.am" target="_blank" rel="noreferrer"><img src={imgAmbigram} alt="ambigr.am"/></a>
        </div>
        <h4>www.pdouglashammond.co.uk</h4>
        <p>This is a website I made for my dad to share his stories. I deviated from standard web design in favour of a more unique user interface with my own illustrations.</p>
        <div className="centre">
            <a href="https://www.pdouglashammond.co.uk" target="_blank" rel="noreferrer"><img src={imgPdouglashammond} alt="www.pdouglashammond.co.uk"/></a>
        </div>
        <h4>www.kaisboatfund.co.uk</h4>
        <p>This is a website I made to sell my artwork. This is where I went all out with a nice loud aggressive design!</p>
        <div className="centre noMarginBottom">
            <a href="https://www.kaisboatfund.co.uk" target="_blank" rel="noreferrer"><img src={imgKaisboatfund} alt="www.kaisboatfund.co.uk"/></a>
        </div>
    </div>
}

function PortClock({ }) {
    let { phone } = useContext(PhoneContext);

    return <div>
        <h2>Mickey Mouse clock</h2>
        <p>For one film I had to make a replica of a Mickey Mouse clock.</p>
        <Clock/>
        <p>I 3d modelled it and then 3d printed it, and painted it to look like metal. You can see from the close-ups how I made areas look like the paint had scratched away to reveal the steel underneath.</p>
        <p>You may {phone?'tap':'click'} an image to view it in full screen:</p>
        <Thumbnails row={3} ratio={[1.1,0.9,1]} imgs={[
            {src: webp.Clock1, alt:'Paint detail'},
            {src: webp.Clock2, alt:'Paint detail'},
            {src: webp.Clock3, alt:'Full clock'},
        ]} />
        <p className="noMarginBottom">Mickey Mouse and the hands are added in post, so they can be animated.</p>
    </div>
}

let clockW = 300;

function Clock({ }) {
    let [hours, setHours] = useState(0);
    let [minutes, setMinutes] = useState(0);
    let [seconds, setSeconds] = useState(0);
    let [w, setW] = useState(Math.min(calcModalW(),clockW));
    let ref = useRef();
    let { sataned, fin } = useContext(GameContext);
    let [loaded, setLoaded] = useState(false);

    let hitler = sataned && !fin;
    let [dialog, setDialog] = useState(false);
    let [dance, setDance] = useState(false);

    useEffect(() => {
        function f() {
            let d = new Date();
            let h = d.getHours();
            let m = d.getMinutes();
            let s = d.getSeconds();
            let hours = d.getTime()/(1000*60*60);
            let minutes = d.getTime() / (1000 * 60);
            let seconds = d.getTime() / (1000);
            setHours(hours);
            setMinutes(minutes);
            setSeconds(seconds);
        }
        f();
        let interval = setInterval(f, 1000/25);

        function resize() {
            let w = ref.current.getBoundingClientRect().width;
            setW(Math.min(w,clockW));
        }
        setTimeout(resize,500);

        window.addEventListener('resize', resize);

        return () => {
            clearInterval(interval);
            window.removeEventListener('resize',resize)
        }
    }, [])
    
    let cd = [559, 669];
    let hd = hitler?[68,189]:[65, 166];
    let md = hitler?[61,222]:[66, 204];
    let sd = hitler?[37, 314]:[29, 314];

    let sc = hitler?[18, 217]:[14, 217];

    let rad = 466;
    let rp = [47, 146];

    let hp = hitler?[183,62]:[182.5, 69.5];
    let mp = hitler?[215,29]:[204.5, 28.5];
    let sp = [rad/2 - sc[0], rad/2 - sc[1]];
    
    let s = w / cd[0];

    cd = cd.map(d => d * s);
    hd = hd.map(d => d * s);
    md = md.map(d => d * s);
    sd = sd.map(d => d * s);
    rp = rp.map(d => d * s);
    hp = hp.map(d => d * s);
    mp = mp.map(d => d * s);
    sp = sp.map(d => d * s);

    rad *= s;

    return <>
        <div className={`Clock ${hitler?'hitlered':''} ${loaded < 4 ? 'invisible':''}`} ref={ref}>
            <div className="clock" onClick={ hitler?()=>{
                setDialog(true)
            }:null} style={{width:cd[0],height:cd[1]}}>
                <img onLoad={ ()=>setLoaded( l => l + 1 )} src={hitler?webp.HitlerClock:webp.Clock0} alt="Clock" style={{ width: cd[0] }} />
                <div className="shadow" style={{width:cd[0],height:cd[1],filter:`drop-shadow(${3*s}px ${9*s}px ${3*s}px rgba(0,0,0,0.25))`}}>
                    <div className="hand" style={{ transform:`rotate(${(minutes/60)*360}deg)`,width: rad, height:rad, left:rp[0], top:rp[1]}}>
                        <img onLoad={ ()=>setLoaded( l => l + 1 )} src={hitler?webp.HitlerMinute:webp.Minute} alt="Minute hand" style={{left:mp[0],top:mp[1],width:md[0]}}/>
                    </div>
                    <div className="hand" style={{ transform:`rotate(${((hitler?hours-0.1:hours)/12)*360}deg)`, width: rad, height:rad, left:rp[0], top:rp[1]}}>
                        <img onLoad={ ()=>setLoaded( l => l + 1 )} src={hitler?webp.HitlerHour:webp.Hour} alt="Hour hand" style={{left:hp[0],top:hp[1],width:hd[0]}}/>
                    </div>
                    <div className="hand" style={{ transform:`rotate(${(seconds/60)*360}deg)`, width: rad, height:rad, left:rp[0], top:rp[1]}}>
                        <img onLoad={ ()=>setLoaded( l => l + 1 )} src={hitler?webp.HitlerSecond:webp.Second} alt="Second hand" style={{left:sp[0],top:sp[1],width:sd[0]}}/>
                    </div>
                </div>
            </div>
        </div>
        {dialog ? <Dialog hitlerMouseGun tree={[
            {text:"AHA! Got you Hitler, you sneaky bastard!",buttons:[["Bravo!",1]], mouse:true}, // 0
            {text:"Damn you pesky mouse",buttons:[["OK",2]], hitler:true}, // 1
            {text:"But you haven't got me yet, first you have to beat me in a dance off!",buttons:[["Damn.",-1]], hitler:true}, // 2
        ]} close={() => {
            setDialog(false);
            setDance(true);
            }} /> : null}
        {dance ? <Dance close={()=>setDance(false)} /> : null}
    </>
}

function randomArray(array, isnt) {
    if (!isnt) return array[Math.floor(Math.random() * array.length)];
    let res = array[Math.floor(Math.random() * array.length)];
    while (res == isnt) {
        res = array[Math.floor(Math.random() * array.length)]; 
    }
    return res;
}

function Dance({ close }) {
    let [invisible, setInvisible] = useState(false);
    let [loaded, setLoaded] = useState(0);
    let [allLoaded, setAllLoaded] = useState(false);
    let audio = useRef();
    let [playing, setPlaying] = useState(false);
    let [s, setS] = useState(1);
    let [wait, setWait] = useState(true);
    let { phone } = useContext(PhoneContext);
    let { setFin } = useContext(GameContext);
    let [pushedButton, setPushedButton] = useState(false);
    let [arrows, setArrows, getArrows] = useGetState([]);
    let [difficulty, setDifficulty, getDifficulty] = useGetState(0);
    let [message, setMessage, getMessage] = useGetState('');
    let [over, setOver, getOver] = useGetState(false);
    let [dialog, setDialog] = useState(false);
    
    function CLOSE() {
        setInvisible(true);
        setTimeout(() => {
            close();
        }, 250)
    }

    let arrow = <svg viewBox="0 0 75 75" > <path d="M36.2,8.8L10.9,34c-1,1-0.3,2.7,1.1,2.7h12.4V65c0,1,0.8,1.8,1.8,1.8h22.3c1,0,1.8-0.8,1.8-1.8V36.8h12.4 c1.4,0,2.2-1.7,1.1-2.7L38.8,8.8C38.1,8,36.9,8,36.2,8.8z" /> </svg>;

    useEffect(() => {
        let images = [webp.M0, webp.M1, webp.L0, webp.L1, webp.R0, webp.R1, webp.U0, webp.U1];
        images.forEach(i => {
            let img = new Image();
            img.src = i;
            img.onload = () => setLoaded(l => l + 1);
        })
        audio.current = new Audio(song);
        audio.current.load();
        setTimeout(() => setWait(false), 250);
        function resize() {
            setS(superLerp([0, 350], [0, 1], window.innerWidth));
        }
        resize();
        window.addEventListener('resize', resize);
        return () => window.removeEventListener('resize', resize);
    }, [])
    
    async function play() {
        await audio.current.play();
        setPlaying(true);
    }

    useEffect(() => {
        if (!wait && loaded == 8) setAllLoaded(true);
    }, [loaded, wait])

    useEffect(() => {
        if (playing) {
            let f = 0;
            let speed = 12;
            let chance = 1;
            function pushArrow() {
                let dir = Math.floor(Math.random() * 3);
                dir = dir == 0 ? 'l' : dir == 1 ? 'u' : 'r';
                setArrows(a => [...a, [dir, 0, 0, false]]);
            }
            let interval = setInterval(() => {
                let difficulty = getDifficulty();
                if (difficulty == 0) {
                    speed = 14;
                    chance = 1;
                } else if (difficulty == 1) {
                    speed = 6;
                    chance = 0.5;
                } else if (difficulty == 2) {
                    speed = 6;
                    chance = 0.75;
                } else if (difficulty == 3) {
                    speed = 3;
                    chance = 0.75;
                }
                if (f % 5 == 0) setMf(f => 1 - f);

                setArrows(a => a.map(([dir, y, o, done])=>{
                    y += 0.028;
                    let opacity = superLerp([0, 0.1, 1.1, 1.2], [0, 1, 1, 0], y);
                    return [dir, y, opacity, done]
                }).filter(a=>a[1]<1.2));
                
                if (f % speed == 0) {
                    if (Math.random() < chance) {
                        if (!getOver()) pushArrow();
                    }
                }
                let monkey = getMonkey();
                let monkeyF = getMonkeyF();
                if (monkey != 'm') {
                    if (monkeyF == monkeyHold) {
                        setMonkey('m');
                        setMonkeyF(0);
                    } else {
                        setMonkeyF(f => f + 1);
                    }
                }
                f++;
            }, 1000 / 30);


            setTimeout(() => setDifficulty(1), 1000 * 12);
            setTimeout(() => setDifficulty(2), 1000 * 35);
            setTimeout(() => setDifficulty(3), 1000 * 53);
            setTimeout(() => {
                setOver(true);
                setTimeout(() => setDialog(true),2000)
            }, 1000 * 63);

            function keydown(e) {
                if (e.key == 'ArrowUp') {
                    action('u');
                } else if (e.key == 'ArrowLeft') {
                    action('l');
                } else if (e.key == 'ArrowRight') {
                    action('r');
                }
            }
            document.addEventListener('keydown', keydown);
            return () => {
                clearInterval(interval);
                document.removeEventListener('keydown', keydown);
            }
        }
    },[playing])

    useEffect(() => {
        if (allLoaded) {
            function keydown(e) {
                if (e.key == ' ') {
                    play();
                    document.removeEventListener('keydown', keydown);
                }
            }
            document.addEventListener('keydown', keydown);
            return () => {
                document.removeEventListener('keydown', keydown);
            }
        }
    },[allLoaded])
    
    let monkeyHold = 4;
    let [monkey, setMonkey, getMonkey] = useGetState('m');
    let [monkeyF, setMonkeyF, getMonkeyF] = useGetState(0);
    let [mf, setMf] = useState(0);
    let timeout = useRef();

    function action(key) {
        setMonkey(key);
        setMonkeyF(0);
        let arrows = getArrows();
        if (arrows.length > 0) {
            let closest = 0;
            for (let i = 1; i < arrows.length; i++) {
                if (Math.abs(1 - arrows[i][1]) < Math.abs(1 - arrows[closest][1])) closest = i;
            }
            arrows[closest][3] = true;
            setArrows(arrows.slice());
            clearTimeout(timeout.current);
            setMessage(randomArray(['Awful!', 'Terrible!', 'Rubbish!', 'Pathetic!', 'Pitiful!', 'Horrible!', 'Too slow!', 'Too fast!','Weak!','Try harder!','Dreadful!','Poor!','Bad!','Not good!'],getMessage()));
            timeout.current = setTimeout(() => setMessage(), 500);
        }
    }

    let d = {
        m: [342, 350],
        a: [75,75],
    }

    let p = {
        al: [15, 0],
        au: [(d.m[0] - d.a[0]) / 2, 0],
        ar: [d.m[0] - d.a[0]-15, 0],
    }

    let minArrow = 400;

    for (let k in d) {
        d[k] = d[k].map(x => x * s);
    }
    for (let k in p) {
        p[k] = p[k].map(x => x * s);
    }
    minArrow *= s;
    let arrowStyle = { width: d.a[0], height: d.a[1] };

    let { VH } = useContext(VHContext);

    return <>
        <div className={`FullScreen Dance ${invisible ? 'invisible' : ''}`} style={{height:VH}}>
            <div className={`starry Game ${!allLoaded ? 'invisible' : ''}`} style={{height:VH}}>
                <div className="monkey" style={{width:d.m[0], height:d.m[1]}}>
                    {!playing ? phone?<div className="instructions">
                        <p>Tap on the yellow arrows to dance.</p>
                        <button onClick={pushedButton?null:() => {
                            play();
                            setPushedButton(true);
                        }}>Tap here to begin!</button>
                    </div>:<div className="instructions">
                        <p>Use the arrow keys to dance.</p>
                        <p>Press space to begin!</p>
                    </div> : <img src={monkey == 'm' ? mf == 0 ? webp.M0 : webp.M1 : monkey == 'l' ? monkeyF == 0 || monkeyF == monkeyHold ? webp.L0 : webp.L1 : monkey == 'r' ? monkeyF == 0 || monkeyF == monkeyHold ? webp.R0 : webp.R1 : monkey == 'u' ? monkeyF == 0 || monkeyF == monkeyHold ? webp.U0 : webp.U1 : null} alt="monkey" style={{ width: d.m[0] }} />}
                    {message ? <div className="message" key={message}>{message}</div>:null}
                </div>
                <div className="arrowButtons">
                    {arrows.map(([dir, y, opacity, done], i) => <div className={`movingArrow ${dir == 'l' ? 'leftArrow' : dir == 'u' ? 'upArrow' : dir == 'r' ? 'rightArrow' : ''} ${done?'done':''}`} style={{ ...arrowStyle, left: p[`a${dir}`][0], top: superLerp([0,1,2],[-minArrow,0,minArrow],y), opacity} } key={`${dir} ${y} ${i}`}>{arrow}</div>)}
                    <div className="inputButtons" style={{width:d.m[0], height:d.a[1]}}>
                        <div className={`leftArrow ${monkey=='l'?'highlight':''}`} onClick={()=>action('l')} style={{...arrowStyle,left:p.al[0]}}>{arrow}</div>
                        <div className={`upArrow ${monkey=='u'?'highlight':''}`} onClick={()=>action('u')} style={{...arrowStyle,left:p.au[0]}}>{arrow}</div>
                        <div className={`rightArrow ${monkey=='r'?'highlight':''}`} onClick={()=>action('r')} style={{...arrowStyle,left:p.ar[0]}}>{arrow}</div>
                    </div>
                </div>
            </div>
        </div>
        {dialog ? <Dialog hitlerMouseGun tree={[
            {text:"Wow, you're really bad at dancing. Hitler wins again!",buttons:[["Nooo!",1]], hitler:true}, // 0
            {text:"Now I need to get back to my time machine...",buttons:[["Oh no!",2]], hitler:true}, // 1
            {text:"Fuck this nonsense, shoot the bastard!",buttons:[["Oh yeah I have a gun!",3]], mouse:true}, // 2
            {text:"Wait, you guys have guns? That's not fair!",buttons:[["Haha!",4]], hitler:true}, // 3
            {text:`[${phone?'Tap':'Click'} Hitler to shoot him.]`, italic:true, buttons:[], hitler:true, shootable:true}, // 4
            {text:"[You shot Hitler!]", italic:true, buttons:[["Cool!",6]], deadHitler:true}, // 5
            {text:"Congratulations! You win!", buttons:[[":)",7]], mouse:true}, // 6
            {text:"Now that was a fun detour, but you should really get back to that portfolio.", buttons:[[":)",8]], mouse:true}, // 7
            {text:"See you!", buttons:[["Good bye!",-1]], mouse:true}, // 8
        ]} close={() => {
            setDialog(false);
            setFin(true);
            CLOSE();
        }} /> : null}
    </>
}

function PortCoin({ }) {
    let { phone } = useContext(PhoneContext);

    return <div>
        <h2>Pewter coin</h2>
        <p>This is the coin worn as a necklace by followers of the evil Cult of the Pink Eye!</p>
        <p>I modelled the coin in blender, 3d printed it at around the size of a pound coin, then made a silicone mould. I then cast it in pewter in the shed. You may {phone?'tap':'click'} an image to expand it:</p>
        <Thumbnails imgs={[
            {src: webp.Coin0, alt:'Front side'},
            {src: webp.Coin1, alt:'Back side'},
        ]} />
        <Door/>
    </div>
}

function Cauldron({ close }) {
    let [invisible, setInvisible] = useState(false);
    let [loaded, setLoaded] = useState(0);
    let [s, setS] = useState(1);
    let [f, setF] = useState(0);
    let [animateLavender, setAnimateLavender] = useState(-1);
    let [animateNewt, setAnimateNewt] = useState(-1);
    let [animateCheese, setAnimateCheese] = useState(-1);
    let [animateHitler, setAnimateHitler] = useState(-1);
    let [message, setMessage] = useState('');
    let [faded, setFaded] = useState(false);
    let [dialog, setDialog] = useState(false);
    let { setSataned } = useContext(GameContext);

    useEffect(() => {
        function resize() {
            setS(superLerp([0,350],[0,1],window.innerWidth))
        }
        resize();
        window.addEventListener('resize', resize);
        let interval = setInterval(() => setF(f => (f + 1) % 4), 1000 / 6);
        setTimeout(() => setFaded(true), 250);
        return () => {
            clearInterval(interval);
            window.removeEventListener('resize', resize);
        }
    },[])

    function CLOSE() {
        setInvisible(true);
        setTimeout(() => {
            close();
        }, 250)
    }

    let cd = [290, 296];
    let bd = [233, 39];
    let bp = [29, 0];
    let fd = [167, 65];
    let fp = [56, 222];
    let ld = [89, 150];
    let nd = [153, 81];
    let chd = [100, 81];
    let hd = [212, 213];

    cd = cd.map(x => x * s);
    bd = bd.map(x => x * s);
    bp = bp.map(x => x * s);
    fd = fd.map(x => x * s);
    fp = fp.map(x => x * s);
    ld = ld.map(x => x * s);
    nd = nd.map(x => x * s);
    chd = chd.map(x => x * s);
    hd = hd.map(x => x * s);

    let bubblesStyle = { width: bd[0], left: bp[0], top: bp[1] };
    let flamesStyle = { width: fd[0], left: fp[0], top: fp[1] };

    let load = () => setLoaded(l => l + 1);

    useEffect(() => {
        if (faded && loaded == 13) {
            setMessage('A sprig of Lavender!');
            setAnimateLavender(0);
            setTimeout(() => {
                setAnimateLavender(1);
                setTimeout(() => {
                    setAnimateLavender(-1);
                    setMessage('A dead Newt!')
                    setAnimateNewt(0);
                    setTimeout(() => {
                        setAnimateNewt(1);
                        setTimeout(() => {
                            setAnimateNewt(-1);
                            setMessage('A slice of cheese!')
                            setAnimateCheese(0);
                            setTimeout(() => {
                                setAnimateCheese(1);
                                setTimeout(() => {
                                    setAnimateCheese(-1);
                                    setMessage('Bubbling...')
                                    setTimeout(() => {
                                        setMessage('')
                                        setAnimateHitler(0);
                                        setTimeout(() => {
                                            setDialog(true);
                                        },1500)
                                    },2000)
                                },1500)
                            },1000)
                        },1500)
                    },1000)
                },1500)
            },2000)
        }
    },[loaded, faded])

    let { VH } = useContext(VHContext);

    return <>
        <div className={`FullScreen ${invisible ? 'invisible' : ''}`} style={{height:VH}}>
            <div className="starry Cauldron" style={{height:VH}}>
                <div className={`cauldron ${loaded < 13||!faded?'invisible':''}`} style={{width:cd[0], height:cd[1]}}>
                    <img className={f!=0?'invisible':''} src={webp.Bubbles0} style={bubblesStyle} alt="bubbles" onLoad={load}/>
                    <img className={f!=1?'invisible':''} src={webp.Bubbles1} style={bubblesStyle} alt="bubbles" onLoad={load}/>
                    <img className={f!=2?'invisible':''} src={webp.Bubbles2} style={bubblesStyle} alt="bubbles" onLoad={load}/>
                    <img className={f!=3?'invisible':''} src={webp.Bubbles3} style={bubblesStyle} alt="bubbles" onLoad={load}/>
                    <img className={f!=0?'invisible':''} src={webp.Flames0} style={flamesStyle} alt="flames" onLoad={load}/>
                    <img className={f!=1?'invisible':''} src={webp.Flames1} style={flamesStyle} alt="flames" onLoad={load}/>
                    <img className={f!=2?'invisible':''} src={webp.Flames2} style={flamesStyle} alt="flames" onLoad={load}/>
                    <img className={f!=3?'invisible':''} src={webp.Flames3} style={flamesStyle} alt="flames" onLoad={load}/>
                    <img src={webp.Lavender} className={`animate ${animateLavender<0?'invisible':''}`} style={{width:ld[0], left:(cd[0]-ld[0])/2, top:animateLavender == 1 ? 40 : -ld[1]-12}} alt="lavender" onLoad={load} />
                    <img src={webp.Newt} className={`animate ${animateNewt<0?'invisible':''}`} style={{width:nd[0], left:(cd[0]-nd[0])/2, top:animateNewt == 1 ? 40 : -nd[1]-12}} alt="newt" onLoad={load} />
                    <img src={webp.Cheese0} className={`animate ${animateCheese<0?'invisible':''}`} style={{width:chd[0], left:(cd[0]-chd[0])/2, top:animateCheese == 1 ? 40 : -chd[1]-12}} alt="cheese" onLoad={load} />
                    <img src={webp.Hitler} className={`animate ${animateHitler<0?'invisible':''}`} style={{width:hd[0], left:(cd[0]-hd[0])/2, top:animateHitler < 0 ? 18 : -hd[1]+40}} alt="hitler" onLoad={load} />
                    <img src={webp.Cauldron} style={{width:cd[0]}} alt="cauldron" onLoad={load} />
                </div>
                <div className="caption">{message}</div>
            </div>
        </div>
        {dialog ? <Dialog hitlerMouseGun tree={[
            {text:"OI! What am I doing here?",buttons:[["No idea!",1]], hitler:true}, // 0
            {text:"I was about to implement a new order of racial supremacy!",buttons:[["Poor you.",2]], hitler:true}, // 1
            {text:"What the hell Hitler? What are you doing here?",buttons:[["How strange",3]], mouse:true}, // 2
            {text:"Damn it, I knew I should have used Brie, like the instructions said.",buttons:[["Oops!",4]], mouse:true}, // 3
            {text:"I won't let a silly mouse foil my plans. I'm outta here!",buttons:[["OK!",-1]], hitler:true}, // 4
        ]} close={() => {
            setDialog(false);
            setSataned(true);
            CLOSE();
        }} /> : null}
    </>
}

function Door({ }) {
    let [open, setOpen] = useState(false);
    let [loaded, setLoaded] = useState(0);
    let [nose, setNose] = useState(false);
    let [dialog, setDialog] = useState(false);
    let { cheesed, moused, setMoused, sataned, fin } = useContext(GameContext);
    let [cauldron, setCauldron] = useState(false);

    useEffect(() => {
        if (open) {
            setNose(true);
            setTimeout(() => setDialog(true), 750);
        }
    }, [open])
    
    return <>
        <div className="Door">
            <div className={`door ${loaded >= 3?'':'invisible'}`} onClick={()=>setOpen(true)}>
                <img src={webp.Door0} alt="door" onLoad={() => setLoaded(l => l + 1)} />
                <div className={`mouse ${nose?'nosey':''} ${open?'':'invisible'}`}>
                    <img src={webp.LilMouse} alt="mouse" />
                </div>
                <img className={`d ${!open?'':'invisible'}`} src={webp.Door1} alt="door" onLoad={()=>setLoaded(l=>l+1)}/>
                <img className={`d ${open?'':'invisible'}`} src={webp.Door2} alt="door" onLoad={()=>setLoaded(l=>l+1)}/>
            </div>
            <div>
                <img className="pleaseKnock" src={webp.PleaseKnock} alt="please knock"/>
            </div>
        </div>
        {cauldron?<Cauldron close={()=>setCauldron(false)}/>:null}
        {dialog ? cheesed ? moused ? sataned ? fin ? <Dialog mouse tree={[
            {text:"Hi there!",buttons:[["Hello!",1]]}, // 0
            {text:"Congratulations again on shooting Hitler!",buttons:[["Thanks.",2]]}, // 1
            {text:"Now if you don't mind, I need to get back to summoning Satan. See you!",buttons:[["Good bye",-1]]}, // 2
        ]} close={() => {
            setDialog(false);
            setNose(false);
            setTimeout(() => setOpen(false), 500);
        }} /> : <Dialog mouse tree={[
            {text:"What are you doing wasting time?",buttons:[["Sorry!",1]]}, // 0
            {text:"Go on - go find Hitler!",buttons:[["OK!",2]]}, // 1
            {text:"And don't forget to shoot him!",buttons:[["OK!",-1]]}, // 2
        ]} close={() => {
            setDialog(false);
            setNose(false);
            setTimeout(() => setOpen(false), 500);
        }} /> : <Dialog mouse tree={[
            {text:"Hi there!",buttons:[["Hello.",1]]}, // 0
            {text:"So... do you think you could give me that cheese?",buttons:[["Yes",2],["No.",3]]}, // 1
            {text:"Thanks! This is exactly what I need for my magic potion!",buttons:[["Magic potion?",4]]}, // 2
            {text:"THANKS FOR NOTHING!",buttons:[["Good bye",-1]], bold:true}, // 3
            {text:"Yes! I'm doing some magic to summon Satan. Come in, I'll show you!",buttons:[["OK!",-2]]}, // 4
        ]} close={() => {
            setDialog(false);
            setNose(false);
            setMoused(true);
            setTimeout(() => setOpen(false), 500);
        }} callback={() => {
            setDialog(false);
            setNose(false);
            setMoused(true);
            setCauldron(true);
            setTimeout(() => setOpen(false), 500);
        }} />  : <Dialog mouse tree={[
            {text:"EEK! I'm a mouse!",buttons:[["Go on...",1]]}, // 0
            {text:"Sorry to bother you - I bought some cheese yesterday but I lost it!",buttons:[["Oh no!",2]]}, // 1
            {text:"Have you seen my cheese?",buttons:[["Yes!",3],["No.",4]]}, // 2
            {text:"Oh thank goodness! Could I please have it?",buttons:[["Yes!",5],["No.",6]]}, // 3
            {text:"What a shame. Let me know if you find it!",buttons:[["OK!",-1]]}, // 4
            {text:"Thanks! This is exactly what I need for my magic potion!",buttons:[["Magic potion?",7]]}, // 5
            {text:"THANKS FOR NOTHING!",buttons:[["Good bye",-1]], bold:true}, // 6
            {text:"Yes! I'm doing some magic to summon Satan. Come in, I'll show you!",buttons:[["OK!",-2]]}, // 7
        ]} close={() => {
            setDialog(false);
            setNose(false);
            setTimeout(() => setOpen(false), 500);
        }} callback={() => {
            setDialog(false);
            setNose(false);
            setMoused(true);
            setCauldron(true);
            setTimeout(() => setOpen(false), 500);
        }} /> : <Dialog mouse tree={[
            {text:"EEK! I'm a mouse!",buttons:[["Go on...",1]]}, // 0
            {text:"Sorry to bother you - I bought some cheese yesterday but I lost it!",buttons:[["Oh no!",2]]}, // 1
            {text:"Have you seen my cheese?",buttons:[["Yes!",3],["No.",4]]}, // 2
            {text:"Oh thank goodness! Could I please have it?",buttons:[["Yes!",5],["No.",6]]}, // 3
            {text:"What a shame. Let me know if you find it!",buttons:[["OK!",-1]]}, // 4
            {text:"Thanks! ... Oh wait a minute, you don't have any cheese!",buttons:[["Oops",6]]}, // 5
            {text:"THANKS FOR NOTHING!",buttons:[["Good bye",-1]], bold:true}, // 6
        ]} close={() => {
            setDialog(false);
            setNose(false);
            setTimeout(() => setOpen(false), 500);
        }} /> : null}
    </>
}

function PortStatue({ }) {
    let { phone } = useContext(PhoneContext);

    return <div>
        <h2>Plaster Statue</h2>
        <p>For one project there was a scene where an old statue was found buried. I needed to make just the head, as the rest would be under the soil.</p>
        <p>As there is heavy snow when writing this, I took a picture of it buried in snow:</p>
        <ImageThumbnail src={imgStatue0} alt="Statue in the snow" />
        <p>I made a mould of my own face, then cast it in plaster. Since the mould did not pick up my hair or eyes, I sculpted the eyes, and made the hair out of clay, which I stuck on.</p>
        <p>I then painted it completely white and applied a black-wash for texture - {phone?'tap':'click'} an image to view it in full screen:</p>
        <Thumbnails ratio={[0.8,1.2]} imgs={[
            {src: webp.Statue1, alt:'Side view'},
            {src: webp.Statue2, alt:'Front view'},
        ]} />
        <p>I don't do much sculpting, but I made this as a gift for a friend:</p>
        <ImageThumbnail src={webp.MuscleMan} maxWidth={200} alt="Muscle man" />
        <p className="noMarginBottom">Since this was the first time really working with clay since school, I consider this less of a demonstration of my skill with clay, and more a demonstration of my ability to pick up new skills!</p>
    </div>
}