import React, { Component } from 'react';

import Banner from './Banner';
import Store from './Store';

export default class Shop extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return <div className="Store">
            <Banner />
            <Store />
        </div>
    }
}