import React, { useEffect, useState } from 'react';
import { useClickOutside } from '../../hooks';
import A from './A';
import I from './I';

export default function Dropdown({ item, items, setItem, addItem }) {
    let [drop, setDrop] = useState(false);
    let [typing, setTyping] = useState(false);
    let [outsideRef, onClickOutside] = useClickOutside();
    onClickOutside(() => {
        setDrop(false);
    })
    useEffect(() => {
        setTyping(false);
    },[drop])
    return <div className={"Dropdown " + (addItem ? 'Addable' : null)} >
        <div className="droopdoop" ref={outsideRef}>
            <div className="drop">
                {!addItem ? <A onClick={()=>setDrop(true)}>{item}</A> : <I
                    onClick={() => setDrop(true)}
                    callback={t => {
                        if (t) {
                            setItem(t);
                            addItem(t);
                        }
                        setDrop(false);
                    }}
                    onChange={t => {
                        setTyping(!!t);
                    }}
                >{item}</I>}
            </div>
            {drop && !typing? <div className="down">
                {items.map((it, i) => <div className={`item ${item==it?'selected':''}`} key={i}><A onClick={() => {
                    setItem(it);
                    setDrop(false);
                }}>{it}</A></div>)}
            </div>:null}
        </div>
    </div>
}