import React, { Component } from 'react';

class Quantity extends Component {
    constructor(props) {
        super(props);
        let { callback, value, max } = props;
        this.state = {
            callback,
            value: value ? value : 1,
            max: max ? max : 5
        }
        this.inc = this.inc.bind(this);
    }

    inc(v) {
        let { value, max } = this.state;
        value += v;
        if (value < 1) value = 1;
        if (value > max) value = max;
        this.setState({ value });
        this.props.callback(value);
    }

    render() {
        let { value, max } = this.state;
        return <div className='quantity'>
            <div className={value > 1 ? 'b' : 'db'} onClick={()=>this.inc(-1)}>-</div>
            <div className='value'>{value}</div>
            <div className={value <= max-1 ? 'b' : 'db'} onClick={()=>this.inc(+1)}>+</div>
        </div>
    }
}

export default Quantity;