import React, { useRef, useState } from 'react';
import { useMount } from '../../hooks';

let pi = 3.14159;

export default function PieGraph({ diameter, values }) {
    let shiftHue = useRef(Math.floor(Math.random() * 360))
    let [slices, setSlices] = useState()
    let { componentDidMount } = useMount();
    let [selected, setSelected] = useState(-1);

    componentDidMount(() => {
        let total = values.reduce((a, b) => a + b.value, 0);
        let d = 0;
        setSlices(values.map((s, i) => {
            let a0 = (d / total) * (2 * pi);
            d += s.value;
            let a1 = (d / total) * (2 * pi);
            let slice = getSlice(a0, a1);
            let path = getPath(slice, diameter);
            return { path, percent: Math.floor((s.value / total) * 100), fill:`hsl(${((i/values.length)*360 + shiftHue.current)%360},100%,${i%2==0?40:60}%)` };
        }))
    })

    return !slices?null:<div className="PieChart" onMouseLeave={()=>setSelected(-1)}>
        <div className="pie" style={{width:diameter}} >
            <svg width={diameter} viewBox={"0 0 " + diameter + ' ' + diameter}>
                {slices.map((s, i) => <path onMouseEnter={() => setSelected(i)} key={i} d={s.path} style={{
                    fill: selected == i ? 'black' : s.fill,
                    stroke: selected == i ? 'black' : s.fill,
                    strokeWidth: 1
                }} />)}
            </svg>
        </div>
        <div className="data">
            {values.map((v,i) => <div key={i} className={selected==i?"selected datum":"datum" } onMouseEnter={()=>setSelected(i)}>
                <div className="swatch" style={{ backgroundColor: slices[i].fill }} />
                <div className="percent">{slices[i].percent}%</div>
                <div>{v.title}</div>
            </div>)}
        </div>
    </div>
}

function getPath(slice, diameter) {
    // let path = 'M' + slice[slice.length - 1].p1.x * diameter + ',' + slice[slice.length - 1].p1.y * diameter;
    let path = 'M' + 0.5 * diameter + ',' + 0.5 * diameter;
    path += 'L' + slice[0].p0.x * diameter + ',' + slice[0].p0.y * diameter;
    let pt = slice[0].p0;
    slice.forEach(l => {
        path += 'c';
        path += ((l.h0.x - pt.x) * diameter) + ',' + ((l.h0.y - pt.y) * diameter) + ' ';
        path += ((l.h1.x - pt.x) * diameter) + ',' + ((l.h1.y - pt.y) * diameter) + ' ';
        path += ((l.p1.x - pt.x) * diameter) + ',' + ((l.p1.y - pt.y) * diameter) + ' ';
        pt = l.p1;
    })
    path += 'z'
    return path;
}

let cb = x => x * x * x;
let sq = x => x * x;

function getAngle(pt, x) {
    let a = Math.atan2(pt.y - 0.5, pt.x - 0.5);
    a += pi / 2;
    while (a < 0) {
        if (Math.abs(a) < 0.001) {
            a = 0;
        } else a += pi * 2;
    }
    if (a == 0 && x == 1) a = 2 * pi;
    return a;
}

function getSlice(a0, a1) {
    let h = 0.27614;
    let b = [
        {
            p0: { x: 0.5, y: 0 },
            h0: { x: 0.5 + h, y: 0 },
            h1: { x: 1, y: 0.5 - h },
            p1: { x: 1, y: 0.5 }
        },
        {
            p0: { x: 1, y: 0.5 },
            h0: { x: 1, y: 0.5 + h },
            h1: { x: 0.5 + h, y: 1 },
            p1: { x: 0.5, y: 1 }
        },
        {
            p0: { x: 0.5, y: 1 },
            h0: { x: 0.5 - h, y: 1 },
            h1: { x: 0, y: 0.5 + h },
            p1: { x: 0, y: 0.5 }
        },
        {
            p0: { x: 0, y: 0.5 },
            h0: { x: 0, y: 0.5 - h },
            h1: { x: 0.5 - h, y: 0 },
            p1: { x: 0.5, y: 0 }
        },
    ];

    function split(a) {
        let l = 0;
        let t = 0;
        for (let i = 0; i < b.length; i++) {
            let aa0 = getAngle(b[i].p1, 1);
            if (aa0 >= a) {
                l = i;
                let aa1 = getAngle(b[i].p0, 0);
                t = (a - aa1) / (aa0 - aa1);
                break;
            }
        }
        let s = (1 - t);
        let st = t * s;
        let { p0, p1, h0, h1 } = b[l];
        let x = (cb(s) * p0.x) + (3 * t * sq(s) * h0.x) + (3 * sq(t) * (s) * h1.x) + (cb(t) * p1.x);
        let y = (cb(s) * p0.y) + (3 * t * sq(s) * h0.y) + (3 * sq(t) * (s) * h1.y) + (cb(t) * p1.y);
        let c0 = { p0, p1: { x, y } };
        let c1 = { p0: { x, y }, p1 };
        x = s * p0.x + t * h0.x;
        y = s * p0.y + t * h0.y;
        c0.h0 = { x, y };
        x = (sq(s) * p0.x) + (2 * st * h0.x) + (sq(t) * h1.x);
        y = (sq(s) * p0.y) + (2 * st * h0.y) + (sq(t) * h1.y);
        c0.h1 = { x, y };
        x = (sq(s) * h0.x) + (2 * st * h1.x) + (sq(t) * p1.x);
        y = (sq(s) * h0.y) + (2 * st * h1.y) + (sq(t) * p1.y);
        c1.h0 = { x, y };
        x = s * h1.x + t * p1.x;
        y = s * h1.y + t * p1.y;
        c1.h1 = { x, y };
        return [l, c0, c1];
    }

    let [l, shit, c1] = split(a0);
    for (let i = 0; i <= l; i++) {
        b.shift();
    }
    b.unshift(c1);
    let [m, c0, poo] = split(a1);
    for (let i = b.length-1; i >= m; i--) {
        b.pop();
    }
    b.push(c0);

    return b;
}