import React, { Component, useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { NavLink, useNavigate, useParams } from "react-router-dom";

import Banner from './Banner';
import Modal from './Modal';
import Dropdown from './Dropdown';
import Quantity from './Quantity';
import Thumb from './Thumb';
import Paragraph from '../Paragraph';

export default function FunctionalWrapper(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <Item {...props} navigate={navigate} match={{params}} />
}

function Thumbs({ className, style, s, pages, setState, id }) {
    let [height, setHeight] = useState();
    let ref = useRef();
    useEffect(() => {
        function resize() {
            if (ref.current) {
                let width = ref.current.getBoundingClientRect().width;
                let f = d => (width) / d;
                if (width <= 333) {
                    setHeight(f(3));
                } else if (width <= 444) {
                    setHeight(f(4));
                } else if (width <= 555) {
                    setHeight(f(5));
                } else if (width <= 666) {
                    setHeight(f(6));
                } else if (width <= 777) {
                    setHeight(f(7));
                } else if (width <= 888) {
                    setHeight(f(8));
                } else {
                    setHeight(f(4));
                    // setHeight(100);
                }
            }
        }
        resize();
        window.addEventListener('resize', resize, true);
        return () => window.removeEventListener('resize', resize, true);
    }, []);
    return <div ref={ref} id={id} className={className} style={{...style, height}}>
        <div
            className="scrollable"
            style={{
                width: 'calc(100% * ' + pages.length + ')',
                left: '-'+ 100 * s +'%'
            }}
        >
            {pages.map((page,i) => <div key={'page'+i+' '+page.length} className="page" style={{width:'calc(100% / '+(pages.length)+')', height}}>
                {page.map(image => <div
                    key={image.path}
                    className="thumb"
                    style={{ backgroundImage: "url(/api/pictures/" + encodeURIComponent(image.path) + ')' , height, width:height}}
                    onClick={()=>setState({path:image.path})}
                >
                </div>)}
            </div>)}
        </div>
    </div>
}

class Image extends Component {
    constructor(props) {
        super(props);
        let { images } = props;
        this.state = {
            images, s:0, path: images[0].path, perpage:4
        }
        this.nextPage = this.nextPage.bind(this);
        this.prevPage = this.prevPage.bind(this);
        this.resize = this.resize.bind(this);

    }

    componentWillReceiveProps(next) {
        this.setState({ images: next.images, path:next.images[0].path, s:0 });
    }

    componentDidMount() {
        window.addEventListener('resize', this.resize, true);
        this.resize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }

    resize() {
        let perpage = 4
        if (window.innerWidth < 888) perpage = 8;
        if (window.innerWidth < 777) perpage = 7;
        if (window.innerWidth < 666) perpage = 6;
        if (window.innerWidth < 555) perpage = 5;
        if (window.innerWidth < 444) perpage = 4;
        if (window.innerWidth < 333) perpage = 3;
        this.setState({ perpage });
    }

    nextPage() {
        let { s, pages } = this.state;
        s++;
        this.setState({ s });
    }

    prevPage() {
        let { s, pages } = this.state;
        s--;
        this.setState({ s });
    }

    render() {
        let { images, s, path, perpage } = this.state;
        let pages = [];
        for (let i = 0; i < images.length; i += perpage){
            let page = [];
            for (let j = 0; j < perpage; j++) {
                if (i + j < images.length) {
                    page.push(images[i + j]);
                }
            }
            pages.push(page);
        }
        while (s >= pages.length) s--;
        return <div className="image">
            <div style={{textAlign:'center'}}>
                <img key={path} src={"/api/pictures/" + path} style={{marginBottom:images.length>1?null:0}} />
            </div>
            {images.length>1?<div className="flex">
                <div
                    className={s > 0 ? "left" : 'dleft'}
                    onClick={s > 0 ? this.prevPage:null}
                >{pages.length>1?'◀':null}</div>
                <Thumbs className="thumbs" s={s} pages={pages} setState={s=>this.setState(s)}/>
                <div
                    className={s < pages.length-1 ? "right" : 'dright'}
                    onClick={s < pages.length-1 ? this.nextPage:null}
                >{pages.length>1?'▶':null}</div>
            </div>:null}
        </div>
    }
}

async function post(url, data) {
    try {
        let res = await axios.post(url, data);
        return res;
    } catch (e) {
        console.log('FUCKING ERROR')
        console.log(e);
    }
}

class Item extends Component {
    constructor(props) {
        super(props);
        let { title } = props.match.params;
        title = title.replace(/_/g, ' ');
        this.state = {
            title, item: null, quantity: 1, basket: [], existing: -1, showBasket: false, showRequired: false, related:null, error: false
        }
        this.loadItem = this.loadItem.bind(this);
        this.loadRelated = this.loadRelated.bind(this);
        this.addToBasket = this.addToBasket.bind(this);
        this.dropdownSelect = this.dropdownSelect.bind(this);
        this.updateBasket = this.updateBasket.bind(this);
        this.buy = this.buy.bind(this);
    }

    componentDidMount() {
        this.loadItem();
    }

    async loadItem() {
        return new Promise(async resolve => {
            let { title } = this.state;
            let country = sessionStorage.getItem('country');
            // console.log(country);
            if (!country) {
                let { data } = await axios.get('/api/country');
                country = data;
                sessionStorage.setItem('country', country);
            }
            let item;
            try {
                item = (await post('/api/item', { title, country })).data;
                this.setState({ item }, () => {
                    this.loadRelated();
                    this.updateBasket();
                    resolve();
                })
            } catch (e) { }
            if (!item) {
                console.log('ERROR!');
                this.setState({ error: true });
            }
        })
    }

    async loadRelated() {
        let { item } = this.state;
        let related = (await axios.post('/api/related', { category: item.category, item:item._id })).data;
        related.forEach((item,i) => {
            item.path = item.images[0].path;
            item.bigText = '£' + item.price.toFixed(2);
            item.link = '/item/' + item.title.replace(/ /g, '_');
        })
        this.setState({ related });
    }

    componentWillReceiveProps(next) {
        this.setState({ item: null, related: null }, () => {
            this.setState({ title: next.match.params.title.replace(/_/g, ' '), showRequired: false, quantity:1 }, this.loadItem);
        })
    }

    updateBasket() {
        return new Promise(resolve => {
            let basket = JSON.parse(localStorage.getItem('b'));
            if (!basket) basket = [];
            let { item } = this.state;
            let existing = basket.findIndex(b => {
                if (b._id == item._id) {
                    for (let i = 0; i < b.variations.length; i++) {
                        if (!item.variations[i].selected) return false;
                        if (b.variations[i].selected.title != item.variations[i].selected.title) return false;
                    }
                    return true;
                } else return false;
            })
            this.setState({ basket, existing }, resolve);
        })
    }

    dropdownSelect(i, s) {
        let { item } = this.state;
        item.variations[i].selected = s;
        this.setState({ item },this.updateBasket);
    }

    async addToBasket() {
        let ITEM = this.state.item;
        let showRequired = false;
        for (let i = 0; i < ITEM.variations.length; i++){
            if (!ITEM.variations[i].selected) {
                showRequired = true;
                break;
            }
        }
        if (showRequired) {
            this.setState({ showRequired });
        } else {
            let { quantity } = this.state;
            let item = {
                _id: ITEM._id,
                title: ITEM.title,
                images:ITEM.images.map(i=>i.path),
                variations: ITEM.variations.map(item => {return{ title: item.title, selected:{ title: item.selected.title, cost: item.selected.cost } }}),
                quantity
            }
            await this.updateBasket();
            let { basket, existing } = this.state;
            if (existing < 0) {
                basket.push(item);
                existing = item;
            } else {
                basket[existing].quantity = quantity;
            }
    
            localStorage.setItem('b', JSON.stringify(basket));
            this.setState({showBasket:true, basket})
        }
    }

    buy() {
        let ITEM = this.state.item;
        let showRequired = false;
        for (let i = 0; i < ITEM.variations.length; i++) {
            if (!ITEM.variations[i].selected) {
                showRequired = true;
                break;
            }
        }
        if (showRequired) {
            this.setState({ showRequired });
        } else {
            let { quantity } = this.state;
            
            let item = {
                _id: ITEM._id,
                title: ITEM.title,
                images:ITEM.images.map(i=>i.path),
                variations: ITEM.variations.map(item => { return { title: item.title, selected: { title: item.selected.title, cost: item.selected.cost } } }),
                quantity
            }
            sessionStorage.setItem('b', JSON.stringify([item]));
            this.props.navigate('/buy/'+item.title.replace(/ /g, '_'));
        }
    }

    render() {
        let { title, item, quantity, existing, showBasket, showRequired, related, error } = this.state;
        let price = 0;
        let k = 0;
        let c = [
            'rgb(41,239,173)',
            'rgb(244,179,33)',
        ]
        if (item) {
            price = item.price;
            item.variations.forEach(v => price += v.selected ? v.selected.cost: 0);
        }
        price = price * quantity;
        return <div className="Store">
            {showBasket ? <Modal  hide={()=>this.setState({showBasket:false})}/>:null}
            <Banner />
            <div className="bg2">
                <div className="content" style={{paddingBottom:0}}><h1>{item?item.title:title}</h1></div>
                {item?<div><div className="split" style={{paddingTop:0}}>
                    <div className="sidebar">
                        <Image images={item.images} />
                    </div>
                    <div className="content">
                        <div className="labelPair">
                            <div className="label">
                                Description
                            </div>
                            <div className="pair">
                                <Paragraph text={item.body} />
                            </div>
                        </div>
                        {item.variations.map((v,i) => <div key={title+' v '+v.title} className="labelPair">
                            <div className="label">
                                {v.title}
                            </div>
                            <div className="pair">
                                <Dropdown options={v.options} left={v.options.map(o=>o.title)} right={v.options.map(o=>o.cost>0?'+ £'+o.cost.toFixed(2):'')} callback={s => this.dropdownSelect(i, s)} k={v.title} />
                                {showRequired?v.selected?null:<div className="required">This field is required</div>:null}
                            </div>
                        </div>)}
                        <div className="labelPair">
                            <div className="label">
                                Quantity
                            </div>
                            <div className="pair">
                                <Quantity max={item.infinite?5:Math.min(5,item.stock)} callback={q => this.setState({quantity:q})} />
                            </div>
                        </div>
                        <div className="labelPair">
                            <div className="label">
                                Price
                            </div>
                            <div className="pair">
                                £{price.toFixed(2)}
                            </div>
                        </div>
                        <div className="labelPair">
                            <div className="label">
                            </div>
                            <div className="pair">
                                <button onClick={this.addToBasket}>{existing >= 0?'In your basket':'Add to basket'}</button>
                                <button onClick={this.buy} className="green">Buy now</button>
                            </div>
                        </div>

                    </div>
                </div>
                
                {related && related.length>0?<div className="content"><h1>Related items</h1><div className="thumbFlex">
                    {related.map((item,i) => <Thumb
                        key={'related ' + item.title}
                        className="item"
                        style={{
                            backgroundImage: 'url(/api/pictures/' + encodeURIComponent(item.path) + ')'
                        }}
                    >
                        <NavLink to={item.link}><div className="tool" >
                            <div className="title" style={{backgroundColor:c[k%2]}}>{item.title}
                            <h3 style={{backgroundColor:c[k++%2]}}>{item.bigText}</h3></div>
                        </div></NavLink>
                    </Thumb>)}
                </div></div>:null}
                
                </div> : error ? <div className="content">
                    <h2>Could not load item from server! Maybe you could disable your adblocker - there are no adverts, but sometimes the adblocker is a bit eager.</h2>
                    <h2>If that doesn't work, send me an email: kai@ambigr.am</h2>
                </div> : <div className="center"><div className="spinner"></div></div>}
            </div>
            
        </div>
    }
}