import React, { Component, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Store from './Store';
import Contact from './Contact';
import AnimatedBackground from './AnimatedBackground';
import axios from 'axios';
import Title from './Title';
import { AdminContext } from '../../hooks';

export default function Front({ }) {
    let [showStore, setShowStore] = useState(false);
    let [small, setSmall] = useState(false);
    let [animating, setAnimating] = useState(false);
    let [h, setH] = useState(0);
    let [contact, setContact] = useState(false);
    let [hhh, setHhh] = useState(-1);
    let [onMobile, setOnMobile] = useState(/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent))

    let navigate = useNavigate();

    let { admin, setAdmin, newOrders } = useContext(AdminContext); 

    let contentRef = useRef();
    let bigBannerRef = useRef();
    let wordsRef = useRef();
    
    useEffect(() => {
        window.addEventListener('resize', resize, true);
        resize();
        return () => {
            window.removeEventListener('resize', resize);
        }
    },[])

    function resize() {
        if (contentRef.current) {
            let small = Math.max(document.documentElement.clientHeight, window.innerHeight || 0) < contentRef.current.clientHeight;
            setSmall(small);
        }
    }

    useEffect(() => {
        if (animating) {
            let h = bigBannerRef.current.clientHeight;
            let hhh = wordsRef.current.clientHeight;
            setH(h);
            setHhh(hhh);
            setShowStore(true);
        }
    }, [animating])
    
    useEffect(() => {
        if (showStore) {
            let hh = contentRef.current.clientHeight;
            let hhh = wordsRef.current.clientHeight;
            setTimeout(() => {
                setH(hh - hhh)
                setTimeout(() => {
                    setH(-1)
                    setTimeout(() => {
                        setAnimating(false);
                    },100)
                }, 1000 * 0.2)
            }, 1)
        }
    },[showStore])
    let b = JSON.parse(localStorage.getItem('b'));
    if (b && b.length == 0) b = false;
    return <div className="Store">
        {contact ? <Contact hide={()=>setContact(false)}/>:null}
        <div className="bigBanner" ref={bigBannerRef} style={{
            height: h == 0 ? '100vh' : h == -1 ? 'auto' : h,
            transition: animating ? 'height 0.2s linear' : null,
            alignItems:small?'flex-start':null
        }}>
            <AnimatedBackground/>
            <div className="content" ref={contentRef}>
                <div className="sub">
                    <Title setAdmin={setAdmin} animating={animating} showStore={showStore}/>
                </div>
                <div ref={wordsRef} style={{
                    overflow:'hidden',
                    maxHeight: showStore ? hhh : null,
                    transition:animating?'max-height 0.2s linear':null
                }}>
                    <div className="sub">I'm saving up for a boat.</div>
                    <div className="sub">And I have some gifts for sale!</div>
                    {onMobile ? null : <div className="sub">Play the fun game below for a chance to win a 10% discount on everything.</div>}
                </div>
                <div className="sub">
                    {h>=0?<button className="green" onClick={() => setAnimating(true)}>Buy something</button>:null}
                    {b?<button className="green" onClick={() => navigate('/basket/')}>Basket</button>:null}
                    <button className="green" onClick={() => setContact(true)}>Contact</button>
                    {admin ? <button className="green" onClick={() => navigate('/admin/')}>Admin ({newOrders})</button>:null}
                    {onMobile ? null : <button className="green" onClick={() => navigate('/game/')}>Fun game</button>}
                </div>
            </div>
        </div>

        {showStore?<div className="expandable" id="gifts">
            <Store />
        </div>:null}
    </div>
}