import React, { Component } from 'react';
import { StripeProvider, Elements, injectStripe } from 'react-stripe-elements';
import { NavLink, useNavigate, useParams } from "react-router-dom";

import Banner from './Banner';
import CheckoutForm from './CheckoutForm';
import Slideshow from './Slideshow';
import { secretItems } from '../../hooks';

export default function FunctionalWrapper(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <Buy {...props} navigate={navigate} match={{params}} />
}

class Buy extends Component {
    constructor(props) {
        super(props);
        let { item } = props.match.params;
        let items = JSON.parse(sessionStorage.getItem('b'));
        let images = [];
        items.forEach(item => {
            item.images.forEach(image => images.push(image));
        })
        let dev = process.env.NODE_ENV != "production";
        // dev = true;
        this.state = {
            item, items, images, dev
        }
    }

    render() {
        let { item, items, images, dev } = this.state;
        return <div className="Store">
            <Banner />
            <div className="bg2">
                <div className="content" >
                    <h1>Buy</h1>
                    <div className="buy">
                        <div id="slideshow" className="box left">
                            <Slideshow images={images}/>
                        </div>
                        <div className="box right">
                            <StripeProvider apiKey={!dev?"pk_live_Oa2tnqKCRP2tGfw9qOLZ9Woh00QuAwHqaz":"pk_test_BotzK9yCGwnnBHVMrgxALqQW006hSwzwaD"}>
                                <Elements>
                                    <CheckoutForm fromBasket={!item}/>
                                </Elements>
                            </StripeProvider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}