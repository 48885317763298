import React, { useState, useEffect, useContext, useRef, useReducer } from 'react';
import AdminTabs from './AdminTabs';
import axios from 'axios';
import { AdminContext, p } from '../../hooks';
import A from './A';
import I from './I';
import Q from './Q';
import MusicAdmin from './MusicAdmin';
import Tabs from './Tabs';


export default function KaiTunes() {
    let { admin, setBackgroundColour } = useContext(AdminContext);
    let [tab, setTab] = useState('Steal')

    useEffect(()=>{setBackgroundColour("Admin");return()=>setBackgroundColour(false);},[]);
    if (!admin) return null;

    
    return <div className="MusicAdmin">
        <AdminTabs tab="Music" />
        <h1>KaiTunes</h1>
        <Tabs tab={tab} setTab={setTab} tabs={['Steal','Upload']} />
        {tab == 'Upload' ? <MusicAdmin/> : <Kai/>}
    </div>
}

function Kai({ }) {
    let [searchForNewAlbum, setSearchForNewAlbum] = useState(false);
    let [force, reloadAlbums] = useReducer(r=>r+1,0);
    let [albums, setAlbums] = useState([]);
    let [album, setAlbum] = useState();
    let [downloading, setDownloading] = useState(false);

    async function loadAlbums() {
        let { data } = await axios.get('/api/albums');
        setAlbums(data);
    }
    useEffect(() => { loadAlbums() }, [force])
    return <div>
        {searchForNewAlbum ? <SearchForNewAlbum
            close={() => setSearchForNewAlbum(false)}
            download={album => {
                setSearchForNewAlbum(false);
                setDownloading(album);
            }}
        /> : null}
        {album ? <Album
            close={() => setAlbum()}
            album={album}
            download={missingSongs => {
                setAlbum(false);
                setDownloading({ ...album, missingSongs });
            }}
            deleteAlbum={async () => {
                await axios.post('/api/deletealbum', { album, p: p() });
                setAlbum(false);
                reloadAlbums();
            }}
            reloadAlbums={reloadAlbums}
        /> : null}
        {downloading ? <DownloadAlbum
            close={()=>setDownloading(false)}
            album={downloading}
            setDownloading={setDownloading}
            reloadAlbums={reloadAlbums}
            setAlbum={album => {
                setDownloading(false);
                setAlbum(album);
            }}
        /> : null}
        
        <div className="downloadNewAlbumButton">
            <A onClick={() => setSearchForNewAlbum(true)}>Download new album</A>
        </div>
        <Albums albums={albums} setAlbum={setAlbum} />
    </div>
}

function DownloadAlbum({ album, reloadAlbums, setAlbum, close }) {
    let [downloadedAlbum, setDownloadedAlbum] = useState(false);
    let [log, setLog] = useState([]);
    let cmd = useRef();
    let timeout = useRef();

    useEffect(() => {
        if (cmd.current) cmd.current.scrollTop = cmd.current.scrollHeight;
    }, [log])
    
    useEffect(() => {
        download();
        return () => clearTimeout(timeout.current);
    },[])

    async function download() {
        let key = (await axios.post('/api/downloadalbum', { album, p: p() })).data;
        async function check() {
            let { data } = await axios.get('/api/albumupdates/' + key);
            let recursion = () => timeout.current = setTimeout(check, data.length == 0 ? 200 : 100);
            if (data.length > 0) {
                if (data[data.length - 1] == 'FINISHED!') {
                    data.pop();
                    reloadAlbums();
                    if (data) setDownloadedAlbum((await axios.get('/api/justdownloaded/' + key)).data);
                } else recursion();
                setLog(log => [...log, ...data]);
            } else recursion()
        }
        timeout.current = setTimeout(check, 1000);
    }

    return <div className="expanded">
        <div className="window">
            <div className="modalHeading">
                <div className="heading">Downloading {album.name}</div>
                {downloadedAlbum ? <button onClick={close}>╳</button> : null}
            </div>
            <div className="downloading">
                <div className="log" ref={cmd}>{log.length == 0 ? <div className="line"><pre>Please wait...</pre></div> : log.map((l, i) => <div className="line" key={i}><pre>{l}</pre></div>)}<div className="blankLine"/></div>
                {downloadedAlbum ? <div className="viewAlbum">
                    <A onClick={()=>setAlbum(downloadedAlbum)}>View album</A>
                </div>:null}
            </div>
        </div>
    </div>
}

let sortingAlgorithms = {
    'Download date': {
        sort: (a, b) => b.downloaded - a.downloaded,
        categorise: a => {
            let now = new Date().getTime();
            let f = days => now - days * (1000 * 60 * 60 * 24);
            return a.downloaded > f(1) ? 'Today' : a.downloaded > f(2) ? 'Yesterday' : a.downloaded > f(7) ? 'This week' : a.downloaded > f(30) ? 'This month' : a.downloaded > f(365) ? 'This year' : 'Ages ago';
        }
    },
    'Release date': {
        sort: (a, b) => b.released - a.released,
        categorise: a =>  new Date(a.released).getFullYear()
    },
    'Album name': {
        sort: (a, b) => a.name.localeCompare(b.name),
        categorise: a => a.name.substring(0, 1).toUpperCase()
    },
    'Artist': {
        sort: (a, b) => b.artist == a.artist ? b.released - a.released : a.artist.localeCompare(b.artist),
        categorise: a => a.artist
    }
}
let sortTabs = Object.keys(sortingAlgorithms);

function Albums({ albums: _albums, setAlbum }) {
    let [sort, setSort] = useState(sortTabs[0]);
    let [albums, setAlbums] = useState([]);

    useEffect(() => {
        if (sortingAlgorithms[sort]) {
            let albums = _albums.sort(sortingAlgorithms[sort].sort);
            let a = [];
            let categories = {};
            for (let album of albums) {
                let category = sortingAlgorithms[sort].categorise(album);
                if (categories[category]!==undefined) {
                    a[categories[category]].albums.push(album);
                } else {
                    categories[category] = a.length;
                    a.push({category, albums: [album]});
                }
            }
            setAlbums(a);
        }
    },[sort, _albums])
    
    return <div>
        <Tabs tab={sort} setTab={setSort} tabs={sortTabs} />
        <div className="albums">
            {albums.map((c, i) => <Category sort={sort} name={c.category} albums={c.albums} key={`${i} ${c.category}`} setAlbum={setAlbum} />)}
        </div>
    </div>
}

function Album({ album, close, download, deleteAlbum, reloadAlbums }) {
    let [songs, setSongs] = useState(album.songs);
    let [missingSongs, setMissingSongs] = useState(false);
    let [missingSong, setMissingSong] = useState(-1);
    let [noCloseButton, setNoCloseButton] = useState(false);
    useEffect(() => {
        if (album.songCount && album.songCount != album.songs.length) {
            let songs = [];
            let missingSongs = [];
            for (let i = 0; i < album.songCount; i++) {
                let song = album.songs.find(s => s.track == i + 1);
                songs.push(song ? song : false);
                if (!song) missingSongs.push(i);
            }
            setSongs(songs);
            setMissingSongs(missingSongs);
        }
    }, []);
    return <div className="expanded">
        <div className="window">
            <div className="modalHeading">
                <div className="heading">{album.name}</div>
                {!noCloseButton?<button onClick={close}>╳</button>:null}
            </div>
            <div className="albumDisplay">
                <div className="albumArtwork"><img alt={album.name} src={`/api/kaitunes/${encodeURIComponent(album.artwork)}`} /></div>
                <div className="deets">
                    <div className="albumArtist">{album.name} {album.released?<span>({new Date(album.released).getFullYear()})</span> : null}</div>
                    <div className="albumArtist">{album.artist}</div>
                    {missingSong >= 0 ? <MissingSong album={album} track={missingSong} reloadAlbums={reloadAlbums} setNoCloseButton={setNoCloseButton} /> : <> <div className="albumSongs"><ol>{songs.map((song, i) => song ? <li key={`${song.name} ${i}`}>{song.name} ({song.length}s)</li> : <li className="songDoesNotExist" key={i}><A onClick={() => setMissingSong(i)}>Missing...</A></li>)}</ol></div>
                    <div className="buts">
                        <Q callback={deleteAlbum}>Delete album</Q>
                        {missingSongs?<Q callback={() => download(missingSongs)}>Download missing songs</Q>:null}
                    </div></>}
                </div>
            </div>
        </div>
    </div>
}

function extractYouTubeID(s) {
    function trimOff(str) {
        if (s.startsWith(str)) s = s.substring(str.length, s.length);
    }
    trimOff('https://');
    trimOff('www.');
    if (s.startsWith('youtube.com')) {
        trimOff('youtube.com/watch?v=');
        let id = s.substring(0, 11);
        return id.length == 11 ? id : null;
    } else if (s.startsWith('youtu.be')){
        trimOff('youtu.be/');
        let id = s.substring(0, 11);
        return id.length == 11 ? id : null;
    }
}

function MissingSong({ album, track, reloadAlbums, setNoCloseButton }) {
    let [name, setName] = useState();
    let [percent, setPercent] = useState(0);
    let [error, setError] = useState();
    let timeout = useRef();
    let [downloading, setDownloading] = useState(false);
    let ref = useRef();
    async function getSongName() {
        let { data } = await axios.post('/api/getsongname', { album, track });
        setName(data);
    }
    useEffect(() => {
        getSongName();
        return () => clearTimeout(timeout.current);
    }, [])
    async function download(youtube) {
        youtube = extractYouTubeID(youtube);
        if (youtube) {
            setDownloading(true);
            setNoCloseButton(true);
            let key = (await axios.post('/api/downloadfromyoutube', { album, track, youtube, name, p:p() })).data;
            async function check() {
                let { data } = await axios.get('/api/youtubeupdates/' + key);
                let recursion = () => timeout.current = setTimeout(check, 1000);
                if (data) {
                    setPercent(data.percent);
                    if (!data.error) {
                        if (data.percent == 100) {
                            reloadAlbums();
                            setNoCloseButton(false);
                        } else {
                            recursion();
                        }
                    } else {
                        setError(data.error);
                    }
                }
            }
            timeout.current = setTimeout(check, 1000);
        }
    }

    let [width, setWidth] = useState(333);

    useEffect(() => {
        if (ref.current) setWidth(ref.current.getBoundingClientRect().width);
    },[percent])

    return <div className="missingSong">
        {name ? downloading ? error ? <div>{error}</div> :  <>
            <div className="missingSongName">Downloading {name}...</div>
            <div className="progress" ref={ref}>
                <div className="redPercent">{percent}%</div>
                <div className="progressBar" style={{ width: `${percent}%` }}>
                    <div className="whitePercent" style={{width}}>{percent}%</div>
                </div>
            </div>
            {percent == 100 ? <div>Finished!</div> : null}
        </> : <>
            <div className="missingSongName">{track + 1}. {name}</div>
            <div className="buts">
                <a href={`https://www.youtube.com/results?search_query=${encodeURIComponent(`${name} ${album.artist}`)}`} target="_blank" rel="noreferrer">Search on YouTube</a>
                <I callback={youtube => {
                    download(youtube);
                }}>Download from YouTube</I>
            </div>
        </> : null}
        
    </div>
}

function Category({ sort, name, albums, setAlbum }) {
    let [expanded, setExpanded] = useState(true);
    return <div className="category" >
        <A onClick={() => setExpanded(e => !e)}><div className="categoryTitle">{name} {expanded ? '▼' : '►'}</div></A>
        {expanded ? <div className="albumThumbnails">{albums.map((album, i) => <AlbumThumbnail album={album} key={album.name} showArtist={sort != 'Artist'} setAlbum={setAlbum} />)}</div> : null}
    </div>
}

function SearchForNewAlbum({ close, download }) {
    let ref = useRef(null);
    let timeout = useRef(null);
    let [albums, setAlbums] = useState([]);
    let [query, setQuery] = useState('');
    let [album, setAlbum] = useState(null);
    useEffect(() => {
        ref.current.focus();
    }, [])
    
    useEffect(() => {(async () => {
        clearTimeout(timeout.current);
        timeout.current = setTimeout(async () => {
            if (query.length > 1) {
                let { data } = await axios.post('/api/searchspotify', { query });
                setAlbums(data);
                console.log(data);
            } else setAlbums([]);
        }, 500);
    })()
    }, [query])

    return <div className="expanded">
        <div className="window">
            <div className="modalHeading">
                <div className="heading">New album</div>
                <button onClick={close}>╳</button>
            </div>
            {album ? <div className="albumDisplay">
                <img alt={album.name} src={album.artwork} />
                <div className="deets">
                    <A className="backButton" onClick={()=>setAlbum()}>⏴Back</A>
                    <div className="albumName">{album.name} {album.released?<span>({new Date(album.released).getFullYear()})</span> : null}</div>
                    <div className="albumArtist">{album.artist}</div>
                    <div className="albumSongs">{album.songCount} song{album.songCount == 1 ? '' : 's'}</div>
                    <Q callback={()=>download(album)}>Download</Q>
                </div>
            </div> : <div className="searchForNewAlbum">
                <input
                    ref={ref}
                    type="text"
                    value={query}
                    onChange={e=>setQuery(e.target.value)}
                />
                <div className="albumThumbnails">
                    {albums.map(a => <AlbumThumbnail key={a.id} album={a} setAlbum={setAlbum} />)}
                </div>
            </div>}
        </div>
    </div>
}

function AlbumThumbnail({ album, setAlbum = () => { }, showArtist = true }) {
    let artwork = album.artwork;
    if (album.songs) {
        artwork = `/api/kaitunes/${encodeURIComponent(album.artwork)}`;
    }
    let missingSongs = album.songs ? album.songCount - album.songs.length : null;
    return <div className="albumThumbnail">
        <A onClick={() => setAlbum(album)}><img alt={album.name} src={artwork} /></A>
        <div className="deets">
            <div className="albumName">{album.name}</div>
            {showArtist?<div className="albumArtist">{album.artist}</div>:null}
            <div className="albumSongs">{album.songCount} song{album.songCount == 1 ? '' : 's'}</div>
        </div>
        {missingSongs ? <div className="missingSong">Missing {missingSongs} song{missingSongs == 1 ? '' : 's'}!</div> : null}
    </div>
}
