import React, { useState, useEffect, useReducer, useRef, createContext, useContext } from 'react';
import AdminTabs from './AdminTabs';
import Tabs from './Tabs';
import Pages from './Pages';
import axios from 'axios';
import Paragraph from '../Paragraph';
import Q from './Q';
import A from './A';
import {DisplayDate, DateSelect, toDateString} from './DateSelect';
import Dropdown from './Dropdown';
import { AdminContext, p, useGetState } from '../../hooks';
import Check from './C';

let CategoriesContext = createContext(null);

export default function SelfAssessment() {
    let [tab, setTab] = useState('Records');
    let [categories, setCategories] = useState([]);
    async function addCategory(category) {
        let {data} = await axios.post('/api/addselfassessmentcategory', { p: p(), category }); 
        setCategories(data);
    }
    let getBg = useRef();

    useEffect(() => {
        setBackgroundColour("Admin");
        return () => setBackgroundColour(false);
    }, [])

    let { admin, setBackgroundColour } = useContext(AdminContext);
    if (!admin) return null;
    
    return <div className="SelfAssessment" ref={getBg}>
        <CategoriesContext.Provider value={{ categories, setCategories, addCategory }}>
            <AdminTabs tab="Self assessment" />
            <h1>Self Assessment</h1>
            <Tabs tab={tab} setTab={setTab} tabs={['Records', 'Graphs', 'Compiler']} />
            {tab == 'Records' ? <Records/> :tab == 'Graphs' ? <Graphs/> :tab == 'Compiler' ? <Compiler/> : null}
        </CategoriesContext.Provider>
    </div>
}

function Compiler({ }) {
    let [loading, setLoading] = useState(true);
    let [compiled, setCompiled] = useState([]);
    let [expand, setExpand] = useState(false);
    let [force, forceRender] = useReducer(f => f + 1, 0);
    useEffect(() => {
        (async () => {
            let { data } = await axios.post('/api/compiledrecords', { p: p() });
            setCompiled(data);
            setLoading(false);
        })()
    },[force])
    return <div className="selfAssessmentCompiler">
        {expand ? <ExpandedCompilation
            year={expand.c.year}
            compiled={!expand.c.uncompiled}
            profit={expand.c.uncompiled ? null : expand.c.profit}
            income={expand.c.uncompiled ? null : expand.c.income}
            expenses={expand.c.uncompiled ? null : expand.c.expenses}
            save={forceRender}
            close={() => setExpand(false)}
        /> : null}
        {loading ? <div className="i">Loading...</div> : <>
            <div className="timeline">
                {compiled.map((c, i) => <div key={i} className="fart">
                    {i>0?'▼':null}
                    <div className={"year " + (c.uncompiled ? 'red' : 'fg')}>
                        <A onClick={()=>setExpand({ i, c })}>{c.year}</A>
                    </div>
                </div>)}                
            </div>
        </>}
    </div>
}

function ExpandedCompilation({ year, compiled: compiled1 = false, profit: profit1, income: income1, expenses: expenses1, close, save }) {
    let [compiled, setCompiled] = useState(compiled1);
    let [yearComplete, setYearComplete] = useState(false);
    let [profit, setProfit] = useState(profit1);
    let [income, setIncome] = useState(income1);
    let [expenses, setExpenses] = useState(expenses1);
    let [loading, setLoading] = useState(false);
    let [loading1, setLoading1] = useState(true);
    useEffect(() => {
        (async () => {
            if (!compiled1) {
                setLoading(true);
                let { data } = await axios.post('/api/compilerecord', { p: p(), year });
                let { profit, income, expenses, yearComplete } = data;
                setProfit(profit);
                setIncome(income);
                setExpenses(expenses);
                setYearComplete(yearComplete);
                setLoading(false);
            }
            setLoading1(false);
        })()
    },[])
    return <div className="expanded expandedCompilation">
        <div className={"window " + (compiled ? '' : 'red')}>
            <div className="modalHeading">
                <div className="heading">{year}</div>
                <button onClick={close}>╳</button>
            </div>
            {loading ? <div className="i">Loading...</div> : <>
                {loading1 ? null :<>
                    {compiled?null:<div className="itemSplit">
                        <div className="left"></div>
                        <div className="right fuckme">{yearComplete ? "This has not yet been saved!" : "This year is not yet over!"}</div>
                    </div>}
                    <div className="itemSplit"><div className="left">Income</div><div className="right bigballs">£{income.toFixed(2)}</div></div>
                    <div className="itemSplit"><div className="left">Expenses</div><div className="right bigballs">£{expenses.toFixed(2)}</div></div>
                    <div className="itemSplit"><div className="left">Taxed profit</div><div className="right bigballs">£{profit.toFixed(2)}</div></div>
                    <div className="itemSplit"><div className="left">Profit</div><div className="right bigballs">£{(income - expenses).toFixed(2)}</div></div>
                    <div className="buts">
                        {!compiled && yearComplete ? <Q
                            className="but"
                            callback={async () => {
                                let { data } = await axios.post('/api/compilerecord', { p: p(), year, update:true });
                                save({ year, profit, income, expenses });
                                close();
                            }}
                        >Save</Q> : null}
                    </div>
                </>}

            </>}
        </div>
    </div>
}

function TabRange({ tabs, setRange: setRange0 = () => { }, setIndexes = () => { }, initial=[] }) {
    let [mouseDown, setMouseDown] = useState(false);
    let [clicked, setClicked] = useState(0);
    let [range, setRange, getRange] = useGetState(initial);
    let [expand, setExpand] = useState(false);
    useEffect(() => {
        if (mouseDown) {
            let f = () => {
                let range = getRange();
                setMouseDown(false);
                setIndexes(range);
                setRange0(range.map(r=>tabs[r]));
                document.removeEventListener('mouseup', f);
            }
            document.addEventListener('mouseup', f);
        }
    }, [mouseDown])
    return <div className="TabRange">
        {tabs.map((t, i) => <div className="tabby" key={t}>
            {i > 0 ? <div className={i > range[0] && i <= range[1] ? 'selected gap' : 'gap'}/>: null}
            <A
                className={i >= range[0] && i <= range[1] ? 'selected tab' : 'tab'}
                onMouseDown={() => {
                    setRange([i, i]);
                    setClicked(i);
                    setMouseDown(true)
                }}
                onMouseEnter={() => {
                    if (mouseDown) {
                        if (i < clicked) {
                            setRange([i, clicked]);
                        } else setRange([clicked, i]);
                    }
                }}>{t}
            </A>
        </div>)}
    </div>
}

function taxYearfromDate(date) {
    date = new Date(date);
    let d = date.getDate();
    let m = date.getMonth();
    let y = date.getFullYear();
    return m > 3 ? y : m < 3 ? y - 1 : d >= 6 ? y : y - 1;
}

function taxWindow(y) {
    if (y > 3000) y = taxYearfromDate(y);
    let d0 = new Date(y, 3, 6).getTime();
    let d1 = new Date(y + 1, 3, 6).getTime();
    return [d0, d1];
}

function Graphs() {
    let [years, setYears] = useState([]);
    let [loading, setLoading] = useState(true);
    let [range, setRange] = useState();
    let [bigData, setBigData] = useState();
    let [graph, setGraph] = useState(false);
    let [path, setPath] = useState()
    let [yLabels, setYlabels] = useState([])
    let [total, setTotal] = useState(0);
    let [hovering, setHovering] = useState(false);
    let [mouse, setMouse] = useState([0, 0]);
    let [y0, setY0] = useState(0);
    let [y1, setY1] = useState(0);
    let [s0, setS0] = useState(0);
    let [s1, setS1] = useState(0);
    let [mouseData, setMouseData] = useState([0, 0]);
    let [expand, setExpand] = useState(false);
    let [nearest, setNearest] = useState(-1);

    let svgRef = useRef();

    useEffect(() => {
        (async () => {
            let { data } = await axios.post('/api/yearsonrecord', { p: p() });
            let years = [];
            for (let i = data[0]; i <= data[1]; i++) years.push(i);
            setYears(years);
            setRange([years[years.length - 1], years[years.length - 1]]);
            setBigData((await axios.post('/api/profitgraph', { p: p() })).data);
            setLoading(false);
        })()
    }, []);

    useEffect(() => {
        if (graph) {
            let money = (((H - mouse[1]) / H) * (s1 - s0) + s0);
            let stamp = (mouse[0] / W) * (y1 - y0) + y0;
            let shortest = Infinity;
            let i = -1;
            graph.forEach((g, j) => {
                let dx = Math.abs(mouse[0] - g.x);
                if (dx < 5) {
                    let dy = Math.abs(mouse[1] - g.y);
                    if (dy < 5) {
                        let d = dx * dx + dy * dy;
                        if (d < shortest && d < 40) {
                            shortest = d;
                            i = j;
                        }
                    }
                }
            })
            setNearest(i)
            setMouseData([money,stamp]);
        }
    }, [mouse])
    
    useEffect(() => {
        if (!hovering) setNearest(-1);
    },[hovering])

    useEffect(() => {
        (async () => {
            if (range && bigData) {
                let y0 = taxWindow(range[0])[0];
                let y1 = taxWindow(range[1])[1];
                setY0(y0);
                setY1(y1);
                let s1 = Math.max(...bigData.map(d => d.sum));
                let s0 = Math.min(...bigData.map(d => d.sum));
                let graph = bigData.filter(g => g.date > y0 && g.date <= y1);
                if (s1 < 0) s1 = 0;
                if (s0 > 0) s0 = 0;
                setS0(s0);
                setS1(s1);
                let sum = s1 - s0;
                let path = '';
                let points = [];
                graph.forEach((d, i) => {
                    if (!d.ignore) {
                        let x = ((d.date - y0) / (y1 - y0)) * W;
                        let y = H - (((d.sum - s0) / sum) * H);
                        points.push({d:graph[i],x,y})
                        path += `${i==0?'M':'L'}${x} ${y} `
                    }
                });
                setGraph(points);
                setPath(path);

                let labelGap = 0;
                let gaps = [1,2,3,5,10,25,50,100,200,500,1000,5000,10000]
                while (labelGap < gaps.length && sum / gaps[labelGap] > 10) labelGap++;
                gaps.push(gaps[gaps.length-1]*2)
                labelGap = gaps[labelGap];
                let yLabels = [];
                for (let i = Math.floor(s0); i < Math.floor(s1); i++) {
                    if (i % labelGap == 0) {
                        yLabels.push({ value: i, y: H - (((i - s0) / sum) * H) });
                    }
                }
                setYlabels(yLabels)

                setTotal(graph[graph.length - 1].sum - graph[0].sum);
            }
        })()
    }, [range, bigData])
    
    let W = 600;
    let H = 600;

    let yearRange = [];
    if (range) for (let i = range[0]; i <= range[1]; i++) yearRange.push(i);
    
    return <div className="selfAssessmentGraphs">
        {expand ? <ExpandedGraph
            close={() => setExpand(false)}
            record={expand}
        /> : null}
        {loading ? <div className="i">Loading...</div> : <>
            <TabRange setRange={setRange} tabs={years} initial={[years.length - 1, years.length - 1]}/>
            <div className="graph">
                <svg
                    height={H} width={W}
                    ref={svgRef}
                    onMouseEnter={() => setHovering(true)}
                    onMouseLeave={() => setHovering(false)}
                    onMouseMove={e => {
                        if (svgRef.current) {
                            let rect = svgRef.current.getBoundingClientRect();
                            setMouse([e.pageX-rect.left,e.pageY-rect.top])
                        }
                    }}
                    onClick={() => {
                        if (nearest >= 0 && graph) {
                            setExpand(graph[nearest].d);
                        }
                    }}
                >
                    <path d={path} />
                    {nearest >= 0 && graph ? <>
                        <line x1={graph[nearest].x} y1={0} x2={graph[nearest].x} y2={H} strokeDasharray="2, 8"/>
                        <line x1={0} y1={graph[nearest].y} x2={W} y2={graph[nearest].y} strokeDasharray="2, 8" />
                        <circle cx={graph[nearest].x} cy={graph[nearest].y} r="5"/>
                    </> : hovering ? <>
                        <line x1={mouse[0]} y1={0} x2={mouse[0]} y2={H} strokeDasharray="2, 8"/>
                        <line x1={0} y1={mouse[1]} x2={W} y2={mouse[1]} strokeDasharray="2, 8" />
                        
                    </> : null}
                </svg>
                {yLabels.map(({ value, y }) => <div className='label' key={y} style={{ top: `calc(${y}px - 0.5rem)` }}>{value}</div>)}
                {hovering ? <>
                    <div className="mouseLabel" style={
                        nearest >= 0 && graph ? { left: graph[nearest].x, top: graph[nearest].y } : { left: mouse[0], top: mouse[1] }
                    }>
                        <div className="innerLabel">
                            {nearest >= 0 && graph ? <>
                                <div>£{graph[nearest].d.sum.toFixed(2)}</div>
                                <div>{toDateString(graph[nearest].d.date)}</div>
                            </> : <>
                                <div>£{mouseData[0].toFixed(2)}</div>
                                <div>{toDateString(mouseData[1])}</div>
                            </>}
                        </div>
                    </div>
                </> : null}
            </div>
            {range[0] == range[1] ? <div className="equidistant">
                {['Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar',''].map(str => <div className="str"key={str}>{str}</div>)}
            </div> : <div className="equidistant">
                {[...yearRange,' '].map(str => <div className="str"key={str}>{str}</div>)}
            </div>}
            <div className="profit">Profit in {range[0] == range[1] ? range[0] : range[0] + '-' + range[1]}: <span>£{total.toFixed(2)}</span></div>
        </>}
    </div>
}

function ExpandedGraph({ record, close }) {
    return <div className="expanded expandedGraph">
        <div className="window">
            <div className="modalHeading">
                <div className="heading">Record</div>
                <button onClick={close}>╳</button>
            </div>
            <div className="itemSplit">
                <div className="left">Type</div>
                <div className="right">{record.type}</div>
            </div>
            <div className="itemSplit">
                <div className="left">Category</div>
                <div className="right">{record.category}</div>
            </div>
            <div className="itemSplit">
                <div className="left">Title</div>
                <div className="right">{record.title}</div>
            </div>
            <div className="itemSplit">
                <div className="left">Description</div>
                <div className="right">{<Paragraph text={record.description}/>}</div>
            </div>
            <div className="itemSplit">
                <div className="left">Amount</div>
                <div className="right">£{record.amount.toFixed(2)}</div>
            </div>
            {record.type == 'Asset' ? <>
                <div className="itemSplit">
                    <div className="left">Book value</div>
                    <div className="right">£{record.bookValue.toFixed(2)}</div>
                </div>
                <div className="itemSplit">
                    <div className="left">Depreciation</div>
                    <div className="right">
                        {record.depreciation}
                    </div>
                </div>
            </> : null}
            <div className="itemSplit">
                <div className="left">Date</div>
                <div className="right">
                    {<DisplayDate date={record.date}/>}
                </div>
            </div>
        </div>
    </div>
}

function Records() {
    let [page, setPage] = useState(0);
    let [pages, setPages] = useState(0);
    let [records, setRecords] = useState([]);
    let [loading, setLoading] = useState(false);
    let [expandRecord, setExpandRecord] = useState(false);
    let [bulkIncome, setBulkIncome] = useState(false);
    let [bulkExpenses, setBulkExpenses] = useState(false);
    let { categories, setCategories } = useContext(CategoriesContext);
    let [years, setYears] = useState([]);
    let [year, setYear] = useState(-1);
    let [months, setMonths] = useState([0,11]);
    let [calendarYear, setCalendarYear] = useState(false);
    let [total, setTotal] = useState(0);
    let [cat, setCat] = useState('No category')

    useEffect(() => {
        (async () => {
            setLoading(true);
            let { data } = await axios.post('/api/yearsonrecord', { p: p() });
            let years = [];
            for (let i = data[0]; i <= data[1]; i++) years.push(i);
            setYears(years);
            setYear(data[1]);
            setLoading(false);
        })()
    },[])

    useEffect(() => {
        if (year >= 0) (async () => {
            setLoading(true);
            let { data } = await axios.post('/api/records', { p: p(), page, year, calendarYear, months, category:cat=='No category'?null:cat });
            setTotal(data.total);
            setPages(data.pages);
            let records = data.records;
            setRecords(records);
            setCategories(data.categories);
            setLoading(false);
        })()
    }, [page, year, months, calendarYear, cat]);
    
    useEffect(() => {
        setMonths([0,11]);
    },[year])

    return <div className="selfAssessmentRecords">
        {bulkIncome ? <Bulk close={()=>setBulkIncome(false)} /> : null}
        {bulkExpenses ? <Bulk close={()=>setBulkExpenses(false)} expenses/> : null}
        {expandRecord ? <ExpandedRecord
            close={() => setExpandRecord(false)}
            record={{
                type: expandRecord,
                category: "Boat fund",
                title: "",
                description: "",
                amount: 0,
                date: new Date().getTime(),
                accounted: false,
                depreciation: 0.18,
                bookValue: 0,
                receipt: null,
            }}
            addRecord={v => {
                records.unshift(v);
                setRecords(records.slice());
            }}
        /> : null}
        <div className="addButtons">
            <A onClick={() => setExpandRecord('Income')}>Add income</A>
            <A onClick={() => setExpandRecord('Expense')}>Add expense</A>
            <A onClick={() => setExpandRecord('Asset')}>Add asset</A>
            <A onClick={() => setBulkIncome(true)}>Add bulk income</A>
            <A onClick={() => setBulkExpenses(true)}>Add bulk expenses</A>
        </div>
        <div className="selfAssessmentOptions">
            <div >
                <Dropdown item={cat} setItem={setCat} items={['No category',...categories]} />
            </div>
            <div className="taxYearCheck">
                <div className="left">
                    Tax year
                </div>
                <div className="right">
                    <Check check={!calendarYear} setCheck={c=>setCalendarYear(!c)}/>
                </div>
            </div>
        </div>
        <Tabs tab={year} setTab={setYear} tabs={years} />
        <Pages page={page} pages={pages} setPage={setPage} />
        {calendarYear?<TabRange key={year} setIndexes={setMonths} tabs={['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']} initial={[0,11]}/>:null}
        {loading ? <div className="i">Loading...</div> : records.length == 0 ? <div className="i">Nothing here...</div> : <div>
            <div className="total">
                Total: {total<0?'-':''}£{Math.abs(total.toFixed(2))}
            </div>
            {records.map((o, i) => <Record
                key={page + ' ' + i + ' ' + o._id}
                record={o}
                deleteMe={() => {
                    records.splice(i, 1);
                    setRecords(records.slice());
                }}
            />)}
        </div>}
    </div>
}

function Record({ record, readOnly, deleteMe }) {
    let [expand, setExpand] = useState(false);
    return <>
        {expand ? <ExpandedRecord record={record} deleteMe={deleteMe} readOnly={readOnly} close={() => setExpand(false)}/> : null}
        <div className="record" onClick={() => { setExpand(true) }}>
            <div className="title">
                <div className="left">
                    <b>{record.title}</b>
                    <div className="smallDate">
                        <DisplayDate date={record.date}/>
                    </div>
                </div>
                <div className="middle">
                    {record.category}
                </div>
                <div className="amount">
                    <b>{record.type != 'Income' ? '-' : '+'}£{record.amount.toFixed(2)}</b>
                </div>
            </div>
        </div>
    </>
}

function BulkRecord({ record, i,  expenses }) {
    let [confirmed, setConfirmed] = useState(false);
    let [loading, setLoading] = useState(false);
    let [found, setFound] = useState(false);
    let [id, setId] = useState();
    let [expanded, setExpanded] = useState(false);
    return <>
        <div className="itemSplit" key={i}>
            <div className="left"><div>Record {i}</div>{confirmed?<div>Confirmed!</div>:loading?<div>...</div>:null}</div>
            <div className="right">
                <div>{record.title}</div>
                <div>{toDateString(record.date)}</div>
                <div>{expenses ? '-' : ''}£{record.amount.toFixed(2)}</div>
                <div className="buts">
                    {confirmed ? <Q callback={async () => {
                        console.log(0,{found})
                        if (found) {
                            setConfirmed(false);
                        } else {
                            setLoading(true);
                            console.log({ ...record, _id: id });
                            let { data } = await axios.post('/api/deleterecord', { p:p(), record:{...record,_id:id} });
                            setConfirmed(false);
                            setLoading(false);
                        }
                    }}>Unconfirm</Q> : loading ? <i>Loading...</i> : <A onClick={async() => {
                        setLoading(true);
                        let { data } = await axios.post('/api/createrecord', { p:p(), record, bulk:true });
                        setFound(data.found);
                        if (data.record) {
                            setId(data.record._id);
                        } else setId();
                        console.log(data)
                        setConfirmed(true);
                        setLoading(false);
                    }}>Confirm</A>}
                    <A onClick={()=>setExpanded(true)}>Show week's records</A>
                </div>
            </div>
        </div>
        {expanded ? <SameDayCheck close={() => setExpanded(false)} record={record} expenses={expenses} />:null}
    </>
}

function SameDayCheck({ close, record, expenses }) {
    let [records, setRecords] = useState();
    useEffect(() => {
        (async () => {
            let from = new Date(new Date(record.date).setHours(0, 0, 0, 0)).getTime();
            let { data } = await axios.post('/api/records', { p: p(), range: { from: from - 7 * 24 * 60 * 60 * 1000, to: from + 1 * 24 * 60 * 60 * 1000, type:expenses?"Expense":"Income", perPage: 9999 } });
            setRecords(data.records);
        })()
    }, [])
    return <div className="expanded SameDayCheck">
        <div className="window">
            <div className="modalHeading">
                <div className="heading">Records for {<DisplayDate date={record.date}/>}</div>
                <button onClick={close}>╳</button>
            </div>
            {records ? records.length > 0 ? <>
                <div className="records">
                    {records.map((r, i) => <Record key={i} record={r} i={i} readOnly />)}
                </div>
            </> : <i>There is nothing...</i> : <i>Loading...</i>}
        </div>
    </div>
}

function Bulk({ close, expenses }) {
    let [csv, setCsv] = useState('');
    let [records, setRecords] = useState();
    return <div className="expanded bulkIncome">
        <div className="window">
            <div className="modalHeading">
                <div className="heading">{records?(expenses?"Add expenses":"Add income"):"Input CSV"}</div>
                <button onClick={close}>╳</button>
            </div>
            {records ? <>
                <div className="records">
                    {records.map((r, i) => <BulkRecord key={i} record={r} i={i} expenses={expenses} />)}
                </div>
            </> : <>
                <div className="itemSplit">
                    <div className="left">CSV</div>
                    <div className="right">
                        <textarea value={csv} onChange={e=>setCsv(e.target.value)} />
                    </div>
                </div>
                <div className="buts">
                    {csv?<A className="but" onClick={async () => {
                        let records = csv.trim().split('\n').map(l => l.trim().split(','));
                            let heading = records.shift();
                            console.log(heading)
                        records = records.map(r => {
                            let record = {type:(expenses?"Expense":"Income"),category:"Boat fund","description":"","accounted":false};
                            heading.forEach((h,i) => {
                                if (h == 'Transaction description') {
                                    record.title = r[i];
                                } else if (h == (expenses?'Debit Amount':'Credit amount')) {
                                    record.amount = parseFloat(r[i]);
                                } else if (h == 'Transaction date') {
                                    record.date = new Date(r[i]).getTime();
                                }
                            })
                            if (!record.title || !record.amount || !record.date) record = null;
                            return record;
                        }).filter(l => l);
                        setRecords(records);
                    }}>Parse</A>:null}
                </div>
            </>}
        </div>
    </div>
}

function ExpandedRecord({ record, close, deleteMe, addRecord, readOnly }) {
    let [type, setType] = useState(record.type);
    let [category, setCategory] = useState(record.category);
    let [title, setTitle] = useState(record.title);
    let [description, setDescription] = useState(record.description);
    let [amount, setAmount] = useState(record.amount);
    let [date, setDate] = useState(record.date);
    let [accounted, setAccounted] = useState(record.accounted);
    let [depreciation, setDepreciation] = useState(record.depreciation || 0.18);
    let [bookValue, setBookValue] = useState(record.bookValue || record.amount);
    let [receipt, setReceipt] = useState(record.receipt);
    let [stickyBookValue, setStickyBookValue] = useState(false);

    let { categories, addCategory } = useContext(CategoriesContext);

    let [uneditable] = useState(readOnly || ((record.accounted || (type == "Asset" && bookValue != amount)) && !addRecord));

    useEffect(() => {
        if (bookValue == amount) setStickyBookValue(true);
    }, [])
    
    useEffect(() => {
        if (stickyBookValue) setBookValue(amount);
    },[amount])

    return <div className="expanded expandedRecord">
        <div className="window">
            <div className="modalHeading">
                <div className="heading">Record</div>
                <button onClick={close}>╳</button>
            </div>
            <div className="itemSplit">
                <div className="left">Type</div>
                <div className="right">
                    {uneditable ? type : <Dropdown item={type} setItem={setType} items={['Income', 'Expense', 'Asset']} />}
                </div>
            </div>
            <div className="itemSplit">
                <div className="left">Category</div>
                <div className="right">
                    {uneditable ? category : <Dropdown item={category} setItem={setCategory} items={categories} addItem={addCategory}/>}
                </div>
            </div>
            <div className="itemSplit">
                <div className="left">Title</div>
                <div className="right">
                    {uneditable ? title : <input
                        type="text"
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                    />}
                </div>
            </div>

            <div className="itemSplit">
                <div className="left">Description</div>
                <div className="right">
                    {uneditable ? <Paragraph text={description}/>:<textarea value={description} onChange={e=>setDescription(e.target.value)} />}
                </div>
            </div>

            <div className="itemSplit">
                <div className="left">Amount</div>
                <div className="right">
                    £{uneditable ? amount.toFixed(2) : <input
                        className="lil"
                        type="number" min={0} step="any"
                        value={amount}
                        onChange={e => setAmount(parseFloat(e.target.value))}
                    />}
                </div>
            </div>

            {type == 'Asset' ? <>
                {!stickyBookValue ? <div className="itemSplit">
                    <div className="left">Book value</div>
                    <div className="right">£{bookValue.toFixed(2)}</div>
                </div> : null}
                <div className="itemSplit">
                    <div className="left">Depreciation</div>
                    <div className="right">
                        {uneditable ? depreciation : <input
                            className="lil"
                            type="number" min={0} max={1} step={0.1}
                            value={depreciation}
                            onChange={e => setDepreciation(parseFloat(e.target.value))}
                        />}
                    </div>
                </div>
            </> : null}


            <div className="itemSplit">
                <div className="left">Date</div>
                <div className="right">
                    {uneditable ? <DisplayDate date={date}/> : <DateSelect date={date} setDate={setDate}/>}
                </div>
            </div>


            <div className="buts">
                {!uneditable?<Q
                    className="but"
                    disabled={!title || !category || amount <= 0}
                    callback={async () => {
                        record.type = type;
                        record.category = category;
                        record.title = title;
                        record.description = description;
                        record.amount = amount;
                        record.date = date;
                        if (type == 'Asset') {
                            record.depreciation = depreciation;
                            record.bookValue = bookValue;
                            record.accountedYears = []
                        } else {
                            delete record.depreciation;
                            delete record.bookValue;
                        }
                        if (addRecord) {
                            let { data } = await axios.post('/api/createrecord', { p:p(), record });
                            addRecord(data);
                        } else {
                            let { data } = await axios.post('/api/updaterecord', { p:p(), record });
                        }
                        close();
                    }}
                >Save</Q> : null}
                {deleteMe && !uneditable?<Q className="but" callback={async () => {
                    deleteMe();
                    axios.post('/api/deleterecord', { p:p(), record });
                    close();
                }}>Delete</Q>:null}
            </div>
        </div>
    </div>
}
