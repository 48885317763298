import React, { useState, useEffect, useContext, useRef } from 'react';
import AdminTabs from './AdminTabs';
import { useMount, useClickOutside, p, AdminContext } from '../../hooks';
import Tabs from './Tabs';
import Pages from './Pages';
import PieGraph from './PieGraph';
import BarGraph from './BarGraph';
import A from './A';
import Q from './Q';
import axios from 'axios';
import countries from './countries';

function getDate(stamp) {
    let d = new Date(stamp);
    let dd = d.getDate();
    if (dd < 10) dd = '0' + dd;
    let mm = d.getMonth() + 1;
    if (mm < 10) mm = '0' + mm;
    let yyyy = d.getFullYear();
    return dd + '/' + mm + '/' + yyyy;
}

export default function Reddit() {
    let { componentDidMount } = useMount();
    let [tab, setTab] = useState('Users');

    let getBg = useRef();
    useEffect(() => {
        setBackgroundColour("Admin");
        return () => setBackgroundColour(false);
    }, [])

    let { admin, setBackgroundColour } = useContext(AdminContext);
    if (!admin) return null;

    return <div className="Reddit" ref={getBg}>
        <AdminTabs tab="Reddit" />
        <h1>Reddit</h1>
            <Tabs tab={tab} setTab={setTab} tabs={['Users', 'Graph', 'Campaigns', 'Calendar']} />
        {tab == 'Users' ? <RedditUsers /> :
            tab == 'Graph' ? <RedditGraph /> :
                tab == 'Calendar' ? <RedditCalendar /> :
                    tab == 'Campaigns' ? <RedditCampaigns /> :
                        null}
    </div>
}

function RedditCampaigns({ }) {
    let { componentDidMount } = useMount();
    let [campaigns, setCampaigns] = useState([]);
    let [expand, setExpand] = useState(false);
    let [expand2, setExpand2] = useState(-1);
    componentDidMount(async () => {
        let { data } = await axios.post('/api/r/campaigns', { p:p() });
        console.log(data.campaigns);
        setCampaigns(data.campaigns);
    })
    return <div className="RedditCampaigns">
        {expand ? <ExpandCampaign
            close={() => setExpand(false)}
            callback={c => {
                campaigns.unshift(c);
                setCampaigns(campaigns.slice());
            }}
        /> : null}
        {expand2 >= 0 ? <ExpandCampaign2
            close={() => setExpand2(-1)}
            campaign={campaigns[expand2]}
            onDelete={c => {
                campaigns.splice(expand2,1);
                setCampaigns(campaigns.slice());
            }}
        /> : null}
        <A onClick={()=>setExpand(true)}>New campaign</A>
        {campaigns.map((c, i) => <div onClick={()=>setExpand2(i)} key={i} className="campaign">
            {JSON.stringify(c)}
        </div>)}
    </div>
}

function ExpandCampaign({ close, callback }) {
    let [id, setId] = useState('');
    return <div className="expanded">
        <div className="window">
            <div className="modalHeading">
                <div className="heading">Campaign</div>
                <button onClick={close}>╳</button>
            </div>
            <h2>Input the post ID</h2>
            <input
                type="text"
                value={id}
                onChange={e => setId(e.target.value)}
            />
            <div className="buts">
                <A className="but" onClick={async () => {
                    let { data } = await axios.post('/api/r/newcampaign', { p:p(), id });
                    callback(data);
                    close();
                }}>Initiate</A>
            </div>
        </div>
    </div>
}

function ExpandCampaign2({ campaign, close, onDelete }) {
    return <div className="expanded">
        <div className="window">
            <div className="modalHeading">
                <div className="heading">Campaign</div>
                <button onClick={close}>╳</button>
            </div>
            <div className="buts">
                <Q className="but" callback={async () => {
                    await axios.post('/api/r/deletecampaign', { p:p(), post:campaign.post });
                    onDelete();
                    close();
                }}>Delete</Q>
            </div>
        </div>
    </div>
}

function RedditCalendar({ }) {
    let { componentDidMount } = useMount();
    let [calendar, setCalendar] = useState();
    let [max, setMax] = useState(0);
    componentDidMount(async () => {
        let { data } = await axios.post('/api/r/calendar', { p:p() });
        setCalendar(data.calendar);
        let max = 0;
        data.calendar.forEach(d => d.forEach(h => max = Math.max(max, h)));
        setMax(max);
        console.log({ max });
    })
    let days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    let hours = ['00', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
    return <div className="RedditCalendar">
        <div className="week topbit">
            <div className="dayTitle" > </div>
            <div className="hour">a.m.</div>
            <div className="hour">p.m.</div>
        </div>
        <div className="week topbit">
            <div className="dayTitle" > </div>
            {hours.map(h => <div className="hour" key={h}>{h}</div>)}
        </div>
        {calendar ? calendar.map((day, i) => <div className="week">
            <div className="dayTitle">{days[i]}</div>
            {day.map((hour, i) => <div
                key={i}
                className="hour"
                style={{
                    backgroundColor:`rgb(${(1-(hour/max)*(hour/max))*255},255,${(1-(hour/max)*(hour/max))*255})`
                }}
            >{hour}</div>)}
        </div>):null}
    </div>
}

function RedditGraph({ }) {
    let [tab, setTab] = useState('Total users')

    return <div className="RedditGraph">
        <Tabs tab={tab} setTab={setTab} tabs={['Total users', 'New users', 'Log ins', 'Countries']} />
        {tab == 'Countries' ? <CountriesGraph /> :
            tab == 'Log ins' ? <RedditBarGraph key={"logins"} url="logins"/> :
                tab == 'New users' ? <RedditBarGraph key={"newusers"} url="newusers"/> :
                    tab == 'Total users' ? <RedditBarGraph key={"totalusers"} url="totalusers"/> :
                        null}
    </div>
}

function RedditBarGraph({ url }) {
    let [time, setTime] = useState('1 month');
    let [data, setData] = useState();

    useEffect(() => {
        (async () => {
            let days = time == '1 month' ? 30 : time == '1 year' ? 365 : 0;
            let { data } = await axios.post('/api/r/'+url, { p:p(), days });
            setData(data.items.map(d => {
                return {
                    title: getDate(d.stamp),
                    value: d.count
                }
            }))
        })()
    },[time])

    return <>
        <Tabs tab={time} setTab={setTime} tabs={['1 month', '1 year']} />
        {data ? <BarGraph data={data} width={600} height={400} /> : null}
    </>
}

function CountriesGraph() {
    let { componentDidMount } = useMount();
    let [data, setData] = useState();
    componentDidMount(async () => {
        let { data } = await axios.post('/api/r/countries', { p:p() });
        console.log(data);
        data = data.countries.map(d => {
            return {
                title: countries[d.country] ? countries[d.country] : d.country,
                value: d.count
            }
        })
        setData(data);
    })
    if (!data) return null;
    return <PieGraph diameter={300} values={data} />
}

function RedditUsers({ }) {
    let [page, setPage] = useState(0);
    let [pages, setPages] = useState(0);
    let [count, setCount] = useState(0);
    let [users, setUsers] = useState();
    let [expand, setExpand] = useState(false);

    useEffect(() => {
        (async () => {
            let { data } = await axios.post('/api/r/users', { p:p(), page });
            let { pages, users, count } = data;
            setUsers(users);
            setPages(pages);
            setCount(count);
        })()
    }, [page])

    return <div className="RedditUsers">
        {users ? <>
            {expand ? <ExpandUser user={expand} close={()=>setExpand(false)} /> : null}
            <h1>{count} Users:</h1>
            <Pages page={page} setPage={setPage} pages={pages} />
            {users.map((u,i) => <div key={i} className="user">
                <h2><a target="_blank" href={"https://www.reddit.com/user/" + u.username}>{u.username}</a></h2>
                <div className="clickMe" onClick={()=>setExpand(u)}>
                    <div className="split">
                        <div className="left">Country:</div>
                        <div className="right">{countries[u.country] ? countries[u.country] : u.country}</div>
                    </div>
                    <div className="split">
                        <div className="left">Rank:</div>
                        <div className="right">{u.rank}</div>
                    </div>
                    <div className="split">
                        <div className="left">Created:</div>
                        <div className="right">{getDate(u.stamp)}</div>
                    </div>
                </div>
            </div>)}
        </>:<div className="i">Loading...</div>}
    </div>
}

function ExpandUser({ user, close }) {
    let [ref, onClickOutside] = useClickOutside();
    onClickOutside(close);
    console.log(user);
    let subrank = Object.keys(user.subrank).map((k,i)=>k+': '+user.subrank[k])
    return <div className="expanded">
        <div className="window" ref={ref}>
            <div className="user">
                <h2><a target="_blank" href={"https://www.reddit.com/user/" + user.username}>{user.username}</a></h2>
                <div className="split">
                    <div className="left">Country:</div>
                    <div className="right">{countries[user.country] ? countries[user.country] : user.country}</div>
                </div>
                <div className="split">
                    <div className="left">IP:</div>
                    <div className="right">{user.ip}</div>
                </div>
                <div className="split">
                    <div className="left">Rank:</div>
                    <div className="right">{user.rank}</div>
                </div>
                <div className="split">
                    <div className="left">Created:</div>
                    <div className="right">{getDate(user.stamp)}</div>
                </div>
                {subrank.length > 0 ? <div className="split">
                    <div className="left">Subrank:</div>
                    <div className="right">{subrank.map(s => <div>{s}</div>)}</div>
                </div> : null}
            </div>
        </div>
    </div>
}