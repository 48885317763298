import React, { useState, useRef, useEffect } from 'react';
import { useClickOutside } from '../../hooks';

import A from './A';

export default function I({ callback, children, className, pre='', post='', onClick, onChange }) {
    let [str, setStr] = useState('');
    let [clicked, setClicked] = useState(false);
    let [ref, onClickOutside] = useClickOutside();
    let input = useRef();

    onClickOutside(() => setClicked(false));

    useEffect(() => {
        if (clicked) {
            setStr('');
            input.current.focus();
        }
    },[clicked])

    return <div ref={ref} className={className}>
        {!clicked ? <>{pre}<A onClick={() => {
            setClicked(true);
            if (onClick) onClick();
        }}>{children}</A>{post}</> : <input
            className="mr"
            ref={input}
            type="text"
            value={str}
                onChange={e => {
                    setStr(e.target.value);
                    if (onChange) onChange(e.target.value);
                }}
            onKeyDown={e => {
                if (e.key == "Enter") {
                    e.preventDefault();
                    callback(str);
                    setClicked(false);
                }
            }}
        ></input>}
    </div>
}