import React, { Component } from 'react';

class AreYouSure extends Component {
    constructor(props) {
        super(props);
        let { title } = props;
        this.state = {
            title, clicked: false
        }
        this.submit = this.submit.bind(this);
    }

    submit() {
        this.props.callback();
        this.setState({ clicked: false });
    }

    render() {
        let { clicked, title } = this.state;
        return !clicked?<div className='inline'>
            <a onClick={()=>this.setState({clicked:true})}>{title}</a>
        </div> : <div className='inline'>
            <div className = 'mr'>
                Are you sure?
            </div>
            <div className = 'mr'>
                <a onClick={this.submit}>Yes</a>
            </div>
            <div className = 'mr'>
                <a onClick={() => this.setState({ clicked: false })}>No</a>
            </div>
        </div>
    }
}

export default AreYouSure;