import React, { Component } from 'react';

export default class Checkbox extends Component {
    constructor(props) {
        super(props);
        let { initial, label } = props;
        let checked = initial;
        this.state = {
            checked, label
        }
        this.click = this.click.bind(this);
    }

    click() {
        let { checked } = this.state;
        checked = !checked;
        this.setState({ checked: checked })
        this.props.callback(checked);
    }

    render() {
        let { label, checked } = this.state;
        return <div className="checkbox" onClick={this.click}>
            <label>{label}</label>
            <div className="inline"><div className="btn">{checked?'✓':''}</div></div>
        </div>
    }
}