import React, { Component, Fragment } from 'react';
import Banner from './Banner';

import axios from 'axios';
import TitleOptions from '../admin/TitleOptions';

let lerp = (v1, v2, p) => v1 * (1 - p) + v2 * p;

let g = 0.0005;

let DIV = Fragment;

let ex = 30;

let k = 0;

function Coin(x) {
    this.k = k++;
    if (k > 10000) k = 0;
    this.x = x?x+(Math.random()*0.1-0.05):(Math.random()*0.9)+0.05;
    this.y = -(20/500);
    this.bounced = false;
    this.a = 0;
    this.animate = () => {
        this.a += g;
        this.y += this.a;
        if (!this.bounced) {
            if (this.y > 0.8) {
                this.bounced = true;
                this.a = 0 - this.a*0.75;
            }
        }
    }
}

function Bomb(x) {
    this.k = k++;
    this.x = x?x:(Math.random()*0.9)+0.05;
    this.y = -(20/500);
    this.bounced = false;
    this.a = 0;
    this.animate = () => {
        this.a += g;
        this.y += this.a;
        if (!this.bounced) {
            if (this.y > 0.8) {
                this.bounced = true;
                this.a = 0 - this.a*0.75;
            }
        }
    }
}

export default class Game extends Component{
    constructor(props) {
        super(props);
        let onMobile = false;
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) onMobile = true;
        this.state = {
            hovered: true,
            x: 0.5, y: 0.5, w: 500, gx: 0, gy: 0, px: 0.5, pw: 70, cw: 20,
            duck: false, c: 0, cc: 0, coins: [], bombs: [],
            explosion: 0, score: 0, menu: true, lost: false, loading: false, onMobile, tilt:0
        }
        this.mouseMove = this.mouseMove.bind(this);
        this.resize = this.resize.bind(this);
        this.draw = this.draw.bind(this);
        this.reset = this.reset.bind(this);
        this.getDiscount = this.getDiscount.bind(this);
        this.orient = this.orient.bind(this);
        setInterval(this.draw, 1000 / 60);
    }

    componentDidMount() {
        this.resize();
        window.addEventListener('resize', this.resize, true);
        window.addEventListener("deviceorientation", this.orient, true);
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }

    orient(e) {
        let { onMobile } = this.state;
        if (onMobile) {
            let {gamma} = e;
            if (gamma < -90) gamma = -90;
            if (gamma > 90) gamma = 90;
            gamma = gamma / 1200;
            this.state.tilt= gamma;
        }
    }

    async getDiscount() {
        this.setState({ loading: true });
        let { score } = this.state;
        let { data } = await axios.post('/api/highscore/', { score });
        let { code } = data;
        this.setState({ loading: false, code });
    }

    resize() {
        let g = document.getElementById('game').getBoundingClientRect();
        let w = g.width;
        let gx = g.x;
        let gy = g.y;
        let pw = (w / 500) * 70;
        let cw = (w / 500) * 20;
        this.setState({ w, gx, gy, pw, cw });
    }

    mouseMove(e) {
        let { w, gx, gy } = this.state;
        let x = (e.clientX - gx) / w;
        let y = (e.clientY - gy) / w;
        this.setState({ x, y });
    }

    reset() {
        return new Promise(resolve => {
            this.setState({ c: 0, cc: 0, coins: [], bombs: [] }, resolve);
        })
    }

    async draw() {
        let { x, px, duck, c, cc, coins, score, bombs, explosion, menu, lost, onMobile, tilt } = this.state;
        if (!menu && !lost) {
            c++;
            cc++
            let speed = Math.floor(100 - score * 2);
            if (speed < 25) speed = 25;
            if (score >= 80) speed = onMobile?20: 14;
            if (c > speed) c = 0;
            if (explosion == 0) {
                if (onMobile) {
                    px = px + tilt;
                } else px = lerp(px, x, 0.08);
                if (px > 1) px = 1;
                if (px < 0) px = 0;
                for (let i = coins.length - 1; i >= 0; i--){
                    coins[i].animate();
                    if (coins[i].y > 1) {
                        coins.splice(i, 1);
                    } else if (
                        coins[i].a > 0 &&
                        coins[i].y > 0.715 &&
                        coins[i].y < 0.763 &&
                        coins[i].x > px - 0.034 &&
                        coins[i].x < px + 0.034
                    ) {
                        score++;
                        coins.splice(i, 1);
                    }
                }
                for (let i = bombs.length - 1; i >= 0; i--){
                    bombs[i].animate();
                    if (bombs[i].y > 1) {
                        bombs.splice(i, 1);
                    } else if (
                        bombs[i].y > 0.6 &&
                        bombs[i].y < 0.815 &&
                        bombs[i].x > px - 0.05 &&
                        bombs[i].x < px + 0.05
                    ) {
                        await this.reset();
                        explosion = ex;
                        this.setState({explosion, lost:true})
                    }
                }
                if (cc % 15 == 0) {
                    duck = !duck;
                }
                if (c == 0) {
                    if (score > 50 && bombs.length < (score>85?onMobile?3:5:onMobile?1:2)) {
                        if (Math.random() < 0.15) {
                            let coin = new Coin();
                            coins.push(coin);
                        } else {
                            let bomb = new Bomb(Math.random()<0.25?onMobile?px:x:null);
                            bombs.push(bomb);
                        }
                    } else {
                        if (score<4||Math.random() < 0.75) {
                            let coin = new Coin();
                            coins.push(coin);
                        } else {
                            let bomb = new Bomb(score>90?Math.random()<0.5?onMobile?px:x:null:null);
                            bombs.push(bomb);
                        }
                    }
                }
                if (explosion == 0) this.setState({ px, c, duck, coins, score, bombs, explosion, cc });
    
            } 
        }
        if (explosion > 0) {
            explosion--;
            this.setState({ explosion });
        }
    }

    render() {
        let { hovered, x, y, w, px, pw, duck, c, coins, cw, bombs, explosion, score, menu, lost, loading, code, onMobile } = this.state;
        let pStyle1 = {
            left: (px * w) - pw / 2,
            width: pw,
            bottom: w / 6
        }
        let pStyle2 = { left: pStyle1.left, width: pStyle1.width, bottom: pStyle1.bottom + (duck ? w * 0.005 : 0) }
        return <div className="Store">
            <Banner />
            <div className="gameContent">
                <div className="heading"><h1>Fun game</h1></div>
                <div
                    className="game"
                    id="game"
                    onMouseEnter={() => this.setState({ hovered: true })}
                    onMouseLeave={() => this.setState({ hovered: false })}
                    onMouseMove={this.mouseMove}
                    style={{
                        cursor: (menu || lost) && explosion == 0 ? 'default' : null
                    }}
                >
                    {explosion == 0 ?
                        menu ? <DIV>
                            <div className="centerFlex lots" >
                                <h3>Coins fall from the sky!</h3>
                                <h3>Help Kai catch £100 to buy his boat.</h3>
                                <h3>If you win, you will receive a 10% discount off everything in the shop!</h3>
                                <h3>Bombs also fall from the sky.</h3>
                                <button className="green" onClick={() => this.setState({ menu: false })}>Start!</button>
                            </div>
                        </DIV>
                        : lost ? <DIV>
                            {loading?
                                <div className="centerFlex" ><div className="spinner"></div></div>:
                            code?<DIV><div className="centerFlex">
                                <h3>Here is your 10% discount code:</h3>
                                <h2>{code}</h2>
                                <h3>Copy and paste this code into the box when making an order!</h3>
                                <button className="green" onClick={()=>this.setState({code:null,lost:false, score:0})}>Play again</button></div>
                            </DIV>:<DIV><div className="centerFlex">
                                <h3>You made £{score}!</h3>
                                {score<100?<DIV>
                                    <h3>Unfortunately, Kai needs £100 for his boat.</h3>
                                    <h3>You'll need to do better if you want that 10% discount!</h3>
                                    <button className="green" onClick={() => this.setState({ lost: false, score:0 })}>Try again</button>
                                </DIV>:<DIV>
                                    <h3>Kai can now buy the boat of his dreams!</h3>
                                    <h3>Click the button to get your 10% discount.</h3>
                                    <button className="green" onClick={this.getDiscount}>Get discount</button>
                                </DIV>}
                            </div></DIV>}
                        </DIV>
                        : <DIV>
                            <div className="floor" style={{ height: w * 0.2 }}></div>

                            <img style={pStyle2} src={require('../../sprites/body.svg') } alt="."/>
                            <img style={pStyle1} src={require('../../sprites/legs.svg')} alt="." />
                            
                            {coins.map(coin => <img key={coin.k} style={{
                                left: (coin.x * w) - cw / 2,
                                width: cw,
                                top: (coin.y * w) - cw / 2
                            }} src={require('../../sprites/coin.svg')} alt="." />)}

                            {bombs.map(bomb => <img key={bomb.k} style={{
                                left: (bomb.x * w) - cw / 2,
                                width: cw,
                                bottom: ((1-bomb.y) * w) - cw / 2
                            }} src={require('../../sprites/bomb.svg')} alt="." />)}

                            <img style={pStyle2} src={require('../../sprites/hat.svg')} alt="." />
                            
                            <img
                                style={{ left: x * w - 6.5, top: y * w - 6.5, width: 13, display: hovered ? onMobile ? 'none' : null : 'none' }}
                                src={require('../../sprites/cursor.svg')} alt="."
                            />
                            <div className="score" style={{
                                left:'1rem',
                                top:'1rem'
                            }}><h2>Bank balance: £{score}</h2></div>
                            <div className="centerFlex opacity" style={{opacity:score==0?1:0}}><h3>{onMobile?'Tilt your device to move the character.':'Move your mouse to move the character.'}</h3></div>
                        </DIV>
                    : null}
                    <DIV>
                        <img style={{
                            width: (w * 2) * (explosion / ex),
                            transform: 'rotate(' + explosion*30 + 'deg)',
                            left: (w-(w * 2) * (explosion / ex))/2,
                            top: (w-(w * 2) * (explosion / ex))/2,
                            visibility:explosion>0?null:'hidden'
                        }} src={require('../../sprites/bang.svg')} alt="." />

                        <img style={{
                            left: ((px * w) - pw / 2)+(ex-explosion)*10,
                            width: pw,
                            bottom: (w / 6) + (ex-explosion) * 10,
                            visibility: explosion > 0 ? null : 'hidden',
                            transform: 'rotate(' + explosion*20 + 'deg)',
                        }} src={require('../../sprites/body.svg')} alt="." />
                        
                        <img style={{
                            left: ((px * w) - pw / 2),
                            width: pw,
                            bottom: (w / 6) - (ex-explosion) * 10,
                            visibility: explosion > 0 ? null : 'hidden',
                            transform: 'rotate(-' + explosion*20 + 'deg)',
                        }} src={require('../../sprites/legs.svg')} alt="." />

                        <img style={{
                            left: ((px * w) - pw / 2)-(ex-explosion)*10,
                            width: pw,
                            bottom: (w / 6) + (ex - explosion) * 10,
                            transform: 'rotate(' + explosion*30 + 'deg)',
                            visibility:explosion>0?null:'hidden'
                        }} src={require('../../sprites/hat.svg')} alt="." />
                    </DIV>
                </div>
            </div>
        </div>
    }
}