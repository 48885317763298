import React, { useState } from 'react';
import axios from 'axios';
import { NavLink, useParams } from "react-router-dom";
import { StripeProvider, Elements, injectStripe } from 'react-stripe-elements';
import CheckoutForm from './CheckoutForm';

import Banner from './Banner';
import Paragraph from '../Paragraph';
import { useMount } from '../../hooks';

import { jsPDF } from "jspdf";


export default function Invoice({ }) {
    let params = useParams();
    let id = params.id;
    let { componentDidMount } = useMount();
    let [invoice, setInvoice] = useState();
    let [deets, setDeets] = useState();
    let [date, setDate] = useState();
    let [paid, setPaid] = useState();
    let [progress, setProgress] = useState(0);

    let [test, setTest] = useState(false);

    componentDidMount(async () => {        
        let { data } = await axios.post('/api/invoice/', { id });
        let { invoice, deets } = data;
        if (!invoice) return;
        let d = new Date(invoice.date);
        let dd = d.getDate();
        if (dd < 10) dd = '0' + dd;
        let mm = d.getMonth() + 1;
        if (mm < 10) mm = '0' + mm;
        let yy = d.getFullYear();
        let date = dd + '/' + mm + '/' + yy;
        setDate(date);
        setDeets(deets);
        setInvoice(invoice);

        if (invoice.paid) {
            d = new Date(invoice.paid);
            dd = d.getDate();
            if (dd < 10) dd = '0' + dd;
            mm = d.getMonth() + 1;
            if (mm < 10) mm = '0' + mm;
            yy = d.getFullYear();
            date = dd + '/' + mm + '/' + yy;
            setPaid(date);
        }
    })

    function downloadInvoice() {
        const doc = new jsPDF("p", "mm", "a4");
        doc.setFont('Courier', 'Bold');

        doc.setFontSize("14");

        let w = doc.internal.pageSize.width;
        let h = doc.internal.pageSize.height;
        let m = 25;

        let y = m;
        let lineHeight = 7;

        function enter(lh=lineHeight) {
            y += lh;
            if (y > h-m) {
                doc.addPage();
                y = m;
            }
        }
        function unenter() {
            y -= lineHeight;
        }

        function text(string, x=m) {
            doc.text(x, y, string);
            enter();
        }

        function paragraph(string,x=m) {
            let split = doc.splitTextToSize(string, w - m - x);
            split.forEach(s => text(s,x));
        }

        function leftRight(left, right, leftWidth = 50, bolden) {
            left = doc.splitTextToSize(left, leftWidth);
            right = doc.splitTextToSize(right, w - m - m - leftWidth);
            let maxHeight = Math.max(left.length, right.length);
            let newY = y + (maxHeight - 1) * lineHeight;
            if (newY > h - m) {
                doc.addPage();
                y = m;
            }
            for (let i = 0; i < maxHeight; i++) {
                if (bolden) doc.setFont('Courier', 'Bold');
                if (i < left.length) doc.text(left[i], m, y);
                if (bolden) doc.setFont('Courier', '');
                if (i < right.length) doc.text(right[i], m+leftWidth, y);
                y += lineHeight;
            }
            // y += lineHeight / 2;
            if (y > h - m) {
                doc.addPage();
                y = m;
            }
        }

        function line() {
            let doit = true;
            let lw = 1;
            for (let i = m; i < w - m; i += lw){
                if (doit) doc.line(i, y -1.5, i+lw, y -1.5);
                doit = !doit;
            }
            enter();
        }

        doc.setFont('Courier', 'Bold');
        text(`Kai Hammond`);
        doc.setFont('Courier', '');
        text(`${deets.email}`);
        if (deets.phone) text(`${deets.phone}`);
        let yy = y;
        y = m;
        if (deets.address) paragraph(`${deets.address}`, w / 2);
        y = Math.max(y, yy);
        enter(lineHeight / 2);

        text(`Sort code:  ${deets.sortCode}`)
        text(`Account no: ${deets.accountNumber}`)

        line();


        doc.setFont('Courier', 'Bold');
        text(`Invoice for ${invoice.client.name}`);
        doc.setFont('Courier', '');

        if (invoice.client.email) {
            text(invoice.client.email);
            if (invoice.client.address) paragraph(invoice.client.address);
            enter(lineHeight / 2);
        }

        text(`Date issued: ${date}`);
        if (paid) text(`Date paid:   ${paid}`);
        text(`Invoice id:  ${invoice._id}`);

        enter(lineHeight);

        invoice.description.split('\n').forEach(l => paragraph(l));

        enter(lineHeight);
        doc.setFont('Courier', 'Bold');
        text('Hours')
        doc.setFont('Courier', '');
        invoice.hours.map(({ title, charge }, i) => {
            leftRight(title,`£${charge.toFixed(2)}`)
        })
        enter(lineHeight / 2);
        doc.setFont('Courier', 'Bold');
        leftRight('Total',`£${invoice.hours.reduce((a,b)=>a+b.charge,0).toFixed(2)}`)
        line();
        enter();
        doc.setFont('Times', 'italic');
        text('Thank you very much!')

        doc.save(`Invoice for ${invoice.client.name}.pdf`);
    }

    return <div className="Store">
        <Banner />
        <div className="content">
        <h1>Invoice</h1>
            {invoice ? <div className='invoice'>
                <div className="box left">
                <h2>Invoice for {invoice.client.name}</h2>
                    {invoice.description ? <><Paragraph text={invoice.description} noGap /><br /></> : null}
                    <h4>Date</h4>
                    <p>{date}</p>
                    {paid?<><h4>Paid</h4>
                    <p>{paid}</p></>:null}
                    <h4>Hours</h4>
                    {invoice.hours.map(({ title, charge }, i) => <div key={i} className="hour">
                        <div className="title">{title}</div>
                        <div className="charge">£{charge.toFixed(2)}</div>
                    </div>)}
                    <br/>
                    <div className="hour total">
                        <div className="title">Total</div>
                        <div className="charge">£{invoice.hours.reduce((a,b)=>a+b.charge,0).toFixed(2)}</div>
                    </div>
                </div>
                <div className="box right">
                    {progress == 0 ? <>
                        <h2>{deets.name}</h2>

                        {deets.address ? <>
                            <h4>Address</h4>
                            <Paragraph text={deets.address} noGap/>
                        </>:null}

                        <br />
                        <div className={deets.address?"sploot":''}>
                            <div className="splootl"><h4>Email</h4></div>
                            <div className="splootr">{deets.email}</div>
                        </div>
                        {deets.phone ? <>
                            <div className="sploot">
                                <div className="splootl"><h4>Phone</h4></div>
                                <div className="splootr">{deets.phone}</div>
                            </div>
                        </>:null}
                        
                        <br />
                        <div className={deets.address?"sploot":''}>
                            <div className="splootl"><h4>Sort code</h4></div>
                            <div className="splootr">{deets.sortCode}</div>
                        </div>
                        {!deets.address ? <br /> :null}
                        <div className={deets.address?"sploot":''}>
                            <div className="splootl"><h4>Account no.</h4></div>
                            <div className="splootr">{deets.accountNumber}</div>
                        </div>
                        <br/>
                        {!paid ? <>
                            <button onClick={() => setProgress(p => p + 1)} className="green">Pay invoice</button>
                        </> : null}
                        <button onClick={downloadInvoice}>Download invoice</button>
                        
                    </> :
                    progress==-1 ? <>
                        <h2>Alternative payment</h2>
                        <p>Hello! I can also accept payment through paypal, or a bank transfer.</p>
                        <p>If you choose to pay via these methods, please let me know when you have made the payment.</p>
                        <p>My details are below.</p>
                        <p>Thank you!</p>
                        <br/>
                        
                        <h4>Paypal email</h4>
                        <p>My paypal email address is: <b>kaimonington@gmail.com</b></p>
                        <br/>
                        <h4>Bank sort code</h4>
                        <p>{deets.sortCode}</p>
                        <h4>Bank account number</h4>
                        <p>{deets.accountNumber}</p>
                        <br/>
                        
                        <button onClick={()=>setProgress(0)}>Back</button>
                    </>:
                    progress==1 ? <>
                        <h2>Payment</h2>
                        <StripeProvider apiKey={"pk_live_Oa2tnqKCRP2tGfw9qOLZ9Woh00QuAwHqaz"}>
                            <Elements>
                                <CheckoutForm 
                                    back={()=>setProgress(p=>p-1)}
                                    invoiceId={id}
                                    charge={invoice.hours.reduce((a,b)=>a+b.charge,0)}
                                />
                            </Elements>
                        </StripeProvider>
                    </>:
                    
                    null}

                </div>
                
            </div> : <div className="center" style={{width:'100%'}}><div className="spinner"></div></div>}
        </div>
    </div>
}