import React, { useState, useReducer } from 'react';
import A from './A';
import Q from './Q';
import I from './I';

export default function SwapTabs({ tab, tabs, setTab, onSwap, onDelete, onAdd, onRename }) {
    tabs = tabs.sort((a, b) => a.order - b.order);
    let [force, forceRender] = useReducer(c => c + 1, 0);

    function swap(i, j) {
        let tmp = tabs[i].order;
        tabs[i].order = tabs[j].order;
        tabs[j].order = tmp;
        forceRender();
        onSwap(i, j);
    }

    return <div className="SwapTabs">
        {tabs.map(({ title }, i) => <div key={title} className="tab">
            {i>0?<A className="leftArrow" onClick={()=>swap(i, i-1)}>◀</A>:null}
            <A className={tab == i ? 'selected' : null} onClick={() => setTab(i)}>{title}</A>
            {i<tabs.length-1?<A className="rightArrow" onClick={()=>swap(i, i+1)}>▶</A>:null}
        </div>)}
        {onAdd?<I className="tab hide" callback={onAdd}>Add</I>:null}
        {onRename?<I className="tab hide" callback={onRename}>Rename</I>:null}
        {onDelete?<Q className="tab hide" callback={onDelete}>Delete</Q>:null}
    </div>
}