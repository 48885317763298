import React, { useState } from 'react';
import A from './A';

let n = 10;

export default function Pages({ pages, page, setPage }) {
    if (pages <= 0) return <div></div>
    let min = page - n;
    let max = page + n;
    if (min < 0) min = 0;
    if (max > pages) max = pages;
    let array = [];
    for (let i = min; i <= max; i++) array.push(i);
    return <div className="Tabs Pages">
        {array.map(t => <A key={t} className={page == t ? 'selected tab' : 'tab'} onClick={()=>setPage(t)}>{t + 1}</A>)}
    </div>
}