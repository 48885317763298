import React, { Component } from 'react';
import axios from 'axios';
import { NavLink, useParams } from "react-router-dom";

import Banner from './Banner';
import Paragraph from '../Paragraph';

export default function FunctionalWrapper(props) {
    let params = useParams();
    return <Order {...props} match={{params}} />
}

class Order extends Component {
    constructor(props) {
        super(props);
        let { id } = props.match.params;
        this.state = {
            id, order:null
        }
    }

    async componentDidMount() {
        let { id } = this.state;
        let { data } = await axios.post('/api/order/', { id });
        let d = new Date(data.stamp);
        let dd = d.getDate();
        if (dd < 10) dd = '0' + dd;
        let mm = d.getMonth() + 1;
        if (mm < 10) mm = '0' + mm;
        let yy = d.getFullYear();
        let date = dd + '/' + mm + '/' + yy;
        data.orderedOn = date;
        if (data.fulfilled) {
            d = new Date(data.fulfilled);
            dd = d.getDate();
            if (dd < 10) dd = '0' + dd;
            mm = d.getMonth() + 1;
            if (mm < 10) mm = '0' + mm;
            yy = d.getFullYear();
            date = dd + '/' + mm + '/' + yy;
            data.fulfilled = date;
        }
        this.setState({order:data})
    }

    render() {
        let { order } = this.state;
        return <div className="Store">
            <Banner />
            <div className="content">
            <h1>Receipt</h1>
                {order ? <div className='receipt'>
                    <div className="box left">
                        {order.items.map((item, i) => <div className="item" key={item.title + ' ' + i}>
                            <div className="first">
                                {item.visible&&!item.deleted?<NavLink to={'/item/' + item.title.replace(/ /g, '_')}>
                                    <div className="image" style={{ backgroundImage: 'url(/api/pictures/' + encodeURIComponent(item.path) + ')' }} />
                                </NavLink>:<div className="image" style={{ backgroundImage: 'url(/api/pictures/' + encodeURIComponent(item.path) + ')' }} />}
                            </div>
                            <div className="second">
                                {item.visible && !item.deleted ? <NavLink className="black" to={'/item/' + item.title.replace(/ /g, '_')}>
                                    <h4>{item.title + (item.quantity > 1 ? ' × ' + item.quantity : '')}</h4>
                                </NavLink> :<h4>{item.title + (item.quantity > 1 ? ' × ' + item.quantity : '')}</h4>}
                                <div className="variations">{item.variations.map((v, i) => <div key={i}>{v.title + ': ' + v.selected.title}</div>)}</div>
                                {!item.visible || item.deleted ?<div className="required">This item is no longer available.</div>:null}
                            </div>
                            <div className="third">
                                <h4>£{item.calculatedPrice.toFixed(2)}</h4>
                            </div>
                        </div>)}
                        <div className="alignRight">
                            {order.voucher?<h4>Discount: -£{(order.voucher.discount*(order.total-(order.postage ? order.postage.cost : 0))).toFixed(2)}</h4>:null}
                            {order.postage ? <h4>Postage: £{order.postage.cost.toFixed(2)}</h4> : null}
                            <h3>Total: £{order.total.toFixed(2)}</h3>
                        </div>
                    </div>
                    <div className="box right">
                        <h2>Order details</h2>
                        <h4>Name</h4>
                        <p style={{marginTop:0}}>{order.name}</p>
                        <h4>Address</h4>
                        <Paragraph text={order.address} noGap />
                        <br/>
                        <h4>Message</h4>
                        {order.message.length > 0 ? <div><Paragraph text={order.message} noGap /><br/></div> :
                        <p style={{ marginTop: 0 }}><i>No message.</i></p>}
                        <h4>Ordered on</h4>
                        <p style={{marginTop:0}}>{order.orderedOn}</p>
                        <h4>Posted on</h4>
                        <p style={{marginTop:0}}>{order.fulfilled?order.fulfilled:'Not yet posted.'}</p>
                    </div>
                    
                </div> : <div className="center" style={{width:'100%'}}><div className="spinner"></div></div>}
            </div>
        </div>
    }
}