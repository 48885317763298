import React, { createContext, useEffect, useRef, useState } from 'react';

export let AdminContext = createContext();

export function p() {
    return localStorage.getItem('p');
}

export function useChange(f, d) {
    let firstTime = useRef(true);
    let k = 0;
    useEffect(() => {
        if (!firstTime.current) {
            f();
        } else {
            firstTime.current = false;
        }
    }, d);
}

export function useGetState(v) {
    let [state, setState] = useState(v);
    let loaded = useRef(false);
    let ref = useRef();
    useEffect(() => {
        ref.current = state;
        loaded.current = true;
    }, [state]);
    return [state, setState, ()=>loaded.current?ref.current:v];
}

export function useClickOutside() {
    let { componentDidMount, componentWillUnmount } = useMount();
    let outsideRef = useRef();
    let callback = useRef(() => { });

    componentDidMount(() => document.addEventListener('mousedown', click));
    componentWillUnmount(() => document.removeEventListener('mousedown', click));

    let click = e => {
        if (outsideRef.current && !outsideRef.current.contains(e.target)) callback.current();
    }
    let onClickOutside = f => callback.current = f;

    return [outsideRef, onClickOutside];
}

export function secretItems(items) {
    if (items.length == 3) {
        if (items.find(i => i._id == 'KAbEcFNqT7c0eqsv') && items.find(i => i._id == 'WRiUxQaqpEth9d9t') && items.find(i => i._id == 'ueggCeg1zMHDpL1J')) {
            return true;
        }
    }
}

export function useMount() {
    let f1 = useRef(() => { });
    let f2 = useRef(() => { });

    let componentDidMount = f => f1.current = f;
    let componentWillUnmount = f => f2.current = f;

    useEffect(() => {
        f1.current();
        return () => f2.current();
    },[])

    return {componentDidMount, componentWillUnmount};
}