import React, { useState } from 'react';
import A from './A';
import { NavLink } from "react-router-dom";

export default function AdminTabs({ tab }) {
    return <div className="Tabs AdminTabs">
        {/* <NavLink to={"/admin/schedule"} className={tab == "Schedule" ? "selected tab" : "tab"}>Schedule</NavLink> */}
        <NavLink to={"/admin/selfassessment"} className={tab == "Self assessment" ? "selected tab" : "tab"}>Self assessment</NavLink>
        <NavLink to={"/admin/ambigrams"} className={tab == "Ambigrams" ? "selected tab" : "tab"}>Ambigrams</NavLink>
        <NavLink to={"/admin/invoices"} className={tab == "Invoices" ? "selected tab" : "tab"}>Invoices</NavLink>
        <NavLink to={"/admin/kaitunes"} className={tab == "KaiTunes" ? "selected tab" : "tab"}>KaiTunes</NavLink>
        <NavLink to={"/admin/orders"} className={tab == "Orders" ? "selected tab" : "tab"}>Orders</NavLink>
        <NavLink to={"/admin/items"} className={tab == "Items" ? "selected tab" : "tab"}>Items</NavLink>
        <NavLink to={"/admin/vouchers"} className={tab == "Vouchers" ? "selected tab" : "tab"}>Vouchers</NavLink>
        <NavLink to={"/admin/secret"} className={tab == "Secret" ? "selected tab" : "tab"}>Secret</NavLink>
        {/* <NavLink to={"/admin/reddit"} className={tab == "Reddit" ? "selected tab" : "tab"}>Reddit</NavLink> */}
    </div>
}