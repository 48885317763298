import React, { useEffect, useRef, useState } from "react";

export default function Thumb({ className, style, children, id }) {
    let [width, setWidth] = useState();
    let ref = useRef();
    useEffect(() => {
        function resize() {
            if (ref.current) {
                setWidth(ref.current.getBoundingClientRect().width)
            }
        }
        resize();
        window.addEventListener('resize', resize, true);
        return () => window.removeEventListener('resize', resize, true);
    }, []);
return <div ref={ref} id={id} className={className} style={{...style, height:width}}>
        {children}
    </div>
}