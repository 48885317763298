import React, { Component } from 'react';

import ModalBasket from './ModalBasket';

class Modal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            opacity:0
        }
        this.disappear = this.disappear.bind(this);
    }

    componentDidMount() {
        setTimeout(() => this.setState({ opacity: 1 }), 100);
    }

    disappear() {
        this.setState({ opacity: 0 });
        setTimeout(this.props.hide, 1000 * 0.3);
    }

    render() {
        let { opacity } = this.state;
        return <div className="modal" style={{opacity}}>
            <div className="transparency" onClick={this.disappear}></div>
            <ModalBasket disappear={this.disappear} />
        </div>
    }
}

export default Modal;