import React,{createContext, useEffect, useState} from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';

import Reddit from './c/admin2/Reddit';
import Orders from './c/admin2/Orders';
import Items from './c/admin2/Items';
import Invoices from './c/admin2/Invoices';
import Secret from './c/admin2/Secret';
import SelfAssessment from './c/admin2/SelfAssessment';
import Ambigrams from './c/admin2/Ambigrams';
import Vouchers from './c/admin2/Vouchers';
import Front from './c/main/Front';
import Basket from './c/main/Basket';
import Category from './c/main/Category';
import Item from './c/main/Item';
import Shop from './c/main/Shop';
import Order from './c/main/Order';
import Invoice from './c/main/Invoice';
import Game from './c/main/Game';
import Buy from './c/main/Buy';
import LinkTree from './c/main/LinkTree';
import Ambigram from './c/main/Ambigram';
import Calligraphy from './c/main/Calligraphy';
import GraphicArt from './c/main/GraphicArt';
import TrainTicket from './c/main/TrainTicket';
import { AdminContext } from './hooks';
import axios from 'axios';
import IpTrack from './c/main/IpTrack';
import Music from './c/main/Music';
import MusicAdmin from './c/admin2/MusicAdmin';
import KaiTunes from './c/admin2/KaiTunes';


function App() {
  let [admin, setAdmin] = useState(false);
  let [newOrders, setNewOrders] = useState(0);
  let [backgroundColour, setBackgroundColour] = useState(false);
  useEffect(() => {
    (async () => {
      let p = localStorage.getItem('p');
      if (p) {
        let { data } = await axios.post('/api/neworders', { p });
        if (data.newOrders !== undefined) {
          setAdmin(true);
          setNewOrders(data.newOrders);
        } else {
          localStorage.removeItem('p')
        }
      }
    })()
  }, []);
  useEffect(() => {
    if (!backgroundColour) {
      document.body.style.setProperty('overflow-y', 'scroll');
    } else {
      document.body.style.setProperty('overflow-y', '');
    }
  },[backgroundColour])
  return <AdminContext.Provider value={{ admin, setAdmin, newOrders, setNewOrders, setBackgroundColour }}>
    <div className={`body ${backgroundColour ? 'backgroundColour'+backgroundColour : ''}`}/>
    <BrowserRouter>
      <Routes>
        <Route path="/admin/orders" element={<Orders/>}/>
        <Route path="/admin/items" element={<Items/>}/>
        <Route path="/admin/invoices" element={<Invoices/>}/>
        <Route path="/admin/selfassessment" element={<SelfAssessment/>}/>
        <Route path="/admin/ambigrams/:params" element={<Ambigrams/>}/>
        <Route path="/admin/ambigrams" element={<Ambigrams/>}/>
        <Route path="/admin/kaitunes" element={<KaiTunes/>}/>
        <Route path="/admin/vouchers" element={<Vouchers/>}/>
        <Route path="/admin/secret" element={<Secret/>}/>
        <Route path="/admin/reddit" element={<Reddit/>}/>
        <Route path="/admin/" element={<SelfAssessment/>}/>
        <Route path="/music" element={<Music/>}/>
        <Route path="/basket/" element={<Basket/>}/>
        <Route path="/shop/" element={<Shop/>}/>
        <Route path="/game/" element={<Game/>}/>
        <Route path="/category/:title" element={<Category/>}/>
        <Route path="/railway-club" element={<TrainTicket/>}/>
        <Route path="/item/:title" element={<Item/>}/>
        <Route path="/buy/:item" element={<Buy/>}/>
        <Route path="/buy/" element={<Buy/>}/>
        <Route path="/receipt/:id" element={<Order/>}/>
        <Route path="/invoice/:id" element={<Invoice/>}/>
        <Route path="/hello" element={<LinkTree/>}/>
        <Route path="/ambigram" element={<Ambigram/>}/>
        <Route path="/calligraphy" element={<Calligraphy/>}/>
        <Route path="/portfolio" element={<GraphicArt />} >
          <Route path=":route" element={<GraphicArt />} />
        </Route>
        <Route path="/file" element={<IpTrack />} >
          <Route path=":path" element={<IpTrack />} />
        </Route>
        <Route path="/" exact element={<Front/>}/>
      </Routes>
    </BrowserRouter>
  </AdminContext.Provider>
}

export default App;
