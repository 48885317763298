let EMOJI = ['😂','❤️','🤣','👍','😭','🙏','😘','🥰','😍','😊','🎉','😁','💕','🥺','😅','🔥','🙄','😆','🤗','😉','🎂','🤔','👏','🙂','😳','🥳','😎','👌','💜','😔','💪','✨','💖','👀','😋','😏','😢','👉','💗','😩','💯','🌹','💞','🎈','💙','😃','😡','💐','😜','🙈','🤞','😄','🤤','🙌','🤪','❣️','😀','💋','💀','👇','💔','😌','💓','🤩','🙃','😬','😱','😴','🤭','😐','🌞','😒','😇','🌸','😈','🎶','✌️','🎊','🥵','😞','💚','☀️','🖤','💰','😚','👑','🎁','💥','🙋','☹️','😑','🥴','👈','💩','👺','👹','👻', '☠️', '👽','🎃','🤟', '🤘', '🤙','💪','👄','🧠','👶','🕶','🐶', '🐱', '🐷', '🐔', '🐰', '🦊', '🐻','🍌','🍆','🍎','🍑','🍒'];

function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

function christmas() {
    let d = new Date();
    let month = d.getMonth();
    let date = d.getDate();
    if (month == 10) return true;
    if (month == 11 && date <25) return true;
    return false;
}

let emojiPool = shuffle(EMOJI);
let emoji = () => {
    if (emojiPool.length == 0) emojiPool = shuffle(EMOJI);
    return EMOJI.pop();
}

function parse(string) {
    let b0 = string.indexOf('[');
    let b1 = b0;
    if (b0 < 0) return string;
    let skip = 0;
    for (let i = b0+1; i < string.length; i++) {
        if (string[i] == '[') {
            skip++;
        } else if (string[i] == ']') {
            skip--;
            if (skip < 0) {
                if (skip < -1) throw 'UNBALANCED BRACKETS'
                b1 = i;
                break;
            }
        }
    }
    if (b1 > b0) {
        let mid = string.substring(b0+1,b1);
        let skip = 0;
        for (let i = 0; i < mid.length; i++) {
            if (mid[i] == '[') {
                skip++;
            } else if (mid[i] == ']') {
                skip--;
                if (skip < 0) throw 'UNBALANCED BRACKETS'
            } else if (mid[i] == '|') {
                if (skip == 0) mid = mid.substring(0,i) + '^' + mid.substring(i+1)
            }
        }
        let split = mid.split('^');
        return string.substring(0,b0) + parse(split[Math.floor(Math.random()*split.length)]) + parse(string.substring(b1+1,string.length));
    } else {
        throw 'UNBALANCED BRACKETS'
    }
}


let tags = ['#ambigrams', '#art','#artsandcrafts','#calligraphy','#typography','#lettering','#handwriting','#penandink','#ink','#handlettering','#handmade','#design','#calligrapher','#calligraphyart','#graphicdesign','#illustration','#logo','#tattoo','#tattooart','#tattooideas', '#graphic', '#font', '#satisfying', '#oddlysatisfying', '#satisfyingart','#mindblown','#penmanship']

function addTags(string, tagLimit = 20, characterLimit = 300, essential=[]) {
    let T = tags.slice();
    shuffle(T);
    essential = ['#spindoctor',...essential]
    T = [...T,...essential];
    let t = T.pop();
    let n = 0;
    while (string.length + t.length + 1 < characterLimit && n < tagLimit) {
        string += ' ' + t;
        t = T.pop();
        n++;
    }
    return string;
}

function concatenateWords(words,hashtags){
    return words.map((w,i)=>(i==words.length-1&&words.length>1?'and ' : '')+(hashtags?'#'+w:w)).join(' ')
}

export function generateTikTokCaption(words) {
    let caption = parse(`#Ambigram of ${concatenateWords(words,true)} - [fun|very cool|nice|enjoy]!`);
    caption = addTags(caption,20,150);
    return caption.length > 150?caption.substring(0,150) : caption;
}

export function generateInstagramCaption(words) {
    let options = [
        '[tattoo|ambigram tattoo]',
        '[[gift|birthday gift|wedding gift]|[present|birthday present|wedding present]]',
        '[logo|business logo]'
    ]
    shuffle(options);
    if (christmas()) options.push('[christmas present|christmas gift]')
    let o1 = options.pop();
    let o2 = options.pop();
    let f = words.length == 1 ? '[I did|Here is|] an [ambigram request|ambigram]':'[I did|Here are|] [some|some more|a couple|a couple more|a couple of|a few|a few more|a selection of] [ambigram requests|ambigrams]'
    let lines = [
        `${f} of ${concatenateWords(words)}[!|.|,|]`,
        `[And |][don't forget, |remember, |also, ]if [you want|anyone wants|any of y'all want|someone wants|you guys want] a ${o1} or ${o2} or something[| like that| similar| else], I [do|accept] [commissions|paid work][!|.|,|]`,
        `Link in[| the] bio[!|.|]`,
    ]

    return addTags(lines.map(line=>capitalise(parse(line).trim())).join('\r\n')+`\r\n${emoji()}\r\n${emoji()}\r\n${emoji()}\r\n`,20,2000,[...words.map(w=>'#'+w),'#ambigram']);
}

export function generateYouTubeTitleAndDescription(words) {
    let title = concatenateWords(words) +` ambigram${words.length > 1 ? 's':''}!`;
    let options = [
        '[tattoo|ambigram tattoo]',
        '[[gift|birthday gift|wedding gift]|[present|birthday present|wedding present]]',
        '[logo|business logo]'
    ]
    shuffle(options);
    if (christmas()) options.push('[christmas present|christmas gift]')
    let o1 = options.pop();
    let o2 = options.pop();
    let f = words.length == 1 ? '[I did|Here is|] an [ambigram request|ambigram]':'[I did|Here are|] [some|some more|a couple|a couple more|a couple of|a few|a few more|a selection of] [ambigram requests|ambigrams]'
    let description = [
        addTags(`#ambigram ${words.map(w=>'#'+w).join(' ')}`,10-(2+words.length))+' ' + emoji(),
        `${f} of ${concatenateWords(words)}[!|.|,|]`,
        `[And |][don't forget, |remember, |also, ]if [you want|anyone wants|any of y'all want|someone wants|you guys want] a ${o1} or ${o2} or something[| like that| similar| else], I [do|accept] [commissions|paid work]: https://kaisboatfund.co.uk/ambigram`,
    ].map(line=>capitalise(parse(line).trim())).join('\r\n')+'\r\n'+emoji()+emoji()+emoji();
    return [title, description]
}

export function generateYouTubeShortsTitleAndDescription(words) {
    let title = concatenateWords(words) +` ambigram${words.length > 1 ? 's':''}!`;
    let options = [
        '[tattoo|ambigram tattoo]',
        '[[gift|birthday gift|wedding gift]|[present|birthday present|wedding present]]',
        '[logo|business logo]'
    ]
    shuffle(options);
    if (christmas()) options.push('[christmas present|christmas gift]')
    let o1 = options.pop();
    let o2 = options.pop();
    let f = words.length == 1 ? '[I did|Here is|] an [ambigram request|ambigram]':'[I did|Here are|] [some|some more|a couple|a couple more|a couple of|a few|a few more|a selection of] [ambigram requests|ambigrams]'
    let description = addTags(`#shorts #ambigram ${words.map(w=>'#'+w).join(' ')}`,10-(2+words.length));
    return [title, description]
}

export function generateTikTokCommentReply(name) {
    return parse(`[Hey|Hello|Hi|Yo|Hola][!| -|.|,|| ${emoji()}] I [did|made] [it|the ambigram|that ambigram|your ambigram|that ambigram of ${name}][!| -|.|,|] [have a look at|it's [in|on]|check[ out|]] my [latest|last|most recent|newest] [TikTok|Tok|video|post]! [|${emoji()}]`);
}

export function generateRedditTitle(words) {
    let ambigrams = words.length == 1 ? 'ambigram' : 'ambigrams';
    let joinedWords = concatenateWords(words);
    let sentence = parse(`[${ambigrams} of ${joinedWords}|${joinedWords} ${ambigrams}][!|.|]`);
    return capitalise(sentence);
}

function capitalise(str) {
    return str[0].toUpperCase() + str.substring(1,str.length);
}

export function generateTikTokLinkInBio() {
    let options = [
        '[tattoo|ambigram tattoo]',
        '[[gift|birthday gift|wedding gift]|[present|birthday present|wedding present]]',
        '[logo|business logo]'
    ]
    shuffle(options);
    if (christmas()) options.push('[christmas present|christmas gift]')
    let o1 = options.pop();
    let o2 = options.pop();
    return capitalise(parse(`[And |][don't forget, |remember, |also, ]if [you want|anyone wants|any of y'all want|someone wants|you guys want] a ${o1} or ${o2} or something[| like that| similar| else], I [do|accept] [commissions|paid work][!|.|,| -] link in [the |]bio[!|.|,|]`));
}