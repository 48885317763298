import React, { Component } from 'react';

class Dropdown extends Component {
    constructor(props) {
        super(props);
        let { options, left, right, k } = props;
        if (!left) {
            left = options.map(o => o.title);
            right = options.map(() => '');
        }
        this.state = {
            drop: false, k, zIndex:0,
            options, selected:null, left, right
        }
        this.dropit = this.dropit.bind(this);
        this.undrop = this.undrop.bind(this);
    }

    componentWillReceiveProps(next) {
        let { options, left, right } = next;
        if (!left) {
            left = options.map(o => o.title);
            right = options.map(() => '');
        }
        this.setState({ options, left, right });
    }

    dropit() {
        let { drop, zIndex,k } = this.state;
        if (!zIndex) {
            let body = document.body;
            let html = document.documentElement;
            let height = Math.max( body.scrollHeight, body.offsetHeight, 
                html.clientHeight, html.scrollHeight, html.offsetHeight);
            zIndex = Math.floor(height - document.getElementById('dropdown ' + k).getBoundingClientRect().top);
            this.setState({ zIndex })
        }
        if (!drop) {
            this.setState({ drop: true, v:true });
        } else this.undrop();
    }

    undrop(selected) {
        if (selected) {
            this.setState({ drop: false, selected });
            this.props.callback(selected);
        } else this.setState({ drop: false });
    }

    render() {
        let { options, drop, selected,k, zIndex, left, right } = this.state;
        return options?<div className="dropdown" id={"dropdown "+k}>
            <div className="title" onClick={this.dropit}>
                {selected ? selected.title : 'Please select'}
            </div>
            <div className="arrow" onClick={this.dropit} style={{zIndex:zIndex+1}}>
                <svg x="0px" y="0px" viewBox="0 0 16 16" width={10}>
                    <polygon style={{fill:'white'}} id="XMLID_1_" class="st0" points="15.3,1 7.9,13.8 0.5,1 "/>
                </svg>
            </div>
            <div className="drop" onMouseLeave={()=>this.undrop()} style={{
                maxHeight: drop ? (options.length * 2) + 'rem' : '0px',
                zIndex
            }}>
                {options.map((o,i) => 
                    <div
                        key={o.title}
                        className="option"
                        onClick={() => this.undrop(o)}
                    >
                        <div className="flex">
                            <div className="flex1">{left[i]}</div>
                            <div>{right[i]}</div>
                        </div>
                        
                    </div>
                )}
            </div>
        </div>:null
    }
}

export default Dropdown;